const error = raw => {
	const convert = {
		messages: [],
		errors: {},
	};

	// exit if no raw error
	if (!raw) return convert;

	const data = (raw.data ? { ...raw.data } : {});

	// messages
	if (data.messages) {
		convert.messages = [
			...data.messages,
		];
	}

    // error  
	if (data.error) {
		convert.error = data.error;
	}
	
	//messsage
	if  (data.message) {
		convert.message = data.message;
	}

	// errors (input)
	if (data.errors) {
		for (let i = 0; i < data.errors.length; i++) {
			convert.errors[data.errors[i].field] = [data.errors[i]];
		}
	}

	// status code if any
	if (raw.status) {
		convert.status = raw.status;
	}

	// status code special case
	if (raw.status === 413) {
		convert.messages.push('File too large');
	}

	return convert;
};

export default {
	error,
};
