import { showLoading, hideLoading } from 'react-redux-loading-bar';
import fetcher from 'helpers/fetcher';
import { getLinkedCards } from 'selectors/user';
import { error } from 'actions/toast';
import API from 'services/api';
import { checkErrorAmount } from 'actions/ui';
import { SHOW_UI_ALERT, SET_LINKED_CARDS } from 'constants';


export const performPayment = ({ amount, tokenId }) => async (dispatch, getState) => {
	const state = getState();
	const cardHolderId = state.card.items.find(x => x.id === state.card.current)
		.id;

	dispatch(showLoading());
	return await fetcher.post(`/private/card-info/${cardHolderId}/trans-to-card`, {
		amount,
		tokenId,
		myData: true,
		mobile: false,
	}).then(({ data: { response } }) => {
		if (response && response.urlToCompletePayment) {
			window.location.replace(response.urlToCompletePayment);
		}
	}).catch(err => {
		if (err.status === 400
			&&err.errors 
			&& err.errors.system
			&& err.errors.system[0]
			&& err.errors.system[0].defaultMessage) {
			if (err.errors.system[0].defaultMessage === 'Method not supported') {
				dispatch({
					type: SHOW_UI_ALERT, 
					payload: {
						title: 'Feature is not available',
						text: 'Unfortunately, this feature is not available at the moment due to technical reasons.',
					}
				});
			} else {
				dispatch({
					type: SHOW_UI_ALERT, 
					payload: {
						title: 'feature.is.not.available',
						text: err.errors.system[0].id,
					}
				});
			}
		} else {
			checkErrorAmount(dispatch, err.errors);
		}
	}).finally(() => {
		dispatch(hideLoading());
	});
};

export const fetchLinkedCards = () => async dispatch => {
	try {
		const { data } = await API.user.getLinkedCards();
		dispatch({
			type: SET_LINKED_CARDS,
			payload: data.response,
		});
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
	}
};

export const removeLinkedCard = cardId => async (dispatch, getState) => {
	const cards =  getLinkedCards(getState());

	try {
		dispatch({
			type: SET_LINKED_CARDS,
			payload: cards.filter(card => card.id !== cardId),
		});

		await API.user.deleteLinkedCard(cardId);

		const { data } = await API.user.getLinkedCards();
   
		dispatch({
			type: SET_LINKED_CARDS,
			payload: data.response,
		});
      
	} catch (err) {
		dispatch({
			type: SET_LINKED_CARDS,
			payload: cards,
		});
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
	}
};

