import PropTypes from 'prop-types';
import intlTypes from 'types/intl';


export const TopUpProps = {
	errorToast: PropTypes.func.isRequired,
	successToast: PropTypes.func.isRequired,
	cards: PropTypes.array.isRequired, 
	intl: intlTypes.isRequired,
};

