import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Timer from 'components/Timer';
import Title from 'components/Title';
import Label from 'components/Label';
import * as paymentActions from 'actions/internationalPayment';
import * as countryActions from 'actions/country';
import * as paymentSelector from 'selectors/payment';
import * as userSelector from 'selectors/user';
import validator from 'helpers/validator';
import InternationalPaymentSchema from 'schemas/internationalPayment';
import M from './Phone.locale.json';

import intlTypes from 'types/intl';
import userTypes from 'types/user';
import paymentTypes from 'types/payment';
import { countriesActionsTypes, paymentActionsTypes } from 'types/actions';

const timeoutTime = 15000;

function Phone(props) {
	const [loader, setLoader] = useState(false);
	const [loaderResend, setLoaderResend] = useState(false);
	const [disabledResend, setDisabledResend] = useState(false);
	const [form, setForm] = useState({ code: '' });
	const [errors, setErrors] = useState({ code: [] });


	const onSubmit = async e => {
		e.preventDefault();

		if (loader || loaderResend) return;

		const { actions, payment } = props;
		const errs = validator.all(form, InternationalPaymentSchema.phone);

		if (errs) {
			setErrors(prevErrors => ({ ...prevErrors, ...errs }));
			return;
		}

		setLoader(true);
		const response = await actions.payment.phone(payment.id, form);
		setLoader(false);

		if (response && response.errors) {
			setErrors(prevErrors => ({ ...prevErrors, ...response.errors }));
		} else {
			setForm({ code: '' });
		}
	};

	useEffect(() => {
		const ac = new AbortController();
		if ('OTPCredential' in window) {

			// Invoke the Web OTP API
			navigator.credentials.get({
				otp: { transport: ['sms'] },
				signal: ac.signal,
			}).then(otp => {
				setForm(prevState => ({ ...prevState, code: otp.code }));
				setTimeout(() => {
					onSubmit();
				}, 0);
				ac.abort();
				// Automatically submit the form when an OTP is obtained.
			}).catch(err => {
				console.log(err);
				ac.abort();
			});
		}
		props.actions.country.fetch();
	});

	const onResend = async () => {
		if (loaderResend || disabledResend) return;

		const { actions, payment } = props;
		setLoaderResend(true);
		await actions.payment.code(payment.id);
		setLoaderResend(false);
		setDisabledResend(true);
		setTimeout(() => setDisabledResend(false), timeoutTime);
	};

	const onRefresh = () => {
		const { actions, payment } = props;
		actions.payment.get(payment.id);
	}

	const onChange = (value, name) => {
		const messages = errors[name].length
			? validator.single({ ...form, [name]: value }, name, InternationalPaymentSchema.phone)
			: [];
		setLoader(false);
		setForm(prevForm => ({ ...prevForm, [name]: value }));
		setErrors(prevErrors => ({ ...prevErrors, [name]: messages }));
	};



	const { user, userPhone, intl, hmacEnabled } = props;
	const t = intl.formatMessage;

	return (
		<div>
			<Title title={t(M.title)} className="InternationalPaymentCreateScreen__Title" />
			<Form onSubmit={onSubmit}>
				{!hmacEnabled ? <React.Fragment>
					<Form.Group>
						<Input
							name="phoneCountry"
							placeholder={t(M.inputs.phoneCountry)}
							value={userPhone}
							disabled
						/>
						<Input
							name="phone"
							placeholder={t(M.inputs.phone)}
							value={user.phone}
							disabled
						/>
					</Form.Group>
					<Form.Group>
						<Input
							name="code"
							placeholder={t(M.inputs.code)}
							onChange={onChange}
							value={form.code}
							errors={errors.code}
							otp
						/>
					</Form.Group>
				</React.Fragment>
					:
					<Label>{t(M.hmac)}</Label>
				}

				<Button
					className="InternationalPaymentCreateScreen__Submit"
					onClick={onResend}
					loading={loaderResend}
					disabled={loader || disabledResend}
					type="div"
					form
					small
					secondary
				>
					{disabledResend && <Timer time={15} />}
					{!disabledResend && t(M.buttons.resend)}
				</Button>
				{!hmacEnabled ?
					<Button
						className="InternationalPaymentCreateScreen__Submit"
						loading={loader}
						disabled={loaderResend}
						form
						small
					>
						{t(M.buttons.pay)}
					</Button>
					:
					<Button
						className="InternationalPaymentCreateScreen__Submit"
						loading={loader}
						disabled={loaderResend}
						onClick={onRefresh}
						form
						small
					>
						{t(M.buttons.next)}
					</Button>
				}
			</Form>
		</div>
	);
}

Phone.propTypes = {
	intl: intlTypes,
	actions: PropTypes.shape({
		country: countriesActionsTypes.isRequired,
		payment: paymentActionsTypes.isRequired,
	}).isRequired,
	user: userTypes.isRequired,
	userPhone: PropTypes.string.isRequired,
	payment: paymentTypes,
};

const mapState = (state, props) => {
	const { match: { params: { id } } } = props;
	return {
		payment: paymentSelector.get(state, id),
		user: state.user,
		userPhone: userSelector.getUserPhone(state),
		hmacEnabled: state.user.hmacSecret,
	};
};

const mapDispatch = dispatch => ({
	actions: {
		country: bindActionCreators(countryActions, dispatch),
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

export default compose(
	injectIntl,
	withRouter,
	connect(mapState, mapDispatch)
)(Phone);
