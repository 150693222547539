import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Layout from 'components/Layout';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Section from 'components/Section';
import Title from 'components/Title';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import M from './Success.locale.json';
import '../Create.scss';

import intlTypes from 'types/intl';
import historyTypes from 'types/history';
import { GTMEvents } from "../../../constants/gtm";

@withRouter
@injectIntl
class Success extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		history: historyTypes.isRequired,
	};

	componentDidMount () {
		gtag('event', GTMEvents.secondaryOrdered.eventName, { ...GTMEvents.secondaryOrdered.eventParameters});
	}

	goBack = () => {
		const { history } = this.props;
		history.push('/cards');
	};

	render () {
		const { intl, history } = this.props;
		const t = intl.formatMessage;

		if (!history.location || !history.location.state) {
			history.push('/');
			return null;
		}

		const { deliveryDate, isVirtual } = history.location.state;

		return (
			<Layout className="PaymentCreateScreen Screen" sidebar>
				<Nav />
				<Header />
				<Section>
					<Container medium>
						<Wrap>
							<div className="ClientCreatePrivateScreen__Icon ClientCreatePrivateScreen__Icon--Success">
								<Icon name="success" />
							</div>
							{!isVirtual && (
								<React.Fragment>
									<Title title={t(M.title)} />
									<p>{t(M.messages.success)}</p>
									<p>{t(M.messages.deliverydate)} <b>{deliveryDate}</b></p>
									<p>{t(M.messages.note)}</p>
								</React.Fragment>
							)}
							{isVirtual && (
								<React.Fragment>
									<Title title={t({ id: 'card.virtual.create.success.title' })} />
									<p>{t({ id: 'card.virtual.create.success.text' })}</p>
								</React.Fragment>
							)}
							<Button
								className="PaymentCreateScreen__Back"
								form
								small
								ghost
								onClick={this.goBack}
							>
								{t(M.buttons.returnToCards)}
							</Button>
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}

export default Success;
