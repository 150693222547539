export const card = {
	CARD01: 'CARD_01',
	CARD02: 'CARD_02',
	CARD03: 'CARD_03',
	CARD04: 'CARD_04',
	CARD05: 'CARD_05',
	CARD06: 'CARD_06',
	CARD07: 'CARD_07',
	CARD08: 'CARD_08',
	CARD14: 'CARD_14', //SMALL
	CARD15: 'CARD_15', //ANONYMOUS
	CARD17: 'CARD_17',
	CARD18: 'CARD_18',
};

export const cards = [
	card.CARD01,
	card.CARD02,
	card.CARD03,
	card.CARD04,
	card.CARD05,
	card.CARD06,
	card.CARD07,
	card.CARD08,
	card.CARD14
];

export const CARD_TO_CARD_LIMITATION = [
	card.CARD04,
	card.CARD05,
	card.CARD06,
	card.CARD07,
	card.CARD08,
	card.CARD14,
	card.CARD17,
	card.CARD18,
];

export const CARD_PRIVATE = [
	card.CARD04,
	card.CARD07,
];

export const CARD_TIANDE = [
	card.CARD03,
];

export const CARD_SMALLC = [
	card.CARD14,
];

export const CARD_ANONYMOUS = [
	card.CARD15,
];


export const deliveryTypes = {
	VIRTUAL: 'VC',
	PLASTIC: 'PC'
};

export const deliveryTypeOptions = [
	{
		title: 'Virtual Card',
		value: deliveryTypes.VIRTUAL
	},
	{
		title: 'Plastic Card',
		value: deliveryTypes.PLASTIC
	}
];

export const IS_VIRTUAL_CARD_MAINTENANCE_MODE_ACTIVE = false;

export const cardStatus = {
	OPEN: 'OPEN',
	ISSUED: 'ISSUED',
	DEPOSIT_ONLY: 'DEPOSIT_ONLY',
	DONE: 'DONE',
	LOST: 'LOST',
	STOLEN: 'STOLEN',
	BLOCKED: 'BLOCKED',
	PIN_CHANGE_REQUIRED: 'PIN_CHANGE_REQUIRED',
	PHONE_NUMBER_VERIFICATION: 'PHONE_NUMBER_VERIFICATION',
	CLOSED: 'CLOSED',
	VERIFIED: 'VERIFIED',
	UNVERIFIED: 'UNVERIFIED',
	EXPIRED: 'E',
};

export const cardStatuses = [
	cardStatus.OPEN,
	cardStatus.ISSUED,
	cardStatus.DEPOSIT_ONLY,
	cardStatus.DONE,
	cardStatus.LOST,
	cardStatus.STOLEN,
	cardStatus.BLOCKED,
	cardStatus.PIN_CHANGE_REQUIRED,
	cardStatus.PHONE_NUMBER_VERIFICATION,
	cardStatus.CLOSED,
	cardStatus.EXPIRED,
];

export const wallet = {
	WALLET01: 'WALLET_01',
	WALLET02: 'WALLET_02',
};

export const paymentStatus = {
	DRAFT: 'DRAFT',
	SIGNING: 'SIGNING',
	DECLINED: 'DECLINED',
	COMPLETED: 'COMPLETED',
	SUCCESSFUL: 'SUCCESSFUL',
	PROCESSING: 'PROCESSING',
};

export const paymentStatuses = [
	paymentStatus.DRAFT,
	paymentStatus.SIGNING,
	paymentStatus.DECLINED,
	paymentStatus.COMPLETED,
	paymentStatus.SUCCESSFUL,
	paymentStatus.PROCESSING,
];

export const memberStatus = {
	ACTIVE: 'ACTIVE',
	REQUESTED: 'REQUESTED',
};

export const memberAccess = {
	OWNER: 'OWNER',
	BOOKKEEPER: 'BOOKKEEPER',
};

export const memberRequestStatus = {
	GRANTED: 'GRANTED',
};

export const transaction = {
	SUCCESSFUL: 'SUCCESSFUL',
	DECLINED: 'DECLINED',
};

export const transactions = [
	transaction.SUCCESSFUL,
	transaction.DECLINED,
];

export const transactionType = {
	IN: 'IN',
	OUT: 'OUT',
};

export const transactionTypes = [
	transactionType.IN,
	transactionType.OUT,
];

export const transactionSubType = {
	SEPA_OUTGOING_FEE: 'SEPA_OUTGOING_FEE',
	POS_DOMESTIC: 'POS_DOMESTIC',
	POS_INTERNATIONAL: 'POS_INTERNATIONAL',
	ATM_DOMESTIC: 'ATM_DOMESTIC',
};

export const transactionSubTypes = [
	transactionSubType.SEPA_OUTGOING_FEE,
	transactionSubType.POS_DOMESTIC,
	transactionSubType.POS_INTERNATIONAL,
	transactionSubType.ATM_DOMESTIC,
];

export const SEPA_SUB_TYPES_LIST =  [
	transactionSubType.POS_DOMESTIC,
	transactionSubType.POS_INTERNATIONAL,
	transactionSubType.ATM_DOMESTIC,
];

export const AUTHNUM = 'AuthNum';

export const userStatus = {
	FRAUDULENT: 'FRAUDULENT',
	ACTIVE: 'ACTIVE',
};

export const userStatuses = [
	userStatus.FRAUDULENT,
	userStatus.ACTIVE,
];

export const migratedStatus = {
	COMPLETED: 'COMPLETED',
};

export const clientStep = {
	DOCUMENTS: 'documents',
	BASIC: 'basic',
	LOCATION: 'location',
	CONTACT: 'contact',
	EMPTY: 'EMPTY',
	UNSAVED: 'UNSAVED',
	COMPLETE: 'COMPLETE',
};

export const clientRole = {
	BUSINESS: 'BUSINESS',
	PRIVATE: 'PRIVATE',
	OWNER: 'OWNER',
};

export const clientRoles = [
	clientRole.BUSINESS,
	clientRole.PRIVATE,
];

export const clientRoleType = {
	PRIVATE: 'PRIVATE',
};

export const clientRoleTypes = [
	clientRoleType.PRIVATE,
];

export const clientStatus = {
	INITIATED: 'INITIATED',
	ACTIVE: 'ACTIVE',
	COMPLETE: 'COMPLETE',
	UNSAVED: 'UNSAVED',
};

export const CLIENT_TYPE = {
	COMMON: 'COMMON',
	PRIVATE: 'PRIVATE',
	TIANDE: 'TIANDE',
	SMALLC: 'SMALLC'
};

export const clientStatuses = [
	clientStatus.INITIATED,
	clientStatus.ACTIVE,
	clientStatus.UNSAVED,
	clientStatus.COMPLETE,
];

export const toastType = {
	SUCCESS: 'success',
	ERROR: 'error',
};

export const UTMParams = 'utm-params';

export const CUSTOMER_SUPPORT = 'cardservices@weststeincard.com';
