import React from 'react';
import Container from 'components/Container';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { CurrenciesListProps } from '../types';
import Item from '../Item';
import M from '../../Exchange.locale.json';

const List = ({ currencies, onCurrency, intl: { formatMessage: t } }) => {
	const history = useHistory();
	
	const onTopUp = () => {
		history.push('/exchange/topup');
	};
	return (
		<React.Fragment>
			<Button
				className="Header__Button"
				id="ExchangeTopUp"
				onClick={onTopUp}
			>
				{t(M.topUp.title)}
			</Button>
			<Container>
				<div className="ExchangeList">
					{currencies &&
						currencies.map((currency, i) => (
							<Item
								key={currency.currencyCode}
								currency={currency}
								index={i}
								onClick={onCurrency}
							/>
						))}
				</div>
			</Container>
		</React.Fragment>
	);
};

List.propTypes = CurrenciesListProps;

export default injectIntl(List);
