import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import intlTypes from 'types/intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import WarningModal from './WarningModal';
import './QuestionModalApp.scss';
import Input from '../../../components/Input';
import speakeasy from './speakeasy';
import base32 from './base32';
import crypto from 'crypto-js';

class QuestionModalApp extends Component {
	constructor (props) {
		super(props);

		if (!props.user.twoFactorSecret) {
			const token = crypto.lib.WordArray.random(5).toString().toUpperCase();
			//console.log('24it token', token);
			props.user.twoFactorSecret = token;
		}

		const identifier = `WestStein (${this.props.user.email})`;
		let token = speakeasy.totp({ secret: props.user.twoFactorSecret, encoding: 'ascii' });

		let keyString = base32.encode(Buffer(props.user.twoFactorSecret)).toString();
		let provisionUrl =  encodeURIComponent(`otpauth://totp/${identifier}?secret=${keyString}`);
		let chartUrl = `https://chart.apis.google.com/chart?cht=qr&chs=300x300&chl=${provisionUrl}`;
		this.state = {
			chartUrl,
			code: '',
			error: ''
		}
	}

	establish() {
		const valid = speakeasy.totp.verify({window:1,token: this.state.code,secret: this.props.user.twoFactorSecret, encoding: 'ascii'});
		if (valid) this.props.onSuccess();
		else this.setState({error: 'Incorrect confirmation code'});
	}

	render() {
		let intl = this.props.intl;
		const t = intl.formatMessage;

		return (
			<Modal>
				<Section>
					<Container medium>
						<Wrap className='SingleWarning__wrap'>
								<React.Fragment >
									<Title
										title=''
										subtitle={`${t({ id: 'screens.setting.setting.two.factor.text.scan' })}`}
										small
										className='SingleWarning-text'
									/>
									<div style={{textAlign:'center'}}>
										<img src={this.state.chartUrl} />
										<div>({t({ id: 'screens.setting.setting.two.factor.text.manual' })} {base32.encode(Buffer(this.props.user.twoFactorSecret)).toString()})</div>

										<Input
											name='code'
											placeholder={t({ id: 'screens.setting.setting.two.factor.text.confirmationcode' })}
											onChange={(e)=>{ this.setState({code:e}); }}
											value={this.state.code}
											errors={[]}
										/>
										{!!this.state.error && <div style={{color:'red'}}>{this.state.error}</div>}
									</div>
									<div className='SingleWarning-controls'>

										<Button
											onClick={()=>{ this.establish(); }}
											small
											className='SingleWarning-button'
											loading={false}
										>
											<FormattedMessage id='screens.setting.setting.two.factor.text.establish' />
										</Button>

										<Button
											onClick={()=>{ this.props.onCancel(); }}
											small
											className='SingleWarning-button'
											loading={false}
										>
											<FormattedMessage id='screens.setting.setting.two.factor.text.cancel' />
										</Button>
									</div>
								</React.Fragment>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}
QuestionModalApp.propTypes = {
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
	user: PropTypes.any,
	//isLoading: PropTypes.bool,
	intl: intlTypes.isRequired
};


//export default injectIntl(QuestionModalApp);
export default QuestionModalApp;
