import React, { useState } from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Nav from 'sections/Nav';
import * as clientSelector from 'selectors/client';
import './ManualVerification.scss';
import AccountReview from './AccountReview';
import AccountReviewDecline from './AccountReviewDecline';
import AccountReviewSuccess from './AccountReviewSuccess';
import {
	ACCOUNT_REVIEW,
	ACCOUNT_REVIEW_DECLINE,
	ACCOUNT_REVIEW_SUCCESS
} from 'constants';

const ManualVerification = ( {user:{status}, client} ) => {
    return (
        <Layout >
            <Nav logo />
            <Section className='manualVerification'>
                <Container medium>
                      {status === ACCOUNT_REVIEW && <AccountReview />}

                      {status === ACCOUNT_REVIEW_DECLINE && <AccountReviewDecline />}

                      {status === ACCOUNT_REVIEW_SUCCESS &&  <AccountReviewSuccess />}
                </Container>
            </Section>
        </Layout>
    );
}

const mapState = state => ({
    user: state.user,
    client: clientSelector.getCurrentClient(state),
});

export default connect(mapState,{})(ManualVerification)
