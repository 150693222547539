import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import Icon from 'components/Icon';

import intlTypes from 'types/intl';

import { CUSTOMER_SUPPORT } from 'constants/common';
import { REISSUE_MODAL_STATE } from '../../constants';

import '../ReissueModals.scss';

const SuccessModal = ({ intl, setReissueState }) => {
	const t = intl.formatMessage;

	const onSkip = () => setReissueState(REISSUE_MODAL_STATE.CLOSE);

	return(

		<Modal>
			<Section>
				<Container medium>
					<Wrap className="ReissueModal__strict-padding">
						<Icon className="ReissueModal-close" onClick={onSkip} name="close-small" />
						<Title
							title={t({ id: 'modals.renew.address.title' })}
							small
							subtitle={CUSTOMER_SUPPORT}
						/>
						<Button onClick={onSkip} small className="ReissueModal-button">
							<FormattedMessage id="card.renew.button.close" />
						</Button>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
}
;

SuccessModal.propTypes = {
	intl: intlTypes,
	setReissueState: PropTypes.func.isRequired,
};



export default injectIntl(SuccessModal);
