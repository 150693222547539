import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as clientSelector from 'selectors/client';
import * as cardSelector from 'selectors/card';
import { getIsUserVerified } from 'selectors/user';
import { getMaintenanceModalStatus } from 'selectors/modals';
import * as toastActions from '../../actions/toast';

import * as cardActions from 'actions/card';
import { isCardExpiated } from 'services/card';

import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';
import Card from 'components/Card';
import Wrap from 'components/Wrap';
import InputFilter from 'components/InputFilter';
import InputSearch from 'components/InputSearch';
import Modal from 'components/Modal';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { cardActionsTypes } from 'types/actions';
import currentCardTypes from 'types/currentCard';
import historyTypes from 'types/history';
import intlTypes from 'types/intl';
import { getDeliveryDate } from './utils';

import VerifyCardModal from '../Dashboard/VerifyCardModal';
import MaintenanceModal from './modals/MaintenanceModal';
import M from './Card.locale.json';

import './Card.scss';
import Tooltip from '../../components/Tooltip';

const STATUS = [
	{
		value: 'ISSUED',
		title: M.status.issued,
	},
	{
		value: 'DEPOSIT_ONLY',
		title: M.status.deposit,
	},
	{
		value: 'DONE',
		title: M.status.done,
	},
	{
		value: 'OPEN',
		title: M.status.open,
	},
	{
		value: 'LOST',
		title: M.status.lost,
	},
	{
		value: 'STOLEN',
		title: M.status.stolen,
	},
	{
		value: 'BLOCKED',
		title: M.status.blocked,
	},
	{
		value: 'PIN_CHANGE_REQUIRED',
		title: M.status.pin,
	},
	{
		value: 'PHONE_NUMBER_VERIFICATION',
		title: M.status.phone,
	},
	{
		value: 'CLOSED',
		title: M.status.closed,
	}
];

const CardUpdateModal = ({ onOkClick, content, isLoading, t }) => (
  <Modal className="DashboardSection__VerifyCardModal">
	  <Section>
		  {isLoading ? (
			<Modal.Loader />
		  ) : (
			<Container medium>
				<Wrap>
					<Title
					  title={t({ id: 'modals.request.card.title' })}
					  small
					/>
					<p className="DashboardSection__VerifyCardModal__Info cardUpdateSuccessContent">
						{content || t({ id: 'modals.request.card.text' })}
					</p>
					<p className="DashboardSection__VerifyCardModal__Info cardUpdateSuccessContent">
						{content || t({ id: 'modals.request.card.date' }, { date: getDeliveryDate() })}
					</p>
					<Button
					  className="DashboardSection__VerifyCardModal__Button"
					  onClick={onOkClick}
					  small
					>
						Ok
					</Button>
				</Wrap>
			</Container>
		  )}
	  </Section>
  </Modal>
);

CardUpdateModal.propTypes = {
	onOkClick: PropTypes.func,
	content: PropTypes.string,
	isLoading: PropTypes.bool
};

const documentUrl = '/settings/document';
const UPDATE_CARD_SUCCESS_TEXT = 'Your card has been successfully updated!';

const mapState = (state, props) => ({
	client: {
		id: clientSelector.getEntityClientId(state),
	},
	isUserVerified: getIsUserVerified(state),
	cards: cardSelector.fetch(state),
	search: cardSelector.getCardSearch(state),
	status: cardSelector.getCardStatus(state),
	statusNav: cardSelector.statusNav(props, STATUS),
	maintenanceModalStatus: getMaintenanceModalStatus(state),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	static propTypes = {
		client: PropTypes.shape({
			id: PropTypes.number,
		}).isRequired,
		isUserVerified: PropTypes.bool,
		search: PropTypes.string,
		status: PropTypes.string,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired,
		}).isRequired,
		cards: PropTypes.arrayOf(currentCardTypes),
		history: historyTypes.isRequired,
		intl: intlTypes.isRequired,
		statusNav: PropTypes.any
	}

	state = {
		isVerificationModalVisible: false,
		isUpdateToPhysicalModalVisible: false,
		isUpdatingCard: false
	};

	componentDidMount () {
		this.mounted = true;
		this.onFetch();
	}

	componentWillUnmount() {
		this.mounted = true;
	}

	componentDidUpdate (prevProps, nextProps) {
		const { client } = this.props;

		const idEqual = (prevProps.client.id === client.id);

		if (idEqual) {
			return;
		}

		this.onFetch(!idEqual);
	}

	onFetch = async shouldUpdate => {
		const { actions } = this.props;

		if (this.mounted) {
			actions.card.fetch(shouldUpdate);
		}
	};

	onFilter = options => {
		const { actions } = this.props;

		actions.card.status(options);
	};

	onSearch = keyword => {
		const { actions } = this.props;

		actions.card.search(keyword);
	};

	onEdit = (item, activate) => {
		const { history } = this.props;

		let pathname = `/cards/${item.id}`;

		if (activate) {
			pathname += '/activate';
		} else if (isCardExpiated(item)) {
			pathname += '/reissue';
		}

		history.push(pathname, { modal: true });
	};

	onCreate = () => {
		const { history } = this.props;

		history.push('/cards/create', { modal: false });
	};

	onVerificationSkip = () => this.setState({ isVerificationModalVisible: false });

	onCloseUpdateToVirtualModal = () => this.setState({ isUpdateToPhysicalModalVisible: false });

	onVerifyCard = () => this.props.history.push(documentUrl);

	mounted = false;

	render () {
		const { cards, search, status, statusNav, maintenanceModalStatus } = this.props;
		const {
			isVerificationModalVisible,
			isUpdateToPhysicalModalVisible,
			isUpdatingCard
		} = this.state;
		const t = this.props.intl.formatMessage;

		const content = cards.map(card => {
			return (
				<Card
					key={card.id}
					card={card}
					actions={[]}
					onClick={() => this.onEdit(card)}
					trunk
					preview
				/>
			);
		});

		const addCard = cards.filter(card => card.canAddSecondaryCard).map(item => {
			const actions = [{
				title: t(M.buttons.edit),
				onClick: () => this.onEdit(item),
			}, {
				title: t(M.buttons.activate),
				onClick: () => this.onEdit(item, true),
			}];

			if (item.cardStatus !== 'ISSUED' && item.cardStatus !== 'DEPOSIT_ONLY') {
				actions.pop();
			}

			return (
				<Card.Create
					key={item.id}
					// onClick={this.onCreate}
					onClick={() => this.props.actions.toast.create({ title: { id: 'hints.unavailable' }, type: 'error' })}
				>
					{t(M.buttons.create)}
				</Card.Create>
			);
		});

		return (
			<React.Fragment>
				{isVerificationModalVisible && !this.props.isUserVerified && (
					<VerifyCardModal
						t={t}
						subtitle=''
						onSkip={this.onVerificationSkip}
						onVerify={this.onVerifyCard}
					/>
				)}

				{maintenanceModalStatus && <MaintenanceModal />}

				{isUpdateToPhysicalModalVisible && (
					<CardUpdateModal
						t={t}
						isLoading={isUpdatingCard}
						onOkClick={this.onCloseUpdateToVirtualModal}
					/>
				)}

				<Section className='CardSection'>
					<Container>
						<Title
							title={`${t(M.title)} *`}
						/>

						<div className="cardViewHint">
							* {t({ id: 'card.view.hint' })}
						</div>

						<div
							className='CardSection__Actions'
						>
							<InputFilter
								name='status'
								value={status.split('')}
								data={statusNav}
								onChange={this.onFilter}
							>
								{t(M.buttons.status)}
							</InputFilter>

							<InputSearch
								name='search'
								value={search}
								placeholder={t(M.inputs.filter)}
								onChange={this.onSearch}
							/>
						</div>
						<Wrap>
							{addCard.length > 0 ? addCard[0] : addCard}
							{content}
						</Wrap>
					</Container>
				</Section>
			</React.Fragment>
		);
	}
}
