import React from 'react';

const ArrowLeftIcon = props => {
    return (
        <svg
            height="36px"
            width="36px"
            {...props}
            enableBackground="new 0 0 32 32"
            version="1.1" viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path clipRule="evenodd" d="M11.262,16.714l9.002,8.999  c0.395,0.394,1.035,0.394,1.431,0c0.395-0.394,0.395-1.034,0-1.428L13.407,16l8.287-8.285c0.395-0.394,0.395-1.034,0-1.429  c-0.395-0.394-1.036-0.394-1.431,0l-9.002,8.999C10.872,15.675,10.872,16.325,11.262,16.714z" fill="#121313" fillRule="evenodd" id="Chevron_Right" />
        </svg>
    );
};

export default ArrowLeftIcon;