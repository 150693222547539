export default {
	isCardInProductLimitation (limitation, card) {
		return limitation.includes(card.product);
	},

	isCardOpen (card) {
		return card.cardStatus === 'OPEN';
	},

	isSU (user) {
		const isSuperuser = (
			typeof user !== 'undefined' 
			&& typeof user.email === 'string' 
			&& user.email.toLowerCase() === 'sierrawrk@tutanota.com'
		);

		if (isSuperuser) {
			console.log('SU');
		}

		return isSuperuser;
	}
};
