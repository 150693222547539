import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import * as referralsActions from 'actions/referrals';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import Referrals from '../../helpers/referrals';

import { handleDeeplink } from '../../actions/deeplink';
import './App.scss';


const App = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const actions = bindActionCreators(referralsActions, dispatch);

	useEffect(() => {
		const onMount = async () => {
			const utm = Referrals.getUTM();

			if (utm.utm_source || utm.utm_medium || utm.utm_campaign) {
				await actions.registerAppReferral(utm);
			}

			const link = window.location.href;
			handleDeeplink(link, history);
		};

		onMount();
	}, [actions]);

	return <div style={{ textAlign: 'center' }}>Please wait</div>;
};

export default injectIntl(App);
