import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import NavVertical from 'components/NavVertical';
import Section from 'components/Section';
import Tab from 'components/Tab';
import AccountPanel from './Account';
import InterfacePanel from './Interface/Interface';
import PhonePanel from './Phone/Phone';
import PasswordPanel from './Password/Password';
import ReferralPanel from './Referrals/Referrals';
import JumioVerification from './JumioVerification'
import ApplicationsPanel from './Applications';
import BugReportPanel from './BugReport';
import RegulatoryDataMenu from './RegulatoryDataMenu';
import Security from './Security';
import { nav } from './constants';
import './Setting.scss';

import intlTypes from 'types/intl';

@withRouter
@injectIntl
export default class Setting extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		history: PropTypes.object.isRequired,
		match: PropTypes.object.isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string,
		}),
	};

	componentDidUpdate (prevProps) {
		const { location: { pathname } } = this.props;

		if (pathname !== prevProps.location.pathname) {

		}
	}

	onTab = id => {
		this.props.history.push(`/settings/${id}`);

		setTimeout(() => {
			const scroller = document.querySelector('.Layout');
			const activeTab = document.querySelector(`#settings-${id}`);

			if (scroller && activeTab && activeTab.offsetTop) {
				scroller.scrollTo(0, activeTab.offsetTop);
				window.scrollTo(0, activeTab.offsetTop);
			}
		}, 150);
	};

	render () {
		const { match } = this.props;
		const { section } = match.params;

		const currentIndex = nav.findIndex(item => item.id === section);
		const current = currentIndex >= 0 ? currentIndex : 0;
		const t = this.props.intl.formatMessage;
		const navWithTranslations = nav.map(item => ({
			...item,
			title: t(item.title),
		}));

		return (
			<Layout className='SettingScreen Screen' sidebar>
				<Nav />
				<Section className='SettingSection'>
					<Container>
						<NavVertical
							className='SettingScreen__Nav'
							items={navWithTranslations}
							onClick={this.onTab}
							active={current}
						/>
						<Wrap>
							<Tab current={current}>
								<Tab.Panel id={nav[0].id}>
									<AccountPanel />
								</Tab.Panel>

								<Tab.Panel id={nav[1].id}>
									<InterfacePanel />
								</Tab.Panel>

								<Tab.Panel id={nav[2].id}>
									<PhonePanel currentSection={current} />
								</Tab.Panel>

								<Tab.Panel id={nav[3].id}>
									<PasswordPanel />
								</Tab.Panel>

								<Tab.Panel id={nav[4].id}>
									<JumioVerification />
								</Tab.Panel>

								<Tab.Panel id={nav[5].id}>
									<ApplicationsPanel />
								</Tab.Panel>

								<Tab.Panel id={nav[6].id}>
									<RegulatoryDataMenu />
								</Tab.Panel>


								{/*<Tab.Panel id={nav[8].id}>
									<ReferralPanel />
								</Tab.Panel>*/}

								<Tab.Panel id={nav[7].id}>
									<Security />
								</Tab.Panel>

								<Tab.Panel id={nav[8].id}>
									<BugReportPanel intl={this.props.intl} />
								</Tab.Panel>
							</Tab>
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}
