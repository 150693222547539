import {
	SET_MODALS_INFO,
	CHANGE_MODAL_INFO,
	SHOW_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE,
	HIDE_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE,
} from 'constants';

const initialState = {
	modals: [],
	isInternationalPaymentModalVisible: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_MODALS_INFO:
			return { ...state, modals: action.payload };
		case CHANGE_MODAL_INFO:
			const updateModals = state.modals.map((modal, index) => {
				if (modal.windowType === action.payload.windowType) {
					modal.show = false;
				}
				return modal;
			});

			return {
				...state,
				modals: updateModals
			};
		case SHOW_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE:
			return {
				...state,
				isInternationalPaymentModalVisible: true,
			};
		case HIDE_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE:
			return {
				...state,
				isInternationalPaymentModalVisible: false,
			};
		default:
			return {
				...state,
				isInternationalPaymentModalVisible: typeof state.isInternationalPaymentModalVisible === 'undefined' ? false : state.isInternationalPaymentModalVisible,
			};
	}
};
