import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';

import intlTypes from 'types/intl';
import M from '../../NotificationPopup.locale.json';

import './SmartWarning.scss';

const SmartWarning = ({ intl, onCancel, onConfirm }) => {
	const t = intl.formatMessage;
	return(

		<Modal>
			<Section>
				<Container medium>
					<Wrap>
						<Title
							title={t(M.warning.title)}
							small
						/>
						<p className='SmartWarning-text'>
							{t(M.warning.text)}
						</p>
						<div className='SmartWarning-controls'>
							<Button onClick={onConfirm} small className='SmartWarning-button'>
								<FormattedMessage id='card.renew.button.yes' />
							</Button>
							<Button onClick={onCancel} small className='SmartWarning-button'>
								<FormattedMessage id='card.renew.button.no' />
							</Button>
						</div>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
}
;

SmartWarning.propTypes = {
	intl: intlTypes,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
};

export default injectIntl(SmartWarning);
