import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import Badge from 'components/Badge';
import { formatDateV2 } from 'helpers/formatter';
import * as exchangeActions from 'actions/exchange';
import DetailsModal from './DetailsModal';
import { DetailsProps } from './types';
import M from '../Exchange.locale.json';

const TRANSACTION_AMOUNT = 5;

const TransactionsList = ({ currency, intl }) => {
	const [transactions, setTransactions] = useState([]);
	const [isDetailsOpen, setDetailsOpen] = useState(false);
	const [currentTransaction, setCurrentTransaction] = useState(null);
	const [hasMoreTransactions, setHasMoreTransactions] = useState(true);

	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);

	const t = intl.formatMessage;

	const columns = ['amount', 'status', 'time', 'actions'];
	const columnsNav = [
		{ id: 'time', title: 'time' },
		{ id: 'amount', title: 'amount' },
		{ id: 'status', title: 'status' },
		{ id: 'id', title: 'id' }
	];

	const onPage = async page => {
		setCurrentPage(page);
		await getAllTransactions(page * TRANSACTION_AMOUNT);
	};

	const getStatusLocalization = status => {
		switch (status) {
			case 'CONFIRMED':
				return t(M.transactions.status.confirmed);
			case 'REJECTED':
				return t(M.transactions.status.rejected);
			case 'WAITING':
				return t(M.transactions.status.waiting);
			default:
				return status;
		}
	};

	const getAllTransactions = async currentOffset => {
		const {
			success,
			data,
			hasMore,
			totalCount
		} = await exchangeActions.getTransactions({
			currencyCodeWallet: currency.currencyCode,
			take: TRANSACTION_AMOUNT,
			offset: currentOffset
		});
		setTotalPages(Math.trunc(totalCount / TRANSACTION_AMOUNT) + 1);
		if (success) {
			setTransactions([...data]);
			setHasMoreTransactions(hasMore);
		}
	};

	const onClose = () => {
		setDetailsOpen(false);
	};

	const onTransactionDetails = async transaction => {
		setCurrentTransaction(transaction);
		setDetailsOpen(true);
	};

	const renderHeader = () => {
		return columnsNav
			.filter(item => columns.indexOf(item.id) > -1)
			.map(item => ({
				...item,
				title: t(M.transactions.details[item.id])
			}));
	};

	const renderCell = (id, content) => {
		if (id === 'time') return formatDateV2(content);
		if (id === 'status')
			return (
				<Badge
					success={content === 'CONFIRMED'}
					error={content === 'REJECTED'}
					warning={content === 'WAITING'}
				>
					{getStatusLocalization(content)}
				</Badge>
			);
		if (id === 'amount') {
			if (content < 0)
				return <span style={{ color: 'red' }}>{content}</span>;
			return <span style={{ color: 'green' }}>{content}</span>;
		}

		return content;
	};

	const renderRow = row => {
		return (
			<Table.Row
				key={row.id}
				onClick={() => {
					onTransactionDetails(row);
				}}
			>
				{columnsNav
					.filter(item => columns.indexOf(item.id) > -1)
					.map(item => {
						return (
							<Table.Cell key={item.id}>
								{renderCell(item.id, row[item.id])}
							</Table.Cell>
						);
					})}
			</Table.Row>
		);
	};

	useEffect(() => {
		getAllTransactions(0);
	}, []);

	return (
		<div className="ExchangeDetails__Transactions">
			<Table
				items={transactions}
				renderHeader={renderHeader}
				renderRow={renderRow}
				className="Templates__Table"
				loading={false}
			/>
			{/* <Button onClick={loadMore} >Loadmore</Button> */}
			{transactions.length > 0 && (
				<Pagination
					total={totalPages}
					current={currentPage}
					onChange={onPage}
				/>
			)}
			<DetailsModal
				isOpen={isDetailsOpen}
				onClose={onClose}
				transaction={currentTransaction}
			/>
		</div>
	);
};

TransactionsList.propTypes = DetailsProps;

export default injectIntl(TransactionsList);
