import { createSelector } from 'reselect';

const getCountryList = state => state.country;
const translations = (state, props) => props.intl.formatMessage;
const getUserAddress = (state) => state.user.address;

export const getCountryListWithTranslations = createSelector(
	getCountryList,
	translations,
	(countryList, t) => countryList.reduce((array, country) => {
		const title = t({ id: `countries.${country.slug}`, defaultMessage: country.name });
		array.push({
			title: typeof title === 'string' ? title : country.name,
			code: country.iso2,
			value: country.id,
		});
		return array;
	}, [])
);

export const getEnrollCountries = createSelector(
	getCountryList,
	translations,
	(countryList, t) => {
		return countryList.reduce((array, country) => {
			if (!country.enroll) return array;
			const title = t({ id: `countries.${country.slug}`, defaultMessage: country.name });
			array.push({ title, code: country.iso2, value: country.id });
			return array;
		}, []);
	}
);

export const getPhoneCodesCountries = createSelector(
	getCountryList,
	translations,
	(countryList, t) =>
		countryList.filter(country => country.enroll).map(country => {
			const subtitle = t({ id: `countries.${country.slug}`, defaultMessage: country.name });
			return {
				title: `+${country.phoneCode}`,
				subtitle,
				code: country.iso2,
				value: country.id,
			};
		})
);

export const getAllPhoneCodesCountries = createSelector(
  getCountryList,
  translations,
  (countryList, t) => {
	return countryList.map(country => {
		const subtitle = t({ id: `countries.${country.slug}`, defaultMessage: country.name });
		return {
			title: `+${country.phoneCode}`,
			subtitle,
			code: country.iso2,
			value: country.id,
		};
	})}
);

export const getCountryUser = createSelector(
  getCountryList,
  getUserAddress,
  (countryList, address) => {
    if (!address) {
      return address;
    }

    return countryList.filter(country => {
      return country.id == address.country;
    })[0]
  }
);
