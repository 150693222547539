import PropTypes from 'prop-types';

export const CurrencyItem = PropTypes.shape({
	currencyCode: PropTypes.string.isRequired,
	balance: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
	isReady: PropTypes.bool.isRequired,
	address: PropTypes.string.isRequired,
});

export const DetailsProps = {
	currency: CurrencyItem,
};

export const DetailsModalProps = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	transaction: PropTypes.shape({
		walletId: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		time: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		currency: PropTypes.string.isRequired,
		amount: PropTypes.number.isRequired,
		fee: PropTypes.number,
		txHash: PropTypes.string,
		confirmations: PropTypes.number,
		payerRequisite: PropTypes.string,
		payerName: PropTypes.string,
		payeeRequisite: PropTypes.string,
		payeeName: PropTypes.string,
		description: PropTypes.string
	})
};

export const CurrenciesListProps = {
	currencies:  PropTypes.arrayOf(CurrencyItem),
	onCurrency: PropTypes.func
};
