import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import helpers from 'helpers';
import * as cardActions from 'actions/card';
import * as openbanking from 'actions/openbanking';
import * as toastActions from 'actions/toast';
import * as cardSelector from 'selectors/card';

import voucherSchema from 'schemas/voucher';
import openbankingSchema from 'schemas/openbanking';

import Section from 'components/Section';
import Container from 'components/Container';
import Input from 'components/Input';
import Button from 'components/Button';
import BlockingScreen from '../../screens/Blocking';

import M from './Load.locale.json';
import './Load.scss';
import Wrap from 'components/Wrap';

import * as userSelector from 'selectors/user';
import Form from '../../components/Form';
import {getPaymentsList} from "../../actions/openbanking";
import ButtonLink from "../../components/ButtonLink";

const mapState = state => ({
	card: state.card,
	currentCard: cardSelector.getById(state, state.card.current),
	currentUser: userSelector.getUser(state),
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		openbanking: bindActionCreators(openbanking, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
	},
});


@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Bank extends Component {
	state = {
		loader: false,
		cardid: 0,
		paymentId: null,
		form: {
			amount: '',
			details: '',
		},
		errors: {
			amount: [],
			details: [],
		}
	};

	onBlur = value => {
		const minAmount = 1;
		const decimalPlaces = 2;
		let newAmount = this.state.form.amount;
		newAmount = newAmount.replace(',', '.');
		newAmount = parseFloat(newAmount);

		if (isNaN(newAmount)) {
			newAmount = minAmount;
		} else {
			newAmount = Math.max(newAmount, minAmount);
			newAmount = newAmount.toFixed(decimalPlaces);
		}
		return this.setState(prevState => ({ form: { ...prevState.form, amount: newAmount.toString() }}));
	}

	onChange = value => {
		this.setState(prevState => ({ form: { ...prevState.form, [value]: value }}));
	};

	onContinue = () => {
		const { form, loader } = this.state;

		const errors = helpers.validator.all(form, openbankingSchema.openbanking);
		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors,
				},
			});

			return false;
		}
		this.setState({ loader: true });
		this.props.actions.openbanking.createPayment(this.props.card.current, Number(this.state.form.amount), this.state.form.details)
			.then(response => this.setState({ paymentId: response.id }))
			.catch(() => this.setState({ form: { amount: '', details: '' } }))
			.finally(() => this.setState({ loader: false }));
	}

	checkStatus = () => {
		this.setState({ loader: true });
		this.props.actions.openbanking.checkPaymentStatus(this.state.paymentId).finally(() => this.setState({ loader: false }))
	}

	getAll = () => {
		this.setState({ loader: true });
		this.props.actions.openbanking.getPaymentsList().finally(() => this.setState({ loader: false }));
	};

	render () {
		const t = this.props.intl.formatMessage;
		const { errors, loader, paymentId } = this.state;
		const { currentCard, currentUser } = this.props;

		if ((['CARD_04', 'CARD_05', 'CARD_06', 'CARD_07', 'CARD_08', 'CARD_14', 'CARD_17', 'CARD_18'].includes(currentCard.product) || helpers.checker.isSU(currentUser))
			&& currentCard.cardStatus === 'OPEN') {
			return (
				<div>
					<h3>{t(M.bank.title)}</h3>
					<Form>
						<Form.Group>
							<Input
								name="amount"
								disabled={paymentId}
								placeholder={t(M.bank.amountPlaceholder)}
								value={this.state.form.amount}
								errors={errors.amount}
								onBlur={this.onBlur}
								onChange={value => this.setState(prevState => ({ form: { ...prevState.form, amount: value }}))}
							/>
						</Form.Group>
						<Form.Group>
							<Input
								name="details"
								disabled={paymentId}
								placeholder={t(M.bank.detailsPlaceholder)}
								value={this.state.form.details}
								errors={errors.details}
								onChange={value => this.setState(prevState => ({ form: { ...prevState.form, details: value }}))}
							/>
						</Form.Group>
					</Form>

					<Button
						onClick={paymentId ? this.checkStatus : this.onContinue}
						loading={loader}
						disabled={!(this.state.form.amount && this.state.form.details)}
						form
						small
					>
						{paymentId ? t(M.bank.checkBtn) : t(M.bank.continueBtn)}
					</Button>

					{!paymentId && (
						<div style={{ marginRight: 10, display: 'flex', flexDirection: 'column' }}>
							{t(M.bank.hint)}
							<ButtonLink onClick={this.getAll} small>{t(M.bank.refreshBtn)}</ButtonLink>
						</div>
					)}
				</div>
			);
		}
		return (
			<Section className="LoadSection">
				<Container>
					<Wrap>
						<BlockingScreen />
					</Wrap>
				</Container>
			</Section>
		);
	}
}
