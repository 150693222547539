import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Section from 'components/Section';
import ExchangeSendConfirmComponent from 'components/Exchange/Send/Confirm';

const Confirm = ({ location, history }) => {
	const data = location.state.data;
	if (!data) return <div />;
	const { requestId, currency } = data;
	return (
		<Layout className="ExchangeScreen Screen" sidebar>
			<Nav />
			<Header withBack />
			<Section large>
				<ExchangeSendConfirmComponent
					id={requestId}
					currency={currency}
				/>
			</Section>
		</Layout>
	);
};

Confirm.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default Confirm;
