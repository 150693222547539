import React, { useEffect } from "react";
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import { getDeliveryDate } from '../../../../../sections/Card/utils';
import Icon from '../../../../../components/Icon';

import './SuccessGetPlasticModal.scss';
import { GTMEvents } from "../../../../../constants/gtm";

const SuccessGetPlasticModal = ({ t, onSkip, ...props }) => {
	useEffect(() => {
		debugger;
		gtag('event', GTMEvents.plasticCardOrdered.eventName, { ...GTMEvents.plasticCardOrdered.eventParameters });
	}, [])

	return (
	<Modal className="DashboardSection__VerifyCardModal" {...props} >
		<Section>
			<Container medium>
				<Wrap className="strict-modal-padding">
					<Icon className="success-get-plastic-modal__button" onClick={onSkip} name="close-small" />
					<Title
						title={t({ id: 'modals.success.get.plastic.title' })}
						legend={getDeliveryDate()}
						small
					/>
				</Wrap>
			</Container>
		</Section>
	</Modal>
)};

SuccessGetPlasticModal.propTypes = {
	t: PropTypes.any.isRequired,
	onSkip: PropTypes.func.isRequired,
};

export default SuccessGetPlasticModal;
