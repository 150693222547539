import React from 'react';
import { REISSUE_MODAL_STATE } from '../constants';

import SecondModal from './SecondModal';
import SecondTiandeModal from './SecondTiandeModal';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import AddressModal from './AddressModal';
import ErrorFeeModal from './ErrorFeeModal';

const STATES = {
	[REISSUE_MODAL_STATE.CLOSE]: null,
	[REISSUE_MODAL_STATE.SECOND]: SecondModal,
	[REISSUE_MODAL_STATE.SECOND_TIANDE]: SecondTiandeModal,
	[REISSUE_MODAL_STATE.SUCCESS]: SuccessModal,
	[REISSUE_MODAL_STATE.ERROR]: ErrorModal,
	[REISSUE_MODAL_STATE.ERROR_FEE]: ErrorFeeModal,
	[REISSUE_MODAL_STATE.ADDRESS]: AddressModal,
};

const ReissueModal = ({ type, ...props }) => (
	STATES[type]
		? React.createElement(STATES[type], props)
		: null
);

export default ReissueModal;