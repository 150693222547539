import { COUNTRY_FETCH_SUCCESS } from 'constants';
import renamer from 'helpers/renamer';
import API from 'services/api';

export const fetch = () => async dispatch => {
	try {
		const result = await API.country.fetch();
		const countries = renamer.from.country.fetch(result.data.response);
		return dispatch({ type: COUNTRY_FETCH_SUCCESS, payload: countries });
	} catch (error) { }
};
