import * as templateActions from 'actions/template';
import Button from 'components/Button';
import Container from 'components/Container';
import Modal from 'components/Modal';
import Title from 'components/Title';
import Wrap from 'components/Wrap';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import intlTypes from 'types/intl';
import './Templates.scss';
import M from './Templates.locale.json';

@injectIntl
@withRouter
class TemplateRemoveModal extends React.PureComponent {
	static propTypes = {
		id: PropTypes.number.isRequired,
		intl: intlTypes.isRequired,
		onClose: PropTypes.func,
	};
	constructor (props) {
		super(props);
		this.state = {
			list: [],
			loader: true,
			form: {},
			errors: {
				recipientCardNumber: [],
				amount: [],
			},
		};
		this.t = props.intl.formatMessage;
	}

	onSubmit = async () => {
		const { id, onClose } = this.props;
		templateActions.removeTemplate(id).then(onClose);
	};

	render () {
		const { onClose } = this.props;
		const t = this.t;
		return (
			<Modal onClick={onClose} className="Template__Modal__Remove">
				<Container medium>
					<Wrap>
						<Modal.Close onClick={onClose} />
						<Title title={t(M.remove.title)} />
						<p>{t(M.remove.subtitle)}</p>
						<div className='ButtonContainer'>
							<Button onClick={this.onSubmit}>
								{t(M.buttons.remove)}
							</Button>
							<Button onClick={onClose} secondary>
								{t(M.buttons.close)}
							</Button>
						</div>
					</Wrap>
				</Container>
			</Modal>
		);
	}
}

export default TemplateRemoveModal;
