import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router';


import { depositSend } from 'constants/paths';
import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import Icon from 'components/Icon';

import historyTypes from 'types/history';
import intlTypes from 'types/intl';

import { REISSUE_MODAL_STATE } from '../../constants';

import '../ReissueModals.scss';

const ErrorModal = ({ intl, setReissueState, history }) => {
	const t = intl.formatMessage;

	const onSkip = () => setReissueState(REISSUE_MODAL_STATE.CLOSE);

	const onLoad = () => {
		history.push(depositSend);
	};


	return(

		<Modal >
			<Section>
				<Container medium>
					<Wrap className="ReissueModal__strict-padding">
						<Icon
							className="ReissueModal-close" 
							onClick={onSkip} 
							name="close-small" 
						/>
						<Title
							title={t({ id: 'modals.renew.error.fee.title' })}
							subtitle={t({ id: 'modals.renew.error.fee.subtitle' })}
							small
						/>
						<Button
							onClick={onSkip}
							small
							className="ReissueModal-button"
						>
							<FormattedMessage id="card.renew.button.close" />
						</Button>
						<Button
							onClick={onLoad}
							small
							className="ReissueModal-button"
						>
							<FormattedMessage id="modals.renew.error.fee.load" />
						</Button>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
}
;

ErrorModal.propTypes = {
	intl: intlTypes,
	setReissueState: PropTypes.func.isRequired,
	history: historyTypes.isRequired,
};



export default compose(withRouter, injectIntl)(ErrorModal);
