import Failed from './failed';
import Retry from './retry';
import Create from './create';
import Processing from './processing';

export default {
	'Failed': Failed,
	'Retry': Retry,
	'Create': Create,
	'Processing': Processing
};
