const normalize = {
	toBackend (locale) {
		const localeToUpperCase = locale.toUpperCase();
		return localeToUpperCase === 'CS' ? 'CZ' : localeToUpperCase;
	},

	fromBackend (locale) {
		const localeToLowerCase = locale.toLowerCase();
		return localeToLowerCase === 'cz' ? 'cs' : localeToLowerCase;
	}
};

export default normalize;
