/*global google*/
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Form from 'components/Form';
import Banner from 'components/Banner';
import Input from 'components/Input';
import InputDate from 'components/InputDate';
import InputSelect from 'components/InputSelect';
import InputCheckbox from 'components/InputCheckbox';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Title from 'components/Title';
import PasswordPolicy from 'components/PasswordPolicy';
import * as authActions from 'actions/auth';
import * as countryActions from 'actions/country';
import * as countrySelector from 'selectors/country';
import * as clientActions from 'actions/client';
import helpers from 'helpers';
import reduxer from 'helpers/reduxer';
import Auth from 'schemas/auth';
import { UTMParams } from 'constants/common';
import tracker from 'helpers/tracker';
import formFocusErrors from '../../../../helpers/form-focus-errors';
import formatPhoneNumberToBEFormat from '../../../../helpers/formatPhoneNumberToBEFormat';
import intlTypes from 'types/intl';
import { validatePhone } from '../../../../helpers/validator';
import Icon from 'components/Icon';
import M from './Private.locale.json';
import './Private.scss';
import Referrals from 'helpers/referrals';
import InputAutocomplete from "../../../../components/InputAutocomplete";
import bannerLMT_en from "../../../../../img/banners/LMT/bannerLMT_en.jpg";
import bannerLMT_lv from "../../../../../img/banners/LMT/bannerLMT_lv.jpg";
import { GTMEvents } from "../../../../constants/gtm";

const SIGN_UP_FORM_ID = 'SIGN_UP_FORM_ID';

const autocompleteService = new window.google.maps.places.AutocompleteService();
const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
function getSessionToken () {
	return new google.maps.places.AutocompleteSessionToken();
}
function normalizeAddress(address) {
	return address.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\x00-\x7F]/g, "");
}

const mapState = (state, props) => ({
	language: state.language,
	user: state.user,
	country: countrySelector.getEnrollCountries(state, props, M.countries),
	allCountries: countrySelector.getCountryListWithTranslations(state, props),
	phoneCountry: countrySelector.getPhoneCodesCountries(state, props, M.countries),
});

const mapDispatch = dispatch => ({
	actions: {
		auth: reduxer.bindNestedActionCreators(authActions, dispatch),
		country: bindActionCreators(countryActions, dispatch),
		client: {
			private: bindActionCreators(clientActions.privateActions, dispatch),
		}
	}
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Private extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		language: PropTypes.shape({
			locale: PropTypes.string.isRequired,
		}).isRequired,
		user: PropTypes.object,
		country: PropTypes.array.isRequired,
		phoneCountry: PropTypes.array.isRequired,
		actions: PropTypes.shape({
			auth: PropTypes.object.isRequired,
			country: PropTypes.object.isRequired,
		}).isRequired,
	}

	state = {
		loader: false,
		sessionToken: getSessionToken(),
		suggestions: [],
		selectedCountryCode: '',
		form: {
			name: '',
			surname: '',
			gender: '',
			nationality: '',
			dateOfBirth: '',
			email: '',
			phone: '',
			phoneCountry: '',
			address: '',
			country: '',
			city: '',
			postalCode: '',
			password: '',
			passwordConfirm: '',
			agree: false,
			agreeMonitored: false,
		},
		messages: [],
		errors: {
			name: [],
			surname: [],
			gender: [],
			nationality: [],
			dateOfBirth: [],
			email: [],
			country: [],
			address: [],
			city: [],
			postalCode: [],
			phoneCountry: [],
			phone: [],
			password: [],
			passwordConfirm: [],
			agree: [],
			agreeMonitored: [],
		},
		autocomplete: [],
	}

	componentDidMount () {
		const { actions } = this.props;
		actions.country.fetch();
		const urlParams = new URLSearchParams(location.search);
		const params = {
			utm_source: urlParams.get('utm_source'),
			utm_medium: urlParams.get('utm_medium'),
			utm_campaign: urlParams.get('utm_campaign'),
		};
		localStorage.setItem(UTMParams, JSON.stringify(params));

		tracker.init();
		document.addEventListener('mousemove', tracker.onMouseMove);

		fbq('track', 'InitiateCheckout');
	}

	componentWillUnmount () {
		document.removeEventListener('mousemove', tracker.onMouseMove);
	}

	onChange = (value, name) => {
		const { form, errors } = this.state, { country } = this.props;
		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single({
				...form,
				[name]: value,
			}, name, Auth.signup.private);
		}

		if (name === 'phone') {
			const validation = validatePhone(value);

			if (validation) {
				messages.unshift(validation);
			}
		}

		const updateObj = {
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages },
		};
		if (name === 'country') {
			const countryObj = country.find(item => item.value === value);
			updateObj.selectedCountryCode = countryObj.code.toLowerCase();
			updateObj.form.postalCode = '';
			updateObj.form.address = '';
			updateObj.form.city = '';
		}

		this.setState(updateObj);
	}

	onChangeName = (value, name) => {
		const { form, errors } = this.state;

		this.setState({ form: { ...form, [name]: value } }, () => {
			const nameValue = this.nameRef.current.props.value;
			const surnameValue = this.surnameRef.current.props.value;
			const messages = helpers.validator.single({
				...form,
				name: nameValue,
			}, 'name', Auth.signup.private);
			const messagesLname = helpers.validator.single({
				...form,
				surname: surnameValue,
			}, 'surname', Auth.signup.private);
			this.setState({ errors: { ...errors, name: messages, surname: messagesLname } });
		});
	}

	onSubmit = async e => {
		e.preventDefault();
		const { actions, language } = this.props;
		const { form, loader, errors } = this.state;

		if (loader || errors.phone.length) return;

		const errorsValidation = helpers.validator.all(form, Auth.signup.private);

		if (errorsValidation) {
			this.setState({ errors: { ...errors, ...errorsValidation } });
			this.focusAfterError({ ...errors, ...errorsValidation });

			tracker.onError();
			return;
		}


		this.setState({ messages: [], loader: true });
		form.language = language.locale.toUpperCase();

		this.fixateTrackResult();

		form.phone = formatPhoneNumberToBEFormat(form.phone);
		form.nationality = form.nationality.replace(/[^a-zA-Z]/g, '');

		const self = this;

		window.grecaptcha.ready(function() {
			window.grecaptcha.execute('6Ld4MZ4mAAAAADQxjPLytjSISPQamhrKG5Ctfc9K', {action: 'submit'}).then(async function(token) {
				const response = await actions.auth.signup.private({...form, 'g-recaptcha-response': token, utm: Referrals.getUTM() });
				if (response && response.errors) {
					const update = {
						errors: { ...errors, ...response.errors },
						loader: false,
					};
					self.focusAfterError({ ...response.errors });

					if (response.messages) update.messages = response.messages;

					self.setState(update);
				}
			});
		});
	};

	nameRef = React.createRef();
	surnameRef = React.createRef();

	fixateTrackResult = () => {
		const result = tracker.analyse();
		if (result.count > 0) {
			const params = {
				utm_source: 'PROBABLY_UNSAFE',
				utm_medium: 'PROBABLY_UNSAFE',
				utm_campaign: result.mark,
			};
			localStorage.setItem(UTMParams, JSON.stringify(params));
		}
	};

	focusAfterError = errors => {
		const errorKeys = Object.keys(errors);

		formFocusErrors(SIGN_UP_FORM_ID, errors, errorKeys, name => {
			if (name === 'country' || name === 'phoneCountry') {
				return `${name}Filter`;
			}

			return name;
		});
	};

	handleSuggestionSelect = placeId => {
		const { sessionToken } = this.state;
		placesService.getDetails({
			placeId,
			language: 'en', sessionToken,
			fields: ['address_components', 'formatted_address']
		}, (place, status) => {
			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				// Extract postal code, street and city
				let postalCode = '', city = '', address = '';
				const adr_address = place.address_components.filter(component => component.types.some(type => ['route', 'street_number', 'sublocality_level_1'].includes(type)));
				const adr_postalCode = place.address_components.find(component => component.types.includes('postal_code'));
				const adr_city = place.address_components.find(component => component.types.includes('locality'));
				if (adr_address.length) address = normalizeAddress(adr_address.map(i => i.long_name).join(", "));
				if (adr_postalCode) postalCode = adr_postalCode.long_name;
				if (adr_city) city = normalizeAddress(adr_city.long_name);
				this.setState(prevState => ({
					form: { ...prevState.form, address, postalCode, city },
					sessionToken: getSessionToken(),
					suggestions: [],
					errors: { ...prevState.errors, postalCode: [], city: [], address: [] },
				}));
			} else {
				console.error('Error fetching place details:', status);
			}
		});
	}

	handleAddressChange = value => {
		const { language: { locale } } = this.props, { sessionToken, selectedCountryCode } = this.state;
		if (value.length > 0) {
			autocompleteService.getPlacePredictions({input: value, language: locale, componentRestrictions: { country: selectedCountryCode }, types: ['address'], sessionToken}, (predictions, status) => {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					this.setState({ suggestions: predictions.map(i => ({ title: i.description, value: i.place_id })) });
				} else {
					this.setState({ suggestions: [] });
				}
			});
		}
	}

	render () {
		const { user, country, allCountries, phoneCountry, intl, language: { locale } } = this.props;
		const { form, loader, errors, messages, suggestions } = this.state;

		if (user) return <Redirect to={{ pathname: '/' }} />;

		const dateOfBirth = new Date();
		dateOfBirth.setFullYear(dateOfBirth.getFullYear() - 18);
		const year = dateOfBirth.getFullYear();

		const t = intl.formatMessage;
		return (
			<Layout className="AuthSignupScreen Screen">
				<Nav logo />
				<Section large>
					<Container small>
						<Title title={t(M.title)} legend={t(M.legend)} />
						{messages && !!messages.length && <Banner type="error" data={messages} />}
						<Form id={SIGN_UP_FORM_ID} onSubmit={this.onSubmit} onKeyDown={tracker.onKeyboardEvent}>
							<Form.Group>
								<Input
									id="name-input"
									name="name"
									ref={this.nameRef}
									placeholder={t(M.inputs.name)}
									onChange={this.onChangeName}
									value={form.name}
									errors={errors.name}
									hotjar
								/>
								<Input
									id="surname-input"
									name="surname"
									ref={this.surnameRef}
									placeholder={t(M.inputs.surname)}
									onChange={this.onChangeName}
									value={form.surname}
									errors={errors.surname}
									hotjar
								/>
							</Form.Group>
							<span className="NameWarning">
								<Icon className="NameWarning__Icon Icon" name={('warning')} />
								<div className="NameWarning__Title">
									{t(M.messages.nameHint)}
								</div>
							</span>
							<Form.Group>
								<InputSelect
									name="gender"
									onChange={this.onChange}
									placeholder={t(M.inputs.gender)}
									value={form.gender}
									errors={errors.gender}
									data={[{value: 'm', title: t(M.inputs.male)}, {value: 'f', title: t(M.inputs.female)}]}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<InputSelect
									name="nationality"
									onChange={this.onChange}
									placeholder={t(M.inputs.nationality)}
									value={form.nationality}
									errors={errors.nationality}
									data={allCountries.map(i => ({value: i.title, title: i.title}))}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<InputDate
									name="dateOfBirth"
									label={t(M.inputs.dateOfBirth)}
									placeholder={t(M.inputs.date)}
									onChange={this.onChange}
									value={form.dateOfBirth}
									errors={errors.dateOfBirth}
									disabledDate={dateOfBirth}
									year={year}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="email"
									placeholder={t(M.inputs.email)}
									onChange={this.onChange}
									value={form.email}
									errors={errors.email}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<InputSelect
									name="country"
									onChange={this.onChange}
									placeholder={t(M.inputs.country)}
									value={form.country}
									errors={errors.country}
									data={country.filter(i => i.value !== 95 && i.value !== 62)}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<InputAutocomplete
									name="address"
									placeholder={t(M.inputs.address)}
									onSelect={this.handleSuggestionSelect}
									onChange={(value, name) => {
										this.onChange(value, name);
										this.handleAddressChange(value);
									}}
									value={form.address}
									errors={errors.address}
									data={suggestions}
									disabled={!Boolean(this.state.form.country)}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="city"
									placeholder={t(M.inputs.city)}
									onChange={this.onChange}
									value={form.city}
									errors={errors.city}
									disabled={!Boolean(this.state.form.country)}
									hotjar
								/>
								<Input
									name="postalCode"
									placeholder={t(M.inputs.postalCode)}
									onChange={this.onChange}
									value={form.postalCode}
									errors={errors.postalCode}
									disabled={!Boolean(this.state.form.country)}
									hotjar
								/>
							</Form.Group>
							<Form.Group>
								<InputSelect
									name="phoneCountry"
									onChange={this.onChange}
									placeholder={t(M.inputs.phoneCountry)}
									value={form.phoneCountry}
									errors={errors.phoneCountry}
									data={phoneCountry}
									hotjar
								/>
								<div className='banner-lmt'
									onClick={() => {gtag(GTMEvents.bannerLMTClicked.eventName, GTMEvents.bannerLMTClicked.eventParameters)}}>
									{locale !== 'lv' &&
										<a href="https://lmt.lmt.lv/en/karte-esim" target='_blank'>
											<img src={bannerLMT_en} alt=""/>
										</a>
									}
									{locale === 'lv' &&
										<a href="https://lmt.lmt.lv/lv/karte-esim" target='_blank'>
											<img src={bannerLMT_lv} alt=""/>
										</a>
									}
								</div>
								<Input
									name="phone"
									type="number"
									placeholder={t(M.inputs.phone)}
									onChange={this.onChange}
									value={form.phone}
									errors={errors.phone}
									hotjar
								/>
							</Form.Group>
							<Form.Group row>
								<Input
									name="password"
									type="password"
									placeholder={t(M.inputs.password)}
									onChange={this.onChange}
									value={form.password}
									errors={errors.password}
									hotjar
								/>

								<PasswordPolicy value={form.password} />
							</Form.Group>
							<Form.Group>
								<Input
									name="passwordConfirm"
									type="password"
									placeholder={t(M.inputs.passwordConfirm)}
									onChange={this.onChange}
									value={form.passwordConfirm}
									errors={errors.passwordConfirm}
									hotjar
								/>
							</Form.Group>
							<Form.Group row>
								<InputCheckbox
									name="agree"
									type="agree"
									onChange={this.onChange}
									value="on"
									checked={form.agree}
									errors={errors.agree}
								>
									<FormattedMessage
										id={M.inputs.agree.id}
										values={{
											terms: (
											  // redirect for now
											  <a className='ButtonLink' href='https://www.weststeincard.com/private/terms-and-conditions/' target='_blank'>
												Terms and conditions
											  </a>
												// <ButtonLink
												// 	to={{
												// 		pathname: '/terms-and-services',
												// 		state: { modal: true }
												// 	}}
												// >
												// 	{t(M.buttons.terms)}
												// </ButtonLink>
											),
											privacy: (
												<ButtonLink
													to={{
														pathname: '/privacy-policy',
														state: { modal: true }
													}}
												>
													{t(M.buttons.privacy)}
												</ButtonLink>
											)
										}}
									/>
								</InputCheckbox>
								<InputCheckbox
									name="agreeMonitored"
									type="agree"
									onChange={this.onChange}
									value="on"
									checked={form.agreeMonitored}
									errors={errors.agreeMonitored}
								>
									<FormattedMessage
										id={M.inputs.agreeMonitored.id}
										values={{
											terms: (
												<ButtonLink
													to={{
														pathname: '/terms-and-services',
														state: { modal: true }
													}}
												/>
											)
										}}
									/>
								</InputCheckbox>
							</Form.Group>

							<Button form loading={loader}>{t(M.buttons.signup)}</Button>
						</Form>
						<Form.Note>
							{`${t(M.messages.login)} `} <ButtonLink to="/auth/login">{t(M.buttons.login)}</ButtonLink>
						</Form.Note>
					</Container>
				</Section>
			</Layout>
		);
	}
}
