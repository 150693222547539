export const SERVER_URL = `http://localhost:1337/api`;

export const StatusCode = {
	NO_DATA: 201
};

export const Path = {
	DEFAULT: `/`,
};

export const BaseUrl = {
	CRYPTO: `/crypto`,
	FIAT: `/fiat`,
	ADMIN: `/admin`,
	USER: `/user`,
	INFO: `/info`,
};

export const CryptoUrl = {
	SELL: BaseUrl.CRYPTO + `/sell`,
	SEND: BaseUrl.CRYPTO + `/send`,
};

export const AdminUrl = {
	SETTING: BaseUrl.ADMIN + `/setting`,
	UPD_SETTING: BaseUrl.ADMIN + `/upd_setting`
};

export const UserUrl = {
	USER_INFO: BaseUrl.USER + `/user_info`,
	CREATE_USER: BaseUrl.USER + `/create_user`
};

export const InfoUrl = {
	TRADE_INFO: BaseUrl.INFO + `/trade_info`,
};

export const FiatUrl = {
	BUY: BaseUrl.FIAT + `/buy`
};

export const ResponeType = {
	BLOB: `blob`
};

export const Method = {
	GET: `GET`,
	POST: `POST`,
	PUT: `PUT`,
	DELETE: `DELETE`
};

export const AuthorizationStatus = {
	AUTH: `auth`,
	NO_AUTH: `notAuth`
};

export const OperationType = {
	CRYPTO_TO_FIAT: 1,
	FIAT_TO_CRYPTO: 2,
	CRYPTO_TO_CRYPTO: 3
};

export const CookieName = {
	USER_ID: `user_id_west`,
};

export const operationTypes = [
	{
		value: OperationType.CRYPTO_TO_FIAT,
		label: `buy`,
		id: 0,
	},
	{
		value: OperationType.FIAT_TO_CRYPTO,
		label: `sell`,
		id: 1,
	},
	//   {
	//     value: OperationType.CRYPTO_TO_CRYPTO,
	//     label: `change`,
	//     id: 2,
	//   },
];

export const UserType = {
	UNSET: `unset`,
	EXISTS: `exists`,
	CREATE: `create`
};

export const TradeType = {
	EUR_USDT: `EUR-USDT`,
	USDT_EUR: `USDT-EUR`
};


export const eventTypes = [
	{
		label: `Пополнение счета`,
		value: `REFILL`,
	},
	{
		label: `Расходы`,
		value: `COSTS`,
	},
	{
		label: `Верификация пользователя`,
		value: `VERIFICATION`,
	},
	{
		label: `Заканчивается срок действия карты`,
		value: `EXPIRING_CARD`,
	},
	{
		label: `Текстовые уведомления`,
		value: `TEXT_NOTIFICATION`,
	},
]

export const EventType = {
	REFILL: `REFILL`,
	COSTS: `COSTS`,
	VERIFICATION: `VERIFICATION`,
	EXPIRING_CARD: `EXPIRING_CARD`,
	TEXT_NOTIFICATION: `TEXT_NOTIFICATION`,
	SEPA_OUTGOING: `SEPA_OUTGOING`,
	SEPA_INCOMING: `SEPA_INCOMING`,
}