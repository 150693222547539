export default {
	day: {
		numericality: {
			greaterThanOrEqualTo: 1,
			lessThanOrEqualTo: 31,
			message: '^schemas.incorrect'
		}
	},
	expirationCount: {
		numericality: {
			greaterThan: 0,
			message: '^schemas.incorrect'
		}
	}
};
