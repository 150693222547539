import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as toastActions from 'actions/toast';

import * as toastSelector from 'selectors/toast';

import Toast from 'components/Toast';

import './Toast.scss';



const mapState = (state, props) => ({
	toast: toastSelector.language(state, props),
});

const mapDispatch = dispatch => ({
	actions: {
		toast: bindActionCreators(toastActions, dispatch)
	}
});

@injectIntl
@connect(mapState, mapDispatch)
export default class ToastSection extends PureComponent {
	onRemove = id => {
		const { actions, onClick } = this.props;

		actions.toast.remove(id);

		onClick && this.props.onClick(id);
	};

	render () {
		const { toast } = this.props;

		if (!toast) return null;

		const content = toast.map(item => (
			<Toast
				key={item.id}
				className="ToastSection__Toast"
				onRemove={this.onRemove}
				toast={item}
			/>
		));

		return (
			<div className="ToastSection">
				{content}
			</div>
		);
	}
}

ToastSection.propTypes = {
	onClick: PropTypes.func,
};
