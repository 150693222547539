import PropTypes from 'prop-types';

export default PropTypes.shape({
	id: PropTypes.number,
	cardNumber: PropTypes.string,
	created: PropTypes.string,
	days: PropTypes.number,
	expirationMonth: PropTypes.string,
	expirationYear: PropTypes.string,
});
