import React from 'react';
import { FormattedMessage } from 'react-intl';
import retryIcon from '../assets/exchange/exchRetry.png';

const Retry = (
	<div className="ExchangeBlocking">
		<FormattedMessage tagName="h1"  id="screens.exchange.accountretry.label1" />
		<img src={retryIcon} alt="" />
		<FormattedMessage tagName="p"  id="screens.exchange.accountretry.label2" />
	</div>
);

export default Retry;
