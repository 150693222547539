import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Section from 'components/Section';
import ExchangeTopUpConfirmComponent from 'components/Exchange/TopUp/Confirm';

const Confirm = ({ match, history }) => {
	if (!match.params.id) {
		history.replace('exchange/topup');
		return null;
	} else {
		const { id } = match.params;
		return (
			<Layout className="ExchangeScreen Screen" sidebar>
				<Nav />
				<Header withBack />
				<Section large>
					<ExchangeTopUpConfirmComponent id={id} />
				</Section>
			</Layout>
		);
	}
};

Confirm.propTypes = ({
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
});

export default Confirm;
