import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ButtonLink from 'components/ButtonLink';
import './Linkify.scss';

const Linkify = (props) => {
  const { children } = props;

  const updateMessage = useMemo(() => {
    const rg = /[a-z|A-Z]{2,}\.[a-z]{2,}/gm
    const links = children.match(rg);
    const arr = children.split(rg);
    if (!links) return children

    const messageWithLinks = arr.reduce((acc, rec, index, array) => {
      const text = <span>{rec}</span>;
      if (index !== 0) {
        const link = <ButtonLink className='Link ButtonLink' type='a' target='_blank' url={`https://${links[index - 1].toLowerCase()}`} >{links[index - 1]}</ButtonLink>
        return [...acc, link, text];
      }
      return[...acc, text];
    }, [])
    return messageWithLinks;
  }, []);

	return (
		<React.Fragment>
      {updateMessage}
    </React.Fragment>
	);
}

export default Linkify;

Linkify.propTypes = {
	className: PropTypes.string,
  message: PropTypes.array,
};
