import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import icon from 'svg/logo.svg';
import intlTypes from 'types/intl';

import M from './Logo.locale.json';
import './Logo.scss';

const Logo = ({ className, intl }) => {
	const t = intl.formatMessage;

	const link = useMemo(() => t(M.link), [t]);

	return (
		<a
			href={link}
			target="_blank"
			rel="noreferrer"
		>
			<div
				className={classnames('Logo', className)}
				dangerouslySetInnerHTML={{ __html: icon }}
			/>
		</a>
	);
};

Logo.propTypes = {
	className: PropTypes.string,
	intl: intlTypes.isRequired,
};

export default injectIntl(Logo);
