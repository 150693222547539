import * as templateActions from 'actions/template';
import Dropdown from 'components/Dropdown';
import ButtonLink from 'components/ButtonLink';
import Table from 'components/Table';
import Wrap from 'components/Wrap';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentCard } from 'selectors/card';
import Icon from 'components/Icon';
import { injectIntl } from 'react-intl';
import intlTypes from 'types/intl';
import historyTypes from 'types/history';

import TemplateEditModal from './TemplateEditModal';
import AutopaymentEditModal from './AutopaymentEditModal';
import TemplateRemoveModal from './TemplateRemoveModal';

import './Templates.scss';
import M from './Templates.locale.json';

const columns = ['id', 'title', 'recipient', 'type', 'actions'];
const columnsNav = [
	{ id: 'title', title: 'Название' },
	{ id: 'recipient', title: 'Получатель' },
	{ id: 'type', title: 'Тип' },
	{ id: 'actions', title: 'Действия' },
];

const mapState = state => ({
	currentCard: getCurrentCard(state),
});

@injectIntl
@withRouter
@connect(mapState)
class TemplatesList extends React.PureComponent {
	static propTypes = {
		history: historyTypes.isRequired,
		intl: intlTypes.isRequired,
	};
	constructor (props) {
		super(props);
		this.state = {
			list: [],
			loader: true,
			editModal: false,
			editAutopayment: false,
			dropdownState: false,
			removeModal: false,
		};
		this.t = props.intl.formatMessage;
		this.onEdit = this.onEdit.bind(this);
		this.currentEdit = null;
		this.currentRemoveId = null;
		this.onAutopayment = this.onAutopayment.bind(this);
		this.currentAutopayment = null;
		this.PAYMENT_TYPES = [
			{
				value: `SEPA`,
				title: this.t(M.types[`SEPA`]),
			},
			{
				value: `CARD_TO_CARD`,
				title: this.t(M.types[`CARD_TO_CARD`]),
			},
			{
				value: `INTERNATIONAL`,
				title: this.t(M.types[`INTERNATIONAL`]),
			},
		];
	}

	componentDidMount () {
		this.fetchList();
	}

	componentDidUpdate (_, prevState) {
		if (this.state.editModal === false && prevState.editModal === true) {
			this.fetchList();
		}
		if (
			this.state.removeModal === false &&
			prevState.removeModal === true
		) {
			this.fetchList();
		}
	}

	//#region onClick
	onDropdown = ({ value }) => this.openAddModal(value);

	onRemove (e, id) {
		e.stopPropagation();
		this.openRemoveModal(id);
	}

	onEdit (e, id) {
		e.stopPropagation();
		this.openEditModal(id);
	}

	onAutopayment (e, id) {
		e.stopPropagation();
		this.openAutopaymentModal(id);
	}

	onAddNewTemplate = type => {
		this.openAddModal(type);
	};
	//#endregion

	//#region fetch
	async getPayment (templateId) {
		const paymentModel = await this.getTemplate(templateId);
		this.gotoPayment(paymentModel.type, {
			...paymentModel,
			beneficiary: paymentModel.recipient,
			details: paymentModel.reference,
		});
	}

	async getTemplate (templateId) {
		try {
			return await templateActions.getTemplate(templateId);
		} catch (error) {
			throw error;
		}
	}

	async getAutopayment (templateId) {
		try {
			return await templateActions.getAutopayment(templateId);
		} catch (error) {
			throw error;
		}
	}

	async getTemplatesList () {
		const list = await templateActions.getSavedTemplates();
		this.setState({ list: list });
	}

	fetchList () {
		this.setState({ loader: true }, () => {
			this.getTemplatesList().then(() => {
				this.setState({ loader: false });
			});
		});
	}
	//#endregion

	//#region modal handlers
	openEditModal = async id => {
		this.currentEdit = await this.getTemplate(id);
		this.setState({ editModal: true });
	};

	openAutopaymentModal = async id => {
		this.currentAutopayment = await this.getAutopayment(id);
		this.currentTemplateId = id;
		this.setState({ autopaymentModal: true });
	};

	openRemoveModal = id => {
		this.currentRemoveId = id;
		this.setState({ removeModal: true });
	};

	openAddModal = type => {
		this.currentEdit = { type, new: true };
		this.setState({ editModal: true });
	};

	toggleDropdown = () => {
		this.setState({ dropdownState: !this.state.dropdownState });
	};

	closeEditModal = () => {
		this.setState({ editModal: false });
		this.currentEdit = null;
	};

	closeAutopaymentModal = () => {
		this.setState({ autopaymentModal: false });
		this.currentAutopayment = null;
	};

	closeRemoveModal = () => {
		this.setState({ removeModal: false });
		this.currentRemoveId = null;
	};
	//#endregion

	gotoPayment (type, payment) {
		const { history } = this.props;

		switch (type) {
			case 'SEPA':
				history.push(`/payments/create/`, { payment });
				break;
			case 'CARD_TO_CARD':
				history.push(`/c2cpayments/create/`, { payment });
				break;
			case 'INTERNATIONAL':
				history.push(`/international-payment/create/`, { payment });
				break;
		}
	}

	renderHeader = () => {
		return columnsNav
			.filter(item => columns.indexOf(item.id) > -1)
			.map(item => ({ ...item, title: this.t(M.fields[item.id]) }));
	};

	renderRow = row => {
		return (
			<Table.Row key={row.id} onClick={() => this.getPayment(row.id)}>
				{columnsNav
					.filter(item => columns.indexOf(item.id) > -1)
					.map(item => {
						return (
							<Table.Cell key={item.id}>
								{item.id === `actions` ? (
									<span className="Template__Icon_Wrap">
										<Icon
											onClick={e =>
												this.onRemove(e, row.id)
											}
											className="Icon Template__Icon"
											name="close"
										/>
										<Icon
											onClick={e =>
												this.onEdit(e, row.id)
											}
											className="Icon Template__Icon"
											name="menu"
										/>
										<Icon
											onClick={e =>
												this.onAutopayment(e, row.id)
											}
											className="Icon Template__Icon"
											name="bell"
										/>
										<Icon
											className="Icon Template__Icon"
											name="caveat-right"
										/>
									</span>
								) : item.id === 'type' ? (
									this.t(M.types[row[item.id]])
								) : (
									row[item.id]
								)}
								{/* {helpers.formatter(item.id, row[item.id])} */}
							</Table.Cell>
						);
					})}
			</Table.Row>
		);
	};

	render () {
		const {
			loader,
			list,
			editModal,
			removeModal,
			autopaymentModal,
			dropdownState,
		} = this.state;
		const t = this.t;
		return (
			<Wrap>
				<ButtonLink
					style={{ textAlign: 'center' }}
					onClick={this.toggleDropdown}
				>
					{t(M.buttons.add)}
					<Dropdown
						className="Template__Dropdown_Add"
						data={this.PAYMENT_TYPES}
						onClick={this.onDropdown}
						open={dropdownState}
					/>
				</ButtonLink>
				<Table
					items={list}
					renderHeader={this.renderHeader}
					renderRow={this.renderRow}
					className="Templates__Table"
					loading={loader}
				/>
				<br />

				{editModal && (
					<TemplateEditModal
						template={this.currentEdit}
						onClose={this.closeEditModal}
					/>
				)}
				{autopaymentModal && (
					<AutopaymentEditModal
						autopayment={this.currentAutopayment}
						templateId={this.currentTemplateId}
						onClose={this.closeAutopaymentModal}
					/>
				)}
				{removeModal && (
					<TemplateRemoveModal
						id={this.currentRemoveId}
						onClose={this.closeRemoveModal}
					/>
				)}
			</Wrap>
		);
	}
}

export default TemplatesList;
