import axios from "axios";
import BASE_URL from '../../../env/development';
const HttpCode = {
	UNAUTHORIZED: 401
};

const REQUEST_TIMEOUT = 50000;

// const onUnauthorized = () => {
//   Cookies.remove(CookieName.SESSION_KEY);
//   browserHistory.push(UrlRoute.LOGIN);
// }

export const createAPI = () => {

	const api = axios.create({
		timeout: REQUEST_TIMEOUT,
		withCredentials: false,
		baseURL: BASE_URL.api
	});

	//   const onSuccess = (response) => response;
	//   const onFail = ({ response }) => {
	//     if (response) {
	//       if (response.status === HttpCode.UNAUTHORIZED) { onUnauthorized() }
	//       throw response;
	//     } else {
	//       onUnauthorized();
	//     }
	//   };

	//   api.interceptors.response.use(onSuccess, onFail);

	let JWT;
	let rawJWT;
	if (localStorage.getItem(`session`)) {
		JWT = Array.from(localStorage.getItem(`session`))
		rawJWT = Array.from(localStorage.getItem(`session`)).slice(1, JWT.length - 1).join(``);
	}

	const requestHandler = request => {
		request.headers.Authorization = rawJWT

		return request;
	};

	api.interceptors.request.use(request => requestHandler(request));

	return api;
};

export const API = createAPI();