import React from 'react';
import { FormattedMessage } from 'react-intl';
import createIcon from '../assets/exchange/exchCreate.png';

const Create = (
	<div className="ExchangeBlocking">
		<FormattedMessage
			tagName="h1"
			id="screens.exchange.accountcreate.label1"
		/>
		<img src={createIcon} alt="" />
		<FormattedMessage
			tagName="p"
			id="screens.exchange.accountcreate.label2"
		/>
		<div className="links">
			<a
				href="https://itunes.apple.com/us/app/weststein/id1131192886?ls=1&amp;mt=8"
				target="_blank"
				rel="noreferrer"
			>
				<img
					src="https://www.weststeincard.com/wp-content/themes/weststein/img/appstore.svg"
					alt="Appstore"
				/>
			</a>
			<a
				href="https://play.google.com/store/apps/details?id=com.weststeincard.weststein"
				target="_blank"
				rel="noreferrer"
			>
				<img
					src="https://www.weststeincard.com/wp-content/themes/weststein/img/googleplay.svg"
					alt="Google play"
				/>
			</a>
		</div>
	</div>
);

export default Create;
