import React from "react";
import { injectIntl } from "react-intl";
import { eventTypes } from "../../constants/exchange-const";
import "./NotificationsFilter.scss";

const NotificationsFilter = ({
	eventType,
	dateFrom,
	dateTo,
	applyFilter,
	setEventType,
	setDateFrom,
	setDateTo,
	setApplyFilter,
	setIsFilter,

	intl,
}) => {
	const typeHandler = ({ target }) => {
		console.log(target.selectedOptions[0].value);
		setEventType(target.selectedOptions[0].value);
	};

	const resetHandler = () => {
		setEventType(``);
		setDateFrom(``);
		setDateTo(``);
		setApplyFilter(!applyFilter);
		setIsFilter(false);
	};

	return (
		<div className="notifications-filter">
			<label>
				<select
					className="notifications-filter_type"
					onChange={typeHandler}
				>
					<option value=''>
					{intl.messages["notify.filter.unset"]}
						</option>
					{eventTypes.map(({ label, value }) => {
						return (
							<option key={value} value={value}>
								{label}
							</option>
						);
					})}
				</select>
			</label>

			<label>
				<input
					className="notifications-filter__date"
					type="date"
					value={dateFrom}
					onChange={({ target }) => setDateFrom(target.value)}
				/>
			</label>

			<label>
				<input
					className="notifications-filter__date"
					type="date"
					value={dateTo}
					onChange={({ target }) => setDateTo(target.value)}
				/>
			</label>

			<button
				className="notifications-filter__reset"
				onClick={resetHandler}
			>
				{intl.messages["notify.filter.reset"]}
			</button>
			<button
				className="notifications-filter__apply"
				onClick={() => {
					setApplyFilter(!applyFilter);
					setIsFilter(false);
				}}
			>
				{intl.messages["notify.filter.apply"]}
			</button>
		</div>
	);
};

export default injectIntl(NotificationsFilter);
