import {
	PAYMENT_GET_SUCCESS,
	PAYMENT_CREATE_SUCCESS,
	PAYMENT_AMOUNT_SUCCESS,
	PAYMENT_AMOUNT_ERROR,
	PAYMENT_PHONE_SUCCESS,
	PAYMENT_PHONE_ERROR,
} from 'constants';
import { clientRole } from 'constants/common';
import { success, error } from 'actions/toast';
import API from 'services/api';
import renamer from 'helpers/renamer';
import * as cardSelector from 'selectors/card';
import * as clientSelector from 'selectors/client';
import M from './payment.locale.json';

export const get = id => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const businessId = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const clientId = cardSelector.getFirstCardId(state);

	try {
		const result = await API.internationalPayment.get(id, clientId, businessId);
		const convert = renamer.from.payment.get(result.data.response);
		dispatch({ type: PAYMENT_GET_SUCCESS, payload: convert });
		return convert;
	} catch (err) { return err; }
};

export const create = form => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const businessId = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const clientId = state.card.current;

	try {
		const result = await API.internationalPayment.create(clientId, { ...form }, businessId);
		const convert = renamer.from.payment.get(result.data.response);
		dispatch({ type: PAYMENT_CREATE_SUCCESS, payload: convert });
		return convert;
	} catch (err) { return err; }
};

export const amount = (id, form) => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const businessId = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const clientId = cardSelector.getFirstCardId(state);

	try {
		const result = await API.internationalPayment.amount(id, clientId, { ...form }, businessId);
		const convert = renamer.from.payment.get(result.data.response);
		dispatch({ type: PAYMENT_AMOUNT_SUCCESS, payload: convert, id: convert.id });
		return convert;
	} catch (err) {
		dispatch({ type: PAYMENT_AMOUNT_ERROR, error: err });
		return err;
	}
};

export const phone = (id, form) => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const businessId = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const clientId = cardSelector.getFirstCardId(state);

	try {
		const result = await API.internationalPayment.phone(id, clientId, { ...form }, businessId);
		const convert = renamer.from.payment.get(result.data.response);
		dispatch({
			type: PAYMENT_PHONE_SUCCESS,
			payload: { ...convert, errorMessage: result.data.messages[0] },
			id: convert.id,
		});
		return convert;
	} catch (err) {
		dispatch({ type: PAYMENT_PHONE_ERROR, error: err });
		return err;
	}
};

export const code = id => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const businessId = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const clientId = cardSelector.getFirstCardId(state);

	try {
		const { data: { response } } = await API.internationalPayment.code(id, clientId, businessId);
		success(dispatch)({ title: M.success.code.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};
