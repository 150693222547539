import React, { useCallback, useState } from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import Container from '../../../components/Container';
import Nav from '../../../sections/Nav';
import M from './PurchaseProposal.locale.json';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import { injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import API from 'services/api';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from 'actions/auth';
import { GTMEvents } from '../../../constants/gtm';
import Tooltip from '../../../components/Tooltip';
import './PurchaseProposal.scss';
import cardPaySystem from '../../../../img/paymentSystems/visamastercard-logo (1).png';
import contactPaySystem from '../../../../img/paymentSystems/contact-pay-logo1.png';
import usdtPaySystem from '../../../../img/paymentSystems/tether-usdt-logo.png';
import Wrap from '../../../components/Wrap';
import Modal from '../../../components/Modal';
import Icon from '../../../components/Icon';
import { success, error } from 'actions/toast';
import moment from 'moment';


const mapState = state => ({
	cards: state.card.items || [],
});
const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
		success: title => success(dispatch)(title),
		error: title => error(dispatch)(title),
	}
});
export const PAYMENTS_TYPES = Object.freeze({
	CARD: 'CARD',
	CONTACTPAY: 'CONTACTPAY',
	MULTIHUB: 'MULTIHUB',
});
const PurchaseProposal = ({ cards, intl, actions, history }) => {
	const [loading, setLoading] = useState(false);
	const [systemsModalVisible, setSystemsModalVisible] = useState(false);

	const t = intl.formatMessage;

	const onCheck = useCallback(
		(type, paymentId = null) => {
			setLoading(true);
			actions.auth.checkPayment(type, cards[0].id, paymentId, { linking: false })
				.then(response => {
					setSystemsModalVisible(false);
					if (response.success) {
						localStorage.removeItem('paymentType');
						localStorage.removeItem('paymentId');
						actions.auth.togglePurchaseRegistration(true);
					}
					else {
						localStorage.setItem('paymentType', type);
						localStorage.setItem('paymentId', response.id);
						history.push(`/auth/purchase/confirm`, { type, id: response.id });
						window.open(response.checkoutUrl, '_blank');
					}
				})
				.catch(() => actions.error({ title: M.errors.check }))
				.finally(() => setLoading(false));
		}, []);

	const onTopUp = useCallback(
		type => {
			setLoading(true);
			actions.auth.createPayment(type)
				.then(({ id, checkoutUrl }) => {
					setSystemsModalVisible(false);
					if (checkoutUrl) {
						localStorage.setItem('paymentType', type);
						localStorage.setItem('paymentId', id);
						history.push(`/auth/purchase/confirm`, { type, id });
						gtag('event', GTMEvents.awaitingPayment.eventName, { ...GTMEvents.awaitingPayment.eventParameters });
						gtag_report_conversion('UnIFCPG8pIkZEIqw7pcD', 'https://my.weststeincard.com/auth/purchase/confirm');
						window.open(checkoutUrl, '_blank');
					} else onCheck(type, id);
				})
				.catch(() => actions.error({ title: M.errors.creation }))
				.finally(() => setLoading(false));
		},
		[onCheck]
	);

	return (
		<Layout className="PurchaseProposalScreen Screen">
			<Nav logo />
			<Section>
				<Container small>
					<Title title={t(M.title)} />

					<p style={{ textAlign: 'center', marginBottom: 10 }}>{t(M.legend)}</p>

					<div>
						<Button onClick={() => setSystemsModalVisible(true)} style={{ marginBottom: 10 }}>
							{t(M.buttons.topup)}
						</Button>
					</div>

					<div className="systemsHintRow">
						<img src={cardPaySystem} className="systemHintImage" alt="system" />
						{/*<img src={usdtPaySystem} className="systemHintImage" alt="system"/>*/}
					</div>

					<div
						style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}
					>
						<span>{t({ id: 'screens.purchaseProposal.hint' })}</span>
						<Tooltip.Mark large>
							<Tooltip
								className="hintText"
								title="Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Lithuania, Latvia, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, Iceland, Liechtenstein, Norway, Switzerland, United Kingdom, India, Japan, Singapore, Thailand, Australia, Canada, Malaysia, Mexico, New Zealand, Brazilia"
								bottom
							/>
						</Tooltip.Mark>
					</div>
				</Container>
			</Section>

			{systemsModalVisible && (
				<Modal>
					<Section>
						<Container medium>
							<Wrap className="PfsWarning__wrap">
								<Icon
									className="PfsWarning-close"
									onClick={() => setSystemsModalVisible(false)}
									name="close-small"
									disabled={loading}
								/>
								<Title
									subtitle={t({ id: 'screens.purchaseProposal.selectSystem' })}
									small
									className='PfsWarning-text'
								/>

								<div>
									<Button onClick={loading ? () => {} : () => onTopUp(PAYMENTS_TYPES.CARD)}>
										<div className="modal__actionBtnRow">
											<div className="iconContainer">
												<img src={cardPaySystem} style={{ objectFit: 'cover', width: '100%', height: '100%' }}
													 alt="system"/>
											</div>
											{t({ id: 'screens.purchaseProposal.system.card' })}
										</div>
									</Button>
								</div>
							</Wrap>
						</Container>
					</Section>
				</Modal>
			)}
		</Layout>
	);
};

export default withRouter(connect(mapState, mapDispatch)(injectIntl(PurchaseProposal)));
