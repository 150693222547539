import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputError from '../InputError';
import './Input.scss';

class Input extends React.PureComponent {
	static propTypes = {
		id: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.string,
		]),
		maxLength: PropTypes.number,
		name: PropTypes.string.isRequired,
		label: PropTypes.string,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		unit: PropTypes.string,
		disabled: PropTypes.bool,
		autoFocus: PropTypes.bool,
		type: PropTypes.string,
		errors: PropTypes.array,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		onChange: PropTypes.func,
		className: PropTypes.string,
		tooltip: PropTypes.node,
		hotjar: PropTypes.bool,
	}

	onChange = e => {
		const { onChange, name } = this.props;
		onChange(e.target.value, name, e);
	}

	onBlur = e => {
		const { onBlur, name } = this.props;
		onBlur && onBlur(e.target.value, name, e);
	}

	render() {
		const { className, errors, placeholder, value, label, name, unit, maxLength,
			onFocus, autoFocus, type, disabled, id, tooltip, hotjar, forwardRef = '', otp } = this.props;
		let restProp = {};
		if (otp) {
			restProp = {
				...restProp, inputMode: "numeric",
				autoComplete: "one-time-code",
				pattern: "[0-9]{6}",
				required: true
			}
		}
		return (
			<div className={classnames('Input', className, errors && 'Input--Invalid')}>
				{((placeholder && value && !!value.length) || label) && (
					<label htmlFor={name} className="Input__Label">
						{label || placeholder}
					</label>
				)}

				<div className="Input__Wrap">
					{unit && (
						<div className="Input__Unit">
							{unit}
						</div>
					)}

					<input
						id={id || name}
						ref={forwardRef}
						className="Input__Input"
						onChange={this.onChange}
						onFocus={onFocus}
						onBlur={this.onBlur}
						name={name}
						value={value}
						type={type}
						autoComplete="off"
						autoFocus={autoFocus}
						placeholder={placeholder}
						disabled={disabled}
						data-hj-allow={hotjar}
						maxLength={maxLength}
						{...restProp}
					/>
				</div>

				{!!tooltip && <div className="Input__Tooltip">{tooltip}</div>}

				{errors && !!errors.length &&
					<InputError
						placeholder={placeholder}
						label={label}
						errors={errors}
					/>
				}
			</div>
		);
	}
}

export default Input;
