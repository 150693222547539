/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { error, success } from 'actions/toast';
import * as exchangeActions from 'actions/exchange';

import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import Title from 'components/Title';
import Container from 'components/Container';
import ButtonLink from 'components/ButtonLink';
import Button from 'components/Button';

import { ExchangeCurrencyProps } from './types';
import swapIcon from './assets/swapIcon';
import M from '../Exchange.locale.json';

const mapDispatchToProps = dispatch => {
	return {
		errorToast: payload => {
			error(dispatch)(payload);
		},
		successToast: payload => {
			success(dispatch)(payload);
		}
	};
};

const ExchangeCurrency = ({ currency, intl, ...props }) => {
	const [loading, setLoading] = useState(false);
	const [currenciesList, setCurrenciesList] = useState({ from: [], to: [] });
	const [amount, setAmount] = useState('0');
	const [convertedAmount, setConvertedAmount] = useState('');
	const [currencyFrom, setCurrencyFrom] = useState(undefined);
	const [currencyTo, setCurrencyTo] = useState(undefined);
	const [available, setAvailable] = useState(0);
	const [rates, setRates] = useState({
		rate: 0,
		maxLimit: null,
		minLimit: null
	});
	const allCurrenciesList = useRef([]);
	const isDisabled =
		isNaN(amount) ||
		amount === 0 ||
		!currencyFrom ||
		!currencyTo ||
		Number(amount) > rates.maxLimit ||
		Number(amount) < rates.minLimit;

	const history = useHistory();
	const t = intl.formatMessage;

	const findCurrencyObject = currencyCode => {
		return allCurrenciesList.current.find(c => c.currencyCode === currencyCode);
	};

	const onChangeAmount = value => {
		if (isNaN(value)) return;

		setAmount(value);
	};

	const calcConvertedAmount = value => {
		const r = Number(value) * Number(rates.rate);
		const currencyToObject = findCurrencyObject(currencyTo);
		if (!currencyToObject) return;
		const a = currencyToObject.currencyCode !== 'EUR' ? r : r.toFixed(2);
		const res = rates.rate && !isNaN(value) ? a : value;
		setConvertedAmount(res.toString());
	};

	const setMaxAmount = () => {
		const max = (rates.maxLimit < available
			? rates.maxLimit
			: available
		).toString();
		setAmount(max);
	};

	const revertCurrencies = async () => {
		const currencyToObject = findCurrencyObject(currencyTo);
		if (currencyToObject && currencyToObject.balance) {
			const newCurrencyFrom = currencyTo;
			const newCurrencyTo = currencyFrom;

			setCurrencyFrom(null);
			setCurrencyTo(null);

			setTimeout(() => {
				setCurrencyFrom(newCurrencyFrom);
				onChangeFrom(newCurrencyFrom);
				setCurrencyTo(newCurrencyTo);
			}, 0);
		} else {
			props.errorToast({
				title: {
					id: 'customMessage',
					defaultMessage: t(M.exchange.errors.noBalance)
				}
			});
		}
	};

	const formatCurrenciesToSelect = currencies =>
		currencies.map(currency => ({
			value: currency.currencyCode,
			title: currency.currencyCode,
			...currency
		}));

	const getDirections = currency => {
		return currency.directions
			.filter(c => c !== currency.currencyCode)
			.map(currency => ({ value: currency, title: currency }));
	};

	const getCurrencies = async () => {
		setLoading(true);
		//{ invalidData: true, from, to, isFrom }
		const propsCurrency = currency;
		try {
			const { success, data } = await exchangeActions.getExchangesInfo({
				invalidData: false
			});
			if (success) {
				const currenciesFiltered = data.filter(
					currency =>
						currency.exists &&
						currency.isReady &&
						currency.balance > 0
				);
				allCurrenciesList.current = data;
				const currenciesFrom = formatCurrenciesToSelect(
					currenciesFiltered
				);

				const selectedFromCurrency = currenciesFrom.find(
					currency =>
						currency.currencyCode === propsCurrency.currencyCode
				);

				const currenciesTo = getDirections(
					selectedFromCurrency || currenciesFiltered[0]
				);

				setCurrencyTo(currenciesTo[0].value);
				setCurrencyFrom(
					selectedFromCurrency.value || currenciesFrom[0].value
				);
				setAvailable(selectedFromCurrency.balance || currenciesFrom[0].balance);
				setCurrenciesList({ from: currenciesFrom, to: currenciesTo });
			}
		} catch (e) {
		} finally {
			setLoading(false);
		}
	};

	const onBlurAmount = value => {
		if (parseFloat(value) > available) {
			setAmount(parseFloat(available) || 0);
			props.errorToast({
				title: {
					id: 'customMessage',
					defaultMessage: t(M.exchange.errors.amount)
				}
			});
		} else {
			setAmount(parseFloat(value) || 0);
		}
	};

	const onChangeFrom = value => {
		const selectedCurrency = findCurrencyObject(value);
		setCurrenciesList(prev => ({
			...prev,
			to: getDirections(selectedCurrency)
		}));
		setCurrencyFrom(value);
		setAvailable(selectedCurrency.balance);
	};

	const onChangeTo = value => {
		setCurrencyTo(value);
	};

	const onSubmit = async e => {
		e.preventDefault();

		setLoading(true);
		try {
			const { success, data } = await exchangeActions.exchangeCurrency({
				amount: Number(amount),
				sourceCurrencyCode: currencyFrom,
				targetCurrencyCode: currencyTo
			});
			history.push(`/exchange/swap/preview`, { data: data });
		} catch ({ errors }) {
			for (const error in errors) {
				if (Object.hasOwnProperty.call(errors, error)) {
					props.errorToast({
						title: {
							...errors[error][0],
							id: errors[error][0].id || 'undefinedError'
						}
					});
				}
			}
		} finally {
			setLoading(false);
		}
	};

	//on change amount useEffect
	useEffect(() => {
		if (!isNaN(amount) && currencyTo) calcConvertedAmount(amount);
	}, [amount, currencyTo, rates]);

	//componentDidMount
	useEffect(() => {
		async function fetchData () {
			const currencies = await getCurrencies();
		}

		fetchData();
	}, []);

	//on currencies change
	useEffect(() => {
		async function refreshRates () {
			setLoading(true);
			const { success, data } = await exchangeActions.getRates({
				currencyFrom: currencyFrom,
				currencyTo: currencyTo
			});

			if (success) {
				setRates(data);
				calcConvertedAmount(amount);
			}
			setLoading(false);
		}

		if (!currencyTo || !currencyFrom || currencyTo === currencyFrom) return;
		refreshRates();
	}, [currencyTo, currencyFrom]);

	return (
		<div className="ExchangeCurrency">
			<Container small>
				<Title title={t(M.exchange.title)} />
				<div className="ExchangeCurrency__Wrapper">
					<div className="ExchangeCurrency__Currencies">
						<div>
							<InputSelect
								name="from"
								onChange={onChangeFrom}
								placeholder={t(M.exchange.inputs.from)}
								value={currencyFrom}
								data={currenciesList.from}
							/>
						</div>
						<div
							onClick={revertCurrencies}
							style={{ cursor: 'pointer' }}
						>
							{swapIcon}
						</div>
						<div>
							<InputSelect
								name="to"
								onChange={onChangeTo}
								placeholder={t(M.exchange.inputs.to)}
								value={currencyTo}
								data={currenciesList.to}
							/>
						</div>
					</div>
					<div className="ExchangeCurrency__Amount">
						<div>
							<Input
								name="amount"
								placeholder={t(M.send.inputs.amount)}
								onChange={onChangeAmount}
								value={amount}
								onBlur={onBlurAmount}
							/>
							{rates.minLimit && rates.maxLimit && <span style={{ fontSize: 13 }}>{`${rates.minLimit} ${currencyFrom} - ${rates.maxLimit} ${currencyFrom}`}</span>}
						</div>
						<div>
							<Input
								name="convertedAmount"
								placeholder={t(M.send.inputs.amount)}
								value={convertedAmount}
								disabled
							/>
						</div>
					</div>
					<div className="ExchangeCurrency__Info">
						<p>
							{currencyFrom} {t(M.exchange.messages.available)}{': '}
							<ButtonLink onClick={setMaxAmount}>
								{available}
							</ButtonLink>
						</p>
					</div>
					<Button
						loading={loading}
						disabled={isDisabled}
						onClick={onSubmit}
					>
						{t(M.exchange.buttons.next)}
					</Button>
				</div>
			</Container>
		</div>
	);
};

ExchangeCurrency.propTypes = ExchangeCurrencyProps;

export default compose(
	injectIntl,
	connect(null, mapDispatchToProps)
)(ExchangeCurrency);
