import * as templateActions from 'actions/template';
import Button from 'components/Button';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentCard } from 'selectors/card';
import currentCardTypes from 'types/currentCard';
import PropTypes from 'prop-types';
import intlTypes from 'types/intl';
import './Templates.scss';

import { injectIntl } from 'react-intl';
import M from './Templates.locale.json';

@injectIntl
class SaveButton extends React.PureComponent {
	static propTypes = {
		id: PropTypes.number,
		type: PropTypes.oneOf(['SEPA', 'CARD_TO_CARD', 'INTERNATIONAL']),
		intl: intlTypes.isRequired
	};

	constructor (props) {
		super(props);
		this.state = {
			saved: false
		};
		this.onSave = this.onSave.bind(this);
	}

	async onSave () {
		try {
			await templateActions.createTemplateFromPayment(
				this.props.id,
				this.props.type
			);
			this.setState({ saved: true });
		} catch (error) {}
	}

	render () {
		const { saved } = this.state;
		const t = this.props.intl.formatMessage;
		return (
			<Button
				disabled={saved}
				form
				small
				secondary
				className="PaymentCreateScreen__Submit"
				onClick={this.onSave}
			>
				{t(M.buttons.save)}
			</Button>
		);
	}
}

export default SaveButton;
