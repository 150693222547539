import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import intlTypes from 'types/intl';

import NotificationItem from '../NotificationItem/NotificationItem';
import Title from '../../../Title';
import M from '../../NotificationPopup.locale.json';

import './NotificationContent.scss';

const NotificationContent = ({ intl, notifications, openNotificationModal, closeNotifications, markAllAsRead, alreadyOrderedCard }) => {
	const t = intl.formatMessage;
	const ref = useRef(null);
	const count = notifications.length;

	const scrollContainerToBottom = useCallback(() => {
		if (ref.current) {
			ref.current.scrollBy(0, 999);
		}
	}, [ref.current]);

	return (
		<div className='notifications-popup-content' ref={ref}>
			<Title title={t(M.title)} className={'notifications-popup-content__title--desktop'} />
			{/* {count > 1 && (
				<div className='notifications-popup-content__mark'>
					<span
						className='ButtonLink'
						tabIndex={0}
						role='button'
						onClick={markAllAsRead}
					>{t(M.buttons.mark.all)}</span>
				</div>
			)} */}
			{notifications.map((notification, index) => (
				<NotificationItem 
					notification={notification}
					key={notification.id}
					closeNotifications={closeNotifications}
					scrollContainerToBottom={index === count - 1 ? scrollContainerToBottom : undefined}
					openNotificationModal={openNotificationModal}
					alreadyOrderedCard={alreadyOrderedCard}
				/>
			))}
		</div>
	);
};


NotificationContent.propTypes = {
	intl: intlTypes.isRequired,
	notifications: PropTypes.array,
	closeNotifications: PropTypes.func,
	markAllAsRead: PropTypes.func,
	openNotificationModal: PropTypes.func,
	alreadyOrderedCard: PropTypes.func,
};

export default injectIntl(NotificationContent);
