import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Route } from "react-router-dom";

import * as cardActions from "actions/card";
import * as clientSelector from "selectors/client";
import * as cardSelector from "selectors/card";
import VerifyCardModal from "../Dashboard/VerifyCardModal";
import Button from "components/Button";
import Balance from "components/Balance";
import Section from "components/Section";
import Container from "components/Container";
import SendPayments from "sections/SendPayments";
import { clientRole, card, cardStatus } from "constants/common";
import { paths } from "./constants";
import M from "./Header.locale.json";
import "./Header.scss";

import clientTypes from "types/client";
import historyTypes from "types/history";
import intlTypes from "types/intl";
import currentCardType from "types/currentCard";
import { cardActionsTypes } from "types/actions";
import { getIsUserVerified } from "../../selectors/user";

import * as userSelector from "selectors/user";
import userType from "types/user";
import helpers from "helpers";

const DOCUMENT_URL = "/settings/document";
const depositProductCards = [
	card.CARD04,
	card.CARD05,
	card.CARD06,
	card.CARD07,
	card.CARD08,
	card.CARD14,
	card.CARD17,
	card.CARD18,
];

const mapState = state => ({
	client: clientSelector.getCurrentClient(state),
	balance: cardSelector.getBalance(state),
	currentCard: cardSelector.getById(state, state.card.current),
	isUserVerified: getIsUserVerified(state),
	currentUser: userSelector.getUser(state)
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch)
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Header extends React.PureComponent {
	static propTypes = {
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired
		}),
		client: clientTypes.isRequired,
		history: historyTypes.isRequired,
		intl: intlTypes.isRequired,
		currentCard: currentCardType,
		balance: PropTypes.number,
		isUserVerified: PropTypes.bool.isRequired,
		currentUser: userType,
		withBack: PropTypes.bool
	};

	state = {
		loader: false,
		isVerificationModalVisible: false
	};

	componentDidMount() {
		this.mounted = true;
		const { history } = this.props;

		if (history.location.pathname !== "/") {
			this.onFetch();
		}
	}

	componentWillUnmount() {
		this.mounted = true;
	}

	componentDidUpdate(prevProps) {
		const { client } = this.props;
		if (client.id === prevProps.client.id) return;
		this.onFetch();
	}

	onFetch = async () => {
		if (this.mounted) {
			this.setState({ loader: true });

			await this.props.actions.card.fetch();

			this.setState({ loader: false });
		}
	};

	onToggleSendMode = () => {
		const { isUserVerified } = this.props;

		// if (!isUserVerified) {
		// 	this.setState({ isVerificationModalVisible: true });
		// 	return;
		// }

		this.props.history.push(paths.payments);
	};

	onDeposit = () => {
		const { isUserVerified } = this.props;

		// if (!isUserVerified) {
		// 	this.setState({ isVerificationModalVisible: true });
		// 	return;
		// }

		this.props.history.push(paths.depositSend);
	};

	onVerificationSkip = () =>
		this.setState({ isVerificationModalVisible: false });

	onVerifyCard = () => this.props.history.push(DOCUMENT_URL);

	showVerificationModal = () =>
		this.setState({ isVerificationModalVisible: true });

	mounted = false;

	render() {
		const { client, balance, currentCard, currentUser } = this.props;
		const { loader, isVerificationModalVisible } = this.state;

		if (!currentCard) return null;

		const isExpired = currentCard.cardStatus === cardStatus.EXPIRED;
		const access =
			(client.role === clientRole.OWNER ||
				client.roleType === clientRole.PRIVATE) &&
			!isExpired;
		const t = this.props.intl.formatMessage;
		const isDepositButtonRender =
			currentCard &&
			(depositProductCards.includes(currentCard.product) ||
				helpers.checker.isSU(currentUser));

		return (
			<React.Fragment>
				<Section className="Header" small>
					{isVerificationModalVisible && (
						<VerifyCardModal
							t={t}
							subtitle=""
							title={t({ id: "modals.verification.title" })}
							skip={t({ id: "modals.verification.skip" })}
							verify={t({ id: "modals.verification.verify" })}
							hideLegend
							onVerify={this.onVerifyCard}
							onSkip={this.onVerificationSkip}
						/>
					)}
					<Container>
						{currentCard && balance !== undefined && !loader && (
							<Balance amount={currentCard.availBal} />
						)}
						{this.props.withBack &&
							<Button
								large
								className="Header__Button_Left"
								onClick={()=>this.props.history.goBack()}
							>
								{t(M.buttons.back)}
							</Button>
						}
						<Button
							large
							className="Header__Button"
							onClick={this.onToggleSendMode}
							disabled={!access}
						>
							{t(M.buttons.send)}
						</Button>
						{isDepositButtonRender && (
							<Button
								large
								ghost
								className="Header__Button"
								onClick={this.onDeposit}
								disabled={!access}
							>
								{t(M.buttons.deposit)}
							</Button>
						)}
					</Container>
				</Section>
				<Route path={paths.payments} component={SendPayments} />
				<Route path={paths.c2cPayments} component={SendPayments} />
				<Route
					path={paths.internationalPayment}
					component={SendPayments}
				/>
			</React.Fragment>
		);
	}
}
