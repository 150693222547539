import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import store from './stores';
import Router from './Router';
import Language from './Language';
import Referrals from './helpers/referrals';

export default class Root extends React.PureComponent {
	constructor () {
		super();
		Referrals.onPageLoad();
		this.handleDynamicLink();
	}
	handleDynamicLink() {
		const urlParams = new URLSearchParams(window.location.search);
		const targetParam = urlParams.get('target');
		if (targetParam) localStorage.setItem('weststein:target', targetParam);
	}

	render () {
		return (
			<Provider store={store}>
				<Language>
					<BrowserRouter>
						<Route component={Router} />
					</BrowserRouter>
				</Language>
			</Provider>
		);
	}
}
