import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Divider from 'components/Divider';
import Banner from 'components/Banner';
import * as authActions from 'actions/auth';
import helpers from 'helpers';
import Auth from 'schemas/auth';
import M from './Login.locale.json';

import intlTypes from 'types/intl';

const mapState = state => ({
	user: state.user
});

const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch)
	}
});

@injectIntl
@connect(mapState, mapDispatch)
export default class LoginWithEmail extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		user: PropTypes.object
	};

	state = {
		loader: false,
		form: {
			email: '',
			password: '',
		},
		messages: [],
		errors: {
			email: [],
			password: []
		},
		user: {}
	};

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single(
				{
					...form,
					[name]: value
				},
				name,
				Auth.login
			);
		}

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: messages }
		});
	};

	onSubmit = async e => {
		e.preventDefault();
		const { form, loader, errors } = this.state;
		const { actions } = this.props;
		const self = this;

		if (loader) return;

		self.setState({ messages: [], loader: true });
		const errorsValidation = helpers.validator.all(form, Auth.login);
		if (errorsValidation) {
			self.setState({ errors: { ...errors, ...errorsValidation } });
			return;
		}

		window.grecaptcha.ready(function() {
			window.grecaptcha.execute('6Ld4MZ4mAAAAADQxjPLytjSISPQamhrKG5Ctfc9K', {action: 'submit'}).then(async function(token) {
				const response = await actions.auth.login({...form, 'g-recaptcha-response': token});
				if (response && response.errors) {
					const update = {
						errors: { ...errors, ...response.errors },
						loader: false
					};
					if (response.messages) update.messages = response.messages;
					self.setState(update);
				}
			});
		});
	};

	render() {
		const { form, errors, messages, loader } = this.state;
		const { user, intl } = this.props;
		const t = intl.formatMessage;

		if (user) return <Redirect to={{ pathname: '/' }} />;

		return (
			<div>
				{messages && !!messages.length && (
					<Banner type='error' data={messages} />
				)}
				<Form onSubmit={this.onSubmit}>
					<Form.Group>
						<Input
							name='email'
							placeholder={t(M.inputs.email)}
							onChange={this.onChange}
							value={form.email}
							errors={errors.email}
							autoFocus
						/>
					</Form.Group>
					<Form.Group>
						<Input
							id='password-login-email'
							name='password'
							type='password'
							placeholder={t(M.inputs.password)}
							onChange={this.onChange}
							value={form.password}
							errors={errors.password}
						/>
					</Form.Group>
					<Button form loading={loader}>
						{t(M.buttons.login)}
					</Button>
				</Form>
				{/*<Form.Note>*/}
				{/*	{`${t(M.messages.signup)} `}*/}
				{/*	<ButtonLink to='/auth/signup/private'>*/}
				{/*		{t(M.buttons.signup)}*/}
				{/*	</ButtonLink>*/}
				{/*</Form.Note>*/}
				{/*<Divider />*/}
				<Form.Note>
					{`${t(M.messages.reset)} `}
					<ButtonLink to='/auth/reset'>
						{t(M.buttons.reset)}
					</ButtonLink>
				</Form.Note>
			</div>
		);
	}
}
