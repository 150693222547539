export default {
	['CARD_TO_CARD']: {
		recipientCardNumber: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
			length: {
				is: 16,
				message: '^schemas.length.is'
			}
		},
		amount: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^([0-9]*|\d*\.\d{0,2}?)$/,
				message: '^schemas.alphanumeric.amount'
			}
		},
		title: {
			presence: { message: '^schemas.presence' },
			alphanumeric: {
				message: '^schemas.alphanumeric.space',
				options: { space: true }
			},
			length: {
				minimum: 1,
				maximum: 20,
				message: '^schemas.length.between'
			}
		}
	},
	['SEPA']: {
		recipient: {
			presence: { message: '^schemas.presence' },
			alphanumeric: {
				message: '^schemas.alphanumeric.space',
				options: { space: true }
			},
			length: {
				minimum: 2,
				maximum: 20,
				message: '^schemas.length.between'
			}
		},
		title: {
			presence: { message: '^schemas.presence' },
			alphanumeric: {
				message: '^schemas.alphanumeric.space',
				options: { space: true }
			},
			length: {
				minimum: 1,
				maximum: 20,
				message: '^schemas.length.between'
			}
		},
		iban: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
			length: {
				minimum: 15,
				maximum: 31,
				message: '^schemas.length.between'
			}
		},
		amount: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^([0-9]*|\d*\.\d{0,2}?)$/,
				message: '^schemas.alphanumeric.amount'
			}
		},
		reference: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^[ /a-zA-Z0-9]+$/,
				message: '^schemas.alphanumeric.slash'
			},
			length: {
				minimum: 1,
				maximum: 35,
				message: '^schemas.length.between'
			}
		}
	},
	['INTERNATIONAL']: {
		title: {
			presence: { message: '^schemas.presence' },
			alphanumeric: {
				message: '^schemas.alphanumeric.space',
				options: { space: true }
			},
			length: {
				minimum: 1,
				maximum: 20,
				message: '^schemas.length.between'
			}
		},
		recipient: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.space', options: { space: true } },
			length: {
				minimum: 2,
				maximum: 100,
				message: '^schemas.length.between',
			},
		},
		recipientLastname: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.space', options: { space: true } },
			length: {
				minimum: 2,
				maximum: 100,
				message: '^schemas.length.between',
			},
		},
		amount: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^([0-9]*|\d*\.\d{0,2}?)$/,
				message: '^schemas.alphanumeric.amount'
			},
		},
		currency: {
			presence: { message: '^schemas.presence' },
		},
		country: {
			presence: { message: '^schemas.presence' },
		},
		city: {
			presence: { message: '^schemas.presence' },
			length: {
				maximum: 35,
				message: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z -]+$',
				message: '^schemas.alphabetic'
			}
		},
		line1: {
			presence: { message: '^schemas.presence' },
			length: {
				maximum: 30,
				message: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z0-9 .,&/()-]+$',
				message: '^schemas.alphanumeric.address',
			}
		},
		line2: {
			length: {
				maximum: 30,
				message: '^schemas.length.max',
			},
			format: {
				pattern: '[a-zA-Z0-9 .,&/()-]+$',
				message: '^schemas.alphanumeric.address',
			}
		},
		postalCode: {
			length: {
				maximum: 15,
				message: '^schemas.length.max',
			},
			alphanumeric: { message: '^schemas.alphanumeric.hyphen', options: { space: true, canBeEmpty: true } },
		},
		iban: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
			length: {
				minimum: 8,
				maximum: 31,
				message: '^schemas.length.between',
			},
		},
		bic: {
			presence: { message: '^schemas.presence' },
			// alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
			format: {
				pattern: /^[-a-zA-Z0-9]+$/,
				message: '^schemas.alphanumeric.hyphen.noSpace'
			},
			length: {
				minimum: 6,
				maximum: 11,
				message: '^schemas.length.between',
			},
		},
		reference: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^[ /a-zA-Z0-9]+$/,
				message: '^schemas.alphanumeric.slash'
			},
			length: {
				minimum: 1,
				maximum: 35,
				message: '^schemas.length.between',
			},
		},
	}
};
