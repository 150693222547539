import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import calendarHelper from 'helpers/calendar';
import intlTypes from '../../types/intl';
import { MONTHS } from './constants';
import InputSelect from '../InputSelect';
import Week from './Week';
import './Calendar.scss';

const years = new Array(111)
	.fill(new Date().getFullYear())
	.map((year, index) => ({ value: year - index, title: `${year - index}` }));

const years_future = new Array(10)
	.fill(new Date().getFullYear())
	.map((year, index) => ({ value: year - index, title: `${year + index}` }));

const mapState = state => ({
	language: state.language
});

@connect(mapState)
@injectIntl
export default class Calendar extends React.PureComponent {
	static propTypes = {
		className: PropTypes.string,
		year: PropTypes.number,
		month: PropTypes.number,
		range: PropTypes.instanceOf(Date),
		disabled: PropTypes.instanceOf(Date),
		onSelect: PropTypes.func,
		onChange: PropTypes.func,
		onClose: PropTypes.func,
		language: PropTypes.object,
		intl: intlTypes.isRequired,
		future: PropTypes.bool,
	};

	onMonthChange = month => {
		const { onChange, year } = this.props;

		onChange({ month, year });
	};

	onYearChange = year => {
		const { onChange, month } = this.props;

		onChange({ year, month });
	};

	onPropagate = e => {
		e.stopPropagation();
	};

	render () {
		const { language, className, intl, onClose }  = this.props;
		const t = intl.formatMessage;

		moment.locale(language.locale);
		const days = moment.weekdaysShort();
		const today = moment(new Date());
		const year = this.props.year || today.year();
		const month =
			typeof this.props.month === 'number'
				? this.props.month
				: today.month();
		const current = moment(new Date(year, month, 1));
		const range = this.props.range ? moment(this.props.range) : null;
		const calendar = calendarHelper.calendar(current, range);

		const header = days.map(item => (
			<div key={item}>{item}</div>
		));

		const content = calendar.map(week => (
			<Week
				key={Math.random() * 10000}
				data={week}
				onSelect={this.props.onSelect}
				disabled={this.props.disabled}
			/>
		));

		const months = MONTHS.map(month => ({
			...month,
			title: t({ id: `calendar.month.${month.value}` }),
		}));

		return (
			<OutsideClickHandler onOutsideClick={onClose}>
				<div className={classnames('Calendar', className)} onClick={this.onPropagate}>
					<div className="Calendar__Content">
						<div className="Calendar__Nav">
							<InputSelect
								className="Calendar__year"
								name="year"
								placeholder=""
								onChange={this.onYearChange}
								value={year}
								data={this.props.future ? years_future : years}
							/>
							<InputSelect
								className="Calendar__month"
								name="year"
								placeholder=""
								onChange={this.onMonthChange}
								value={month}
								data={months}
							/>
						</div>

						<div className="Calendar__Header">{header}</div>

						{content}
					</div>
				</div>
			</OutsideClickHandler>
		);
	}
}
