/* eslint-disable react/display-name */
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';

import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';

import * as notificationsSelector from 'selectors/notifications';
import * as notificationsActions from 'actions/notifications';
import { notificationsActionsTypes } from 'types/actions';

import { PUSH_NOTIFICATION_TYPE } from 'constants/notifications';

import { NotificationIcon } from '../icons';
import NotificationContent from './components/NotificationContent/NotificationContent';
import SmartWarning from './components/SmartWarning';
import Icon from '../Icon';
import NotificationWarning from './components/NotificationWarning';

import './NotificationsPopup.scss';

const NotificationsPopup = React.memo(function ({ notifications, actions, count }) {

	const [isOpened, setIsOpened] = useState(false);
	const [isWarningModal, setWarningModal] = useState(false);
	const [isOrderCardModal, setOrderCardModal] = useState(false);
	const [isNotOrderCardModal, setNotOrderCardModal] = useState(false);
	const [currentNotificationId, setCurrentNotificationId] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const onlyUnreadNotifications = notifications.filter(notification => notification.unread);

	const counterView = useMemo(() => {
		return count >= 10 ? '9+' : count;
	}, [count]);

	const markAllAsRead = () => {
		const isSmart = !!notifications.filter(
			notification =>(
				notification.pushNotificationType === PUSH_NOTIFICATION_TYPE.PRIVATE 
				|| notification.pushNotificationType === PUSH_NOTIFICATION_TYPE.TIANDE
			)
		).length;
		if (isSmart) {
			setWarningModal(true);
		} else {
			actions.notifications.markAsRead(notifications);
		}
	};


	const toggleOpen = () => { actions.notifications.get().then(() => setIsOpened(state => !state)) };

	const handleClose = () => { actions.notifications.getCount(); setIsOpened(false) };

	const handleOutsideClose = e => {
		if (!e.target.closest('.notifications-icon-wrap') && isOpened) {
			actions.notifications.getCount();
			setIsOpened(false);
		}
	};

	const handleMarkConfirm = () => {
		actions.notifications.markAsRead(notifications);
		setWarningModal(false);
	};

	const handleMarkDecline = () => {
		setWarningModal(false);
	};

	const openNotificationModal = (notificationId, action) => {
		setCurrentNotificationId(notificationId);
		
		switch (action) {
			case 'orderCard': {
				setOrderCardModal(true);
				break;
			}

			case 'notOrderCard': {
				setNotOrderCardModal(true);
				break;
			}
		}

		setIsOpened(false);
	};

	const closeOrderModal = () => {
		setOrderCardModal(false);
	};

	const closeNotOrderOpen = () => {
		if (!isLoading) {
			setNotOrderCardModal(false);
			setIsOpened(true);
		}
	};

	const notOrderCardAction = async () => {
		setIsLoading(true);
		await actions.notifications.cancelOrderingCard(currentNotificationId);
		setIsLoading(false);
		setNotOrderCardModal(false);
	};

	const alreadyCardAction = id => {
		actions.notifications.alreadyCard(id);
	};

	const isActiveNotificationModal = isOrderCardModal || isNotOrderCardModal;

	return (
		<div className='notifications-popup-wrap'>
			<div
				onClick={count ? toggleOpen : undefined}
				className='notifications-icon-wrap'
				role='button'
				tabIndex={0}
			>
				<NotificationIcon
					className={cn('notifications-icon', {
						'notifications-icon--disable': !count
					})}
				/>
				{!!count && (
					<span className='notifications-icon-count'>
						{counterView}
					</span>
				)}
			</div>

			<OutsideClickHandler onOutsideClick={handleOutsideClose}>
				{isWarningModal && (
					<SmartWarning
						onCancel={handleMarkDecline}
						onConfirm={handleMarkConfirm}
					/>
				)}

				{!!count && isOpened && (
					<div className='notifications-popup-desktop'>
						<div className='notifications-popup-desktop-corner__back' />
						<div className='notifications-popup-desktop-wrap'>
							<NotificationContent
								notifications={onlyUnreadNotifications}
								closeNotifications={handleClose}
								markAllAsRead={markAllAsRead}
								openNotificationModal={openNotificationModal}
								alreadyOrderedCard={alreadyCardAction}
							/>
						</div>
						<div className='notifications-popup-desktop-corner__top' />
					</div>
				)}

				<div
					className={cn('notifications-popup-small', {
						'notifications-popup-small--open': isOpened && !!count
					})}
				>
					<Icon
						className='notifications-popup-small__close'
						name='close-small'
						onClick={handleOutsideClose}
					/>
					<NotificationContent
						notifications={onlyUnreadNotifications}
						closeNotifications={handleClose}
						markAllAsRead={markAllAsRead}
						openNotificationModal={openNotificationModal}
						alreadyOrderedCard={alreadyCardAction}
					/>
				</div>
			</OutsideClickHandler>
			{isActiveNotificationModal && (
				<NotificationWarning
					setCloseOrderModal={closeOrderModal}
					closeNotOrderOpen={closeNotOrderOpen}
					isNotOrderCardModal={isNotOrderCardModal}
					isOrderCardModal={isOrderCardModal}
					isLoading={isLoading}
					notOrderCardAction={notOrderCardAction}
				/>
			)}
		</div>
	);
});

NotificationsPopup.propTypes = {
	actions: PropTypes.shape({
		notifications: notificationsActionsTypes
	}),
	notifications: PropTypes.array,
	count: PropTypes.number,
};

const mapState = state => ({
	notifications: notificationsSelector.getNotifications(state),
	count: notificationsSelector.getNotificationsCount(state)
});

const mapDispatch = dispatch => ({
	actions: {
		notifications: bindActionCreators(notificationsActions, dispatch)
	}
});

export default compose(connect(mapState, mapDispatch))(NotificationsPopup);
