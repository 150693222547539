import API from 'services/api';

const { template: templateApi } = API;
const { payment: sepaApi } = API;
const { c2cPayment: c2cApi } = API;
const { internationalPayment: intApi } = API;

export async function getRecurring () {
	try {
		const {
			data: {
				response: { lastPayment, recurringPayments }
			}
		} = await templateApi.getRecurringPayments();

		return {
			lastPayment,
			recurringPayments: formatRecurringToDropdownList(recurringPayments)
		};
	} catch (e) {
		console.log('Error getting reccuring payments : ', e);
	}
}

export function formatRecurringToDropdownList (list) {
	return list.map(payment => ({
		value: payment.id,
		title: payment.recipient,
		subtitle: payment.type
	}));
}

export async function getPaymentFromId (template, cid) {
	let response = {};
	try{
		switch (template.type) {
			case 'SEPA':
				response = await sepaApi.get(template.id, cid);
				break;
			case 'CARD_TO_CARD':
				response = await c2cApi.get(template.id, cid);
				break;
			case 'INTERNATIONAL':
				response = await intApi.get(template.id, cid);
				break;
		}
	} catch (e){
	}
	
	return response; 
}

export async function getSavedTemplates () {
	try {
		const { data: { response } } = await templateApi.getTemplatesList(); 
		return response;
	} catch (error) {
		console.debug(error);
	}
}

export async function createTemplateFromPayment (id, type) {
	try {
		const { data: { response } } = await templateApi.createTemplate({ id, type }); 
	} catch (error) {
		console.log(error);
	}
}

export async function createCustomTemplate (template) {
	try {
		const { data: { response } } = await templateApi.createCustomTemplate(template); 
	} catch (error) {
		console.log(error);
	}
}

export async function getTemplate (id) {
	try {
		const { data: { response } } = await templateApi.getTemplate(id); 
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function removeTemplate (id) {
	try {
		const { data: { response } } = await templateApi.deleteTemplate(id); 
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function updateTemplate (template) {
	try {
		const { data: { response } } = await templateApi.updateTemplate(template); 
		return response;
	} catch (error) {
		console.log(error);
		throw error;
	}
}


export async function getAutopayment (id) {
	try {
		const { data: { response } } = await templateApi.getAutopayment(id);
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function removeAutopayment (id) {
	try {
		const { data: { response } } = await templateApi.deleteAutopayment(id);
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function saveAutopayment (autopayment) {
	try {
		const { data: { response } } = await templateApi.saveAutopayment(autopayment);
		return response;
	} catch (error) {
		console.log(error);
		return error;
	}
}

export async function autopaymentCardholders () {
	try {
		const { data: { response } } = await templateApi.autopaymentCardholders();
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function autopaymentSms (id) {
	try {
		const { data: { response } } = await templateApi.autopaymentSms(id);
		return response;
	} catch (error) {
		console.log(error);
		return error;
	}
}
