import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Section from 'components/Section';
import SwapSendComponent from 'components/Exchange/Exchange';

const Create = ({ location, history }) => {
	const { currency } = location.state || { currency: undefined };
	if (!currency || !currency.currencyCode) {
		history.replace('/exchange');
		return <div />;
	} else {
		return (
			<Layout className="ExchangeScreen Screen" sidebar>
				<Nav />
				<Header withBack />
				<Section large>
					<SwapSendComponent currency={currency} />
				</Section>
			</Layout>
		);
	}
};

Create.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

export default Create;
