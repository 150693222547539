import {
	CARD_SET_SEARCH,
	CARD_SET_STATUS,
	CARD_CURRENT,
	CARD_SET_REGULATORY_DATA_COLLECTION,
	CARD_REMOVE_REGULATORY_DATA_COLLECTION,
	CARD_FETCH_REQUEST,
	CARD_FETCH_SUCCESS,
	CARD_GET_SUCCESS,
	CARD_CREATE_SUCCESS,
	CARD_SET_NEW_DATA_REQUEST,
	CARD_SET_NEW_DATA_SUCCESS,
	CARD_SET_NEW_DATA_ERROR,
	CARD_GET_CVV_REQUEST,
	CARD_GET_CVV_SUCCESS,
	CARD_GET_PIN_REQUEST,
	CARD_GET_PIN_SUCCESS,
	CARD_UPDATE_TO_PLASTIC_REQUEST,
	CARD_UPDATE_TO_PLASTIC_SUCCESS
} from 'constants';
import { clientRole, clientStep, UTMParams } from 'constants/common';
import { success, error } from 'actions/toast';
import API from 'services/api';
import renamer from 'helpers/renamer';
import * as clientSelector from 'selectors/client';
import { getCurrentCard } from 'selectors/card';
import { isVirtualCard } from 'services/card';
import { checkErrorAmount } from 'actions/ui';

import M from './card.locale.json';
import { GTMEvents } from "../constants/gtm";
import {batch} from "react-redux";

export const fetch = clear => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const clientId = isBusiness ? client.entityId : null;

	dispatch({ type: CARD_FETCH_REQUEST, clear });

	try {
		const { data: { response } } = isBusiness
			? await API.client.business.get(clientId, clientStep.BASIC)
			: await API.client.private.get();
		const requests = response.cardholderIds.map(card => API.card.get(card.id, clientId));
		const result = await Promise.all(requests);
		const cards = renamer.from.card.fetch(result.map(item => item.data.response));

		dispatch({ type: CARD_FETCH_SUCCESS, payload: cards });

		return cards;
	} catch (err) {
		return err;
	}
};

export const get = rawId => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const clientId = isBusiness ? client.entityId : null;
	const id = Number(rawId);

	try {
		const result = await API.card.get(id, clientId);
		let card = renamer.from.card.get(result.data.response);

		if (!isBusiness && isVirtualCard(card)) {
			const virtualCardsResponse = await API.card.virtualCards.number(card.id, clientId);

			card = {
				...card,
				cardNumberFull: virtualCardsResponse.data.response.cardNumber
			};
		}

		dispatch({ type: CARD_GET_SUCCESS, payload: { ...card, id } });

		return card;
	} catch (err) {
		return err;
	}
};

export const getCVV = rawId => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const clientId = isBusiness ? client.entityId : null;
	const id = Number(rawId);

	dispatch({ type: CARD_GET_CVV_REQUEST });

	try {
		const { data: { response } } = isBusiness
			? await API.client.business.get(clientId, clientStep.BASIC)
			: await API.client.private.get();
		const result = await API.card.get(id, clientId);
		let card = renamer.from.card.get(result.data.response);


		if (!isBusiness && isVirtualCard(card)) {
			await API.card.virtualCards.cvv(
				card.id,
				clientId,
				response.address.postalCode
			);

			dispatch({ type: CARD_GET_CVV_SUCCESS });
		}
	} catch (err) {
		return err;
	}
};

export const getPIN = rawId => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const clientId = isBusiness ? client.entityId : null;
	const id = Number(rawId);

	dispatch({ type: CARD_GET_PIN_REQUEST });

	try {
		const {
			data: { response }
		} = isBusiness
			? await API.client.business.get(clientId, clientStep.BASIC)
			: await API.client.private.get();
		const result = await API.card.get(id, clientId);
		let card = renamer.from.card.get(result.data.response);

		if (!isBusiness && isVirtualCard(card)) {
			await API.card.virtualCards.pin(
				card.id,
				clientId,
				response.address.postalCode
			);

			dispatch({ type: CARD_GET_PIN_SUCCESS });
		}
	} catch (err) {
		return err;
	}
};

export const updateVirtualToPlastic = card => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const clientId = isBusiness ? client.entityId : null;

	dispatch({ type: CARD_UPDATE_TO_PLASTIC_REQUEST });

	try {
		if (!isBusiness && isVirtualCard(card)) {
			await API.card.virtualCards.updateVirtualToPlastic(
				card.id,
				clientId
			);

			const updatedCardGetter = get(card.id);
			await updatedCardGetter(dispatch, getState);

			dispatch({ type: CARD_UPDATE_TO_PLASTIC_SUCCESS });
		}
	} catch (err) {
		return err;
	}
};

export const create = form => async dispatch => {
	try {
		const utmParams = localStorage.getItem(UTMParams);
		const utm = utmParams ? JSON.parse(utmParams) : {};
		const { data: { response } } = await API.card.secondary(form.card, { ...form, utm, chanel: 'web' });
		const card = renamer.from.card.get(response);
		dispatch({ type: CARD_CREATE_SUCCESS, payload: card });
		return response;
	} catch (err) {
		checkErrorAmount(dispatch, err.errors);
		return err;
  }
};

export const createOther = form => async dispatch => {
	try {
		const utmParams = localStorage.getItem(UTMParams);
		const utm = utmParams ? JSON.parse(utmParams) : {};
		const { data: { response } } = await API.card.secondaryOther(form.card, { ...form, utm, chanel: 'web' });
		const card = renamer.from.card.get(response);
		dispatch({ type: CARD_CREATE_SUCCESS, payload: card });
		return response;
	} catch (err) {
		checkErrorAmount(dispatch, err.errors);
		return err;
  }
};

export const reissue = id => async dispatch => {
	try {
		const utmParams = localStorage.getItem(UTMParams);
		const utm = utmParams ? JSON.parse(utmParams) : {};
		const { data: { response } } = await API.card.reissue(id, { utm, chanel: 'web' });
		return response;
	} catch (err) { return err; }
};


export const activate = (number, id) => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const cid = isBusiness ? client.entityId : null;
	try {
		const { data: { response } } = await API.card.activate(number, id, cid);
		gtag('event', GTMEvents.cardActivated.eventName, { ...GTMEvents.cardActivated.eventParameters});
		success(dispatch)({ title: M.success.activate.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const pin = id => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const cid = isBusiness ? client.entityId : null;
	try {
		const { data: { response } } = await API.card.pin(id, cid);
		success(dispatch)({ title: M.success.pin.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const block = id => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const cid = isBusiness ? client.entityId : null;
	try {
		const { data: { response, errors } } = await API.card.block(id, cid);
		if (response && response.errors) throw response.errors;
		if (errors) throw errors;
		success(dispatch)({ title: M.success.block.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const unblock = id => async (dispatch, getState) => {
	const client = clientSelector.getCurrentClient(getState());
	const isBusiness = client.roleType === clientRole.BUSINESS;
	const cid = isBusiness ? client.entityId : null;
	try {
		const { data: { response } } = await API.card.unblock(id, cid);
		success(dispatch)({ title: M.success.unblock.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const search = payload => ({
	type: CARD_SET_SEARCH,
	payload,
});

export const status = payload => ({
	type: CARD_SET_STATUS,
	payload,
});

export const current = payload => ({
	type: CARD_CURRENT,
	payload,
});

export const setNewCardData = payload => async (dispatch, getState) => {
	const currentCard = getCurrentCard(getState());
	try {
		dispatch({
			type: CARD_SET_NEW_DATA_REQUEST,
			payload: { cardId: currentCard.id },
		});
		const { data: { errors } } = await API.card.update(currentCard.id, payload);
		if (errors && !!errors.length) throw errors;
		dispatch({
			type: CARD_SET_NEW_DATA_SUCCESS,
			payload: { cardId: currentCard.id, data: payload }
		});
		success(dispatch)({ title: M.success.update.title });
	} catch (err) {
		dispatch({ type: CARD_SET_NEW_DATA_ERROR, payload: currentCard });
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
	}
};

export const setRegulatoryDataCollection = payload => async (dispatch, getState) => {
	try {
		const response = await API.card.createRegulatory(payload);

		gtag('event', GTMEvents.regulatoryData.eventName, { ...GTMEvents.regulatoryData.eventParameters });
		gtag_report_conversion("lLLSCIKDj40YEIqw7pcD", "https://my.weststeincard.com/auth/regulatory-data-collection");

		const { data: { response: { cardholderIds } } } = await API.client.private.get();
		batch(() => {
			dispatch({
				type: CARD_SET_REGULATORY_DATA_COLLECTION,
				payload: payload.udfs,
			});
			dispatch({ type: CARD_CREATE_SUCCESS, payload: cardholderIds[0] });
		});
	}
	catch (err) {
		if (err.errors) {
			error(dispatch)({ title: { id: err.errors[Object.keys(err.errors)[0]][0].defaultMessage } });
		}
		throw err;
	}
	finally {
	}
}

export const removeRegulatoryDataCollection = () => ({
	type: CARD_REMOVE_REGULATORY_DATA_COLLECTION,
});
