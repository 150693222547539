import { success, error } from 'actions/toast';
import API from 'services/api';

export const getCode = () => async dispatch => {
	try {
		const { data: { response } } = await API.referrals.getPersonalCode();
		//success(dispatch)({ title: M.success.edit.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const registerAppReferral = (utm) => async dispatch => {
	try {
		const { data: { response } } = await API.referrals.registerAppReferral(utm);
		//success(dispatch)({ title: M.success.edit.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};
