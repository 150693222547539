import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Timer.scss';

const updateTime = (time) => {
  const currentTime = time - 1;
  const minutes = Math.trunc(currentTime / 60 % 60);
  const seconds = currentTime % 60;

  return {
    time: (currentTime),
    minutes: minutes < 10 ? '0' + minutes : minutes,
    seconds: seconds < 10 ? '0' + seconds : seconds,
  }
}

class Timer extends React.PureComponent {
  static propTypes = {
    time: PropTypes.number,
    className: PropTypes.string,
    showMinutes: PropTypes.bool,
    onFinish: PropTypes.func
  }

  state = updateTime(this.props.time || 30)

  componentDidMount() {
    this.interval = setInterval(this.onInterval, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showMinutes && prevProps.time !== this.props.time) {
      this.setState(() => updateTime(this.props.time))
    }
  }

  onInterval = () => {
    const { time } = this.state;
    if (time <= 0) {
      clearInterval(this.interval);
      if (this.props.onFinish) {
        this.props.onFinish()
      }
      return
    };
    return this.setState((state) => updateTime(state.time));
  }

  render() {
    const { time, minutes, seconds } = this.state;
    const { className, showMinutes } = this.props;
    return (
      <span className={classnames('Timer', className)}>
        {showMinutes ? minutes + ':' + seconds : time}
      </span>
    );
  }
}

export default Timer;
