import React, { useEffect, useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import PropTypes from 'prop-types';
import Form from 'components/Form';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';
import Title from 'components/Title';
import Banner from 'components/Banner';
import Tooltip from 'components/Tooltip';
import Loader from 'components/Loader';
import * as paymentActions from 'actions/internationalPayment';
import * as currencyActions from 'actions/currency';
import * as modalsActions from 'actions/modals';
import InternationalPaymentSchema from 'schemas/internationalPayment';
import InternationalPaymentModal from 'sections/InternationalPaymentModal';
import internationalPaymentPrivateClient from 'schemas/internationalPaymentPrivateClient';
import helpers from 'helpers';
import { getModalInfo } from 'selectors/modals';
import { internationalPaymentCreate } from 'constants/paths';
import { SEPA_WINDOW_TYPE } from 'constants';
import { defaultFormData, defaultErrorsData, controls, exceptedCountries, beneficiaryTypePrivateClient, beneficiaryTypeCompany } from './constants';
import intlTypes from 'types/intl';
import countryTypes from 'types/country';
import currencyTypes from 'types/currency';
import { currencyActionsTypes } from 'types/actions';
import { prepareCurrencies, prepareCountries } from './utils';

import M from './Beneficiary.locale.json';
import * as cardSelector from "../../../../selectors/card";


const Beneficiary = props => {
	const { modal, isInternationalPaymentModal } = props;
	const [loader, setLoader] = useState(false);
	const [calculateAmountLoader, setCalculateAmountLoader] = useState(false);
	const [form, setForm] = useState(props.formState);
	const [errors, setErrors] = useState(defaultErrorsData);
	const [warning, setWarning] = useState(false);
	const [beneficiaryType, setBeneficiaryType] = useState(beneficiaryTypePrivateClient);

	const isPrivatClient = beneficiaryType === beneficiaryTypePrivateClient;

	const validationSchema = isPrivatClient ? internationalPaymentPrivateClient : InternationalPaymentSchema;

	useEffect(() => {
		const onFetch = async () => {
			await props.actions.currency.fetch();

			if (!modal) {
				props.actions.modal.getModalInfo();
			}
		};

		onFetch();
	}, []);

	useEffect(() => {
		const { payment } = props.location.state || { payment: undefined };
		if (typeof payment !== 'undefined') {
			const { beneficiary } = payment;
			const [firstName, surname] = beneficiary.split(' ');
			let tempCountry ='';
			if (typeof payment.address !== 'undefined') {
				tempCountry = props.countries.find(country=> country.id === payment.address.country).iso2;
			} 
			setForm(prevForm => {
				return({
					...prevForm,
					iban: payment.iban,
					bic: payment.bic,
					amount: payment.amount,
					beneficiary: payment.recipient || firstName ,
					surname: payment.recipientLastname || surname ,
					currency: payment.currency,
					creditorCountryCode: payment.creditorCountryCode,
					creditorCountryCode: tempCountry || payment.country,
					city: payment.address.city || payment.city,
					creditorAddress1: payment.address.line1 || payment.creditorAddress1,
					creditorAddress2: payment.address.line2 || payment.creditorAddress2,
					postCode: payment.address.postalCode || payment.postCode,
					details: payment.details,
				});
			});
			queueMicrotask(async ()=>{
				const params = {
					amount: form.amount,
					currencyFrom: form.currency,
					currencyTo: 'EUR'
				};
				setCalculateAmountLoader(true);
				const debitAmount = await onConvertCurrency(params);
				setCalculateAmountLoader(false);
				setForm(prevForm => ({ ...prevForm, debitAmount }));
			});
		}
	}, [props.location.payment]);

	const memoizedCurrenciesCallback = useCallback(
		() => prepareCurrencies(props.currencies),
		[props.currencies],
	);

	const memoizedCountriesCallback = useCallback(
		() => prepareCountries(props.countries),
		[props.countries],
	);

	const sendData = async () => {
		const { actions, history } = props;

		setLoader(true);
		const beneficiaryNameForPrivateClient = isPrivatClient ? {
			beneficiary: form.beneficiary.trim(),
			lastname: form.surname.trim(),
		} : { beneficiary: form.beneficiary.trim() };
		delete form.surname;
		const response = await actions.payment.create({ ...form, ...beneficiaryNameForPrivateClient });
		form.surname = beneficiaryNameForPrivateClient.lastname;
		setLoader(false);
		setErrors(prevErrors => ({ ...prevErrors, ...response.errors }));

		if (response && response.errors && response.messages) return;

		// setForm(defaultFormData);
		props.setForm(form);
		history.push(`${internationalPaymentCreate}/${response.id}`);
	};

	const closeModal = useCallback(() => {
		setWarning(false);
	}, [form]);

	const nextStep = notShowAnymore => {
		if (notShowAnymore) {
			props.actions.modal.sendModalInfo([{ windowType: SEPA_WINDOW_TYPE }]);
		}

		closeModal();
		sendData();
	};

	// eslint-disable-next-line no-return-await
	const onConvertCurrency = async params => await props.actions.currency.convert(params);

	const onLostFocus = async (value, name) => {
		if ([controls.currency, controls.amount].includes(name)
			&& form.amount.length && form.currency.length
			&& !errors[controls.currency].length && !errors[controls.amount].length) {
			const params = {
				amount: form.amount,
				currencyFrom: form.currency,
				currencyTo: 'EUR'
			};
			setCalculateAmountLoader(true);
			const debitAmount = await onConvertCurrency(params);
			setCalculateAmountLoader(false);
			setForm(prevForm => ({ ...prevForm, debitAmount }));
		}
	};

	const onChange = (value, name) => {
		let messages = [];
		let convert = value;

		if (errors[name].length) {
			messages = helpers.validator.single(
				{ ...form, [name]: value }, name, validationSchema.create);
		}

		setForm(prevForm => ({ ...prevForm, [name]: convert }));
		setErrors(prevErrors => ({ ...prevErrors, [name]: messages }));
		setLoader(false);
	};

	const showConfirmationModal = () => {
		if (props.actions) {
			props.actions.modal.showInternationalPaymentModal();
		}
	};

	const onInternationalPaymentModalHide = () => {
		props.actions.modal.hideInternationalPaymentModal();
	};

	const onInternationalPaymentModalSubmit = () => {
		onInternationalPaymentModalHide();
		sendData();
	};

	useEffect(() => {
		onInternationalPaymentModalHide();
	}, []);

	const onSubmit = async e => {
		e.preventDefault();

		if (loader) return;

		const errs = helpers.validator.all(form, validationSchema.create);

		if (!props.currentCard.availBal || props.currentCard.availBal < form.amount) {
			const amountError = {
				id: 'schemas.not.enough',
				variables: {
					attribute: 'amount',
					value: form.amount
				}
			}

			if (!errs.amount) {
				errs.amount = [amountError]
			} else errs.amount.push(amountError)
		}

		if (errs) {
			setErrors(prevErrors => ({ ...prevErrors, ...errs }));
			return;
		}

		showConfirmationModal();

		if (modal.show) {
			setWarning(true);
		}

	};

	const onChangeBeneficiarySelect = value => {
		setBeneficiaryType(value);
		setForm(() => ({
			iban: '',
			bic: '',
			amount: '',
			beneficiary: '',
			currency: '',
			creditorCountryCode: '',
			city: '',
			creditorAddress1: '',
			creditorAddress2: '',
			postCode: '',
			details: '',
		}));
	};

	const countries = memoizedCountriesCallback();
	const currencies = memoizedCurrenciesCallback();
	const { intl } = props;
	const t = intl.formatMessage;
	const isExceptedState = exceptedCountries.includes(form.creditorCountryCode);
	const beneficiaryTypeListSelect = [
		{
			title: t(M.inputs.beneficiaryTypePrivateClient),
			value: beneficiaryTypePrivateClient
		},
		{
			title: t(M.inputs.beneficiaryTypeCompany),
			value: beneficiaryTypeCompany
		}
	];
	return (
		<div>
			{isInternationalPaymentModal && (
				<InternationalPaymentModal
					onClose={onInternationalPaymentModalHide}
					onSubmit={onInternationalPaymentModalSubmit}
				/>
			)}
			<Title title={t(M.title)} className="InternationalPaymentCreateScreen__Title" />
			<Form onSubmit={onSubmit}>
				<Form.Group>
					<InputSelect
						name="beneficiaryType"
						placeholder={t(M.inputs.beneficiaryType)}
						onChange={onChangeBeneficiarySelect}
						onBlur={onLostFocus}
						value={beneficiaryType}
						data={beneficiaryTypeListSelect}
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="beneficiary"
						placeholder={t(M.inputs.beneficiaryName)}
						onChange={onChange}
						value={form.beneficiary}
						errors={errors.beneficiary}
						autoFocus
					/>
				</Form.Group>
				{isPrivatClient &&
					<Form.Group>
						<Input
							name="surname"
							placeholder={t(M.inputs.beneficiarySurname)}
							onChange={onChange}
							value={form.surname}
							errors={errors.surname}
						/>
					</Form.Group>
				}
				<Form.Group>
					<InputSelect
						name="currency"
						placeholder={t(M.inputs.currency)}
						onChange={onChange}
						onBlur={onLostFocus}
						value={form.currency}
						errors={errors.currency}
						data={currencies}
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="amount"
						placeholder={t(M.inputs.amount)}
						onChange={onChange}
						onBlur={onLostFocus}
						value={form.amount}
						unit={form.currency || 'EUR'}
						errors={errors.amount}
					/>
				</Form.Group>
				<Form.Note>{t(M.prompts.equivalent)} EUR:{' '}
					{calculateAmountLoader && (
						<span className="AmountLoaderContainer">
							<Loader className="AmountLoaderContainer__Loader" />
						</span>
					)}
					{!calculateAmountLoader && (form.debitAmount || 0)}
				</Form.Note>
				<Form.Note>{t(M.prompts.beneficiaryAddress)}</Form.Note>
				<Form.Group>
					<InputSelect
						name="creditorCountryCode"
						placeholder={t(M.inputs.creditorCountryCode)}
						onChange={onChange}
						value={form.creditorCountryCode}
						errors={errors.creditorCountryCode}
						data={countries}
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="city"
						placeholder={t(M.inputs.city)}
						onChange={onChange}
						value={form.city}
						errors={errors.city}
					/>
					<Form.Note>{t(M.prompts.base)}</Form.Note>
				</Form.Group>
				{isExceptedState && (
					<Form.Group>
						<Input
							name="state"
							placeholder={t(M.inputs.state)}
							onChange={onChange}
							value={form.state}
							errors={errors.state}
						/>
						<Form.Note>{t(M.prompts.base)}</Form.Note>
					</Form.Group>
				)}
				<Form.Group>
					<Input
						name="creditorAddress1"
						placeholder={t(M.inputs.creditorAddress1)}
						onChange={onChange}
						value={form.creditorAddress1}
						errors={errors.creditorAddress1}
						tooltip={(
							<Tooltip.Mark large>
								<Tooltip title={t(M.tooltips.address1)} />
							</Tooltip.Mark>
						)}
					/>
					<Form.Note>{t(M.prompts.address)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<Input
						name="creditorAddress2"
						placeholder={t(M.inputs.creditorAddress2)}
						onChange={onChange}
						value={form.creditorAddress2}
						errors={errors.creditorAddress2}
						tooltip={(
							<Tooltip.Mark large>
								<Tooltip title={t(M.tooltips.address2)} />
							</Tooltip.Mark>
						)}
					/>
					<Form.Note>{t(M.prompts.address)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<Input
						name="postCode"
						placeholder={t(M.inputs.postCode)}
						onChange={onChange}
						value={form.postCode}
						errors={errors.postCode}
					/>
					<Form.Note>{t(M.prompts.postCode)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<Input
						name="iban"
						placeholder={t(M.inputs.iban)}
						onChange={onChange}
						value={form.iban}
						errors={errors.iban}
						tooltip={(
							<Tooltip.Mark large>
								<Tooltip title={t(M.tooltips.iban)} />
							</Tooltip.Mark>
						)}
					/>
					<Form.Note>{t(M.prompts.iban)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<Input
						name="bic"
						placeholder={t(M.inputs.bic)}
						onChange={onChange}
						value={form.bic}
						errors={errors.bic}
						tooltip={(
							<Tooltip.Mark large>
								<Tooltip title={t(M.tooltips.bic)} />
							</Tooltip.Mark>
						)}
					/>
					<Form.Note>{t(M.prompts.bic)}</Form.Note>
				</Form.Group>
				<Form.Group>
					<Input
						name="details"
						placeholder={t(M.inputs.details)}
						onChange={onChange}
						value={form.details}
						errors={errors.details}
					/>
					<Form.Note>{t(M.prompts.base)}</Form.Note>
				</Form.Group>
				<Banner type="info" data={[M.notify]} />
				<Button
					className="InternationalPaymentCreateScreen__Submit"
					loading={loader}
					small
					form
				>
					{t(M.buttons.next)}
				</Button>
			</Form>
		</div>
	);
};


const mapState = state => ({
	user: state.user,
	currencies: state.currency,
	countries: state.country,
	currentCard: cardSelector.getById(state, state.card.current),
	modal: getModalInfo(state, SEPA_WINDOW_TYPE),
	isInternationalPaymentModal: state.modals.isInternationalPaymentModalVisible,
});
const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
		currency: bindActionCreators(currencyActions, dispatch),
		modal: bindActionCreators(modalsActions, dispatch)
	},
});

export default compose(
	injectIntl,
	withRouter,
	connect(mapState, mapDispatch),
)(Beneficiary);
