import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Section from 'components/Section';
import Tab from 'components/Tab';
import * as paymentActions from 'actions/internationalPayment';
import * as paymentSelector from 'selectors/payment';
import * as cardSelector from 'selectors/card';
import { card, cardStatus, paymentStatus } from 'constants/common';
import Beneficiary from '../Beneficiary';
import Amount from '../Amount';
import Phone from '../Phone';
import Summary from '../Summary';
import BlockingScreen from '../../../Blocking';

import './InternationalPaymentCreate.scss';

const availableCards = [card.CARD01, card.CARD02, card.CARD03, card.CARD07, card.CARD08, card.CARD14, card.CARD17, card.CARD18];
const availableCardStatuses = [cardStatus.OPEN, cardStatus.ISSUED];

function InternationalPaymentCreate(props) {
	const [loader, setLoader] = useState(true);
	const [form, setForm] = useState({
		beneficiary: '',
		surname: '',
		currency: '',
		amount: '',
		creditorCountryCode: '',
		city: '',
		state: '',
		creditorAddress1: '',
		creditorAddress2: '',
		postCode: '',
		iban: '',
		bic: '',
		details: '',
		debitAmount: '',
	});
	const { current, currentCard } = props;
	useEffect(() => {
		const onMount = async () => {
			const { match: { params: { id } }, actions } = props;
			if (typeof id === 'undefined') return setLoader(false);
			await actions.payment.get(id);
			return setLoader(false);
		};
		onMount();
	}, [props.match.params.id]);
	const isOpen = availableCards.includes(currentCard.product) && currentCard.iban
		&& availableCardStatuses.includes(currentCard.cardStatus);
	const isDeposit = [cardStatus.DEPOSIT_ONLY].includes(currentCard.cardStatus)
		&& !currentCard.activated;

	const onBack = () => {
		const { history } = props;
		history.push(`/international-payment/create`);
	}
	if (isOpen || isDeposit) {
		return (
			<Layout className="InternationalPaymentCreateScreen Screen" sidebar>
				<Nav />
				<Header />
				<Section>
					<Container>
						<Wrap>
							{!loader && (
								<Tab current={current}>
									<Tab.Panel>
										<Beneficiary formState={form} setForm={form => setForm(form)} />
									</Tab.Panel>
									<Tab.Panel>
										<Amount onBack={onBack} />
									</Tab.Panel>
									<Tab.Panel>
										<Phone />
									</Tab.Panel>
									<Tab.Panel>
										<Summary />
									</Tab.Panel>
								</Tab>
							)}
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}

	return (
		<Layout className="InternationalPaymentCreateScreen Screen" sidebar>
			<Nav />
			<Header />
			<Section className="LoadSection">
				<Container>
					<Wrap>
						{!loader && <BlockingScreen />}
					</Wrap>
				</Container>
			</Section>
		</Layout>
	);
}

InternationalPaymentCreate.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.number,
		}).isRequired,
	}).isRequired,
	current: PropTypes.number.isRequired,
	currentCard: PropTypes.shape({
		product: PropTypes.string.isRequired,
		cardStatus: PropTypes.string.isRequired,
		activated: PropTypes.bool.isRequired,
	}).isRequired,
};

const getCurrent = payment => {
	if (typeof payment === 'undefined') return 0;
	switch (payment.status) {
		case paymentStatus.DRAFT: return 1;
		case paymentStatus.SIGNING: return 2;
		case paymentStatus.DECLINED:
		case paymentStatus.COMPLETED: return 3;
		default: return 0;
	}
};

const mapState = (state, props) => {
	const { match: { params: { id } } } = props;
	const payment = paymentSelector.get(state, id);
	return {
		payment,
		current: getCurrent(payment),
		currentCard: cardSelector.getById(state, state.card.current)
	};
};

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

export default compose(
	injectIntl,
	withRouter,
	connect(mapState, mapDispatch)
)(InternationalPaymentCreate);
