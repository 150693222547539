import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Section from 'components/Section';
import ExchangeSendSuccessComponent from 'components/Exchange/Send/Success';

const Preview = ({ location }) => {
	const isFiat = location.state.isFiat;
	return (
		<Layout className="ExchangeScreen Screen" sidebar>
			<Nav />
			<Header withBack />
			<Section large>
				<ExchangeSendSuccessComponent isFiat={isFiat} />
			</Section>
		</Layout>
	);
};

Preview.propTypes = {
	location: PropTypes.object.isRequired
};

export default Preview;
