import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import helpers from 'helpers';

import * as referralsActions from 'actions/referrals';

import SettingSchema from 'schemas/setting';

import Title from 'components/Title';
import Form from 'components/Form';

import M from './Referrals.locale.json';


const mapState = () => ({});

const mapDispatch = dispatch => ({
	actions: {
		referrals: bindActionCreators(referralsActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		code: '',
		loading: true,
		success: false
	}

	componentDidMount() {
		this.onMount();
	}

	onMount = async () => {
		const { actions } = this.props;
		const response = await actions.referrals.getCode();
		this.setState({ code: response.code, success: response.success, loading: false });
	}

	render () {
		const { code, loading, success } = this.state;

		const t = this.props.intl.formatMessage;

		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)+":"}
					className="SettingScreen__Title"
				/>

				<Form.Note>{loading ? t(M.loading) : (success ? 'https://www.weststeincard.com/?utm_source=referral&utm_medium='+code : t(M.loading_failed))}</Form.Note>

			</div>
		);
	}
}
