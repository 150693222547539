import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as jumioActions from 'actions/jumio';
import * as clientSelector from 'selectors/client';
import storage from 'services/storage';
import { timeDifferenceCreatedToken } from 'helpers/timeDifference';
import { clientStatus } from 'constants/common';
import * as clientActions from 'actions/client';
import {
	AUTH_LOGOUT,
	TOKEN_LIFE_TIME,
	ACCOUNT_REVIEW,
	ACCOUNT_REVIEW_DECLINE,
	ACCOUNT_REVIEW_SUCCESS,
	IS_UPLOAD_DOCUMENTS
} from 'constants';

const env = process.env.BUILD_ENV;
const nodeEnv = process.env.NODE_ENV;

const mapState = state => ({
	purchaseCompleted: state.ui.registration ? state.ui.registration.purchaseCompleted : false,
	user: state.user,
	cards: state.card,
	client: clientSelector.getCurrentClient(state),
	initialRegulatoryDataCollection: state.card.initialRegulatoryDataCollection,
});

const mapDispatch = dispatch => ({
	actions: {
		logout: () => {
			dispatch({type: AUTH_LOGOUT})
		},
		jumio: bindActionCreators(jumioActions, dispatch),
		client: {
			private: bindActionCreators(clientActions.privateActions, dispatch),
		}
	}
})


// TODO this component should be rewrite
@connect(mapState, mapDispatch)
export default class RoutePrivate extends PureComponent {
	static propTypes = {
		client: PropTypes.object,
		user: PropTypes.object,
		location: PropTypes.object,
		component: PropTypes.oneOfType([
			PropTypes.node,
			PropTypes.func,
		]),
		initialRegulatoryDataCollection: PropTypes.object,
		ignoreEnv: PropTypes.arrayOf(PropTypes.string)
	}

	render () {
		const { client, user, cards, location, component, initialRegulatoryDataCollection, purchaseCompleted, actions, ignoreEnv = [] } = this.props;
		const { pathname, state } = location;
		const lastTokenCreated = storage.get('sessionCreated');
		const currentDate = new Date().toUTCString();
		const differenceTime = timeDifferenceCreatedToken(lastTokenCreated, currentDate);

		if (ignoreEnv.includes(env) || ignoreEnv.includes(nodeEnv)) {
			return (
				<Redirect
					to={{
						pathname: '/',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (!lastTokenCreated || (differenceTime > TOKEN_LIFE_TIME)) {
			actions.logout();

			return (
				<Redirect
					to={{
						pathname: '/auth/login',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		// have user and is verified
		if (!user || !client) {
			return (
				<Redirect
					to={{
						pathname: '/auth/login',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (state && state.redirect) {
			delete this.props.location.state;
			return (
				<Route component={component} {...this.props} />
			);
		}

		if (user.migration === 'STARTED') {
			return (
				<Redirect
					to={{
						pathname: '/auth/resetMigrated',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (user.status === ACCOUNT_REVIEW || user.status === ACCOUNT_REVIEW_DECLINE ||  user.status === ACCOUNT_REVIEW_SUCCESS) {
			return (
				<Redirect
					to={{
						pathname:'/account/review',
						state: {
							from: location,
							redirect: true
						}
					}}
				/>
			)
		}

		if (!user.verified) {
			return (
				<Redirect
					to={{
						pathname: '/auth/email',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}
		if (!user.phoneVerified && user.verified && !initialRegulatoryDataCollection && user.status !== clientStatus.ACTIVE) {
			return (
				<Redirect
					to={{
						pathname: '/auth/regulatory-data-collection',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (!user.phoneVerified) {
			return (
				<Redirect
					to={{
						pathname: '/auth/phone',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			);
		}

		if (cards.items[0] && cards.items[0].status === 'PRE_REQUEST' && purchaseCompleted === false) {
			return (
				<Redirect
					to={{
						pathname: '/auth/purchase',
						state: {
							from: location,
							redirect: true,
						},
					}}
				/>
			)
		}

		// if invited must change password
		if (user.status === 'REQUESTED') {
			return (
				<Redirect
					to={{
						pathname: `/invite/password`,
						state: {
							redirect: true,
						},
					}}
				/>
			);
		}

		const { status, role } = client;
		const inShareholders = (pathname.indexOf('shareholders') !== -1);

		if (status === 'INITIATED' && inShareholders) {
			delete this.props.location.state;
			return (
				<Route component={component} {...this.props} />
			);
		}

		if ((purchaseCompleted === true && status === 'INITIATED' && pathname.indexOf('/clients/create/') === -1)) {
			return (
				<Redirect
					to={{
						pathname: `/clients/create/${role === 'PRIVATE' ? 'private' : `business/${client.entityId}`}`,
						state: {
							redirect: true,
						},
					}}
				/>
			);
		}

		if (status === 'REQUESTED') {
			return (
				<Redirect
					to={{
						pathname: `/clients/create/business/${client.entityId}/submitted`,
						state: {
							redirect: true,
						},
					}}
				/>
			);
		}
		if (cards.items[0]) {
			return (
				<Route component={component} {...this.props} />
			);
		}
	}
}
