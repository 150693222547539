import fetcher from 'helpers/fetcher';

const CRYPTO = {
	ACCOUNT: 'crypto/account',
	TOPUP: 'crypto/fiat/top-up',
	TOPUP_RESEND_CODE: 'crypto/fiat/top-up/resend-code',
	TOPUP_CONFIRM: 'crypto/fiat/top-up/confirm',
	VERIFY_ACCOUNT: 'crypto/account/verification',
	SEND_REQUEST: 'crypto/centralised',
	SEND_REQUEST_FIAT: 'crypto/fiat',
	CONFIRM_REQUEST: 'crypto/centralised/confirm',
	CONFIRM_REQUEST_FIAT: 'crypto/fiat/confirm',
	RECENT_RECEIVERS: 'crypto/centralised/addresses',
	RESEND_CODE: 'crypto/centralised/resend-code',
	RESEND_CODE_FIAT: 'crypto/fiat/resend-code',
	CONFIRM_EXCHANGE: id => `crypto/exchange/${id}/confirm`,
	EXCHANGE: 'crypto/exchange',
	GET_EXCHANGES: 'crypto/getExchangesInfo',
	GET_WALLETS: 'crypto/wallets',
	OPEN_WALLET: currency => `crypto/wallet/${currency}`,
	GET_EXCHANGES_INFO: ({ currencyFrom, currencyTo }) =>
		`crypto/exchange-info?sourceCurrencyCode=${currencyFrom}&targetCurrencyCode=${currencyTo}`,
	GET_TRANSACTIONS: ({ currencyCodeWallet, take, offset }) =>
		`crypto/centralised/transactions?currencyCodeWallet=${currencyCodeWallet}&take=${take}&offset=${offset}`,
	GET_TRANSACTION: id => `crypto/centralised/transaction/${id}`
};

/*
    Quppi model 

    SepaQuppiModel {
        amount (number, optional),
        appHash (string, optional),
        cardId (integer, optional)
    }
*/

export default {
	getAccount () {
		return fetcher.get(CRYPTO.ACCOUNT);
	},
	topUp (model) {
		return fetcher.post(CRYPTO.TOPUP, model);
	},
	create (cid, form, bid) {
		return fetcher.post(
			`${
				bid ? `business/${bid}` : `private`
			}/payment/${cid}/card-to-card/create`,
			form
		);
	},
	amount (id, cid, params, bid) {
		return fetcher.post(
			`${
				bid ? `business/${bid}` : `private`
			}/payment/${cid}/card-to-card/${id}/confirm`,
			{},
			{ params }
		);
	},
	phone (id, cid, params, bid) {
		return fetcher.post(
			`${
				bid ? `business/${bid}` : `private`
			}/payment/${cid}/card-to-card/${id}/sign`,
			{},
			{ params }
		);
	},
	code (id, cid, bid) {
		return fetcher.post(
			`${
				bid ? `business/${bid}` : `private`
			}/payment/${cid}/card-to-card/${id}/resend-token`
		);
	}
};
