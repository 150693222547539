import {
	AUTH_LOGIN_SUCCESS,
	AUTH_SIGNUP_SUCCESS,
	AVATAR_CREATE_SUCCESS,
	AVATAR_REMOVE_SUCCESS,
	PHONE_CREATE_SUCCESS,
	CLIENT_CREATE_PRIVATE_ERROR,
	CLIENT_GET_ADDRESS_SUCCESS,
	AUTH_EMAIL_SUCCESS,
	PASSWORD_SET_SUCCESS,
	AUTH_LOGOUT,
	SET_LINKED_CARDS,
	CHANGE_TWOFACTOR,
	NOT_SHOW_TWOFACTOR_MODAL,
	STATUS__ACTIVE
} from 'constants';
import { userStatus, migratedStatus } from 'constants/common';

const initial = null;

const reducer = (state = initial, action) => {
	switch (action.type) {
		case AUTH_LOGIN_SUCCESS:
		case AUTH_SIGNUP_SUCCESS:
			return { ...action.payload.user };
		case AVATAR_CREATE_SUCCESS:
			return { ...state, ...action.payload };
		case AVATAR_REMOVE_SUCCESS:
			return {
				...state,
				avatar: null,
				avatarId: null,
			};
		case PHONE_CREATE_SUCCESS:
			return { ...state, phoneVerified: true };
		case CLIENT_CREATE_PRIVATE_ERROR: {
			if (!action.error || !action.error.messages) return state;
			const fidx = action.error.messages.findIndex(item => (item.id === 'screens.apply.private.create.errors.ofac'));
			if (fidx === -1) return state;
			return { ...state, status: userStatus.FRAUDULENT };
		}
		case CLIENT_GET_ADDRESS_SUCCESS:
			return { ...state, ...action.payload };
		case AUTH_EMAIL_SUCCESS:
			return { ...state, verified: true };
		case PASSWORD_SET_SUCCESS:
			return { ...state, migration: migratedStatus.COMPLETED };
		case AUTH_LOGOUT:
			return null;
		case SET_LINKED_CARDS:
			return {
				...state,
				linkedCards: action.payload,
			};
		case CHANGE_TWOFACTOR:
			return {
				...state,
				twoFactor: !state.twoFactor
			}

		case NOT_SHOW_TWOFACTOR_MODAL:
			return {
				...state,
				twoFactorNotification: true
			}
		case STATUS__ACTIVE :
			return {
				...state,
				status: 'ACTIVE'
			}

		default:
			return state;
	}
};

export default reducer;
