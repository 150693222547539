import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import intlTypes from 'types/intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';

import Icon from 'components/Icon';

import './PfsWarningModal.scss';

const SecondModal = ({ intl, setCloseModal, onSubmit, isLoading }) => {
	const t = intl.formatMessage;

	return(
		<Modal>
			<Section>
				<Container medium>
					<Wrap className="PfsWarning__wrap">
						<Icon
							className="PfsWarning-close" 
							onClick={setCloseModal} 
							name="close-small"
							disabled={isLoading}
						/>
						<Title
							subtitle={t({ id: 'pages.payments.pfs.warning.text' })}
							small
							className='PfsWarning-text'
						/>
						<div className="PfsWarning-controls">
							<Button 
								onClick={onSubmit} 
								small 
								className="PfsWarning-button"
								loading={isLoading}
							>
								<FormattedMessage id="button.simple.ok" />
							</Button>
							<Button 
								onClick={setCloseModal} 
								small 
								className="PfsWarning-button"
								disabled={isLoading}
							>
								<FormattedMessage id="button.simple.cancel" />
							</Button>
						</div>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
}
;

SecondModal.propTypes = {
	setCloseModal: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	intl: intlTypes.isRequired,
};


export default injectIntl(SecondModal);
