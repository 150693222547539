import {
	MEMBER_FETCH_SUCCESS,
	MEMBER_GET_SUCCESS,
	MEMBER_CREATE_SUCCESS,
	MEMBER_EDIT_SUCCESS,
	MEMBER_SET_SEARCH,
	MEMBER_SET_ACCESS,
	MEMBER_SET_STATUS,
} from 'constants';
import { success, error } from 'actions/toast';
import API from 'services/api';
import renamer from 'helpers/renamer';
import * as clientSelector from 'selectors/client';
import M from './member.locale.json';

export const fetch = () => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const result = await API.member.fetch(clientId);
		const members = renamer.from.member.fetch(result.data.response);
		dispatch({ type: MEMBER_FETCH_SUCCESS, payload: members });
		return members;
	} catch (err) { return err; }
};

export const get = (id, rid) => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const result = await API.member.get(id, rid, clientId);
		const payload = renamer.from.member.get(result.data.response);
		dispatch({ type: MEMBER_GET_SUCCESS, payload });
		return payload;
	} catch (err) { return err; }
};

export const create = form => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const result = await API.member.create(clientId, form);
		const payload = renamer.from.member.get(result.data.response);
		dispatch({ type: MEMBER_CREATE_SUCCESS, payload });
		success(dispatch)({ title: M.success.create.title });
		return payload;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const edit = (id, rid, form) => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const result = await API.member.edit(id, rid, clientId, form);
		const payload = renamer.from.member.get(result.data.response);
		dispatch({ type: MEMBER_EDIT_SUCCESS, payload: { userId: id, position: form.position } });
		success(dispatch)({ title: M.success.edit.title });
		return payload;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const remove = rid => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	try {
		const result = await API.member.remove(rid, clientId);
		return result.data.response;
	} catch (err) { return err; }
};

export const search = value => ({
	type: MEMBER_SET_SEARCH,
	payload: value,
});

export const access = value => ({
	type: MEMBER_SET_ACCESS,
	payload: value,
});

export const status = value => ({
	type: MEMBER_SET_STATUS,
	payload: value,
});
