import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "components/Button";
import * as toastActions from 'actions/toast';
import * as cardActions from "actions/card";
import { getById } from "selectors/card";

import currentCardTypes from "types/currentCard";
import { cardActionsTypes } from "types/actions";
import intlTypes from "../../../../types/intl";
import M from "./GetCVV.locale.json";

const CVV_AVAILABILITY_TIMER = 60;

const mapState = (state, props) => ({
	card: getById(state, props.match.params.id)
});

const mapDispatch = dispatch => ({
	actions: {
		toast: bindActionCreators(toastActions, dispatch),
		card: bindActionCreators(cardActions, dispatch)
	}
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
class GetCVV extends React.PureComponent {
	static propTypes = {
		card: currentCardTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired
		}).isRequired,
		intl: intlTypes.isRequired
	};

	state = {
		isCVVRequestAvailable: true,
		timer: 0
	};

	componentDidMount () {
		this.mounted = true;
	}

	componentWillUnmount () {
		this.mounted = false;
	}

	onSendCVVRequest = () => {
		const isZeroBalance = this.props.card.availBal === 0;
		if (isZeroBalance) return this.props.actions.toast.create({ title: M.messages.zeroError, type: 'error' });

		this.setState({
			isCVVRequestAvailable: false,
			timer: CVV_AVAILABILITY_TIMER
		});

		this.props.actions.card.getCVV(this.props.card.id);

		const intervalHandle = setInterval(() => {
			this.setState({ timer: this.state.timer - 1 });
		}, 1000);

		setTimeout(() => {
			clearInterval(intervalHandle);
			this.setState({ isCVVRequestAvailable: true });
		}, CVV_AVAILABILITY_TIMER * 1000);
	};

	mounted = false;

	render () {
		const { isCVVRequestAvailable, timer } = this.state;
		const t = this.props.intl.formatMessage;

		return (
			<div>
				<React.Fragment>
					<p>{t({ id: 'card.get.cvv.text' })}</p>
				</React.Fragment>
				<Button
					onClick={this.onSendCVVRequest}
					disabled={!isCVVRequestAvailable}
				>
					{isCVVRequestAvailable ? <FormattedMessage id="card.get.button.title" /> : <span>{timer}</span>}
				</Button>
			</div>
		);
	}
}

export default GetCVV;
