import { AVATAR_CREATE_SUCCESS, AVATAR_REMOVE_SUCCESS } from "constants";
import { toastType } from "constants/common";
import renamer from "helpers/renamer";
import API from "services/api";
import { success, error } from "actions/toast";
import M from "./avatar.locale.json";

const errorMessage = "File too large";

const handleSuccess = (dispatch, title) => success(dispatch)({ title });

export const create = (file) => async (dispatch) => {
  try {
    const result = await API.avatar.create(file);
    const user = renamer.from.user.get(result.data.response);

    dispatch({ type: AVATAR_CREATE_SUCCESS, payload: user });
    handleSuccess(dispatch, M.success.create.title);
  } catch (err) {
    if (!err.status) {
      err.status = 413;
      err.messages.push({ message: errorMessage });
    }
    if (err.messages.legth) {
      error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
    } else {
      error(dispatch)({ title: M.error.create.title });
    }
  }
};

export const remove = () => async (dispatch) => {
  try {
    await API.avatar.remove();
    dispatch({ type: AVATAR_REMOVE_SUCCESS });
    handleSuccess(dispatch, M.success.remove.title);
  } catch (err) {
    if (err.messages.legth) {
      error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
    } else {
      error(dispatch)({ title: M.error.create.title });
    }
  }
};
