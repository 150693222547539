import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Icon from 'components/Icon';
import Wrap from 'components/Wrap';
import * as authActions from 'actions/auth';
import Title from 'components/Title';
import Button from 'components/Button';
import M from './ManualVerification.locale.json';
import './ManualVerification.scss';

const AccountReview = ({ actions, intl }) => {
    const onLogout = () => {
        const { auth } = actions;
        auth.logout();
    };

    const t = intl.formatMessage;

    return (
        <Wrap className='manualVerification__content'>
            <div
                className="ClientCreatePrivateScreen__Icon ClientCreatePrivateScreen__Icon--Error"
            >
                <Icon
                    name="error"
                />
            </div>
            <Title
                title={t(M.accountDecline.title)}
            />

            <p className='manualVerification__text'>{t(M.accountDecline.text)}</p>

            <Button
                form
                small
                onClick={onLogout}
                className='manualVerification__button'
            >
                {t(M.accountDecline.logout)}
            </Button>
        </Wrap>
    );
}

const mapState = state => ({});

const mapDispatch = dispatch => ({
    actions: {
        auth: bindActionCreators(authActions, dispatch),
    },
});

export default injectIntl(connect(mapState, mapDispatch)(AccountReview))
