import PropTypes from 'prop-types';
import intlTypes from 'types/intl';

export const CurrencyItem = PropTypes.shape({
	currencyCode: PropTypes.string.isRequired,
	balance: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
	isReady: PropTypes.bool.isRequired,
	address: PropTypes.string.isRequired,
});

export const SendProps = {
	errorToast: PropTypes.func.isRequired,
	successToast: PropTypes.func.isRequired,
	cards: PropTypes.array.isRequired, 
	intl: intlTypes.isRequired,
	currency: CurrencyItem
};

