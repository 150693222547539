export const generateReferralLink = (source, medium, campaign = 'Anton2') => {
	const baseUrl = 'https://my.weststeincard.com/';
	const utmSource = `utm_source=${source}`;
	const utmMedium = `utm_medium=${medium}`;
	const utmCampaign = `utm_campaign=${campaign}`;

	const queryParams = [utmSource, utmMedium, utmCampaign].join('&');

	return `${baseUrl}?${queryParams}`;
}
