export const objectToQueryParams = queryObject => {
	const str = [];

	for (let property in queryObject) {
		if (queryObject.hasOwnProperty(property)) {
			str.push(encodeURIComponent(property) + '=' + encodeURIComponent(queryObject[property]));
		}
	}

	return str.join('&');
};
