import PropTypes from 'prop-types';

export const CurrencyItem = PropTypes.shape({
	currencyCode: PropTypes.string.isRequired,
	balance: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
	isReady: PropTypes.bool.isRequired,
	requisites: PropTypes.shape({
		address: PropTypes.string.isRequired
	})
});

export const CurrencyItemProps = {
	currency: CurrencyItem,
	index: PropTypes.number,
	onClick: PropTypes.func
};

export const CurrenciesListProps = {
	currencies:  PropTypes.arrayOf(CurrencyItem),
	onCurrency: PropTypes.func
};
