import { PASSWORD_SET_SUCCESS } from 'constants';
import { success, error } from 'actions/toast';
import API from 'services/api';
import M from './password.locale.json';

export const edit = form => async dispatch => {
	try {
		const { data: { response } } = await API.password.edit(form);
		success(dispatch)({ title: M.success.edit.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const migration = form => async dispatch => {
	try {
		const { data: { response } } = await API.password.migrate(form);
		const list = await API.client.business.fetch();
		dispatch({ type: PASSWORD_SET_SUCCESS, payload: { ...response, list: list.data.response } });
		success(dispatch)({ title: M.success.edit.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};
