import validator from 'validate.js';
import moment from 'moment';
import { parseNumber } from 'libphonenumber-js';

validator.validators.file = (file, options, key, attributes) => {
	if (options.type) {
		const type = options.type;

		if (!type.value.test(file.type)) {
			return (type.message || 'file type not allowed');
		}
	}
	if (options.size) {
		const size = options.size;
		if (size.max) {
			return file.size > size.max.value ? (size.max.message || 'file too large') : null;
		}
	}

	return null;
};

validator.validators.presenceDependency = (value, options, key, attributes) => {
	const keys = Object.keys(options.attributes);

	let presence = false;

	const message = options.message || 'can\'t be blank';

	for (let i = 0; i < keys.length; i++) {
		if (attributes[keys[i]] === options.attributes[keys[i]]) presence = true;
	}

	if (!presence || !validator.isEmpty(value)) return null;

	return message;
};



validator.validators.phonenumber = (value, options, key, attributes) => {
	const countryCode = document.getElementById('phoneCountryFilter').value;
	const checkNumber = parseNumber(countryCode + value, { extended: true });
	
	if (checkNumber.valid === false) {
		return options.message || 'number seems invalid for this country code';
	}
	return null;
};

validator.validators.fullnamelenght = (value, options) => {
	const name = document.getElementById('name-input').value;
	const surname = document.getElementById('surname-input').value;
	const fullname = name + surname;
	if (fullname.length > 25) {
		return options.message || 'and name cannot be longer than 26 characters';
	}
	return null;
};

validator.validators.dateBefore = (value, options, key) => {
	const regex = /^(0?[1-9]|[12][0-9]|3[01])[- /.](0?[1-9]|1[012])[- /.](19|20)\d\d$/;

	const message = options.message || 'is invalid';

	if (!regex.test(value)) return message;

	const before = moment(value, 'DD/MM/YYYY').isAfter(moment(new Date()), 'day');

	return (before ? message : null);
};

validator.validators.alphanumeric = (value, params, key) => {
	const { message, options = {} } = params;
	if (options.canBeEmpty && !value) return null;
	const regex = (options.space ? /^[ a-zA-Z0-9]+$/ : /^[a-zA-Z0-9]+$/);

	if (typeof value !== 'string' || !value.match(regex)) {
		return message || 'can only contain alphanumeric symbols';
	}

	return null;
};

const PHONE_FRONT_VALIDATION_ERROR = 'validations.phone.error.format';

export const validatePhone = value => {
	if (value.split('')[0] === '0') {
		return {
			id: PHONE_FRONT_VALIDATION_ERROR
		};
	}

	return '';
};

const groupByAttribute = errors => {
	const ret = {};

	errors.forEach(error => {
		const list = ret[error.attribute];

		if (list) list.push(error);
		else ret[error.attribute] = [error];
	});

	return ret;
};

validator.formatters.custom = errors => {
	const groups = groupByAttribute(errors);

	return Object.keys(groups).reduce((obj, attr) => {
		obj[attr] = groups[attr].map(error => {
			const format = {
				id: error.error,
				variables: {
					attribute: error.attribute,
					value: error.value,
					min: error.options.minimum,
					max: error.options.maximum,
					length: error.options.is,
				},
			};
			return format;
		});

		return obj;
	}, {});
};

export default {
	all (form, schema) {
		const cleanForm = {
			...form,
		};

		Object.keys(cleanForm).forEach(key => {
			if (typeof cleanForm[key] === 'string' && cleanForm[key].length === 0) delete cleanForm[key];
		});

		const result = validator.validate(cleanForm, schema, { format: 'custom' });
      
		return result;
	},

	single (form, target, schema) {
		const cleanForm = {
			...form,
		};

		Object.keys(cleanForm).forEach(key => {
			if (typeof cleanForm[key] === 'string' && !cleanForm[key].length) delete cleanForm[key];
		});

		// part of schema
		const part = {
			[target]: {
				...schema[target],
			},
		};

		const result = validator.validate(cleanForm, part, { format: 'custom' });

		if (!result) return [];

		return result[target];
	},

	custom (target, schema) {
		return validator.single(target, schema);
	}
};
