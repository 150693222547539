import { success, error } from 'actions/toast';
import API from 'services/api';
import M from './email.locale.json';

export const get = () => async dispatch => {
	try {
		const result = await API.email.get();
		success(dispatch)({ title: M.success.get.title });
		return result.data.response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};
