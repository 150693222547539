import PropTypes from 'prop-types';

export const cardActionsTypes = PropTypes.shape({
	create: PropTypes.func.isRequired,
	createOther: PropTypes.func.isRequired,
	activate: PropTypes.func.isRequired,
	unblock: PropTypes.func.isRequired,
	pin: PropTypes.func.isRequired,
	get: PropTypes.func.isRequired,
	fetch: PropTypes.func.isRequired,
});

export const cardModalActionsTypes = PropTypes.shape({
	toggleMaintenanceModal: PropTypes.func.isRequired
});

export const privateActionsTypes = PropTypes.shape({
	getAddress: PropTypes.func.isRequired,
});

export const countriesActionsTypes = PropTypes.shape({
	fetch: PropTypes.func.isRequired,
});

export const dashboardActionsTypes = PropTypes.shape({
	fetch: PropTypes.func.isRequired,
	sort: PropTypes.func.isRequired,
});

export const authActionsTypes = PropTypes.shape({
	logout: PropTypes.func.isRequired,
});

export const currencyActionsTypes = PropTypes.shape({
	fetch: PropTypes.func.isRequired,
	convert: PropTypes.func.isRequired,
});

export const paymentActionsTypes = PropTypes.shape({

});

export const notificationsActionsTypes = PropTypes.shape({
	get: PropTypes.func.isRequired,
	markAsRead: PropTypes.func.isRequired,
});
