import BCH from './assets/currencies/BCH.png';
import BTC from './assets/currencies/BTC.png';
import ETH from './assets/currencies/ETH.png';
import EUR from './assets/currencies/EUR.png';
import GBP from './assets/currencies/GBP.png';
import LTC from './assets/currencies/LTC.png';
import TRX from './assets/currencies/TRX.png';
import USD from './assets/currencies/USD.png';
import USDT from './assets/currencies/USDT.png';
import USDC from './assets/currencies/USDC.png';
import XXX from './assets/currencies/XXX.png';

export const STATUS = {
	NONE: 'NONE',
	FAILED: 'FAIL',
	PROCESSING: 'PROCESSING',
	RETRY_REQUIRED: 'RETRY_REQUIRED',
	OK: 'OK',
	EMPTY: ''
};

export const MAPPING_CRYPTO_ICON = {
	BCH,
	BTC,
	ETH,
	EUR,
	GBP,
	LTC,
	TRX,
	USD,
	USDT,
	XXX,
	USDC
};
