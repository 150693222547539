import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import intlTypes from 'types/intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';

import Icon from 'components/Icon';

import './WarningModal.scss';

const SecondModal = ({ intl, setCloseModal, accept, isLoading, warning, goToLoadPage }) => {
	const t = intl.formatMessage;

	return(
		<Modal>
			<Section>
				<Container medium>
					<Wrap className="SingleWarning__wrap">
						<Icon
							className="SingleWarning-close" 
							onClick={setCloseModal} 
							name="close-small"
							disabled={isLoading}
						/>
						<Title
							subtitle={t(warning ? { id: 'card.get.plastic.payment.warning' } : { id: 'card.get.plastic.payment.insufficiently' })}
							small
							className='SingleWarning-text'
						/>
						<div className="SingleWarning-controls">
							{warning ? 
								( <Button 
									onClick={accept} 
									small 
									className="SingleWarning-button"
									loading={isLoading}
								>
									<FormattedMessage id="card.get.plastic.button" />
								</Button>) :
								(<Button 
									onClick={goToLoadPage} 
									small 
									className="SingleWarning-button"
									loading={isLoading}
								>
									<FormattedMessage id="button.simple.top.balance" />
								</Button>)}
							
							<Button 
								onClick={setCloseModal} 
								small 
								className="SingleWarning-button"
								disabled={isLoading}
							>
								<FormattedMessage id="button.simple.cancel" />
							</Button>
						</div>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
}
;

SecondModal.propTypes = {
	setCloseModal: PropTypes.func.isRequired,
	accept: PropTypes.func.isRequired,
	goToLoadPage:PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	intl: intlTypes.isRequired,
	underResourced: PropTypes.bool,
	warning: PropTypes.bool
};


export default injectIntl(SecondModal);
