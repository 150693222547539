export default {
	openbanking: {
		amount: {
			presence: { message: '^schemas.presence' },
		},
		details: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: '[a-zA-Z0-9 .,&/()-]+$',
				message: '^schemas.alphanumeric.address',
			},
		}
	},
};
