import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { error, success } from 'actions/toast';
import * as exchangeActions from 'actions/exchange';
import Form from 'components/Form';
import Input from 'components/Input';
import Title from 'components/Title';
import Container from 'components/Container';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';
import M from '../Exchange.locale.json';
import { TopUpProps } from './types';

const fiterCard = (card, name, surname) => {
	return !!(
		card.cardStatus === 'OPEN' &&
		card.activated &&
		card.cardName &&
		card.cardName.trim() === (name + ' ' + surname).trim()
	);
};

const mapState = state => ({
	cards: state.card.items
		.filter(card => fiterCard(card, state.user.name, state.user.surname))
		.map(card => ({
			...card,
			value: card.id,
			balance: card.availBal,
			title: card.cardNumber
		}))
});

const mapDispatchToProps = dispatch => {
	return {
		errorToast: payload => {
			error(dispatch)(payload);
		},
		successToast: payload => {
			success(dispatch)(payload);
		}
	};
};

const ExchangeTopUp = ({ cards, intl, ...props }) => {
	const [currentCard, setCurrentCard] = useState(null);
	const [amount, setAmount] = useState('');
	const history = useHistory();
	const isDisabled = !amount || !currentCard;
	const t = intl.formatMessage;

	const onSubmit = async e => {
		e.preventDefault();
		const currentCardInfo = cards.find(({ id }) => id === currentCard);

		if (
			!+currentCardInfo.availBal ||
			parseFloat(currentCardInfo.availBal) < amount
		) {
			props.errorToast({
				title: {
					id: 'schemas.not.enough'
				}
			});
		} else {
			try {
				const { success, data } = await exchangeActions.topUp({
					amount: amount,
					cardId: currentCard
				});
				history.push(`topup/confirm/${data.id}`);
			} catch ({ errors }) {
				for (const error in errors) {
					if (Object.hasOwnProperty.call(errors, error)) {
						props.errorToast({
							title: {
								...errors[error][0],
								id: errors[error][0].id || 'undefinedError'
							}
						});
					}
				}
			}
		}
	};

	const onChangeAmount = value => {
		if (!+value && +value !== 0) return;
		setAmount(value);
	};

	const onBlurAmount = value => {
		console.log(isNaN(value));
		if (isNaN(value) || value === '') {
			setAmount('');
			return;
		}
		setAmount(parseFloat(value).toFixed(2));
	};

	const onChangeCard = value => {
		setCurrentCard(value);
	};

	useEffect(() => {
		if (cards.length) setCurrentCard(cards[0].id);
	}, []);

	return (
		<div className="Exchange__TopUp">
			<Container small>
				<Title
					title={t(M.topUp.title)}
					subtitle={t(M.topUp.subtitle1) + ' ' + t(M.topUp.subtitle2)}
				/>
				<Form onSubmit={onSubmit}>
					<Form.Group>
						<Input
							name="amount"
							placeholder={t(M.topUp.inputs.amount)}
							onChange={onChangeAmount}
							value={amount}
							onBlur={onBlurAmount}
							// errors={errors.registrationNumber}
						/>
					</Form.Group>
					<Form.Group>
						<InputSelect
							name="cardId"
							onChange={onChangeCard}
							placeholder={t(M.topUp.inputs.card)}
							value={currentCard}
							// errors={errors.businessCountry}
							data={cards}
						/>
					</Form.Group>
					<Form.Note>{t(M.topUp.messages.cardMessage)}</Form.Note>
					<Button disabled={isDisabled} form>
						{t(M.topUp.buttons.send)}
					</Button>
				</Form>
			</Container>
		</div>
	);
};

ExchangeTopUp.propTypes = TopUpProps;

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps)
)(ExchangeTopUp);
