import { createSelector } from 'reselect';



export const list = state => state.payment.items;

// filters
export const columns = state => state.payment.columns;
export const columnsNav = (props, items) => {
	const t = props.intl.formatMessage;

	return items
		.map(item => ({
			...item,
			title: t(item.title),
		}));
};

export const status = state => state.payment.status;
export const statusNav = (props, items) => {
	const t = props.intl.formatMessage;

	return items
		.map(item => ({
			...item,
			title: t(item.title),
		}));
};

export const search = state => state.payment.search;
export const sort = state => state.payment.sort;

export const get = (state, id) => {
	if (id) {
		return state.payment.items.find(item => item.id === parseInt(id));
	}

	return undefined; // I'm assuming that there will be no cards with id 0
};

const filterTypes = items => item => items.indexOf(item.paymentType) > -1;

const filterStatus = items => item => items.indexOf(item.status) > -1;

const filterSearch = raw => {
	const keyword = raw.toLowerCase();

	return item => {
		// details
		if (item.beneficiary && item.details.toLowerCase().includes(keyword)) return true;

		// recipient
		if (item.beneficiary && item.beneficiary.toLowerCase().includes(keyword)) return true;

		// id
		if (item.id && item.id.toString().toLowerCase().includes(keyword)) return true;

		return false;
	};
};

export const fetch = createSelector(
	list,
	status,
	search,
	(list, status, search) => {
		let items = list;

		if (status) items = items.filter(filterStatus(status));
		else items = [];

		if (items && search) items = items.filter(filterSearch(search));

		return items;
	},
);
