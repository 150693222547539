export const defaultFormData = {
	beneficiary: '',
	surname: '',
	currency: '',
	amount: '',
	creditorCountryCode: '',
	city: '',
	state: '',
	creditorAddress1: '',
	creditorAddress2: '',
	postCode: '',
	iban: '',
	bic: '',
	details: '',
	debitAmount: '',
};

export const defaultErrorsData = {
	beneficiary: [],
	currency: [],
	surname: [],
	amount: [],
	creditorCountryCode: [],
	city: [],
	state: [],
	creditorAddress1: [],
	creditorAddress2: [],
	postCode: [],
	iban: [],
	bic: [],
	details: [],
};

export const controls = {
	bic: 'bic',
	iban: 'iban',
	currency: 'currency',
	amount: 'amount',
};

export const exceptedCountries = ['CA', 'MX', 'US'];

export const beneficiaryTypePrivateClient = 'private';
export const beneficiaryTypeCompany = 'company';
