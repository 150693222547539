import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import OrderCardMessage from './OrderCardMessage';
import NotOrderNewCard from './NotOrderNewCard';

import './NotificationWarning.scss';

const NotificationWarning = ({
	setCloseOrderModal,
	closeNotOrderOpen,
	isNotOrderCardModal,
	isOrderCardModal,
	notOrderCardAction,
	isLoading
}) => {
	return (
		<Modal>
			<Section>
				<Container medium>
					{isOrderCardModal && <OrderCardMessage setCloseModal={setCloseOrderModal} />}
					{isNotOrderCardModal && (
						<NotOrderNewCard
							isLoading={isLoading}
							setCloseModal={closeNotOrderOpen}
							notOrderCardAction={notOrderCardAction}
						/>
					)}
				</Container>
			</Section>
		</Modal>
	);
};
NotificationWarning.propTypes = {
	setCloseOrderModal: PropTypes.func.isRequired,
	closeNotOrderOpen: PropTypes.func.isRequired,
	notOrderCardAction: PropTypes.func.isRequired,
	isNotOrderCardModal: PropTypes.bool,
	isOrderCardModal: PropTypes.bool,
	isLoading: PropTypes.bool
};

export default NotificationWarning;
