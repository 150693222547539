import API from 'services/api';
import storage from 'services/storage';
import { JUMIO_LINK_SUCCESS, JUMIO_VERIFICATION_STATUS, IS_UPLOAD_DOCUMENTS, JUMIO_VERIFICATIONS__STATUS } from 'constants';
import { logEvent } from '@firebase/analytics';
import { firebaseAnalytics } from 'helpers/analytics';
import { START_JUMIO_VERIFICATION_DATA, STATUSES_UPLOAD_DOCUMENTS } from '../constants';

export const getLinkVerification = () => async dispatch => {
  try {
    const { data: { response } } = await API.jumio.getLink();
    // return dispatch({
    //   type: JUMIO_LINK_SUCCESS,
    //   payload: response
    // });
    return response;
  } catch (error) {
    throw error;
  }
};

export const startVerification = (cardholderId) => async (dispatch) => {
  try {
    const data = {
      cardholder_id: cardholderId,
      type: 'ID'
    };
    await API.jumio.startVerification(data);

    dispatch({ type: JUMIO_LINK_SUCCESS, payload: START_JUMIO_VERIFICATION_DATA });

    return dispatch({
      type: JUMIO_VERIFICATION_STATUS,
      payload: JUMIO_VERIFICATIONS__STATUS.IN_PROCESS
    });

  } catch (error) {
    throw new Error(error);
  }
}

export const getVerificationStatus = (cardholderId) => async (dispatch) => {
  try {
    const { data: { response } } = await API.jumio.getVerificationStatus(cardholderId);
    const status = response['VID_Status'];
    const currentStatusVerification = storage.get(IS_UPLOAD_DOCUMENTS);

    if (STATUSES_UPLOAD_DOCUMENTS.includes(status) && !currentStatusVerification) {
      logEvent(firebaseAnalytics, 'DOCUMENTS_UPLOADED');
      storage.set(IS_UPLOAD_DOCUMENTS, 'true');
    }
    return dispatch({
      type: JUMIO_VERIFICATION_STATUS,
      payload: status
    });
  } catch (error) {
    throw new Error(error);
  }
}
