import React, { Fragment } from 'react';
import { error, success } from 'actions/toast';
import AutopaymentSchema from 'schemas/autopayment';
import { getCurrentCard } from 'selectors/card';
import helpers from 'helpers';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import Button from 'components/Button';
import Container from 'components/Container';
import Form from 'components/Form';
import Input from 'components/Input';
import InputDate from 'components/InputDate';
import InputSelect from 'components/InputSelect';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import Title from 'components/Title';
import Wrap from 'components/Wrap';

import * as templateActions from 'actions/template';

import intlTypes from 'types/intl';
import M from './Templates.locale.json';

import './Templates.scss';

const mapState = state => ({
	currentCard: getCurrentCard(state),
});

const mapDispatchToProps = dispatch => {
	return {
		errorToast: payload => {
			error(dispatch)(payload);
		},
		successToast: payload => {
			success(dispatch)(payload);
		},
	};
};

@injectIntl
@withRouter
@connect(mapState, mapDispatchToProps)
class AutopaymentEditModal extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		onClose: PropTypes.func,
		autopayment: PropTypes.object.isRequired,
	};
	constructor (props) {
		super(props);
		const now = new Date();
		const autopayment = props.autopayment;
		this.state = {
			errors: [],
			loader: false,
			autopayment: autopayment,
			is_edit: !!autopayment,
			howoften: autopayment && autopayment.repeatDay && autopayment.repeatDay!="*" ? 0 : 1,
			day: autopayment && autopayment.repeatDay && autopayment.repeatDay!="*" ? parseInt(autopayment.repeatDay) : new Date(new Date().getTime() + 24*60*60*1000).getDate(),
			dayOfTheWeek: autopayment && autopayment.repeatWeekday && autopayment.repeatWeekday != "*" ? parseInt(autopayment.repeatWeekday) : 1,
			expiration: autopayment && autopayment.repeatEnd && autopayment.repeatCount ? 2 : (
				autopayment && autopayment.repeatEnd && !autopayment.repeatCount ? 1 : 0
			),
			expirationDate: autopayment && autopayment.repeatEnd ? autopayment.repeatEnd.replace("-","/").replace("-","/") : now.getDate()+"/"+(now.getMonth()+1)+"/"+now.getFullYear(),
			expirationCount: autopayment && autopayment.repeatCount ? parseInt(autopayment.repeatCount+"") : 1,
			showDayTip: false,
			codeStep: false, 
			code: '',
		};
		// console.log("24it autopayment", props.autopayment);
		this.t = props.intl.formatMessage;
		this.cardHolderId = 0; 
	}

	async onRemove (e) {
		e.stopPropagation();
		this.setState({loader: true}, async () => {
			await templateActions.removeAutopayment(this.state.autopayment.templateId);
			this.props.onClose();
		});
	}

	async onSave (e) {
		e.stopPropagation();

		const { howoften, day, expiration, expirationCount } = this.state;
		let form = {};

		if (howoften === 0) {
			form = { ...form, day };
		}

		if (expiration === 2) {
			form = { ...form, expirationCount };
		}
		
		const errors = helpers.validator.all(form, AutopaymentSchema);

		if (errors) {
			this.setState({ errors });
			return;
		}

		this.setState({ loader: true }, async () => {
			const cardholders = await templateActions.autopaymentCardholders();
			if (cardholders && cardholders.length > 0) {
				for(const c of cardholders) {
					if (c.cardholderId == this.props.currentCard.cardholderId) this.cardHolderId = c.id;
				}
			}
			if (this.cardHolderId > 0) {
				const resultSMS = await templateActions.autopaymentSms(this.props.templateId);
				if (resultSMS.errors) {
					this.setState({loader: false});

					for (const error in resultSMS.errors) {
						if (Object.hasOwnProperty.call(resultSMS.errors, error)) {
							this.props.errorToast({ title: resultSMS.errors[error][0] });
							
						}
					}
					return;
				}

				this.setState({ loader: false, codeStep: true });

			} else {}
		});
	}

	onChange = (value, id) => {
		// console.log('onChange', value, id);

	};

	saveAutopayment = async e => {
		e.preventDefault();
		// console.log('saveAutopayment');
		const { code } = this.state; 

		const s = this.state.expirationDate.split("/");
		let expirationDateAPI = s.length == 3 ? (s[0].length==1?"0":"")+s[0]+"-"+(s[1].length==1?"0":"")+s[1]+"-"+s[2] : null;

		if (!code) {				
			return;
		}
		
		const autopayment = {
			"templateId": this.props.templateId,
			"cardHolderId": this.cardHolderId,
			"repeatDay": this.state.howoften === 0 ? this.state.day+"" : "*",
			"repeatWeekday": this.state.howoften === 1 ? this.state.dayOfTheWeek+"" : "*",
			"repeatEnd": this.state.expiration === 1 ? expirationDateAPI : null,
			"repeatCount": this.state.expiration === 2 ? this.state.expirationCount : null,
			"code": code
		};
		// console.log('saving autopayment', autopayment);
		const result = await templateActions.saveAutopayment(autopayment);

		if (result.errors) {
			for (const error in result.errors) {
				if (Object.hasOwnProperty.call(result.errors, error)) {
					this.props.errorToast({ title: result.errors[error][0] });
					
				}
			}
			return;
		}
		// console.log('24it autopayment results', result, error);
		this.props.onClose();
	};

	handleDay = e => {
		if ([29,30,31].includes(+e)) {this.setState({ showDayTip: true });}
		else {this.setState({ showDayTip: false });}
	};


	render () {
		const { loader, autopayment, is_edit, errors, showDayTip, codeStep } = this.state;
		const { onClose } = this.props;
		const t = this.t;
		console.debug(this.props);
		return (
			<Modal onClick={onClose} className="Template__Modal__Edit">
				<Container medium>
					<Wrap>
						<Modal.Close onClick={onClose} />
						<Title title={t(M.autopayments.title)} />
						<Form>
							{loader ? (
								<Loader className="Template__Loader" />
							) : (
								<Fragment>
									<Form.Group >
										<InputSelect
											disabled={codeStep}
											name="freq"
											placeholder={t(M.autopayments.inputs.freq)}
											onChange={e=>{
												this.setState({ howoften: e });
											}}
											value={this.state.howoften}
											errors={''}
											data={[
												{ value: 0, title: t(M.autopayments.values.freq.month) },
												{ value: 1, title:  t(M.autopayments.values.freq.week) }
											]}
										/>
										{this.state.howoften === 0 && 
										<React.Fragment>
											<Input
												disabled={codeStep}
												name="day"
												label={t(M.autopayments.inputs.day)}
												placeholder={t(M.autopayments.inputs.day)}
												onChange={e=>{
													this.setState({ day:e });
													this.handleDay(e);
												}}
												value={this.state.day}
												errors={errors['day']}
												autoFocus
											/>
											{showDayTip && <Form.Note style={{ marginTop: 8 }} className="margin-top">{t(M.autopayments.dayTip)}</Form.Note>}
										</React.Fragment>}
										{this.state.howoften === 1 && 
										<InputSelect
											disabled={codeStep}
											name="weekly"
											placeholder={t(M.autopayments.inputs.weekly)}
											onChange={e=>{
												this.setState({ dayOfTheWeek:e });
											}}
											value={this.state.dayOfTheWeek}
											errors={''}
											data={[
												{ value: 1, title: t(M.autopayments.values.dayOfWeek[0]) },
												{ value: 2, title: t(M.autopayments.values.dayOfWeek[1]) },
												{ value: 3, title: t(M.autopayments.values.dayOfWeek[2]) },
												{ value: 4, title: t(M.autopayments.values.dayOfWeek[3]) },
												{ value: 5, title: t(M.autopayments.values.dayOfWeek[4]) },
												{ value: 6, title: t(M.autopayments.values.dayOfWeek[5]) },
												{ value: 0, title: t(M.autopayments.values.dayOfWeek[6]) }
											]}
										/>}
										<InputSelect
											disabled={codeStep}
											name="expiration"
											placeholder={t(M.autopayments.inputs.expiration)}
											onChange={e=>{
												this.setState({ expiration: e });
											}}
											value={this.state.expiration}
											errors={''}
											data={[
												{ value: 0, title:  t(M.autopayments.values.expiration.no) },
												{ value: 1, title: t(M.autopayments.values.expiration.date) },
												{ value: 2, title: t(M.autopayments.values.expiration.number) }
											]}
										/>
										{this.state.expiration === 1 && <InputDate
											name="onDate"
											label={t(M.autopayments.inputs.expirationDate)}
											placeholder={t(M.autopayments.inputs.expirationDate)}
											onChange={e=>{
												this.setState({ expirationDate:e });
											}}
											value={this.state.expirationDate}
											errors={''}
											future
										/>}
										{this.state.expiration === 2 && <Input
											name="count"
											label={t(M.autopayments.inputs.expirationCount)}
											placeholder={t(M.autopayments.inputs.expirationCount)}
											onChange={e=>{
												this.setState({ expirationCount:e });
											}}
											value={this.state.expirationCount}
											errors={errors['expirationCount']}
										/>}
									</Form.Group>
									<div style={{
										display: 'flex',
									}}
									>
										<Button disabled={codeStep} onClick={e=>{this.onSave(e);}}>
											{t(M.buttons.next)}
										</Button>
										{is_edit && 
										<Fragment>
											<div>&nbsp;</div>
											<Button  onClick={e=>{this.onRemove(e);}}>
												{t(M.autopayments.button_remove)}
											</Button>
										</Fragment>}
									</div>
								</Fragment>
							)}

						</Form>

						{codeStep && 
							<Fragment>
								<div>&nbsp;</div>
								<Form onSubmit={this.saveAutopayment}>
									<Input
										name="code"
										label={t(M.autopayments.inputs.code)}
										placeholder={t(M.autopayments.inputs.code_short)}
										value={this.state.code}
										errors={errors['code']}
										onChange={e=>{
											this.setState({ code: e });
										}}
									/>	
									<Button disabled={!(/^[0-9]{6}$/.test(this.state.code))} form>
										{t(M.autopayments.button_save)}
									</Button>
								</Form>
							</Fragment>}
					</Wrap>
				</Container>
			</Modal>
		);
	}
}

export default AutopaymentEditModal;
