import fetcher from 'helpers/fetcher';

export default {
	sendSurveyInfo (source) {
		return fetcher.post('user/set-source', {}, { params: { source } });
	},
	getPersonalCode () {
		return fetcher.get('user/referrals/get-code');
	},
	registerAppReferral (utm) {
		return fetcher.post('open/register-app-referral', {}, { params: utm });
	},
};
