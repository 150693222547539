import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import ButtonLink from 'components/ButtonLink';
import Button from 'components/Button';

import M from './VerifyCardModal.locale.json';
import './VerifyCardModal.scss';
import { getList } from '../../../selectors/card';
import { connect } from 'react-redux';

const VerifyCardModal = ({ t, cards, onSkip, onVerify, subtitle, title, skip, verify, hideLegend, ...props }) => {
	const hasCard17 = useMemo(() => {
		return cards.some(card => card.product === 'CARD_17');
	}, [cards]);

	return (
		<Modal className="DashboardSection__VerifyCardModal" {...props} >
			<Section>
				<Container medium>
					<Wrap className="verify-modal-wrap">
						<Title
							className="verify-notification-modal__title"
							title={hasCard17 ? t(M.repeatTitle) : (title || t(M.title))}
							legend={hasCard17 ? t(M.repeatDesc) : (!hideLegend ? (subtitle || t(M.legend)) : '')}
							small
						/>

						<div className="verify-notification-modal__actions">
							<Button
								onClick={onVerify}
								small
							>
								{verify || t(M.buttons.verify)}
							</Button>
							<ButtonLink
								className="verify-notification-modal__button-link"
								onClick={onSkip}
								secondary
								small
							>
								{skip || t(M.buttons.skip)}
							</ButtonLink>
						</div>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
};

VerifyCardModal.propTypes = {
	t: PropTypes.any.isRequired,
	onVerify: PropTypes.func.isRequired,
	onSkip: PropTypes.func.isRequired,
	subtitle: PropTypes.string,
	title: PropTypes.string,
	skip: PropTypes.string,
	verify: PropTypes.string,
	hideLegend: PropTypes.bool,
};
const mapStateToProps = state => ({
	cards: getList(state),
});
export default connect(mapStateToProps)(VerifyCardModal);
