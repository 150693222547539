import React, { PureComponent } from 'react';
import { FormattedMessage as FM, injectIntl } from 'react-intl';
import API from 'services/api';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import Logo from 'components/Logo';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Client from 'components/Client';
import Loader from "../../components/Loader";
import fetcher from 'helpers/fetcher';
import * as countrySelector from "../../selectors/country";
import * as countryActions from 'actions/country';
import * as clientSelector from 'selectors/client';
import * as clientActions from 'actions/client';
import * as authActions from 'actions/auth';
import countries from "../../screens/Setting/Phone/Phone.locale.json";
import { BOOKING_URL_LOGIN, BOOKING_TOKEN } from 'constants/booking';
import { FRESHCHAT_HOST, FRESHCHAT_TOKEN, FRESHCHAT_WIDGETUUID } from "../../constants/chat";
import { isProduction } from '../../constants';
import './Sidebar.scss';
import M from './Sidebar.locale.json';
import Modal from '../../components/Modal';
import Section from '../../components/Section';
import Container from '../../components/Container';
import Wrap from '../../components/Wrap';
import Title from '../../components/Title';
import ReferralModal from '../../components/ReferralModal';



const mapState = (state, props) => ({
	currentCardId: state.card.current,
	current: clientSelector.getCurrentClient(state),
	user: state.user,
	allPhoneCountry: countrySelector.getAllPhoneCodesCountries(state, props, countries.countries),
	country: countrySelector.getEnrollCountries(state, props, countries.countries),
});

const mapDispatch = dispatch => ({
	actions: {
		client: bindActionCreators(clientActions, dispatch),
		auth: bindActionCreators(authActions, dispatch),
		country: bindActionCreators(countryActions, dispatch),
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Sidebar extends PureComponent {
	state = {
		collapsed: false,
		width: window.innerWidth,
		bookingHint: false,
		chatLoading: false,
		referralVisible: false,
	};

	componentDidMount = () => {
		const { actions, country, user } = this.props;

		window.addEventListener('resize', this.updateDimensions);

		if (this.state.width < 960) {
			this.setState({
				collapsed: true
			});
		}

		if (!country.length) {
			actions.country.fetch({
				entroll: true
			});
		}

		this.initChat(user);

		this.handleDeepLink();
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({
			width: window.innerWidth
		});

		if (this.state.width < 960) {
			this.setState({
				collapsed: true
			});
		} else {
			this.setState({
				collapsed: false
			});
		}
	};

	onCollapse = () => {
		this.setState({
			collapsed: !this.state.collapsed
		});
	};

	onLogout = () => {
		const { auth } = this.props.actions;

		auth.logout();

		window.fcWidget.destroy();
		localStorage.removeItem(FRESHCHAT_TOKEN);
	};

	onSettings = () => {
		const { history } = this.props;

		history.push('/settings');
	};


	onBooking = async () => {
		fetcher.post(`user/hotel/click/${this.props.currentCardId}`);
		const { id, email, name, surname } = this.props.user;
		const formValues = {
			CustomerEmail: email,
			FirstName: name,
			LastName: surname,
			MemberID: id,
			lang: 'en',
			currency: 'EUR',
			UniqueToken: BOOKING_TOKEN
		  };

		  const form = document.createElement('form');
		  form.method = 'POST';
		  form.action = BOOKING_URL_LOGIN;
		  form.target = '_blank';

		  for (const key in formValues) {
			if (formValues.hasOwnProperty(key)) {
			  const input = document.createElement('input');
			  input.type = 'hidden';
			  input.name = key;
			  input.value = formValues[key];
			  form.appendChild(input);
			}
		  }

		  document.body.appendChild(form);
		  form.submit();
		  document.body.removeChild(form);

	};

	handleDeepLink = () => {
		const isDeepLink = localStorage.getItem('weststein:target') === 'referral';
		if (isDeepLink) {
			this.setState({ referralVisible: true });
			localStorage.removeItem('weststein:target');
		}
	}

	toggleChat = () => {
		if (window.fcWidget.isOpen()) {
			window.fcWidget.close();
		} else {
			window.fcWidget.open();
		}
	};

	initWidget = () => {
		const { user } = this.props;

		this.setState({ chatLoading: true });

		var restoreId = user.restoreId;
		window.fcWidget.init({
			token: FRESHCHAT_TOKEN,
			host: FRESHCHAT_HOST,
			widgetUuid: FRESHCHAT_WIDGETUUID,
			externalId: user.id,
			restoreId: restoreId ? restoreId : null,
			config: {
				headerProperty: {
					hideChatButton: true
				}
			}
		});
	};

	toggleChatLoading = (value) => {
		this.setState({ chatLoading: value });
	}

	createUser = (userData) => {
		const { user } = this.props;

		const toggleChatLoading = this.toggleChatLoading;

		window.fcWidget.user.get(function(resp) {
			var status = resp && resp.status,
				data = resp && resp.data;

			toggleChatLoading(false);

			if (status !== 200) {
				console.log("Updating user properties");
				window.fcWidget.user.setProperties({
					firstName: userData.firstName,
					lastName: userData.lastName,
					email: userData.email,
					phone: userData.phone,
					phoneCountryCode: userData.phoneCountryCode,
					loginMethod: "WEB"
				});

				window.fcWidget.on("user:created", function(resp) {
					var status = resp && resp.status,
						data = resp && resp.data;
					debugger
					if (status === 200) {
						if (data.restoreId) {
							console.log("Update restore ID for User:" + data.restoreId);
							// Update restoreId in your database
							API.chat.put(data.restoreId).then(response => {
								console.log("RestoreId updated.");
								console.log(response)
							}).catch(error => {
								console.log("Something happened while updating restoreId");
								console.log(error);
							})
						}
					}
				});
			} else {
				// if user doesn't have a restoreId and we have it in request response
				if (!user.restoreId && resp.data.restoreId) {
					console.log("Updating restoreId for existing user.");
					API.chat.put(resp.data.restoreId).then(response => {
						console.log("RestoreId updated.");
						console.log(response);
					}).catch(error => {
						console.log("Something happened while updating restoreId");
						console.log(error);
					})
				}
			}
		});
	};

	findCodeCountry = (currentCountry) => {
		const { allPhoneCountry } = this.props;
		const codeCountry = allPhoneCountry.filter(list => list.value == currentCountry);
		return codeCountry[0] ? codeCountry[0].title : "";
	};

	initChat = (user) => {
		this.initWidget();
		const userData = {
			firstName: user.name,
			lastName: user.surname,
			email: user.email,
			phone: user.phone,
			phoneCountryCode: this.findCodeCountry(user.phoneCountry)
			// phoneCountryCode: user.phoneCountry
		};
		this.createUser(userData);
	}

	render () {
		let className = ['Sidebar'];

		const { current, logo } = this.props;
		if (this.props.className) {
			className = [
				...className,
				...this.props.className
			];
		}

		const access = (current.role === 'OWNER');
		const isPrivate = (current.roleType === 'PRIVATE');

		const t = this.props.intl.formatMessage;

		// collapse sidebar on click
		if (this.state.collapsed) {
			className.push('Collapsed');
		}


		return (
			<div
				className={className.join(' ')}
			>
				<div
					className="Sidebar__Logo"
				>
					<Logo />
				</div>
				{
					!this.state.collapsed &&
					<Client className="Sidebar__Nav__Client" />
				}

				<div className="Sidebar__Primary">
					<Button onClick={this.onCollapse}>
						{!this.state.collapsed &&
							<Icon name="close" />
						}
						{this.state.collapsed &&
							<Icon name="menu" />
						}
					</Button>
					<ul>
						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/"
								exact
							>
								<Icon name="nav-dashboard" /><span>{t(M.nav.dashboard)}</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/history"
							>
								<Icon name="nav-history" /><span>{t(M.nav.history)}</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/cards"
							>
								<Icon name="nav-cards" /><span>{t(M.nav.cards)}</span>
							</NavLink>
						</li>

						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/payments"
							>
								<Icon name="nav-payments" /><span>{t(M.nav.payments)}</span>
							</NavLink>
						</li>

						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to="/notifications"
							>
								<Icon name="nav-notifications" /><span>{t(M.nav.notifications)}</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								className="Sidebar__Link"
								// activeClassName="Sidebar__Link--Current"
								to='#'
								onClick={this.onBooking}
								// target="_blank"
								onMouseEnter={() => this.setState({ bookingHint: true })}
								onMouseLeave={() => this.setState({ bookingHint: false })}
							>
								<Icon name="nav-booking" /><span>{t(M.nav.booking)}</span>
								<div className={`booking__hint${this.state.bookingHint ? ' visible' : ''}`}>
									<p>
										{t(M.nav.bookingHint)}
									</p>
								</div>
							</NavLink>
						</li>
						{!isProduction &&
						<li>
							<NavLink
								className="Sidebar__Link"
								activeClassName="Sidebar__Link--Current"
								to='/exchange'
							>
								<Icon name="crypto" /><span>{t(M.nav.exchange)}</span>
							</NavLink>
						</li>
						}
						<li>
							{
								(access && !isPrivate) &&
								<NavLink
									className="Sidebar__Link"
									activeClassName="Sidebar__Link--Current"
									to="/members"
								>
									<Icon name="nav-team" /><span>{t(M.nav.members)}</span>
								</NavLink>
							}

							{
								(!access && !isPrivate) &&
								<div
									className="Sidebar__Link Sidebar__Link--Disabled"
								>
									<Icon name="nav-team" /><span>{t(M.nav.members)}</span>
								</div>
							}
						</li>
						<li>
							<NavLink
								className="Sidebar__Link"
								to=''
								onClick={() => this.setState({ referralVisible: true })}
							>
								<Icon name="nav-team" /><span>{t({id: 'screens.sidebar.referrals.title'})}</span>
							</NavLink>
						</li>
					</ul>
					<div className="User-actions">
						<ul>
							<li>
								<div className='Sidebar__Chat'>
										{this.state.chatLoading ?
											<Loader /> :
											<NavLink
												className="Sidebar__Link"
												activeClassName=""
												to="#"
												onClick={this.toggleChat}
												exact
											>
												<Icon name="chat" /><span>{t(M.nav.chat)}</span>
											</NavLink>
										}
								</div>
							</li>
							<li>
								<NavLink
									className="Sidebar__Link"
									activeClassName=""
									to="/settings"
									exact
								>
									<Icon name="settings" /><span>{t(M.nav.settings)}</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									className="Sidebar__Link"
									activeClassName=""
									onClick={this.onLogout}
									to=""
									exact
								>
									<Icon name="logout" /><span>{t(M.nav.logout)}</span>
								</NavLink>
							</li>
						</ul>
					</div>
				</div>

				{this.state.referralVisible && <ReferralModal onClose={() => this.setState({ referralVisible: false })}/>}
			</div>
		);
	}
}

Sidebar.propTypes = {
	className: PropTypes.arrayOf(PropTypes.string),
};
