export const formatTemplate = template => {
	let payment = {};
	switch (template.type) {
		case 'INTERNATIONAL':
			if (template.new) {
				payment = {
					type: template.type,
					title: '',
					iban: '',
					bic: '',
					recipient: '',
					recipientLastname: '',
					country: '',
					city: '',
					line1: '',
					line2: '',
					postalCode: '',
					amount: '',
					currency: '',
					reference: ''
				};
			} else {
				payment = {
					type: template.type,
					title: template.title,
					iban: template.iban,
					bic: template.bic,
					recipient: template.recipient,
					recipientLastname: template.recipientLastname,
					line1: template.address.line1,
					line2: template.address.line2,
					postalCode: template.address.postalCode,
					city: template.address.city,
					country: template.address.country,
					amount: template.amount,
					currency: template.currency,
					reference: template.reference
				};
			}
			break;
		case 'SEPA':
			payment = {
				type: template.type,
				title: template.title,
				iban: template.iban,
				recipient: template.recipient,
				amount: template.amount,
				reference: template.reference
			};
			break;
		case 'CARD_TO_CARD':
			payment = {
				type: template.type,
				title: template.title,
				recipientCardNumber: template.recipientCardNumber,
				amount: template.amount
			};
			break;
	}
	return payment;
};

export const formatTemplateToObject = template => {
	let payment = {};
	switch (template.type) {
		case 'INTERNATIONAL':
			payment = {
				...template,
				amount: parseFloat(template.amount).toFixed(2),
				address: {
					country: template.country,
					city: template.city,
					line1: template.line1,
					line2: template.line2,
					postalCode: template.postalCode
				}
			};
			break;
		default:
			return { ...template, amount: parseFloat(template.amount).toFixed(2) };
	}
	return payment;
};

export const prepareCurrencies = currencies =>
	currencies.reduce((state, currency) => {
		if (currency.outgoing) {
			state.push({
				title: `${currency.name} (${currency.code})`,
				value: currency.code
			});
		}
		return state;
	}, []);

export const prepareCountries = countries =>
	countries.reduce((state, country) => {
		if (country.priority) {
			state.push({
				title: `${country.name} (${country.iso2})`,
				value: country.id
			});
		}
		return state;
	}, []);
