import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Button from 'components/Button';
import Layout from 'components/Layout';
import Title from 'components/Title';
import Section from 'components/Section';
import CurrencyDetailsComponent from 'components/Exchange/Details';

const Currency = ({ history, location }) => {
	const { currency } = location.state || { currency: undefined };

	if (!currency || !currency.currencyCode) {
		history.replace('/exchange');
		return <div />;
	} else {
		return (
			<Layout className="ExchangeScreen Screen" sidebar>
				<Nav />
				<Header withBack />

				<Section large>
					<Title title={currency.currencyCode} />
					<CurrencyDetailsComponent currency={currency} />
				</Section>
			</Layout>
		);
	}
};

Currency.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

export default Currency;
