export default {
	create: {
		beneficiary: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.space', options: { space: true } },
			length: {
				minimum: 2,
				maximum: 20,
				message: '^schemas.length.between',
			},
		},
		iban: {
			presence: { message: '^schemas.presence' },
			alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
			length: {
				minimum: 15,
				maximum: 31,
				message: '^schemas.length.between',
			},
		},
		// bic: {
		// 	presence: { message: '^schemas.presence' },
		// 	alphanumeric: { message: '^schemas.alphanumeric.alphanumeric' },
		// 	length: {
		// 		minimum: 8,
		// 		maximum: 11,
		// 		message: '^schemas.length.between',
		// 	},
		// },
		amount: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^([0-9]*|\d*\.\d{0,2}?)$/,
				message: '^schemas.alphanumeric.amount'
			},
		},
		details: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^[ /a-zA-Z0-9]+$/,
				message: '^schemas.alphanumeric.slash'
			},
			length: {
				minimum: 1,
				maximum: 35,
				message: '^schemas.length.between',
			},
		},
	},

	amount: {
		amount: {
			presence: { message: '^schemas.presence' },
			numericality: { message: '^schemas.numerical' },
			equality: {
				attribute: 'original',
				message: '^schemas.match',
				comparator: (v1, v2) => parseFloat(v1) === parseFloat(v2)
			},
		},
	},

	phone: {
		code: {
			presence: { message: '^schemas.presence' },
		},
	},
};
