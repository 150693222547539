import {
	SHOW_UI_ALERT,
	HIDE_UI_ALERT,
	SHOW_TWOFACTOR_ALERT,
	HIDE_TWOFACTOR_ALERT
} from 'constants';
import {SET_REGISTRATION_PAYMENT} from "../constants";

const initial = {
	alert : {
		showUiAlert: false,
		title: '',
		subtitle: '',
		text: '',
	},
	twoFactor: {
		showTwoFactorAlert:false
	},
	registration: {
		purchaseCompleted: false,
	}
};

export default (state = initial, action) => {
	switch (action.type) {
		case SHOW_UI_ALERT:
			return {
				...state,
				alert: {
					...state.alert,
					showUiAlert: true,
					...action.payload
				}
			};
		case HIDE_UI_ALERT:
			return { ...state, alert: { ...initial.alert } };
		case SHOW_TWOFACTOR_ALERT:
			return {
				...state,
				twoFactor: {
					...state.twoFactor,
					showTwoFactorAlert:true
				}
			};
		case HIDE_TWOFACTOR_ALERT:
			return {
				...state,
				twoFactor: {
					...state.twoFactor,
					showTwoFactorAlert:false
				}
			};
		case SET_REGISTRATION_PAYMENT:
			return {
				...state,
				registration: {
					purchaseCompleted: action.payload,
				}
			};
		default:
			return state;
	}
};
