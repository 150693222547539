/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Form from 'components/Form';
import Input from 'components/Input';
import Title from 'components/Title';
import Container from 'components/Container';
import Button from 'components/Button';
import { error, success } from 'actions/toast';
import * as exchangeActions from 'actions/exchange';
import M from '../../Exchange.locale.json';

const mapDispatchToProps = dispatch => {
	return {
		errorToast: payload => {
			error(dispatch)(payload);
		},
		successToast: payload => {
			success(dispatch)(payload);
		}
	};
};

const ExchangeTopUp = ({ intl, id, ...props }) => {
	const [code, setCode] = useState();
	const history = useHistory();
	const isDisabled = !code || String(code).length !== 6;
	const t = intl.formatMessage;

	const showToastOnErrors = errors => {
		for (const error in errors) {
			if (errors.hasOwnProperty(error)) {
				props.errorToast({
					title: {
						...errors[error][0],
						id: errors[error][0].id || 'undefinedError'
					}
				});
			}
		}
	};

	const onSubmit = async e => {
		e.preventDefault();
		try {
			const { success, data } = await exchangeActions.topupConfirmRequest(
				id,
				code
			);
			history.push('/exchange/topup/success');
		} catch ({ errors }) {
			for (const error in errors) {
				if (errors.hasOwnProperty(error)) {
					props.errorToast({
						title: {
							...errors[error][0],
							id: errors[error][0].id || 'undefinedError'
						}
					});
				}
			}
		}
	};

	const onChangeCode = value => {
		if (!+value && +value !== 0) return;
		setCode(value);
	};

	const onResend = async () => {
		setCode('');
		try {
			const response = await exchangeActions.topupResendCode(id);
			props.successToast({
				title: { id: 'screens.exchange.sendconfirm.label5' }
			});
		} catch ({ errors }) {
			showToastOnErrors(errors);
		}
	};
	return (
		<div className="Exchange__TopUp">
			<Container small>
				<Title
					title={t(M.topUpConfirm.title)}
				/>
				<Form onSubmit={onSubmit}>
					<Form.Group>
						<Input
							name="code"
							placeholder={t(M.topUpConfirm.inputs.code)}
							onChange={onChangeCode}
							value={code}
							maxLength="6"
							
						/>
					</Form.Group>
					<Button secondary onClick={onResend}>
						{t(M.topUpConfirm.buttons.resend)}
					</Button>
					<Button disabled={isDisabled} form>
						{t(M.topUpConfirm.buttons.confirm)}
					</Button>
				</Form>
			</Container>
		</div>
	);
};

export default compose(
	injectIntl,
	connect(null, mapDispatchToProps)
)(ExchangeTopUp);
