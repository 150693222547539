import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { AUTH_LOGOUT } from 'constants';
import storage from 'services/storage';
import transaction from './transaction';
import dashboard from './dashboard';
import user from './user';
import place from './place';
import client from './client';
import member from './member';
import card from './card';
import country from './country';
import currency from './currency';
import document from './document';
import shareholder from './shareholder';
import toast from './toast';
import session from './session';
import payment from './payment';
import language from './language';
import bank from './bank';
import address from './address';
import maintenanceModal from './maintenance-modal';
import notifications from './notifications';
import ui from './ui';
import twoFactorAuth from './twoFactor';
import notify from './notify';

import modals from './modals'
const rootReducer = combineReducers({
	transaction,
	dashboard,
	user,
	place,
	client,
	member,
	card,
	country,
	currency,
	document,
	shareholder,
	toast,
	session,
	payment,
	language,
	bank,
	address,
	loadingBar: loadingBarReducer,
	maintenanceModal,
	notifications,
	ui,
	twoFactorAuth,
	notify,
	modals
});

export default (state, action) => {
	const logout = action.error && action.error.status === 401;
	const isLogout = action.type === AUTH_LOGOUT || logout;

	if (isLogout) {
		storage.set('session', null);
		storage.set('sessionCreated', null);
	}

	return rootReducer(
		isLogout ? { language: state.language } : state,
		action
	);
};
