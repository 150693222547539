import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Error from './Error';
import './InputRadio.scss';

class InputRadio extends React.PureComponent {
	static propTypes = {
		id: PropTypes.string,
		name: PropTypes.string.isRequired,
		value: PropTypes.any,
		children: PropTypes.any,
		checked: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
		errors: PropTypes.array,
		className: PropTypes.string,
	}

	onSpace = e => e.keyCode === 32 && this.onChange()
	onChange = e => {
		const { onChange, value, name } = this.props;
		onChange(value, name, e);
	}

	render () {
		const { className, errors, name, id, value, checked, children } = this.props;
		return (
			<div className={classnames('InputRadio', className, errors && 'InputRadio--Invalid')}>
				<input
					id={id || name}
					type="radio"
					className="InputRadio__Input"
					name={name}
					value={value}
					checked={checked}
					onChange={this.onChange}
				/>
				<label
					htmlFor={id || name}
					className="InputRadio__Label"
					onKeyUp={this.onSpace}
				>
					{children}
				</label>
			</div>
		);
	}
}

InputRadio.Error = Error;

export default InputRadio;
