import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import intlTypes from 'types/intl';

import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';

import Icon from 'components/Icon';

import './NotificationWarning.scss';

const OrderCardMessage = ({ intl, setCloseModal }) => {
	const t = intl.formatMessage;
	
	const checkLinksMessage = (str) => {
		const reg = /[-a-zA-Z0-9@:%_\+.~#?&\/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&\/=]*)?/g;
		const urls = str.match(reg) || [];
		const strs = str.split(reg).filter(n => n !== undefined);
		return (
			<React.Fragment>
				{strs.shift()}
				{strs.map((n, i) => (
					<React.Fragment>
						<a className={'NotificationMessage_linkTiande'} href={urls[i]}>{urls[i]}</a>
						{n}
					</React.Fragment>
				))}
			</React.Fragment>
		);
	};

	return (
		<Wrap className='NotificationMessage__wrap'>
			<Icon
				className='NotificationMessage-close'
				onClick={setCloseModal}
				name='close-small'
			/>
			<Title
				title=''
				subtitle={checkLinksMessage(t({ id: 'components.notificatonspopup.components.notificationactions.information.for.order.card' }))}
				small
				className='NotificationMessage-text'
			/>
			<div className='NotificationMessage-controls'>
				<Button
					onClick={setCloseModal}
					small
					className='NotificationMessage-button'
				>
					<FormattedMessage id='button.simple.ok' />
				</Button>
			</div>
		</Wrap>
	);
};

OrderCardMessage.propTypes = {
	intl: intlTypes.isRequired,
	setCloseModal: PropTypes.func.isRequired
};

export default injectIntl(OrderCardMessage);
