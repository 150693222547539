import queryString from 'query-string';
import { getOS } from '../screens/App/utils';
import Referrals from '../helpers/referrals';

export const storeDeeplinkTarget = target => {
	if (target) localStorage.setItem('weststein:target', target);
};

export const removeDeeplinkTarget = target => {
	if (target) localStorage.removeItem('weststein:target');
};

export const executeMobileDeeplink = (target, params) => {
	const paramsQuery = queryString.stringify({ ...params });
	const fullQuery = `target=${target}&${paramsQuery}`;
	const longDynamicLink = `https://weststeinapp.page.link/?link=https://my.weststeincard.com/app?${encodeURIComponent(fullQuery)}&apn=com.weststeincard.weststein&isi=1131192886&ibi=com.weststeincard.weststein&efr=1`;

	window.open(longDynamicLink, '_self');
};

export const executeWebDeeplink = (target, params, history) => {
	switch (target) {
		case 'referral':
			storeDeeplinkTarget(target);
			history.push('/');
			break;

		default:
			break;
	}
};

export const handleDeeplink = (link, history) => {
	const os = getOS();
	const utm = Referrals.getUTM();
	const { target, ...params } = queryString.parse(new URL(link).search);

	if (!target) {
		switch (os) {
			case 'android':
				window.location.replace('https://play.google.com/store/apps/details?id=com.weststeincard.weststein');
				break;
			case 'ios':
				window.location.replace('https://itunes.apple.com/app/weststein/id1131192886');
				break;

			default:
				history.push(
					`/auth/signup/private?utm_source=${
						utm.utm_source || ''
					}&utm_medium=${utm.utm_medium || ''}&utm_campaign=${
						utm.utm_campaign || ''
					}`,
				);
				break;
		}
	} else if (os === 'android' || os === 'ios') {
		executeMobileDeeplink(target, params);
	} else {
		executeWebDeeplink(target, params, history);
	}
};
