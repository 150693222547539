import API from 'services/api';

export const UTMParamsGlobal = 'utm-params-global';

export default class Referrals {

	static loadFromStorage() {
		const data = localStorage.getItem(UTMParamsGlobal);
		return data ? JSON.parse(data) : null;
	}
	static saveToStorage(utm_source, utm_medium, utm_campaign) {
		const time = new Date().getTime();
		localStorage.setItem(UTMParamsGlobal, JSON.stringify({ utm_source, utm_medium, utm_campaign, time }));
	}
	static removeFromStorage() {
		localStorage.removeItem(UTMParamsGlobal);
	}

	static onPageLoad () {
		const urlParams = new URLSearchParams(location.search);
		const utm_source = urlParams.get('utm_source');
		const utm_medium = urlParams.get('utm_medium');
		const utm_campaign = urlParams.get('utm_campaign');
		const json = this.loadFromStorage();

		if (utm_campaign || utm_medium || utm_source) {
			this.saveToStorage(utm_source, utm_medium, utm_campaign);
		} else if (json) {
			const exp_period = 30*24*60*60*1000; // 30 days
			if (json && json.time && new Date().getTime() - json.time > exp_period)
				this.removeFromStorage();
		}
	}

	static getUTM() {
		let utm_source = "", utm_medium = "", utm_campaign = "";
		const json = this.loadFromStorage();
		if (json) {
			utm_source = json.utm_source || "";
			utm_medium = json.utm_medium || "";
			utm_campaign = json.utm_campaign || "";
		}
		return { utm_source, utm_medium, utm_campaign };
	}

	static get exists() {
		const json = this.loadFromStorage();
		return json && json.utm_source;
	}

	static async save(source) {
		await API.referrals.sendSurveyInfo(source);
		this.saveToStorage(source, "", "");
	}

}
