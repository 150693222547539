import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import Title from 'components/Title';
import Wrap from 'components/Wrap';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Form from 'components/Form';
import Input from 'components/Input';
import * as paymentSelector from 'selectors/payment';
import helpers from 'helpers';
import {
	internationalPayment,
	internationalPaymentCreate
} from 'constants/paths';
import M from './Declined.locale.json';

import intlTypes from 'types/intl';
import historyTypes from 'types/history';
import paymentTypes from 'types/payment';

function Declined (props) {
	const onCreate = () => props.history.push(internationalPaymentCreate);

	const onCopy = () => {
		const { history, payment } = props;

		history.push({
			pathname: internationalPaymentCreate,
			payment,
			state: { payment },
		});
	};

	const onDashboard = () => props.history.push(internationalPayment);

	const { payment, intl, user } = props;
	const t = intl.formatMessage;
	const { errorMessage } = payment;
	const legend = errorMessage ? errorMessage.defaultMessage : '';
	
	return (
		<Wrap>
			<div className="InternationalPaymentCreateScreen__Icon InternationalPaymentCreateScreen__Icon--Error">
				<Icon name="error" />
			</div>
			<Title
				title={t(M.title)}
				legend={legend}
				className="InternationalPaymentCreateScreen__Subtitle"
			/>
			<p>{t(M.messages.error)}</p>
			<Form.Group>
				<Input
					name="fromIban"
					placeholder={t(M.inputs.fromIban)}
					value={payment.fromIban}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="cardNumber"
					placeholder={t(M.inputs.cardNumber)}
					value={payment.fromCardNumber}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="beneficiary"
					placeholder={t(M.inputs.beneficiary)}
					value={payment.beneficiary}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="iban"
					placeholder={t(M.inputs.iban)}
					value={payment.iban}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="amount-amount"
					placeholder={t(M.inputs.amount)}
					value={helpers.formatter('payment', payment.amount)}
					unit={payment.currency}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="currency"
					placeholder={t(M.inputs.currency)}
					value={payment.currency}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="creditorCountryCode"
					placeholder={t(M.inputs.creditorCountryCode)}
					value={payment.creditorCountryCode}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="city"
					placeholder={t(M.inputs.city)}
					value={payment.city}
					disabled
				/>
			</Form.Group>
			{payment.state && (
				<Form.Group>
					<Input
						name="state"
						placeholder={t(M.inputs.state)}
						value={payment.state}
						disabled
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Input
					name="creditorAddress1"
					placeholder={t(M.inputs.creditorAddress1)}
					value={payment.creditorAddress1}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="creditorAddress2"
					placeholder={t(M.inputs.creditorAddress2)}
					value={payment.creditorAddress2}
					disabled
				/>
			</Form.Group>
			{payment.postCode && (
				<Form.Group>
					<Input
						name="postCode"
						placeholder={t(M.inputs.postCode)}
						value={payment.postCode}
						disabled
					/>
				</Form.Group>
			)}
			{payment.details && (
				<Form.Group>
					<Input
						name="details"
						placeholder={t(M.inputs.details)}
						value={payment.details}
						disabled
					/>
				</Form.Group>
			)}
			<Form.Group>
				<Input
					name="signerName"
					placeholder={t(M.inputs.signerName)}
					value={payment.signerName}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="signerLastName"
					placeholder={t(M.inputs.signerLastName)}
					value={payment.signerLastName}
					disabled
				/>
			</Form.Group>
			<Form.Group>
				<Input
					name="paymentDate"
					placeholder={t(M.inputs.paymentDate)}
					value={helpers.formatter('paymentDate', payment.paymentDate)}
					disabled
				/>
			</Form.Group>
			<Button
				className="InternationalPaymentCreateScreen__Back"
				form
				small
				ghost
				onClick={onDashboard}
			>
				{t(M.buttons.payments)}
			</Button>
			<Button
				className="InternationalPaymentCreateScreen__Submit"
				form
				small
				onClick={onCreate}
			>
				{t(M.buttons.create)}
			</Button>
			<Button
				className="InternationalPaymentCreateScreen__Submit"
				form
				small
				secondary
				onClick={onCopy}
			>
				{t(M.buttons.copy)}
			</Button>
		</Wrap>
	);
}

Declined.propTypes = {
	intl: intlTypes.isRequired,
	history: historyTypes,
	payment: paymentTypes,
};

const mapState = (state, props) => {
	const { match: { params: { id } } } = props;
	return {
		user: state.user,
		payment: paymentSelector.get(state, id),
	};
};

export default compose(
	injectIntl,
	withRouter,
	connect(mapState)
)(Declined);
