import PropTypes from 'prop-types';
import intlTypes from 'types/intl';


export const ExchangeCurrencyProps = {
	errorToast: PropTypes.func.isRequired,
	successToast: PropTypes.func.isRequired,
	intl: intlTypes.isRequired,
};

export const ExchangeCurrencyPreviewProps = {
	errorToast: PropTypes.func.isRequired,
	intl: intlTypes.isRequired,
	data: PropTypes.shape({
		amount: PropTypes.number.isRequired,
		exchangeId: PropTypes.string.isRequired,
		rate: PropTypes.number.isRequired,
		sourceCurrencyCode: PropTypes.string.isRequired,
		targetCurrencyCode: PropTypes.string.isRequired,
	})
};

