import React, { Component } from 'react';

import Nav from 'sections/Nav';

import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Title from 'components/Title';

import Create from './Create';
import Submitted from './Submitted';
import Start from './Start';
import ManualVerification from './ManualVerification'

export default class Screen extends Component {
	render () {
		return (
			<Layout
				className="ClientScreen Screen"
				sidebar
			>
				<Nav />

				<Section>
					<Container>
						<Title
							title="Client"
						/>
					</Container>
				</Section>
			</Layout>
		);
	}
}



Screen.Create = Create;
Screen.Submitted = Submitted;
Screen.Start = Start;
Screen.ManualVerification = ManualVerification