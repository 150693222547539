import React from 'react';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Title from 'components/Title';
import Section from 'components/Section';
import Container from 'components/Container';
import ExchangeComponent from 'components/Exchange';

const Exchange = () => {
	return (
		<Layout className="ExchangeScreen Screen" sidebar>
			<Nav />
			<Header />
			<Section large>
				<Title title={'Exchange'} />
				<Container className="ExchangeContainer">
					<ExchangeComponent />
				</Container>
			</Section>
		</Layout>
	);
};

export default Exchange;
