export default {
	pfs: {
		amount: {
			presence: { message: '^schemas.presence' },
			format: {
				pattern: /^([0-9]*|\d*\.\d{0,2}?)$/,
				message: '^schemas.alphanumeric.amount'
			},
			numericality: {
				greaterThan: 0,
				message: '^schemas.greater.than'
			}
		}
	}
};
