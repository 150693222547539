import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import * as paymentActions from 'actions/payment';
import * as paymentSelector from 'selectors/payment';
import * as cardSelector from 'selectors/card';

import Nav from 'sections/Nav';
import Header from 'sections/Header';

import Layout from 'components/Layout';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Section from 'components/Section';
import Tab from 'components/Tab';
import BlockingScreen from '../../Blocking';

import Beneficiary from './Beneficiary';
import Amount from './Amount';
import Phone from './Phone';
import Summary from './Summary';

import { cardStatus } from 'constants/common';

import M from './Create.locale.json';

import './Create.scss';


const getCurrent = payment => {
	// no payment means new payment
	if (typeof payment === 'undefined') return 0;

	switch (payment.status) {
		case 'DRAFT': return 1;
		case 'SIGNING': return 2;
		case 'DECLINED':
		case 'COMPLETED': return 3;
		default: return 0;
	}
};

const NAV = [{
	id: 'beneficiary',
	title: M.nav.beneficiary,
}, {
	id: 'amount',
	title: M.nav.amount,
}, {
	id: 'phone',
	title: M.nav.phone,
}, {
	id: 'summary',
	title: M.nav.summary,
}];

const mapState = (state, props) => {
	const { match } = props;
	const { id } = match.params;
	const payment = paymentSelector.get(state, id);
	return {
		payment,
		current: getCurrent(payment),
		currentCard: cardSelector.getById(state, state.card.current),
	};
};

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: true,
	}

	componentDidMount () {
		this.onMount();
	}

	componentDidUpdate (prevProps) {
		const { match } = this.props;
		const { id } = match.params;

		if (prevProps.match.params.id !== id) {
			this.onMount();
		}
	}

	onMount = async () => {
		const { match, actions } = this.props;
		const { id } = match.params;
		// check id
		// has id => get payment => off loader => push to section

		if (typeof id === 'undefined') {
			this.setState({
				loader: false,
			});

			return;
		}

		await actions.payment.get(id);

		this.setState({
			loader: false,
		});
	}

	render () {
		const { loader } = this.state;
		const { current, currentCard } = this.props;
		if (!currentCard) return null;
		if (currentCard.cardStatus === cardStatus.EXPIRED) return null;

		if (['CARD_01', 'CARD_02', 'CARD_03', 'CARD_07', 'CARD_08', 'CARD_14', 'CARD_17', 'CARD_18'].includes(currentCard.product) && currentCard.iban && ['OPEN', 'ISSUED'].includes(currentCard.cardStatus) || ['DEPOSIT ONLY'].includes(currentCard.cardStatus) && currentCard.activated !== true) {
			return (
				<Layout
					className="PaymentCreateScreen Screen"
					sidebar
				>
					<Nav />
					<Header />

					<Section>
						<Container>
							<Wrap>
								{
									!loader &&
									<Tab
										current={current}
									>
										<Tab.Panel>
											<Beneficiary />
										</Tab.Panel>

										<Tab.Panel>
											<Amount />
										</Tab.Panel>

										<Tab.Panel>
											<Phone />
										</Tab.Panel>

										<Tab.Panel>
											<Summary />
										</Tab.Panel>
									</Tab>
								}
							</Wrap>
						</Container>
					</Section>
				</Layout>
			);
		}
		return (
			<Layout
				className="PaymentCreateScreen Screen"
				sidebar
			>
				<Nav />
				<Header />

				<Section className="LoadSection">
					<Container>
						<Wrap>
							{
								!loader &&
								<BlockingScreen />
							}
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}
