import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Loader';
import * as exchangeActions from 'actions/exchange';
import CurrenciesList from './Currency/List';
import { STATUS } from './constants';
import Blocking from './Blocking';
import './Exchange.scss';

const Exchange = () => {
	const [status, setStatus] = useState(null);
	const [currencies, setCurrencies] = useState(null);
	const history = useHistory();
	const fetchAccount = async () => {
		try {
			const response = await exchangeActions.getAccount();
			if (response.success && response.data.status) {
				setStatus(response.data.status);
			}
		} catch (err) {
			setStatus(STATUS.EMPTY);
		}
	};

	const getCurrencies = async () => {
		try {
			const { success, data } = await exchangeActions.getExchangesInfo({
				invalidData: false
			});
			if (success) {
				setCurrencies(data);
				return data;
			}
		} catch (e) {
		}
		return [];
	};

	const handleCurrencyClick = async currency => {
		let cs = currencies;
		if (!currency.exists) {
			const { data, success } = await exchangeActions.openWallet(
				currency
			);
			if (success) cs = await getCurrencies();
		}

		const firstCurrency = currencies.find(
			i => i.currencyCode === currency.currencyCode
		);
		if (
			firstCurrency &&
			firstCurrency.currencyCode === 'EUR' &&
			!firstCurrency.isReady
		) {
			console.log('navigate notReady');
		} else {
			history.push('/exchange/currency/' + firstCurrency.currencyCode.toLowerCase(), { currency: firstCurrency });
		}
	};

	useEffect(() => {
		fetchAccount();
		getCurrencies();
	}, []);

	const renderList = () => {
		return (
			<CurrenciesList
				currencies={currencies}
				onCurrency={handleCurrencyClick}
			/>
		);
	};

	const render = () => {
		switch (status) {
			case STATUS.FAILED:
				return Blocking.Failed;
			case STATUS.NONE:
				return Blocking.Create;
			case STATUS.EMPTY:
				return Blocking.Create;
			case STATUS.RETRY_REQUIRED:
				return Blocking.Retry;
			case STATUS.PROCESSING:
				return Blocking.Processing;
			case STATUS.OK:
				return renderList();
			default:
				return <Loader className="ExchangeLoader" />;
		}
	};

	return render();
 
};

export default Exchange;
