import API from 'services/api';
import { success, error } from 'actions/toast';
import M from './openbanking.locale.json';
import * as cardActions from 'actions/card';

export const getPaymentsList = () => async dispatch => {
	try {
		const { data: { response } } = await API.openbanking.list();
		if (response.length > 0) {
			dispatch(cardActions.fetch()).then(() => success(dispatch)({ title: M.success.getPaymentsList.success }));
		} else error(dispatch)({ title: M.errors.getPaymentsList.notFound });
		return response;
	} catch (e) {
		error(dispatch)({ title: M.errors.wrong });
		throw e;
	}
};

export const createPayment = (cardId, amount, details) => async dispatch => {
	try {
		const { data: { response } } = await API.openbanking.create(cardId, {
			amount: +amount,
			details,
		});
		window.open(response.redirectUrl, '_blank');
		return response;
	} catch (e) {
		if (e.errors) {
			let msg = '';
			const firstErrorKey = Object.keys(e.errors)[0];
			switch (e.errors[firstErrorKey][0].id) {
				case 'errors.card.access':
					msg = M.errors.onContinue.access;
					break;
				case 'error.service.unavailable': {
					msg = M.errors.onContinue.unavailable;
					break;
				}
				case 'screens.cards.verify.errors.status':
					msg = M.errors.onContinue.status;
					break;
				default:
					msg = M.errors.wrong;
			}
			error(dispatch)({ title: msg });
		} else error(dispatch)({ title: M.errors.wrong });
		throw e;
	}
};
export const getPaymentInfo = paymentId => async dispatch => {
	try {
		const { data: { response } } = await API.openbanking.info(paymentId);
		return response;
	} catch (e) {
		error(dispatch)({ title: M.errors.wrong });
		throw e;
	}
};
export const checkPaymentStatus = paymentId => async dispatch => {
	try {
		const { data: { response } } = await API.openbanking.info(paymentId);
		switch (response.status) {
			case 'SETTLEMENT_COMPLETE': {
				success(dispatch)({ title: M.success.checkPayment.success });
				await dispatch(cardActions.fetch());
				break;
			}
			case 'SETTLEMENT_REJECTED':
				error(dispatch)({ title: M.errors.checkPayment.declined });
				break;
			case 'TIMEOUT':
				error(dispatch)({ title: M.errors.checkPayment.timeout });
				break;
			case 'CONSENT_TIMEOUT':
				error(dispatch)({ title: M.errors.checkPayment.consent });
				break;
			case 'UNKNOWN' || 'UNEXPECTED_ERROR':
				error(dispatch)({ title: M.errors.checkPayment.unknown });
				break;
			default:
				error(dispatch)({ title: M.errors.checkPayment.notFound });
		}
		return response;
	} catch (e) {
		if (e.errors) {
			let msg = '';
			const firstErrorKey = Object.keys(e.errors)[0];
			switch (e.errors[firstErrorKey][0].id) {
				case 'errors.card.access':
					msg = M.errors.checkPayment.access;
					break;
				case 'error.service.unavailable': {
					msg = M.errors.checkPayment.unavailable;
					break;
				}
				case 'screens.cards.verify.errors.status':
					msg = M.errors.checkPayment.status;
					break;
				default:
					msg = M.errors.wrong;
			}
			error(dispatch)({ title: msg });
		} else error(dispatch)({ title: M.errors.wrong });
		throw e;
	}
};
