import React, { Children } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { bindActionCreators } from "redux";
import { IntlProvider as Provider, addLocaleData } from "react-intl";
import de from "react-intl/locale-data/de";
import en from "react-intl/locale-data/en";
import lv from "react-intl/locale-data/lv";
import ru from "react-intl/locale-data/ru";
import pl from "react-intl/locale-data/pl";
import ee from "react-intl/locale-data/ee";
import lt from "react-intl/locale-data/lt";
import it from "react-intl/locale-data/it";
import nl from "react-intl/locale-data/nl";
import es from "react-intl/locale-data/es";
import cs from "react-intl/locale-data/cs";
import bg from "react-intl/locale-data/bg";
import fr from "react-intl/locale-data/fr";
import PropTypes from "prop-types";
import storage from "./services/storage";
import * as languageActions from "./actions/language";

const cpplangs = ["en", "lv", "ru", "lt", "ee", "pl", "de", "it", "nl", "es", "cs", "bg", "fr"];
const DEFAULT_LOCALE = "en";

addLocaleData([
	...de,
	...en,
	...lv,
	...ru,
	...pl,
	...ee,
	...lt,
	...it,
	...nl,
	...es,
	...cs,
	...bg,
	...fr,
]);

const mapState = state => ({
	language: state.language
});

const mapDispatch = dispatch => ({
	actions: {
		language: bindActionCreators(languageActions, dispatch)
	}
});

@connect(mapState, mapDispatch)
export default class Language extends React.PureComponent {
	static propTypes = {
		language: PropTypes.shape({
			locale: PropTypes.string,
			messages: PropTypes.object
		}).isRequired,
		children: PropTypes.node.isRequired
	};

	state = {
		messages: {}
	};

	componentDidMount() {
		this.detectLanguage();
	}

	componentDidUpdate() {
		this.detectLanguage();
	}

	detectLanguage = async () => {
		const { actions, language } = this.props;
		const localStorage = storage.get("locale");
		const localeParam = queryString.parse(location.search).locale;

		if (
			(language.locale &&
				cpplangs.includes(language.locale) &&
				!localeParam) ||
			(language.locale &&
				cpplangs.includes(language.locale) &&
				language.locale === localeParam)
		)
			return;

		let locale = localeParam || DEFAULT_LOCALE;

		if (locale === 'auth') {
			locale = DEFAULT_LOCALE;
		}

		if (
			cpplangs.includes(
				window.location.pathname.split("/")[1].toLowerCase()
			)
		) {
			locale = window.location.pathname.split("/")[1].toLowerCase();
		}

		if (localStorage && localStorage === localeParam) {
			if (cpplangs.includes(localStorage))
				locale = localStorage || locale;
			else {
				storage.set("locale", "en");
				locale = "en";
			}
		}

		await actions.language.get(locale);
	};

	render() {
		const { language, children } = this.props;
		let locale = language.locale;

    if (locale === 'cz') {
      locale = 'cs'
    }

		if (!locale) return null;

		let messages = require(`../locales`).default[locale];

		return (
			<Provider locale={locale} messages={messages} defaultLanguage="en">
				{Children.only(children)}
			</Provider>
		);
	}
}
