import React, { useEffect, useState } from 'react';
import Section from '../Section';
import Container from '../Container';
import Wrap from '../Wrap';
import Modal from '../Modal';
import Title from '../Title';
import { injectIntl } from 'react-intl';
import './ReferralModal.scss';
import * as toastActions from 'actions/toast';
import * as referralsActions from 'actions/referrals';
import ButtonLink from '../ButtonLink';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import copyToClipboard from 'helpers/copyToClipboard';
import { generateReferralLink } from '../../helpers/referral';

const mapDispatch = dispatch => ({
	actions: {
		toast: bindActionCreators(toastActions, dispatch),
		referrals: bindActionCreators(referralsActions, dispatch),
	},
});
const ReferralModal = ({intl, actions, onClose}) => {
	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState('');
	const t = intl.formatMessage;

	const _onClose = () => {
		localStorage.removeItem('weststein:target');
		onClose();
	}
	const copyLink = type => {
		copyToClipboard(generateReferralLink(code, type));
		actions.toast.create({ title: { id: 'screens.exchange.currencydetails.label4' }, type: 'success' });
		_onClose();
	};

	const fetchCode = async () => {
		setLoading(true);
		const response = await actions.referrals.getCode();
		setCode(response.success ? response.code : '');
		setLoading(false);
	}

	useEffect(() => {
		fetchCode();
	}, []);

	if (loading) return (
		<Modal>
			<Modal.Loader className="CardSingleScreen_Loader" />
		</Modal>
	)

	return (
		<Modal className="ReferralModalScreen Screen">
			<Section>
				<Container medium>
					<Wrap className="PfsWarning__wrap">
						<Modal.Close onClick={_onClose}/>
						<Title title={t({id: 'screens.sidebar.referrals.title'})}/>
						<div>
							<p className="ReferralModalScreen__Note">
								{t({id: 'screens.sidebar.referrals.desc'})}
							</p>
							<div className="ReferralModalScreen__Code">
								{code}
							</div>
							<ul>
								<li className="ReferralModalScreen__Link">
									<ButtonLink onClick={copyLink.bind(this, 'referral_app')}>
										• {t({id: 'screens.sidebar.referrals.mobile'})}
									</ButtonLink>
								</li>
								<li>
									<ButtonLink onClick={copyLink.bind(this, 'referral_web')}>
										• {t({id: 'screens.sidebar.referrals.web'})}
									</ButtonLink>
								</li>
							</ul>
						</div>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
};

export default connect(null, mapDispatch)(injectIntl(ReferralModal));
