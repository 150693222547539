import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import intlTypes from 'types/intl';

import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import Icon from 'components/Icon';

import './NotificationWarning.scss';

const NotOrderCardMessage = ({
	intl,
	setCloseModal,
	isLoading,
	notOrderCardAction
}) => {
	const t = intl.formatMessage;
	return (
		<Wrap className='NotificationMessage__wrap'>
			<Icon
				className='NotificationMessage-close'
				onClick={setCloseModal}
				name='close-small'
			/>
			<Title
				title=''
				subtitle={t({
					id: 'components.notificatonspopup.components.notificationactions.information.not.order.card'
				})}
				small
				className='NotificationMessage-text'
			/>
			<div className='NotificationMessage-controls'>
				<Button
					onClick={notOrderCardAction}
					small
					className='NotificationMessage-button'
					loading={isLoading}
					disabled={isLoading}
				>
					<FormattedMessage id='components.notificatonspopup.components.notificationactions.button.not.order.card' />
				</Button>
				<Button
					onClick={setCloseModal}
					small
					className='NotificationMessage-button'
					loading={isLoading}
					disabled={isLoading}
				>
					<FormattedMessage id='components.notificatonspopup.components.notificationactions.not.order.card.button.cancel' />
				</Button>
			</div>
		</Wrap>
	);
};
NotOrderCardMessage.propTypes = {
	intl: intlTypes.isRequired,
	setCloseModal: PropTypes.func.isRequired,
	notOrderCardAction: PropTypes.func.isRequired,
	isLoading: PropTypes.bool
};

export default injectIntl(NotOrderCardMessage);
