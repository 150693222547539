import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';

import * as uiActions from 'actions/ui';
import * as twoFactorActions from 'actions/twoFactor';
import M from './InternationalPaymentModal.locale.json';
import './InternationalPaymentModal.scss';

const InternationalPaymentModal = ({ intl, actions, onClose, onSubmit }) => {
	const t = intl.formatMessage;

	return (
		<Modal>
			<Section>
				<Container medium>
					<Wrap className='InternationalPaymentModal'>
						<div className='Content'>
							<div className='Content__info'>
								{t(M.information)}
							</div>
						</div>

						<div className='Buttons'>
							<ButtonLink
								onClick={onClose}
								small
								className='ui-modal__button'
							>
								{t(M.buttons.cancel)}
							</ButtonLink>

							<Button
								onClick={onSubmit}
								small
								className='ui-modal__button'
							>
								{t(M.buttons.submit)}
							</Button>
						</div>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
};

const mapDispatch = dispatch => ({
	actions: {
		ui: bindActionCreators(uiActions, dispatch),
		twoFactor: bindActionCreators(twoFactorActions, dispatch)
	}
});
export default injectIntl(
	withRouter(connect(null, mapDispatch)(InternationalPaymentModal))
);
