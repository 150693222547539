import axios from 'axios';
import storage from 'services/storage';
import renamer from 'helpers/renamer';
import handler from 'helpers/handler';
import { increase, decrease } from 'helpers/fetchCounter';
import CONFIG from '../../../env';
import store from '../stores'
import { AUTH_LOGOUT, SHOW_UI_ALERT } from 'constants';

const client = axios.create({
	baseURL: CONFIG.api,
	headers: {
		'X-Frame-Options': 'SAMEORIGIN',
		'Content-Security-Policy': "frame-ancestors 'self'",
		'Content-Type': 'application/json'
	}
});

const setToken = (token) =>  {
	storage.set('session', token);
	storage.set('sessionCreated', new Date().toUTCString());
}

client.interceptors.response.use(response => {
	if (response.headers && response.headers.authorization) {
		setToken(response.headers.authorization);
	}
	const convert = { ...response };

	if (convert.data.response && Object.prototype.toString.call(convert.data.response) === '[object Object]') {
		convert.data.response = renamer.from.clean.wrap(convert.data.response);
	}

	decrease();
	return convert;
}, error => {
	const handle = error.response || error.request;
  const convertError = handler.error(handle);

	if (handle && (handle.headers && handle.headers.authorization)) {
		setToken(handle.headers.authorization);
	}

	if (error.response && error.response.status === 401 ) {
		store.dispatch({type: AUTH_LOGOUT});
	}

  if (convertError.errors.feign) {
    store.dispatch({
      type: SHOW_UI_ALERT,
      payload: {
        title: 'feature.is.not.available',
        text: convertError.errors.feign[0].id
      }
    });
  }

	decrease();
	return Promise.reject(convertError);
});

client.interceptors.request.use(config => {
	if (storage.get('session')) config.headers.authorization = storage.get('session');
	increase();
	return config;
}, error => Promise.reject(handler.error(error)));

export default {
	get (endpoint, config) {
		return client.get(endpoint, config);
	},
	post (endpoint, payload, config) {
		return client.post(endpoint, payload, config);
	},
	put (endpoint, payload, config) {
		return client.put(endpoint, payload, config);
	},
	patch (endpoint, payload, config) {
		return client.patch(endpoint, payload, config);
	},
	delete (endpoint, config) {
		return client.delete(endpoint, config);
	}
};
