/* eslint-disable import/extensions */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as toastActions from 'actions/toast';
import Button from '../Button';
import Icon from '../Icon';
import M from './Balance.locale.json';
import './Balance.scss';

import intlTypes from 'types/intl';

const mapState = state => ({
	card: state.card.items[0],
});

const mapDispatch = dispatch => ({
	actions: {
		toast: bindActionCreators(toastActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Balance extends React.PureComponent {
	static propTypes = {
		amount: PropTypes.number,
		className: PropTypes.string,
		card: PropTypes.object,
		intl: intlTypes.isRequired,
	}

	state = {
		hidden: JSON.parse(localStorage.getItem('isHidden')) || false,
	}

	onHide = () => {
		const { hidden } = this.state;
		this.setState({ hidden: !hidden });
		localStorage.setItem('isHidden', String(!hidden));
	}

	getCurrency = () => {
		const { card } = this.props;
		if (!card) return '';
		if (card.accountBaseCurrency === '978') return 'EUR';
		if (card.accountBaseCurrency === '985') return 'PLN';
		return '';
	}

	render() {
		const { hidden } = this.state;
		const { amount, card, className, intl } = this.props;
		const t = intl.formatMessage;
		const formatted =
			`${(amount || 0).toFixed(2)}`.replace(/\./g, ',');

		if (!card) {
			return null;
		}

		return (
			<div className={classnames('Balance', className)}>
				<div className="Balance__Balance">
					<div className="Balance__Amount">
						{(hidden ? t(M.hidden) : formatted)}
					</div>
					<div className="Balance__Switch">
						<div className="Balance__Currency Balance__Currency--Current">
							{this.getCurrency()}
						</div>
					</div>
				</div>
				<div className="Balance__Actions">
					<Button
						className={classnames('Balance__Hide', hidden && 'Balance__Hide--Active')}
						onClick={this.onHide}
						small
						ghost
					>
						<Icon name="eye" />
					</Button>
				</div>
			</div>
		);
	}
}
