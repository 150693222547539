import React, {useState} from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Icon from 'components/Icon';
import Wrap from 'components/Wrap';
import * as authActions from 'actions/auth';
import Title from 'components/Title';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import * as cardActions from 'actions/card';
import * as clientActions from 'actions/client';
import M from './ManualVerification.locale.json';
import './ManualVerification.scss';

const AccountReview = ({ actions: { auth, client, card }, intl, history }) => {
    const t = intl.formatMessage;
    const [isLoading, setLoading] = useState(false)
    const onSkip = async() => {
        setLoading(true)
        await auth.accountReviewSuccess()
        history.push('/')
        setLoading(false)
    };

    const verifyAccount = async() => {
        setLoading(true)
        await auth.accountReviewSuccess()
        await card.fetch();
        history.push('/settings/document')
        setLoading(false)
    }

return (
    <Wrap className='manualVerification__content'>
        <div
            className="ClientCreatePrivateScreen__Icon ClientCreatePrivateScreen__Icon--Success"
        >
            <Icon
                name="success"
            />
        </div>
        <Title
            title={t(M.accountSuccess.title)}
        />

        <p className='manualVerification__text'>{t(M.accountSuccess.text)}</p>
        <div className='manualVerification__actions'>
            <ButtonLink
                form
                small
                onClick={onSkip}
                className='manualVerification__skip'
                loading={isLoading}
            >
                {t(M.accountSuccess.skip)}
            </ButtonLink>
            <Button
                form
                small
                onClick={verifyAccount}
                className='manualVerification__success'
                loading={isLoading}
            >
                {t(M.accountSuccess.verify)}
            </Button>
        </div>

    </Wrap>
);
}
const mapState = state => ({});

const mapDispatch = dispatch => ({
    actions: {
        auth: bindActionCreators(authActions, dispatch),
        card: bindActionCreators(cardActions, dispatch),
        client: {
			private: bindActionCreators(clientActions.privateActions, dispatch),
		},    
    },
});

export default withRouter(injectIntl(connect(mapState, mapDispatch)(AccountReview)))
