import {
	TOGGLE_MAINTENANCE_MODAL,
	SET_MODALS_INFO,
	CHANGE_MODAL_INFO,
	SHOW_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE,
	HIDE_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE,
} from 'constants';
import API from 'services/api';

export const toggleMaintenanceModal = () => dispatch => {
	dispatch({ type: TOGGLE_MAINTENANCE_MODAL });
};

export const getModalInfo = () => async (dispatch) => {
	try {
		const { data: { response } } = await API.modal.getModalInfo();
		dispatch({ type: SET_MODALS_INFO, payload: response });

		return data;
	} catch (error) {
		return error;
	}
};

export const showInternationalPaymentModal = () => dispatch => {
	dispatch({ type: SHOW_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE, payload: null });
};

export const hideInternationalPaymentModal = () => dispatch => {
	dispatch({ type: HIDE_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE, payload: null });
};

export const sendModalInfo = windowData => async (dispatch) => {
	try {
		const { data } = await API.modal.sendModalInfo(windowData);
		dispatch({ type: CHANGE_MODAL_INFO, payload: windowData[0] });

		return data;
	} catch (error) {
		return error;
	}
};