import * as templateActions from 'actions/template';
import Button from 'components/Button';
import Container from 'components/Container';
import Form from 'components/Form';
import Input from 'components/Input';
import Modal from 'components/Modal';
import Title from 'components/Title';
import Wrap from 'components/Wrap';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentCard } from 'selectors/card';
import helpers from 'helpers';
import './Templates.scss';
import {
	formatTemplate,
	formatTemplateToObject,
	prepareCountries,
	prepareCurrencies
} from './helpers';
import PaymentSchema from 'schemas/template';
import InputSelect from 'components/InputSelect';
import M from './Templates.locale.json';
import intlTypes from 'types/intl';

const mapState = state => ({
	currentCard: getCurrentCard(state),
	currencies: state.currency,
	countries: state.country
});

@injectIntl
@withRouter
@connect(mapState)
class TemplateEditModal extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		onClose: PropTypes.func,
		template: PropTypes.object.isRequired,
		currencies: PropTypes.array,
		countries: PropTypes.array
	};
	constructor (props) {
		super(props);
		this.state = {
			list: [],
			loader: true,
			form: {},
			errors: {
				recipientCardNumber: [],
				amount: []
			}
 
		};
		this.t = props.intl.formatMessage;
		this.countries = this.memoizedCountriesCallback();
		this.currencies = this.memoizedCurrenciesCallback();
	}



	componentDidMount () {
		this.renderForm(this.props.template);
	}

	onRemove (e, id) {
		e.stopPropagation();
		templateActions.removeTemplate(id).then(() => this.fetchList());
	}

	onChange = (value, id) => {
		this.setState({
			form: {
				...this.state.form,
				[id]: value
			}
		});
	};

	onSubmit = async () => {
		const combinedTemplate = {
			...this.props.template,
			...this.state.form
		};
		const newTemplate = formatTemplateToObject(combinedTemplate);
		const errors = helpers.validator.all(newTemplate, PaymentSchema[newTemplate.type]);
		if (errors) {
			this.setState({ errors });
			return;
		}
		try {
			if (this.props.template.new) {
				const response = await templateActions.createCustomTemplate(
					newTemplate
				);
				this.props.onClose();
			} else {
				const response = await templateActions.updateTemplate(
					newTemplate
				);
				this.props.onClose();
			}
		} catch (e) {
			// console.log(e);
		}
	};

	memoizedCurrenciesCallback () {
		return prepareCurrencies(this.props.currencies);
	}

	memoizedCountriesCallback () {
		return prepareCountries(this.props.countries);
	}

	renderForm = template => {
		this.setState({
			form: {
				...formatTemplate(template)
			},
			loader: false
		});
	};

	render () {
		const { loader, list, editModal, errors, form } = this.state;
		const { onClose, template } = this.props;
		const t = this.t;
		return (
			<Modal onClick={onClose} className="Template__Modal__Edit">
				<Container medium>
					<Wrap>
						<Modal.Close onClick={onClose} />
						<Title title={t(M.buttons.edit)} />
						<Form>
							{loader ? (
								<span>Loading</span>
							) : (
								<React.Fragment>
									{Object.keys(form).map(el => {
										if (el === 'country')
											return (
												<Form.Group key={el}>
													<InputSelect
														name="country"
														placeholder={
															t(M.inputs[el]) ||
															''
														}
														onChange={this.onChange}
														// onBlur={onLostFocus}
														value={
															form.country
														}
														errors={errors[el]}
														data={this.countries}
													/>
												</Form.Group>
											);
										if (el === 'currency')
											return (
												<Form.Group key={el}>
													<InputSelect
														name="currency"
														placeholder={
															t(M.inputs[el]) ||
															''
														}
														onChange={this.onChange}
														// onBlur={onLostFocus}
														value={
															form.currency
														}
														errors={errors[el]}
														data={this.currencies}
													/>
												</Form.Group>
											);
										return (
											<Form.Group key={el}>
												<Input
													disabled={el === `type`}
													name={el}
													placeholder={
														t(M.inputs[el]) || ''
													}
													value={el === 'type'? this.t(M.types[form[el]]) : form[el]}
													errors={errors[el]}
													onChange={this.onChange}
												/>
											</Form.Group>
										);
									})}
								</React.Fragment>
							)}
							<Button onClick={this.onSubmit}>
								{t(M.buttons.submit)} 
							</Button>
						</Form>
					</Wrap>
				</Container>
			</Modal>
		);
	}
}

export default TemplateEditModal;
