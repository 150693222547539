import React from 'react';
import { injectIntl, FormattedMessage as FM } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Timer from 'components/Timer';
import Title from 'components/Title';
import Button from 'components/Button';
import Section from 'components/Section';
import * as sessionActions from 'actions/session';
import * as authActions from 'actions/auth';
import M from './Timeout.locale.json';
import './Timeout.scss';

import intlTypes from 'types/intl';

const mapState = state => ({
	session: state.session,
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		session: bindActionCreators(sessionActions, dispatch),
		auth: bindActionCreators(authActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class CardCreate extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		actions: PropTypes.shape({
			session: PropTypes.shape({
				extend: PropTypes.func.isRequired,
				modal: PropTypes.func.isRequired,
			}).isRequired,
			auth: PropTypes.shape({
				logout: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
		session: PropTypes.shape({
			timeout: PropTypes.number.isRequired,
			modal: PropTypes.bool.isRequired,
		}).isRequired,
		user: PropTypes.object,
	}

	state = {
		loader: false,
	}

	componentWillUnmount () {
		this.onClear();
	}

	onExtend = async () => {
		const { actions } = this.props;
		const { loader } = this.state;

		if (loader) return;

		this.setState({ loader: true });
		await actions.session.extend();
		this.setState({ loader: false });
	};

	onLogout = () => {
		const { loader } = this.state;
		const { actions } = this.props;

		if (loader) {
			this.logoutTimeout = setTimeout(() => {
				this.onLogout();
			}, 3000);
			return;
		}

		this.onClear();
		actions.auth.logout();
	};

	onModal = () => {
		const { actions } = this.props;
		actions.session.modal();
	};

	onClear = () => {
		clearTimeout(this.modalTimeout);
		clearTimeout(this.logoutTimeout);
	};

	render () {
		const { loader } = this.state;
		const { session, user, intl } = this.props;

		const timeout = session.timeout - new Date().getTime();
		this.onClear();

		if (timeout <= 0 || !user) return null;

		this.modalTimeout = setTimeout(() => {
			this.onModal();
		}, timeout);

		this.logoutTimeout = setTimeout(() => {
			this.onLogout();
		}, timeout);

		if (!session.modal) return null;

		const t = intl.formatMessage;

		const seconds = (
			<strong><Timer time={Math.round((timeout / 1000))} /> {t(M.seconds)}</strong>
		);

		return (
			<Modal className="TimeoutScreen Screen">
				<Section>
					<Container medium>
						<Wrap>
							<Title title={t(M.title)} small />
							<p className="TimeoutScreen__Note">
								<FM id={M.messages.note.id} values={{ seconds }} />
							</p>
							<p className="TimeoutScreen__Note">
								{t(M.messages.continue)}
							</p>
							<Button
								className="TimeoutScreen__Button"
								onClick={this.onExtend}
								loading={loader}
								form
								small
							>
								{t(M.buttons.extend)}
							</Button>
							<Button
								className="TimeoutScreen__Button"
								onClick={this.onLogout}
								form
								ghost
								small
							>
								{t(M.buttons.logout)}
							</Button>
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}
