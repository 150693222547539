export default {
	stepone: {
		phone: {
			numericality: {
				onlyInteger:true,
				message: 'can only contain numbers',
			},
			presence: { message: '^schemas.presence' },	
			length: {
				minimum: 2,
				tooShort: '^schemas.length.min',
				maximum:12,
				tooLong: '^screens.setting.setting.phone.errors.maxlength.phone'
			},
			phonenumber: {
				message: '^schemas.phone.phonenumber'
			}
		},
		phoneCountry: {
			presence: { message: '^schemas.presence' },
		},
	},

	steptwo: {
		code: {
			presence: { message: '^schemas.presence' },
		}
	}
};
