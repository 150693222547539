import fetcher from 'helpers/fetcher';

export default {
  getModalInfo() {
    return fetcher.get('private/window-notification')
  },
  sendModalInfo(data) {
    return fetcher.post('private/window-notification', data)
  }
};
