import * as templateActions from 'actions/template';
import Dropdown from 'components/Dropdown';
import Wrap from 'components/Wrap';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentCard } from 'selectors/card';
import currentCardTypes from 'types/currentCard';
import TemplatesList from './TemplatesList';
import SaveButton from './TemplateSaveButton';
import Loader from 'components/Loader';
import './Templates.scss';
import { injectIntl } from 'react-intl';
import M from './Templates.locale.json';

const mapState = state => ({
	currentCard: getCurrentCard(state)
});

@injectIntl
@withRouter
@connect(mapState)
class Templates extends React.PureComponent {
	static propTypes = {
		currentCard: currentCardTypes
		// time: PropTypes.number,
		// className: PropTypes.string,
		// showMinutes: PropTypes.bool,
		// onFinish: PropTypes.func
	};
	constructor (props) {
		super(props);
		this.state = {
			recurringList: [],
			lastPayment: {},
			loader: true,
			dropdownState: false
		};
		this.t = this.props.intl.formatMessage;
	}

	componentDidMount () {
		this.getLatestList();
	}

	onDropdown = () => {
		const { dropdownState } = this.state;
		this.setState({ dropdownState: !dropdownState });
	};

	getLatestList = async () => {
		const {
			lastPayment,
			recurringPayments: recurringList
		} = await templateActions.getRecurring();
		this.setState({ lastPayment, recurringList, loader: false });
	};



	async getPayment (template) {
		const {
			currentCard: { id: cid }
		} = this.props;
		const {
			data: { response: payment }
		} = await templateActions.getPaymentFromId(template, cid);
		this.gotoPayment(template.type, payment);
	}

	gotoPayment (type, payment) {
		const { history } = this.props;

		switch (type) {
			case 'SEPA':
				history.push(`/payments/create/`, { payment });
				break;
			case 'CARD_TO_CARD':
				history.push(`/c2cpayments/create/`, { payment });
				break;
			case 'INTERNATIONAL':
				history.push(`/international-payment/create/`, { payment });
				break;
		}
	}


	gotoTemplatesList = () => {
		this.props.history.push('/payments/templates'); 
	}

	render () {
		const {
			lastPayment,
			recurringList,
			loader,
			dropdownState
		} = this.state;

		const t = this.t;

		return loader
			? <Loader className="Template__Loader" />
			: (
				<div className="Template">
					<Wrap className="Template__Wrap">
						{lastPayment &&
						<React.Fragment>
							<span className="Template_button" onClick={() => this.getPayment(lastPayment)}> {/*toTranslate*/}
								{t(M.last)}
							</span>
							<div className="divider" />
						</React.Fragment>
						}	
						{recurringList.length > 0 &&
						<React.Fragment>
							<span className="Template_button" onClick={this.onDropdown}>
								{t(M.reccuring)}
								<Dropdown
									className="Template__Dropdown"
									data={recurringList}
									onClick={({ value, subtitle }) => this.getPayment({ id: value, type: subtitle })}
									open={dropdownState}
								/>
							</span>
							<div className="divider" />
						</React.Fragment>
						}
						<span onClick={this.gotoTemplatesList} className="Template_button">
							{t(M.title)}
						</span>
					</Wrap>
				</div>
			);
	  
	}
}

Templates.SaveButton = SaveButton;
Templates.List = TemplatesList;

export default Templates;
