import fetcher from 'helpers/fetcher';

export const getCurrencyName = name => {
	switch (name) {
		case 'EUR':
			return 'Euro';
		case 'BCH':
			return 'Bitcoin Cash';
		case 'BTC':
			return 'Bitcoin';
		case 'ETH':
			return 'Ethereum';
		case 'TRX':
			return 'TRON';
		case 'LTC':
			return 'Litecoin';
		case 'USDT':
			return 'Tether';
		default:
			return name;
	}
};

const USER_CTRL = {
	INFO: 'user/info'
};

const CARD_CTRL = {
	INFO: cardId => {
		return `private/card-info/${cardId}`;
	}
};

const COUNTRY = 'open/country';

const CRYPTO = {
	ACCOUNT: 'crypto/account',
	TOPUP: 'crypto/fiat/top-up',
	TOPUP_RESEND_CODE: 'crypto/fiat/top-up/resend-code',
	TOPUP_CONFIRM: 'crypto/fiat/top-up/confirm',
	VERIFY_ACCOUNT: 'crypto/account/verification',
	SEND_REQUEST: 'crypto/centralised',
	SEND_REQUEST_FIAT: 'crypto/fiat',
	CONFIRM_REQUEST: 'crypto/centralised/confirm',
	CONFIRM_REQUEST_FIAT: 'crypto/fiat/confirm',
	RECENT_RECEIVERS: 'crypto/centralised/addresses',
	RESEND_CODE: 'crypto/centralised/resend-code',
	RESEND_CODE_FIAT: 'crypto/fiat/resend-code',
	CONFIRM_EXCHANGE: id => `crypto/exchange/${id}/confirm`,
	EXCHANGE: 'crypto/exchange',
	GET_EXCHANGES: 'crypto/getExchangesInfo',
	GET_WALLETS: 'crypto/wallets',
	OPEN_WALLET: currency => `crypto/wallet/${currency}`,
	GET_EXCHANGES_INFO: ({ currencyFrom, currencyTo }) =>
		`crypto/exchange-info?sourceCurrencyCode=${currencyFrom}&targetCurrencyCode=${currencyTo}`,
	GET_TRANSACTIONS: ({ currencyCodeWallet, take, offset }) =>
		`crypto/centralised/transactions?currencyCodeWallet=${currencyCodeWallet}&take=${take}&offset=${offset}`,
	GET_TRANSACTION: id => `crypto/centralised/transaction/${id}`
};

export const getAccount = async () => {
	for (let i = 0; i < 3; i++) {
		try {
			const { data: { response } } = await fetcher.get(CRYPTO.ACCOUNT);
			return { data: response, success: true };
		} catch (errorResponse) {
			if (i === 2) {
				return { success: false, data: errorResponse };
			}
		}
	}
};

export const getUserInfo = async () => {
	try {
		const { data: { response } } = await fetcher.get(USER_CTRL.INFO);
		return { data: response, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const getCardInfo = async cardId => {
	try {
		const { data: { response } } = await fetcher.get(
			CARD_CTRL.INFO(cardId)
		);
		return { data: response, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const getCountries = async () => {
	try {
		const { data: { response } } = await fetcher.get(COUNTRY);
		return { data: response, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const getRecentReceivers = async () => {
	try {
		const { data: { response } } = await fetcher.get(CRYPTO.RECENT_RECEIVERS);
		return { data: response, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const createAccount = async () => {
	try {
		const { data: { response } } = await fetcher.post(CRYPTO.ACCOUNT);
		return { data: response, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const verifyAccount = async payload => {
	try {
		const { data: { response } } = await fetcher.post(CRYPTO.VERIFY_ACCOUNT, payload);
		return { data: response, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const topUp = async payload => {
	try {
		const { data: { response } } = await fetcher.post(CRYPTO.TOPUP, payload);
		return { data: response, success: true };
	} catch (errorResponse) {
		throw errorResponse;
	}
};

export const sendRequest = async ({
	amount,
	currencyWallet,
	address,
	subtractFee,
	noSms
}) => {
	for (let i = 0; i < 3; i++) {
		try {
			const payload = {
				amount,
				currencyWallet,
				requisites: { address },
				subtractFee,
				noSms
			};
			const { data: { response } } = await fetcher.post(
				CRYPTO.SEND_REQUEST,
				payload
			);
			return { data: response, success: true };
		} catch (errorResponse) {
			if (i === 2) {
				throw errorResponse;
			}
		}
	}
};

export const sendRequestFiat = async ({
	amount,
	currencyWallet,
	iban,
	noSms
}) => {
	for (let i = 0; i < 3; i++) {
		try {
			const payload = {
				amount,
				currencyWallet,
				requisites: { iban, transferMethod: '1' },
				noSms
			};
			const { data: { response } } = await fetcher.post(
				CRYPTO.SEND_REQUEST_FIAT,
				payload
			);
			return { data: response, success: true };
		} catch (errorResponse) {
			if (i === 2) {
				throw errorResponse;
			}
		}
	}
};

export const reSendCode = async requestId => {
	for (let i = 0; i < 3; i++) {
		try {
			const { data: { response } } = await fetcher.post(CRYPTO.RESEND_CODE, {
				requestId
			});
			return { data: response, success: true };
		} catch (errorResponse) {
			if (i === 2) {
				return { success: false, data: errorResponse };
			}
		}
	}
};

export const reSendCodeFiat = async requestId => {
	for (let i = 0; i < 3; i++) {
		try {
			const { data: { response } } = await fetcher.post(CRYPTO.RESEND_CODE_FIAT, {
				requestId
			});
			return { data: response, success: true };
		} catch (errorResponse) {
			if (i === 2) {
				return { success: false, data: errorResponse };
			}
		}
	}
};

export const topupResendCode = async requestId => {
	for (let i = 0; i < 3; i++) {
		try {

			const { data: { response } } = await fetcher.post(CRYPTO.TOPUP_RESEND_CODE, {
				requestId
			});
			return { data: response, success: true };
		} catch (errorResponse) {
			if (i === 2) {
				return { success: false, data: errorResponse };
			}
		}
	}
};

export const topupConfirmRequest = async (requestId, code) => {
	try {
		const payload = {
			requestId,
			code
		};
		const { data: { response } } = await fetcher.post(CRYPTO.TOPUP_CONFIRM, payload);
		return { data: response, success: true };
	} catch (errorResponse) {
		throw errorResponse;
	}
};

export const confirmRequest = async (requestId, code) => {
	try {
		const payload = {
			requestId,
			code
		};
		const { data: { response } } = await fetcher.post(
			CRYPTO.CONFIRM_REQUEST,
			payload
		);
		return { data: response, success: true };
	} catch (errorResponse) {
		throw errorResponse;
	}
};

export const confirmRequestFiat = async (requestId, code) => {
	try {
		const payload = {
			requestId,
			code
		};
		const { data: { response } } = await fetcher.post(
			CRYPTO.CONFIRM_REQUEST_FIAT,
			payload
		);
		return { data: response, success: true };
	} catch (errorResponse) {
		return errorResponse;
	}
};

export const getTransaction = async id => {
	try {
		const { data: { response } } = await fetcher.get(CRYPTO.GET_TRANSACTION(id));
		return { data: response, success: true };
	} catch (errorResponse) {
		throw errorResponse;
	}
};

export const getTransactions = async ({ currencyCodeWallet, take, offset }) => {
	try {
		const payload = {
			currencyCodeWallet,
			take,
			offset
		};
		const { data: { response } } = await fetcher.get(
			CRYPTO.GET_TRANSACTIONS(payload)
		);
		return {
			data: response.transactions,
			success: true,
			hasMore: response.hasMore,
			totalCount: response.totalCount
		};
	} catch (errorResponse) {
		return { success: false, data: errorResponse, hasMore: false };
	}
};

export const getRates = async ({ currencyFrom, currencyTo }) => {
	try {
		const payload = { currencyFrom, currencyTo };
		const { data: { response } } = await fetcher.get(
			CRYPTO.GET_EXCHANGES_INFO(payload)
		);
		return { data: response, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const exchangeCurrency = async ({
	amount,
	sourceCurrencyCode,
	targetCurrencyCode
}) => {
	try {
		const payload = {
			amount,
			sourceCurrencyCode,
			targetCurrencyCode
		};
		const { data: { response } } = await fetcher.post(CRYPTO.EXCHANGE, payload);
		return { data: response, success: true };
	} catch (errorResponse) {
		throw errorResponse;
	}
};

export const confirmExchange = async exchangeId => {
	try {
		const { data: { response } } = await fetcher.post(
			CRYPTO.CONFIRM_EXCHANGE(exchangeId)
		);
		return { data: response, success: true };
	} catch (errorResponse) {
		throw errorResponse;
	}
};

export const getExchangesInfo = async ({
	invalidData,
	from,
	to,
	isFrom,
	empty
}) => {
	const { data: wallets } = await fetcher.get(CRYPTO.GET_WALLETS);
	if (empty) wallets.response = [];

	try {
		const { data: { response } } = await fetcher.get(CRYPTO.GET_EXCHANGES);

		let data = [];

		Object.keys(response.availableDirections).forEach(i => {
			if (
				i !== 'GBP' &&
				i !== 'USD' &&
				(invalidData
					? isFrom
						? i !== to.currencyCode
						: i !== from.currencyCode
					: true)
			) {
				const walletExists = wallets.response.find(
					w => w.currencyCode === i
				) || {};
				data.push({
					name: getCurrencyName(i),
					image: i,
					currencyCode: i,
					balance: walletExists.currencyCode
						? walletExists.balance
						: 0,
					exists: !!walletExists.currencyCode,
					isReady: walletExists.isReady && walletExists.isReady,
					address: walletExists.requisites && walletExists.requisites.address || '',
					type: walletExists.type,
					directions: response.availableDirections[i]
				});

				const first = 'EUR';
				data = data.sort(function (x, y) {
					return x.currencyCode === first
						? -1
						: y.currencyCode === first
							? 1
							: 0;
				});
			}
		});
		return { data, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const openWallet = async currency => {
	try {
		const { data: { response } } = await fetcher.post(
			CRYPTO.OPEN_WALLET(currency.currencyCode)
		);
		const data = {
			name: getCurrencyName(response.currencyCode),
			image: response.currencyCode,
			currencyCode: response.currencyCode,
			balance: 0,
			exists: true,
			isReady: response.isReady,
			address: response.requisites.address,
			type: response.type
		};
		return { data, success: true };
	} catch (errorResponse) {
		return { success: false, data: errorResponse };
	}
};

export const refresh = (navigation, payload, screen) => {
	if (payload && payload.action && payload.action.key === 'App') {
		// Store.navigate('Exchange', {
		// 	headerTitle: Lang.t('screens.exchange.headers.header1')
		// });
	}
};
