import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Section from 'components/Section';
import Container from 'components/Container';
import TemplateComponent from 'components/Templates';
import Title from 'components/Title';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import historyTypes from 'types/history';
import intlTypes from 'types/intl';

import M from 'components/Templates/Templates.locale.json';

@withRouter
@injectIntl
class Templates extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired
	};

	constructor (props) {
		super(props);

	}


	render () {
		const t = this.props.intl.formatMessage;
		return (
			<Layout className="Template Screen" sidebar>
				<Nav />
				<Header />
				<Section className="TemplateSection">
					<Container>
						<Title title={t(M.title)} />
						<TemplateComponent.List />
					</Container>
				</Section>
			</Layout>
		);
	}
}

export default Templates;
