import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Title from 'components/Title';
import Banner from 'components/Banner';
import * as authActions from 'actions/auth';
import Nav from 'sections/Nav';
import LoginWithEmail from './LoginWithEmail';
import TwoFactorApp from './TwoFactorApp';
import M from './Login.locale.json';
import './Login.scss';

import intlTypes from 'types/intl';

// Disabled in WS3-1399
// const tabs = [
// 	{
// 		id: "email",
// 		title: M.tabs.email,
// 	},
// {
// 	id: "card",
// 	title: M.tabs.card,
// }
// ]

const mapState = (state, props) => ({
	user: state.user,
	twoFactor: state.twoFactorAuth
});

const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Login extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		user: PropTypes.object,
	}

	state = {
		loader: true,
		// Disabled in WS3-1399
		// current: 0,
	}

	// Disabled in WS3-1399
	// componentDidMount = () => {
	// const { history } = this.props
	// if (history.location.pathname === "/auth/login/cardnumber") {
	// 	this.setState({ current: 1 })
	// }
	// }
	// Disabled in WS3-1399
	// onTab = (tab, idx) => this.setState({ current: idx })

	render () {
		const { messages } = this.state;
		const { user, intl, twoFactor } = this.props;
		const t = intl.formatMessage;
        let { isOpenTwoFactorScreen } = twoFactor;
		isOpenTwoFactorScreen = isOpenTwoFactorScreen &&
			twoFactor && twoFactor.user && twoFactor.user.twoFactorSecret;

		if (user) return <Redirect to={{ pathname: '/' }} />;

		return (
			<Layout className="AuthLoginScreen Screen">
				<Nav logo />
				<Section large>
					<Container small>
						<Wrap>
						{!isOpenTwoFactorScreen && <Title title={t(M.title)} legend={t(M.legend)} />}
							{messages && !!messages.length && <Banner type="error" data={messages} />}
							{!isOpenTwoFactorScreen && <LoginWithEmail />}
							{isOpenTwoFactorScreen && <TwoFactorApp />}
							{/* Disabled in WS3-1399 */}
							{/* <Tab */}
							{/*	header={tabs.map(item => ({ ...item, title: t(item.title) }))} */}
							{/*	current={current} */}
							{/*	onChange={this.onTab} */}
							{/* > */}
							{/* <Tab.Panel> */}
							{/*	<LoginWithEmail /> */}
							{/*	</Tab.Panel> */}
							{/* <Tab.Panel> */}
							{/*	<LoginWithCard /> */}
							{/* </Tab.Panel> */}
							{/* </Tab> */}
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}
