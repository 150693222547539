import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Tab from 'components/Tab';
import Section from 'components/Section';
import Container from 'components/Container';
import Templates from 'components/Templates';
import Title from 'components/Title';
import { payment, c2cPayment, internationalPayment, headers, paths, mapIds } from './constants';
import M from './SendPayment.locale.json';

import historyTypes from 'types/history';
import intlTypes from 'types/intl';

const sendPaymentAvailablePaths = [paths.payments, paths.c2cPayments, paths.internationalPayment];

@withRouter
@injectIntl
class SendPayment extends React.PureComponent {
	static propTypes = {
		history: historyTypes.isRequired,
		intl: intlTypes.isRequired,
	}

	constructor (props) {
		super(props);
		const t = props.intl.formatMessage;
		this.headers = headers.map(method => ({ ...method, title: t(M.buttons[method.title]) }));
	}

	state = {
		current: mapIds[sendPaymentAvailablePaths.find(path => location.pathname.includes(path))],
	}

	onChange = item => {
		if (item.id === payment) this.props.history.push(paths.payments);
		if (item.id === c2cPayment) this.props.history.push(paths.c2cPayments);
		if (item.id === internationalPayment) this.props.history.push(paths.internationalPayment);
		this.setState({ current: item.id });
	}

	render () {
		const t = this.props.intl.formatMessage;
		return (
			<Section className="LoadSection">
				<Container>
					<Title title={t(M.header)} />
					<Templates />
					<Tab header={this.headers} current={this.state.current} onChange={this.onChange}>
						<Tab.Panel />
						<Tab.Panel />
						<Tab.Panel />
					</Tab>
				</Container>
			</Section>
		);
	}
}

export default SendPayment;
