/* eslint-disable space-before-function-paren */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable import/first */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import Favicon from 'react-favicon';
import { ENVIRONMENT } from './constants';
import Auth from './screens/Auth';
import App from './screens/App';
import Blocking from './screens/Blocking';
import Dashboard from './screens/Dashboard';
import History from './screens/History';
import Card from './screens/Card';
import Load from './screens/Load';
import Member from './screens/Member';
import Shareholder from './screens/Shareholder';
import Setting from './screens/Setting';
import Payment from './screens/Payment';
import Client from './screens/Client';
import Timeout from './screens/Timeout';
import General from './screens/General';
import Invite from './screens/Invited';
import Toast from './sections/Toast';
import UiAlert from './sections/UiAlert';
import Templates from './sections/Templates';
import TwoFactorAlert from './sections/TwoFactorAlert';
import Notifications from './screens/Notifications';
import Exchange, { Currency as ExchangeCurrencyDetails, TopUp as ExchangeTopUp, Send as ExchangeSend, ExchangeCurrency } from './screens/Exchange';
import { PFSSuccessScreen, PFSErrorScreen, PFSSuccessMobile, PFSErrorMobile } from './sections/PFS';
import RoutePrivate from './components/RoutePrivate';
import C2CPayment from './screens/C2CPayment';
import InternationalPayment from './screens/InternationalPayment';
import NotFound from './screens/NotFound/NotFound';
import * as paths from 'constants/paths';
import PurchaseProposalSuccess from './screens/Auth/PurchaseProposalSuccess';



const mapState = state => ({
	user: state.user,
	client: state.client,
	ui: state.ui,
});

@injectIntl
@connect(mapState)
export default class Root extends PureComponent {
	constructor(props) {
		super(props);

		this.previous = props.location;
	}


	UNSAFE_componentWillUpdate(next) {
		const { location } = this.props;
		const modal = (!location.state || !location.state.modal);
		const replace = (next.location.state && next.location.state.replace);

		// set previous location on modeal close
		// if (next.history.action !== 'POP' && (modal && replace)) this.previous = this.props.location;
		if (next.history.action !== 'POP' && (modal && !replace)) this.previous = this.props.location;
	}

	render() {
		const { location, user, client } = this.props;
		const modal = !!(
			location.state &&
			location.state.modal &&
			// location.state.replace &&
			this.previous !== location
		); // not initial render

		return (
			<div className="Router">
				<Favicon url="/favicon.ico" />
				<LoadingBar />
				<Switch location={(modal ? this.previous : location)}>
					<RoutePrivate exact path="/" component={Dashboard} />

					<RoutePrivate exact path="/history" component={History} />
					<RoutePrivate exact path="/history/export" component={History.Export} />
					<RoutePrivate exact path="/history/:id" component={History.Single} />

					<RoutePrivate exact path="/clients" component={Client} />
					<RoutePrivate exact path="/clients/create/start" component={Client.Start} />
					<RoutePrivate exact path="/clients/create/private" component={Client.Create.Private} />
					<RoutePrivate exact path="/clients/create/business/:id" component={Client.Create.Business} />
					<RoutePrivate exact path="/clients/create/business/:id/submitted" component={Client.Submitted} />
					<RoutePrivate exact path="/clients/create/business/:id/:section" component={Client.Create.Business} />
					<RoutePrivate exact path="/clients/:id" component={Client} />

					<RoutePrivate exact path="/cards" component={Card.Card} />
					<RoutePrivate exact path="/cards/create" component={Card.CardCreateTab} />
					<RoutePrivate exact path="/cards/create/me" component={Card.CardCreateMe} />
					<RoutePrivate exact path="/cards/create/other" component={Card.CardCreateOther} />
					<RoutePrivate exact path="/cards/create/success" component={Card.Success} />
					{!modal && [
						<RoutePrivate exact path="/cards/:id" component={Card.Single} key={'/cards/:id'} />,
						<RoutePrivate exact path="/cards/:id/:tab" component={Card.Single} key={'/cards/:id'} />
					]}

					<RoutePrivate exact path="/blocked" component={Blocking} />

					<RoutePrivate exact path="/load" component={Load.Load} />

					<RoutePrivate exact path="/members" component={Member} />
					<RoutePrivate exact path="/members/create" component={Member.Create} />
					<RoutePrivate exact path="/members/edit/:id/:rid" component={Member.Edit} />

					<RoutePrivate exact path="/shareholders/create" component={Shareholder.Create} />
					<RoutePrivate exact path="/shareholders/edit/:id" component={Shareholder.Edit} />

					<RoutePrivate exact path="/settings" component={Setting} />
					<RoutePrivate exact path="/settings/:section" component={Setting} />
					<RoutePrivate exact path="/settings/:section/:block" component={Setting} />

					<RoutePrivate exact path="/payments" component={Payment} />
					<RoutePrivate exact path="/payments/create" component={Payment.Create} />
					<RoutePrivate exact path="/payments/create/:id" component={Payment.Create} />
					<RoutePrivate exact path="/payments/templates" component={Templates} />

					<RoutePrivate exact path="/c2cpayments" component={C2CPayment} />
					<RoutePrivate exact path="/c2cpayments/create" component={C2CPayment.Create} />
					<RoutePrivate exact path="/c2cpayments/create/:id" component={C2CPayment.Create} />

					<RoutePrivate exact path="/payments/pfs/success" component={PFSSuccessScreen} />
					<RoutePrivate exact path="/payments/pfs/error" component={PFSErrorScreen} />
					<Route exact path="/payments/pfs/success/mobile" component={PFSSuccessMobile} />
					<Route exact path="/payments/pfs/error/mobile" component={PFSErrorMobile} />
					<Route exact path="/payments/success" component={PurchaseProposalSuccess} />

					<RoutePrivate exact path={paths.internationalPayment} component={InternationalPayment} />
					<RoutePrivate
						exact
						path={paths.internationalPaymentCreate}
						component={InternationalPayment.InternationalPaymentCreate}
					/>
					<RoutePrivate
						exact
						path={`${paths.internationalPaymentCreate}/:id`}
						component={InternationalPayment.InternationalPaymentCreate}
					/>

					<RoutePrivate exact path="/auth/regulatory-data-collection" component={Auth.RegulatoryDataCollection} />
					<RoutePrivate exact path="/auth/email" component={Auth.Email} />
					<RoutePrivate exact path="/auth/phone" component={Auth.Phone} />
					<RoutePrivate exact path="/auth/purchase" component={Auth.PurchaseProposal} />
					<Route exact path="/auth/purchase/confirm" component={Auth.PaymentInProgress} />
					<Route exact path="/auth/login" component={Auth.Login} />
					<Route exact path="/auth/login/cardnumber" component={Auth.Login} />
					<Route exact path="/auth/activate" component={Auth.Activate} />
					<Route path="/app" component={App} />

					/* Catch requests from old links and redirect them */
					<Route exact path="/en/apply" component={Auth.Signup.Private} />
					<Route exact path="/lv/apply" component={Auth.Signup.Private} />
					<Route exact path="/ru/apply" component={Auth.Signup.Private} />
					<Route exact path="/lt/apply" component={Auth.Signup.Private} />
					<Route exact path="/ee/apply" component={Auth.Signup.Private} />
					<Route exact path="/pl/apply" component={Auth.Signup.Private} />
					<Route exact path="/de/apply" component={Auth.Signup.Private} />
					<Route exact path="/cs/apply" component={Auth.Signup.Private} />
					<Route exact path="/bg/apply" component={Auth.Signup.Private} />

					<Route exact path="/en/login" component={Auth.Login} />
					<Route exact path="/lv/login" component={Auth.Login} />
					<Route exact path="/ru/login" component={Auth.Login} />
					<Route exact path="/lt/login" component={Auth.Login} />
					<Route exact path="/ee/login" component={Auth.Login} />
					<Route exact path="/pl/login" component={Auth.Login} />
					<Route exact path="/de/login" component={Auth.Login} />
					<Route exact path="/cs/login" component={Auth.Login} />
					<Route exact path="/bg/login" component={Auth.Login} />

					<Route exact path="/auth/reset" component={Auth.Reset} />
					<Route exact path="/auth/resetMigrated" component={Auth.ResetMigrated} />
					<Route exact path="/auth/password/:token" component={Auth.Password} />
					<RoutePrivate  exact path='/account/review' component={Client.ManualVerification} />

					<RoutePrivate exact path="/invite/password" component={Invite.Password} access={{ user, client }} />

					<RoutePrivate exact path="/notifications" component={Notifications} />

					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange" component={Exchange} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/currency/:code" component={ExchangeCurrencyDetails} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/topup" component={ExchangeTopUp.Create} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/topup/confirm/:id" component={ExchangeTopUp.Confirm} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/topup/success" component={ExchangeTopUp.Success} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/send/:currency" component={ExchangeSend.Create} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/send/:currency/preview" component={ExchangeSend.Preview} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/send/:currency/confirm" component={ExchangeSend.Confirm} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/send/:currency/success" component={ExchangeSend.Success} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/swap" component={ExchangeCurrency.Create} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/swap/preview" component={ExchangeCurrency.Preview} />
					<RoutePrivate ignoreEnv={[ENVIRONMENT.production]} exact path="/exchange/swap/success" component={ExchangeCurrency.Success} />

					<Route component={NotFound} />
				</Switch>

				{
					modal &&
					<Switch>
						<Route exact path="/terms-and-services" component={General.Terms} />
						<Route exact path="/privacy-policy" component={General.Privacy} />

						<RoutePrivate exact path="/history/export" component={History.Export} />
						<RoutePrivate exact path="/history/:id" component={History.Single} />

						<RoutePrivate exact path="/members/create" component={Member.Create} />
						<RoutePrivate exact path="/members/edit/:id/:rid" component={Member.Edit} />

						<RoutePrivate exact path="/cards/create" component={Card.Create} />
						<RoutePrivate exact path="/cards/:id" component={Card.Single} />
						<RoutePrivate exact path="/cards/:id/:tab" component={Card.Single} />

						<RoutePrivate exact path="/shareholders/create" component={Shareholder.Create} />
						<RoutePrivate exact path="/shareholders/edit/:id" component={Shareholder.Edit} />
					</Switch>
				}

				<Route component={Timeout.Timeout} />
				<UiAlert />
				<Toast />
				<TwoFactorAlert />
			</div>
		);
	}
}
