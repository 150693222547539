import PropTypes from 'prop-types';
import { userStatuses } from 'constants/common';
import linkedCard from './linkedCard';

export default PropTypes.shape({
	id: PropTypes.number,
	language: PropTypes.string,
	phone: PropTypes.string,
	phoneCountry: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	email: PropTypes.string,
	verified: PropTypes.bool,
	phoneVerified: PropTypes.bool,
	status: PropTypes.oneOf(userStatuses),
	avatarId: PropTypes.number,
	migration: PropTypes.bool,
	name: PropTypes.string,
	surname: PropTypes.string,
	linkedCards: PropTypes.arrayOf(linkedCard)
});
