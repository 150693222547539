import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Form from 'components/Form';
import InputRadio from 'components/InputRadio';
import Button from 'components/Button';

import * as cardActions from 'actions/card';
import * as privateActions from 'actions/client/private';

import intlTypes from 'types/intl';
import { cardActionsTypes, privateActionsTypes } from 'types/actions';
import currentCardTypes from 'types/currentCard';

import Icon from 'components/Icon';

import { REISSUE_ADDRESS_CHECK, REISSUE_MODAL_STATE } from '../../constants';

import '../ReissueModals.scss';

const SecondModal = ({ intl, setReissueState, address, actions, card }) => {
	const t = intl.formatMessage;
	const [radioValue, setRadioValue] = useState(REISSUE_ADDRESS_CHECK.CONFIRM);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (!address) {
			actions.private.getAddress();
		}
	}, []);

	const onChange = value => {
		setRadioValue(value);
	};

	//TODO! Change this way!
	const onSubmit = async e => {
		e.preventDefault();
		if (radioValue === REISSUE_ADDRESS_CHECK.CONFIRM) {
			setLoading(true);
			const response = await actions.card.reissue(card.id);
			setLoading(false);
			if (!response || response.errors) {
				setReissueState(REISSUE_MODAL_STATE.ERROR, response.errors);
				return;
			}

			setReissueState(REISSUE_MODAL_STATE.SUCCESS);
		} else {
			setReissueState(REISSUE_MODAL_STATE.ADDRESS);
		}
	};

	const onSkip = () => setReissueState(REISSUE_MODAL_STATE.CLOSE);

	return(

		<Modal>
			<Section>
				<Container medium>
					<Wrap className="ReissueModal__strict-padding">
						<Icon
							className="ReissueModal-close" 
							onClick={onSkip} 
							name="close-small"
						/>
						<Title
							title={t({ id: 'modals.renew.second.title' })}
							small
							subtitle={address ? `${address.line1}, ${address.city}, ${address.postalCode}` : undefined}
						/>
						<Form className="ReissueModal-form">
							<Form.Group>
								<InputRadio
									id="typeA"
									name="renew-type-A"
									value={REISSUE_ADDRESS_CHECK.CONFIRM}
									checked={radioValue === REISSUE_ADDRESS_CHECK.CONFIRM}
									onChange={onChange}
								>
									{t({ id: 'modals.renew.second.order' })}
								</InputRadio>

								<InputRadio
									id="typeB"
									name="renew-type-B"
									value={REISSUE_ADDRESS_CHECK.CLARIFY}
									checked={radioValue === REISSUE_ADDRESS_CHECK.CLARIFY}
									onChange={onChange}

								>
									{t({ id: 'modals.renew.second.address' })}
								</InputRadio>
							</Form.Group>
							<div className="ReissueModal-controls">
								<Button 
									onClick={onSubmit} 
									small 
									className="ReissueModal-button"
									loading={isLoading}
								>
									<FormattedMessage id="modals.renew.second.button.order" />
								</Button>
								<Button 
									onClick={onSkip} 
									small 
									className="ReissueModal-button"
									disabled={isLoading}
								>
									<FormattedMessage id="modals.renew.second.button.cancel" />
								</Button>
							</div>

						</Form>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
}
;

SecondModal.propTypes = {
	intl: intlTypes,
	setReissueState: PropTypes.func.isRequired,
	actions: PropTypes.shape({ 
		card: cardActionsTypes.isRequired,
		private: privateActionsTypes.isRequired,
	}),
	address: PropTypes.any,
	card: currentCardTypes.isRequired,
};

const mapState = (state, props) => ({
	address: state.user.address,
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
		private: bindActionCreators(privateActions, dispatch),
	},
});


export default compose(
	connect(mapState, mapDispatch),
	injectIntl,
)(SecondModal);
