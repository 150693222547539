import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './ButtonLink.scss';

export default function ButtonLink(props) {
  const { className, to, children, onClick, type, url } = props;
  if (type === 'a') {
    return (
      <a href={url} {...props} className={classnames('ButtonLink', className)}>
        {children}
      </a>
    );
  }

  if (!to) {
    return (
      <span className={classnames('ButtonLink', className)} onClick={onClick}>
        {children}
      </span>
    );
  }

  return (
    <Link to={to} className={classnames('ButtonLink', className)} onClick={onClick}>
      {children}
    </Link>
  );
}

ButtonLink.propTypes = {
  to: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
