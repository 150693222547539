import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import numeral from 'numeral';
import moment from 'moment';
import Badge from 'components/Badge';
import Button from 'components/Button';
import M from './formatter.locale.json';

const formatId = id => <Button plain>#{id}</Button>;

const formatAmount = amount => <span className={'amount-col'}>{`${numeral(amount).format('- 0.00')} €`}</span>;

const formatBadge = status => {
	switch (status) {
		case 'SUCCESSFUL':
		case 'COMPLETED':
			return <Badge success><FM id={M.status.accepted.id} /></Badge>;
		case 'DECLINED':
			return <Badge error><FM id={M.status.declined.id} /></Badge>;
		case 'PROCESSING':
			return <Badge><FM id={M.status.processing.id} /></Badge>;
		case 'DRAFT':
			return <Badge><FM id={M.status.draft.id} /></Badge>;
		case 'SIGNING':
			return <Badge info><FM id={M.status.signing.id} /></Badge>;
		case 'DELETED':
			return <Badge><FM id={M.status.deleted.id} /></Badge>;
		case 'IN':
			return <Badge orange><FM id={M.status.in.id} /></Badge>;
		case 'OUT':
			return <Badge info><FM id={M.status.out.id} /></Badge>;
		case 'PHONE':
			return <FM id={M.applications.phone.id} />;
		case 'GRANTED':
			return <Badge success><FM id={M.status.granted.id} /></Badge>;
		case 'REQUESTED':
			return <Badge info><FM id={M.status.requested.id} /></Badge>;
		default:
			return <Badge><FM id={M.status.processing.id} /></Badge>;
	}
};

export const formatDate = input => {	
	const date = moment(new Date(input));

	if (!date.isValid()) return '';

	return `${moment(date).format('L')} ${moment(date).add(7, 'h').format('HH:mm:ss')}`;
};

/**
 * Formats the input date with options.
 * @param {string | number | Date} input - The input date to be formatted.
 * @param {Object} options - The formatting options.
 * @param {number} [options.increment=0] - The number of hours to increment the date by.
 * @param {boolean} [options.utc=false] - Whether to return the formatted UTC date or not.
 * @returns {string} The formatted date.
 */
export const formatDateV2 = (input, options = {}) => {
	const { increment = 0, utc = false } = options;
	const date = moment(new Date(input));

	if (!date.isValid()) return '';

	const dateToFormat = utc ? moment(date).utc().add(increment, 'h') : moment(date).add(increment, 'h');

	return `${dateToFormat.format('L')} ${dateToFormat.format('HH:mm:ss')}`;

};

export default function formater (name, field) {
	switch (name) {
		case 'id':
		case 'idShort':
			return formatId(field);
		case 'status':
		case 'badge':
			return formatBadge(field);
		case 'amount':
		case 'money':
		case 'fee':
		case 'predictedFee':
		case 'price':
		case 'balanceAfter':
			return formatAmount(field);
		case 'payment' : return numeral(field).format('- 0.00');
		case 'date':
		case 'created':
		case 'paymentDate':
			return formatDate(field);
		case 'type':
			return formatBadge(field);
		default:
			return field;
	}
}

/**
 * Formats a floating-point number to a fixed-point notation.
 *
 * @param {number|string} float - The floating-point number or string representation of the number to format.
 * @param {number} [minPrecision=2] - The minimum number of digits to appear after the decimal point.
 * @param {number} [maxPrecision=10] - The maximum number of digits to appear after the decimal point.
 * @returns {string} Formatted string representing the original number with the specified precision constraints applied.
 */
export const formatFloatToFixed = (
	float,
	minPrecision = 2,
	maxPrecision = 10,
) => {
	const number = Number.parseFloat(float);
	if (isNaN(number)) return '0.00'; 
	return number.toFixed(
		Math.max(
			minPrecision,
			Math.min(
				(number.toString().split('.')[1] || '').length,
				maxPrecision,
			),
		),
	);
};
