import React, {useCallback, useEffect, useState} from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import Container from '../../../components/Container';
import Nav from '../../../sections/Nav';
import M from './PurchaseProposal.locale.json';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from 'actions/auth';
import './PurchaseProposal.scss';
import { error, success } from 'actions/toast';
import Icon from "../../../components/Icon";
import ButtonLink from "../../../components/ButtonLink";


const mapState = state => ({
	cards: state.card.items || [],
});
const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
		success: title => success(dispatch)(title),
		error: title => error(dispatch)(title),
	}
});
const PaymentInProgress = ({ cards, intl, actions, history }) => {
	const [loading, setLoading] = useState(false);
	const [paymentType, setPaymentType] = useState(null);
	const [paymentId, setPaymentId] = useState(null);

	const location = useLocation();
	const t = intl.formatMessage;

	useEffect(() => {
		let savedPaymentType, savedPaymentId;

		if (location.state && location.state.type && location.state.id) {
			savedPaymentType = location.state.type;
			savedPaymentId = location.state.id;
		} else {
			savedPaymentType = localStorage.getItem('paymentType');
			savedPaymentId = localStorage.getItem('paymentId');
		}

		if (!savedPaymentType || !savedPaymentId) {
			history.push('/auth/purchase');
		} else {
			setPaymentType(savedPaymentType);
			setPaymentId(savedPaymentId);
		}
	}, [history, location]);

	const onCheck = useCallback(
		() => {
			setLoading(true);
			actions.auth.checkPayment(paymentType, cards[0].id, paymentId, { linking: false })
				.then(response => {
					if (response.success) {
						localStorage.removeItem('paymentType');
						localStorage.removeItem('paymentId');
						actions.auth.togglePurchaseRegistration(true);
						history.push('/clients/create/private');
					}
					else window.open(response.checkoutUrl, '_blank');
				})
				.catch(() => actions.error({title: M.errors.check})).finally(() => setLoading(false));
		}, [paymentType, paymentId]);


	return (
		<Layout className="PaymentInProgressScreen Screen">
			<Nav logo />
			<Section className="PaymentInProgressScreen__Container">
				<Container small>
					<div>
						<div className={'PaymentInProgressScreen__Icon'}>
							<Icon name={'nav-payments'} />
						</div>
						<h3 style={{ textAlign: 'center' }}>In progress...</h3>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Button
							loading={loading}
							onClick={loading ? () => {} : onCheck}
							style={{ marginBottom: 10 }}
						>
							{t(M.buttons.check)}
						</Button>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<ButtonLink onClick={history.goBack} small>{t({ id: 'modals.verification.skip' })}</ButtonLink>
						</div>
					</div>
				</Container>
			</Section>
		</Layout>
	);
};

export default withRouter(connect(mapState, mapDispatch)(injectIntl(PaymentInProgress)));
