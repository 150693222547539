/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { error, success } from 'actions/toast';
import * as exchangeActions from 'actions/exchange';
import Form from 'components/Form';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import Title from 'components/Title';
import Container from 'components/Container';
import Button from 'components/Button';
import { formatDateV2 } from 'helpers/formatter';

import { SendProps } from './types';
import walletIcon from '../assets/exchange/icoSend.png';

import M from '../Exchange.locale.json';

const fiterCard = (card, name, surname) => {
	return !!(
		card.cardStatus === 'OPEN' &&
		card.activated &&
		card.cardName &&
		card.cardName.trim() === (name + ' ' + surname).trim()
	);
};

const mapState = state => ({
	cards: state.card.items
		.filter(card => fiterCard(card, state.user.name, state.user.surname))
		.map(card => ({
			...card,
			value: card.id,
			balance: card.availBal,
			title: card.cardNumber
		}))
});

const mapDispatchToProps = dispatch => {
	return {
		errorToast: payload => {
			error(dispatch)(payload);
		},
		successToast: payload => {
			success(dispatch)(payload);
		}
	};
};

const ExchangeSend = ({ currency, cards, intl, ...props }) => {
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState();
	const [address, setAddress] = useState();
	const [currentCard, setCurrentCard] = useState(null);
	const [isRequestStep, setRequestStep] = useState(false);
	const [receivers, setReceivers] = useState();
	const isFiat = currency.type === 'FIAT';
	const currencyBalance = currency.balance || 0; 
	const isDisabled = isRequestStep? isNaN(amount) || amount === 0 || amount > currencyBalance : false;

	const history = useHistory();
	const t = intl.formatMessage;

	const onReceiver = address => {
		setAddress(address);
	};

	const onBack = () => {
		setRequestStep(false);
	};

	const onSubmit = async e => {
		e.preventDefault();

		if (!isRequestStep) {
			setRequestStep(true);
			return;
		} else {
			const currentCardInfo = cards.find(
				({ id }) => id === currentCard
			);
			setLoading(true);
			try {
				const { success, data } = await exchangeActions.sendRequest({
					amount: Number(amount),
					currencyWallet: currency.currencyCode,
					address: address,
					subtractFee: true,
					noSms: true
				});

				if (success) {
					history.push(`/exchange/send/${currency.currencyCode.toLowerCase()}/preview`, {
						data: {
							requestId: data.requestId,
							fee: data.fee,
							card: currentCardInfo,
							currency,
							address,
							amount
						}
					});
				}
			} catch ({ errors }) {
				for (const error in errors) {
					if (Object.hasOwnProperty.call(errors, error)) {
						props.errorToast({
							title: {
								...errors[error][0],
								id: errors[error][0].id || 'undefinedError'
							}
						});
					}
				}
			} finally {
				setLoading(false);
			}
		}
	};

	const onSubmitFiat = async e => {
		e.preventDefault();

		if (!isRequestStep) {
			setRequestStep(true);
			return;
		} else {
			setLoading(true);
			try {
				const currentCardInfo = cards.find(
					({ id }) => id === currentCard
				);

				const { success, data } = await exchangeActions.sendRequestFiat(
					{
						amount: Number(amount),
						currencyWallet: currency.currencyCode,
						iban: currentCardInfo.iban,
						noSms: true
					}
				);

				if (success) {
					history.push(`/exchange/send/${currency.currencyCode.toLowerCase()}/preview`, {
						data: {
							requestId: data.requestId,
							fee: data.fee,
							card: currentCardInfo,
							currency,
							address,
							amount
						}
					});
				}
			} catch ({ errors }) {
				for (const error in errors) {
					if (Object.hasOwnProperty.call(errors, error)) {
						props.errorToast({
							title: {
								...errors[error][0],
								id: errors[error][0].id || 'undefinedError'
							}
						});
					}
				}
			} finally {
				setLoading(false);
			}
		}
	};



	const onChangeAmount = value => {
		if (isNaN(value)) return;
		setAmount(value);
	};

	const onChangeBeneficiary = value => {
		setAddress(value);
	};

	const onChangeCard = value => {
		setCurrentCard(value);
	};

	const onBlurAmount = value => {
		setAmount(parseFloat(value) || 0);
	};

	const onMaxAmount = () => {
		if (currency.balance || currency.balance === 0) {
			setAmount(currency.balance);
		}
	};

	async function fetchData () {
		try {
			let response = await exchangeActions.getRecentReceivers();
			if (response && response.success && response.data.length > 0) {
				const receiversArray = response.data
					.filter(receiver => receiver.lastOperation)
					.map(({ lastOperation, ...rest }) => ({
						...rest,
						lastOperation: new Date(Date.parse(lastOperation))
					}));
				setReceivers(receiversArray);
			}
		} catch (e) {
		}
	}

	useEffect(() => {
		if (cards.length) setCurrentCard(cards[0].id);

		fetchData();
	}, []);

	

	return (
		<div className="ExchangeSend">
			<Container small>
				<Title
					title={t(M.send.title)}
					subtitle={isFiat ? t(M.send.subtitleFiat) : ''}
				/>

				{isFiat ? (
					<Form onSubmit={onSubmitFiat}>
						<Form.Group>
							<InputSelect
								name="cardId"
								onChange={onChangeCard}
								placeholder={t(M.topUp.inputs.card)}
								value={currentCard}
								data={cards}
							/>
						</Form.Group>
						<Form.Note>{t(M.topUp.messages.cardMessage)}</Form.Note>
						{isRequestStep && (
							<Form.Group>
								<Input
									name="amount"
									placeholder={t(M.send.inputs.amount)}
									onChange={onChangeAmount}
									value={amount}
									onBlur={onBlurAmount}
								/>
							</Form.Group>
						)}
						<Button disabled={isDisabled} form>
							{isRequestStep
								? t(M.send.buttons.send)
								: t(M.send.buttons.next)}
						</Button>
					</Form>
				) : (
					<Form onSubmit={onSubmit}>
						<Form.Group>
							<Input
								name="address"
								placeholder={t(M.send.inputs.address)}
								onChange={onChangeBeneficiary}
								disabled={isRequestStep}
								value={address}
							/>
							{receivers && !isRequestStep && (
								<div className="ExchangeSend__Receivers">
									<h3>Recent receivers</h3>
									<ul>
										{receivers.map((receiver, index) => {
											const {
												address,
												lastOperation
											} = receiver;
											const formattedAddress =
												address.slice(0, 6) +
												'...' +
												address.slice(-6);
											const formattedDate = formatDateV2(
												lastOperation
											);
											if (index > 4) return;
											return (
												<li
													key={lastOperation}
													onClick={() =>
														onReceiver(address)
													}
												>
													<img
														src={walletIcon}
														alt=""
														width={48}
														height={48}
													/>
													<div className="ExchangeSend__Receivers_Item">
														<span>
															{formattedAddress}
														</span>
														<span>
															<i>
																{formattedDate}
															</i>
														</span>
													</div>
												</li>
											);
										})}
									</ul>
								</div>
							)}
						</Form.Group>
						{isRequestStep && (
							<Form.Group>
								<Input
									name="amount"
									placeholder={t(M.send.inputs.amount)}
									onChange={onChangeAmount}
									value={amount}
									onBlur={onBlurAmount}
									// errors={errors.registrationNumber}
								/>
								<Button
									style={{ marginTop: 16, marginBottom: 16 }}
									small
									onClick={onMaxAmount}
								>
									MAX
								</Button>
							</Form.Group>
						)}
						{isRequestStep && (
							<Button secondary onClick={onBack}>
								{t(M.send.buttons.back)}
							</Button>
						)}

						<Button disabled={isDisabled} loading={loading} form>
							{isRequestStep
								? t(M.send.buttons.send)
								: t(M.send.buttons.next)}
						</Button>
					</Form>
				)}
			</Container>
		</div>
	);
};

ExchangeSend.propTypes = SendProps;

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps)
)(ExchangeSend);
