import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import './PurchaseProposalSuccess.scss';
import Icon from '../../../components/Icon';

@injectIntl
export default class PurchaseProposalSuccess extends PureComponent {
	state = {
		loader: false,
	};

	render () {
		const t = this.props.intl.formatMessage;
		return (
			<div className="Openbanking__Container">
				<div>
					<div className={'Openbanking__Icon Openbanking__Icon--Success'}>
						<Icon name={'success'} />
					</div>
					<h3 style={{ textAlign: 'center' }}>{t({ id: 'screens.exchange.topupsuccess.label1' })}</h3>
					<p>{t({id: 'screens.purchaseProposal.success.subTitle'})}</p>
				</div>
			</div>
		);
	}
}
