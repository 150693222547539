import fetcher from 'helpers/fetcher';

export default {

	getUnread (eTypeProp,fromProp,toProp) {
		const eType = eTypeProp ? `&eventType=${eTypeProp}` : ``
		const from = fromProp ? `&dateFrom=${fromProp}` : ``
		const to = toProp ? `&dateTo=${toProp}` : ``

		return fetcher.get(`notification/get?limit=50${eType}${from}${to}`);
	},
	getAll () {
		return fetcher.get('private/push-notification/ALL');
	},
	getCount () {
		return fetcher.get(`notification/getUnreadCount`);
	},
	markAsRead (ids) {
		fetcher.post('private/push-notification', { ids });
	},
	alreadyCard (id) {
		return fetcher.post(`private/push-notification/tiande/order-card/${id}`);
	},
	cancelOrderingCard (id) {
		return fetcher.post(`private/push-notification/tiande/not-order-card/${id}`);
	},
	addToken (cardId,currentToken) {
		return fetcher.post(`/notification/${cardId}/addToken?token=${currentToken}`);
	}
};
