import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as paymentActions from 'actions/payment';

import Button from 'components/Button';
import Form from 'components/Form';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Templates from 'components/Templates';
import Title from 'components/Title';
import Wrap from 'components/Wrap';
import helpers from 'helpers';
import * as paymentSelector from 'selectors/payment';
import { GTMEvents } from '../../../constants/gtm';
import M from './Completed.locale.json';


const mapState = (state, props) => {
	const { match } = props;
	const { id } = match.params;

	return {
		payment: paymentSelector.get(state, id),
	};
};

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Completed extends Component {
	componentDidMount () {
		gtag('event', GTMEvents.sepaCompleted.eventName, { ...GTMEvents.sepaCompleted.eventParameters });
	}

	onCreate = () => {
		const { history } = this.props;

		history.push('/payments/create');
	}

	onCopy = () => {
		const { history, payment } = this.props;
		history.push({
			pathname: '/payments/create',
			payment,
			state: {
				payment,
			},
		});
	}

	onDashboard = () => {
		const { history } = this.props;

		history.push('/payments');
	}

	render () {
		const { payment } = this.props;
		const t = this.props.intl.formatMessage;

		return (
			<Wrap>
				<div
					className="PaymentCreateScreen__Icon PaymentCreateScreen__Icon--Success"
				>
					<Icon
						name="success"
					/>
				</div>

				<Title
					className="PaymentCreateScreen__Subtitle"
					title={t(M.title)}
				/>

				<p>{t(M.messages.success)}</p>


				<Form.Group>
					<Input
						name="fromIban"
						placeholder={t(M.inputs.fromIban)}
						value={payment.fromIban}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="cardNumber"
						placeholder={t(M.inputs.cardNumber)}
						value={payment.fromCardNumber}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="beneficiary"
						placeholder={t(M.inputs.beneficiary)}
						value={payment.beneficiary}
						disabled
					/>
				</Form.Group>

				<Form.Group>
					<Input
						name="iban"
						placeholder={t(M.inputs.iban)}
						value={payment.iban}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="amount-amount"
						placeholder={t(M.inputs.amount)}
						value={helpers.formatter('payment', payment.amount)}
						unit="EUR"
						disabled
					/>
				</Form.Group>

				{
					payment.details &&
					<Form.Group>
						<Input
							name="details"
							placeholder={t(M.inputs.details)}
							value={payment.details}
							disabled
						/>
					</Form.Group>
				}

				<Form.Group>
					<Input
						name="signerName"
						placeholder={t(M.inputs.signerName)}
						value={payment.signerName}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="signerLastName"
						placeholder={t(M.inputs.signerLastName)}
						value={payment.signerLastName}
						disabled
					/>
				</Form.Group>
				<Form.Group>
					<Input
						name="paymentDate"
						placeholder={t(M.inputs.paymentDate)}
						value={helpers.formatter('paymentDate', payment.paymentDate)}
						disabled
					/>
				</Form.Group>
				<Button
					form
					ghost
					small
					className="PaymentCreateScreen__Back"
					onClick={this.onDashboard}
				>
					{t(M.buttons.payments)}
				</Button>
				<Button
					form
					small
					className="PaymentCreateScreen__Submit"
					onClick={this.onCreate}
				>
					{t(M.buttons.create)}
				</Button>

				<Button
					form
					small
					secondary
					className="PaymentCreateScreen__Submit"
					onClick={this.onCopy}
				>
					{t(M.buttons.copy)}
				</Button>
				<Templates.SaveButton id={payment.id} type={'SEPA'} />
			</Wrap>
		);
	}
}
