import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';

import intlTypes from 'types/intl';
import Title from 'components/Title';
import Button from 'components/Button';
import fetcher from 'helpers/fetcher';
import { objectToQueryParams } from '../../../utils/query';
import * as toastActions from 'actions/toast';

import M from './BugReportSection.locale.json';
import './BugReportSection.scss';
import Icon from '../../../components/Icon';
import Axios from 'axios';
import {bytesToMbConverter} from '../../../helpers/bytesToMbConverter';

const MAX_FILE_SIZE = 20;

const getOSInformation = () => {
	const userAgent = navigator.userAgent;
	let browser;
	let browserVersion;

	if (userAgent.includes('Firefox/')) {
		browser = 'Firefox';
		browserVersion = `v${userAgent.split('Firefox/')[1]}`;
	} else if (userAgent.includes('Edg/')) {
		browser = 'Edg';
		browserVersion = `v${userAgent.split('Edg/')[1]}`;
	} else if (userAgent.includes('Chrome/')) {
		browser = 'Chrome';
		browserVersion = `v${userAgent.split('Chrome/')[1]}`;
	} else if (userAgent.includes('Safari/')) {
		browser = 'Safari';
		browserVersion = `v${userAgent.split('Safari/')[1]}`;
	}

	return {
		os: navigator.userAgentData ? navigator.userAgentData.platform : userAgent,
		os_version: userAgent,
		browser,
		browser_version: browserVersion
	};
};

const BugReportSection = ({ intl, user, card, actions }) => {
	const t = intl.formatMessage;

	const [text, setText] = useState("");
	const [subject, setSubject] = useState("");
	const [attachments, setAttachments] = useState([]);
	const [error, setError] = useState(false);
	const [subjectError, setSubjectError] = useState(false);
	const [clientIP, setClientIP] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [fileSizeError, setFileSizeError] = useState(false);
	const [bugReported, setBugReported] = useState(false);
	const [bugReportId, setBugReportId] = useState('');

	const getIPData = async () => {
		const res = await Axios.get('https://geolocation-db.com/json/')
		setClientIP(res.data.IPv4)
	}

	useEffect(() => {
		getIPData()
	}, [])

	const handleTextChange = useCallback(e => {
		setText(e.target.value);
		setError(false);
	}, []);

	const handleSubjectChange = useCallback(e => {
		setSubject(e.target.value);
		setSubjectError(false);
	}, []);

	const isFieldValid = useCallback(() => {
		if (text.length) {
			return true;
		}

		setError(true);

		return false;
	}, [text]);

	const isSubjectFieldValid = useCallback(() => {
		if (subject.length && subject.length < 255) {
			return true;
		}

		setSubjectError(true);

		return false;
	}, [subject]);

	const makeReportFormData = (attachments) => {
		const osInfo = getOSInformation();
		const device = "chid: " + card.cardholderId + "\n" +
			"os: " + osInfo.os + "\n" +
			"os_version: " + osInfo.os_version + "\n" +
			"browser: " + osInfo.browser + "\n" +
			"browser_version: " + osInfo.browser_version + "\n" +
			"ip: " + clientIP + "\n" +
			"login_method: password" + "\n";

		const reportProblem = {
			email: user.email,
			subject: subject,
			description: text,
			source: "WEB",
			device: device
		};

		const reportProblemForm = new FormData();
		reportProblemForm.append("email", reportProblem.email);
		reportProblemForm.append("subject", reportProblem.subject);
		reportProblemForm.append("description", reportProblem.description);
		reportProblemForm.append("source", reportProblem.source);
		reportProblemForm.append("device", reportProblem.device);

		attachments.forEach((file, idx) => {
			reportProblemForm.append("attachments[]", attachments[idx]);
		});

		return reportProblemForm;
	}

	const onSendBugReport = useCallback((attachments) => {
		return fetcher.post(`user/report-problem`, makeReportFormData(attachments), {
			headers: {
				"Content-Type": "multipart/form-data"
			}
		}).then(response => {
			return response.data.response
		});
	}, [text, subject, user]);

	const apiErrorHandler = (errors) => {
		const allErrors = Object.values(errors).reduce((init, current) => {
			return [...init, ...current];
		}, []);

		allErrors.forEach((error) => actions.error({ title: error }));
	};

	const isFilesSizeValid = (attachments) => {
		let attachmentsSize = 0;
		let isError = false;
		attachments.forEach(a => attachmentsSize += a.size)
		const attachmentsSizeInMb = bytesToMbConverter(attachmentsSize);
		if (attachmentsSizeInMb > MAX_FILE_SIZE) {
			setFileSizeError(true);
			isError = true;
		}

		return !isError;
	}

	const onSubmit = useCallback((attachments) => {
		const isFilesValid = isFilesSizeValid(attachments);

		const isTextValid = isFieldValid();
		const isSubjectValid = isSubjectFieldValid();

		if (isTextValid && isSubjectValid && isFilesValid) {
			setIsLoading(true);
			onSendBugReport(attachments)
				.then((response) => {
					setText("");
					setSubject("");
					setAttachments([]);
					setFileSizeError(false)
					setBugReportId(response.id)
					setBugReported(true);
					actions.success({ title: M.success });
				})
				.catch(e => {
					console.error(e);
					apiErrorHandler(e.errors);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, [isFieldValid, isSubjectFieldValid]);

	const onDeleteFile = useCallback(index => {
		setFileSizeError(false)

		const tempFiles = [...attachments];
		tempFiles.splice(index, 1);

		setAttachments([...tempFiles]);
	}, [attachments]);

	const onAddFile = useCallback(e => {
		setFileSizeError(false);
		const file = Array.from(e.target.files)[0];

		const fileSizeInMb = bytesToMbConverter(file.size);

		e.target.value = null;
		if (fileSizeInMb > MAX_FILE_SIZE) {
			setFileSizeError(true);
			return;
		} else {
			setAttachments(state => [...state, file]);
		}
	}, [attachments]);

	const isFileButtonDisabled = useMemo(() => attachments.length >= 3, [attachments]);

	if (bugReported) {
		return (
			<div className="BugReportSection">
				<div className="BugReportSection__successScreen">
					<div className="BugReportSection__successIcon">
						<Icon name='success'/>
					</div>
					<div>
						{t(M.successScreen)}
						<span> {bugReportId}</span>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className='BugReportSection'>
			<Title title={t(M.title)}/>
			<input
				type="text"
				value={subject}
				onInput={handleSubjectChange}
				className="BugReportSection__subject Input__Input"
				placeholder={t(M.subjectPlaceholder)}
			/>
			<textarea
				value={text}
				onChange={handleTextChange}
				className='BugReportSection__textarea Input__Input'
				placeholder={t(M.placeholder)}
			/>

			{subjectError && (
				<span className="BugReportSection__error">
					{t(M.formSubjectError)}
				</span>
			)}

			{error && (
				<span className='BugReportSection__error'>
					{t(M.formError)}
				</span>
			)}

			{fileSizeError && (
				<span className='BugReportSection__error'>
					{t(M.fileSizeValidationError)}
				</span>
			)}

			<div className='BugReportSection__content'>
				<div className='BugReportSection__files'>
					{attachments.map((file, index) => (
						<div
							className='BugReportSection__file'
							// eslint-disable-next-line react/no-array-index-key
							key={`${index}-${file.name}`}
						>
							<span>
								{file.name}
							</span>

							<Icon
								onClick={() => onDeleteFile(index)}
								className='BugReportSection__file-icon'
								name='close-small'
							/>
						</div>
					))}
				</div>

				<div className='BugReportSection__buttons'>
					<label
						htmlFor="inputTag"
						className={cn('BugReportSection__addAttachment', {
							'BugReportSection__addAttachment--disabled': isFileButtonDisabled,
						})}
					>
						{t(M.addAttachment)}

						<input
							onChange={onAddFile}
							id="inputTag"
							type="file"
							accept=".jpg, .jpeg, .png"
							disabled={isFileButtonDisabled}
						/>
					</label>

					<Button
						onClick={() => onSubmit(attachments)}
						loading={isLoading}
					>
						{t(M.send)}
					</Button>
				</div>
			</div>
		</div>
	);
};

BugReportSection.propTypes = {
	intl: intlTypes.isRequired,
	user: PropTypes.object.isRequired,
	card: PropTypes.object,
};

const mapState = state => ({
	user: state.user,
	card: state.card.items[0],
});

const mapDispatch = dispatch => ({
	actions: {
		success: title => toastActions.success(dispatch)(title),
		error: title => toastActions.error(dispatch)(title),
	}
});


export default connect(mapState, mapDispatch)(BugReportSection);