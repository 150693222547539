import React from 'react';

const WarningIcon = props => {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24px"
			height="24px"
			fill="#d0d001"
			viewBox="0 0 24 24"
		>
			<path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
		</svg>
	);
};

export default WarningIcon;