import fetcher from "helpers/fetcher";

export default {
	create (cardHolderId, data) {
		return fetcher.put(`openbanking/${cardHolderId}/create`, data);
	},
	info (paymentId) {
		return fetcher.get(`openbanking/info/${paymentId}`);
	},
	list () {
		return fetcher.get('openbanking/list');
	}
};
