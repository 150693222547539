import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isVirtualCard, isCardExpiated } from 'services/card';
import Create from './Create';
import Status from './Status';
import { CopyIcon } from '../icons';
import { get } from '../../actions/card';
import * as toastActions from '../../actions/toast';
import copyToClipboard from '../../helpers/copyToClipboard';

import './Card.scss';

const TOAST_COPY_TITLE_ID = 'toasts.clipboard.copy.title';

const mapDispatch = dispatch => ({
	storeActions: {
		getCard: bindActionCreators(get, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
	},
});

@connect(null, mapDispatch)
class Card extends React.PureComponent {
	static propTypes = {
		card: PropTypes.shape({
			cardName: PropTypes.string,
			cardNumber: PropTypes.string,
			cardStatus: PropTypes.string,
			expirationDate: PropTypes.string
		}).isRequired,
		trunk: PropTypes.bool,
		storeActions: PropTypes.shape({
			getCard: PropTypes.func,
			toast: PropTypes.any
		}),
		onClick: PropTypes.func,
		className: PropTypes.string,
		preview: PropTypes.bool,
		showCardStatus: PropTypes.bool
	};

	state = {
		cardNumberFull: ''
	};

	componentDidMount = () => {
		const { card, storeActions } = this.props;

		storeActions.getCard(card.id).then(cardFull => {
			if (cardFull && (cardFull.cardNumberFull || cardFull.cardNumber)) {
				this.setState({ cardNumberFull: cardFull.cardNumberFull || cardFull.cardNumber });
			}
		});
	};

	onCopyIconClick = e => {
		const { cardNumberFull } = this.state;

		e.stopPropagation();

		if (cardNumberFull.length) {
			copyToClipboard(cardNumberFull);
			this.props.storeActions.toast.create({ title: { id: TOAST_COPY_TITLE_ID }, type: 'success' });
		}
	};

	onPropagation = (e, onClick) => {
		e.preventDefault();
		e.stopPropagation();

		if (typeof onClick === 'function') onClick();
	};

	render () {
		const {
			card,
			className,
			trunk,
			onClick,
			preview,
			showCardStatus = true,
		} = this.props;

		const {
			cardName,
			cardStatus,
			expirationDate,
			cardNumber,
			deliveryType
		} = card;

		const cardNumberToDisplay =
			!preview && isVirtualCard(card) && card.cardNumberFull
				? card.cardNumberFull
				: cardNumber;

		const isVirtual = isVirtualCard(card);
		const isExpiated = isCardExpiated(card);
		const isClosed = cardStatus === 'CLOSED';

		return (
			<div
				className={classnames('Card', className)}
				onClick={onClick}
				role="button"
				tabIndex={0}
			>
				<div className="Card__Content">
					<div className="Card-Header">
						{showCardStatus && (
							<div className="Card-Header-Status">
								<Status
									status={cardStatus}
									trunk={trunk}
								/>
								<Status
									status={deliveryType}
									trunk={trunk}
								/>
							</div>
						)}
					</div>

					<div className="Card__Meta">
						<div className="Card__Number">
							{isClosed ? "**** **** **** ****" : (
								<React.Fragment>
									{cardNumberToDisplay.substring(0, 4)}
									{cardNumberToDisplay.substring(4, 8)}
									{cardNumberToDisplay.substring(8, 12)}
									{cardNumberToDisplay.substring(12, 16)}
								</React.Fragment>
							)}
							{!preview && isVirtual && !isClosed && <CopyIcon onClick={this.onCopyIconClick} className="Card__Copy" />}
						</div>
						{expirationDate && (
							<div className={classnames('Card__Valid', { Card__Expiated: isExpiated })}>
								{expirationDate.substr(2)}/
								{expirationDate.substr(0, 2)}
							</div>
						)}
						<div className="Card__Name">{cardName}</div>
					</div>
				</div>
			</div>
		);
	}
}

Card.Create = Create;
Card.Status = Status;

export default Card;
