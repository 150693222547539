import React from 'react';
import { injectIntl } from 'react-intl';
import { formatDateV2 } from 'helpers/formatter';
import Modal from 'components/Modal';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Title from 'components/Title';
import { DetailsModalProps } from './types';

import M from '../Exchange.locale.json';

const DetailsModal = ({ isOpen, transaction, onClose, intl }) => {
	const t = intl.formatMessage;
	const {
		amount = null,
		confirmations = null,
		currency = null,
		description = null,
		fee = null,
		id = null,
		payeeName = null,
		payeeRequisite = null,
		payerRequisite = null,
		status = null,
		time = null,
		type = null,
		walletId = null
	} = transaction || {
		amount: null,
		confirmations: null,
		currency: null,
		description: null,
		fee: null,
		id: null,
		payeeName: null,
		payeeRequisite: null,
		payerRequisite: null,
		status: null,
		time: null,
		type: null,
		walletId: null
	};

	if (!isOpen) return null;

	return (
		<Modal onClick={onClose} className="Template__Modal__Edit">
			<Container medium>
				<Wrap>
					<Modal.Close onClick={onClose} />
					<Title title={t(M.transactions.headers.transactionDetails)} />
					<div className="ExchangeDetails__Transactions__Details">
						<table>
							{type && (
								<tr>
									<th>{t(M.transactions.details.type)}</th>
									<td>
										{type === 'FIAT'
											? t(M.transactions.details.fiat)
											: t(M.transactions.details.centralized)}
									</td>
								</tr>
							)}
							{time && (
								<tr>
									<th>{t(M.transactions.details.time)}</th>
									<td>{formatDateV2(time)}</td>
								</tr>
							)}
							{status && (
								<tr>
									<th>{t(M.transactions.details.status)}</th>
									<td>{status}</td>
								</tr>
							)}
							{id && (
								<tr>
									<th>{t(M.transactions.details.id)}</th>
									<td>{id}</td>
								</tr>
							)}
							{currency && (
								<tr>
									<th>{t(M.transactions.details.currency)}</th>
									<td>{currency}</td>
								</tr>
							)}
							{amount && (
								<tr>
									<th>{t(M.transactions.details.amount)}</th>
									<td>{amount}</td>
								</tr>
							)}
							{fee && (
								<tr>
									<th>{t(M.transactions.details.fee)}</th>
									<td>{fee}</td>
								</tr>
							)}
							{confirmations && (
								<tr>
									<th>
										{t(M.transactions.details.confirmations)}
									</th>
									<td>{confirmations}</td>
								</tr>
							)}
							{walletId && (
								<tr>
									<th>
										{t(
											M.transactions.details
												.blockExplorerId
										)}
									</th>
									<td>{walletId}</td>
								</tr>
							)}
							{description && (
								<tr>
									<th>
										{t(M.transactions.details.description)}
									</th>
									<td>{description}</td>
								</tr>
							)}
						</table>
					</div>
				</Wrap>
			</Container>
		</Modal>
	);
};

DetailsModal.propTypes = DetailsModalProps;

export default injectIntl(DetailsModal);
