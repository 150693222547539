import Create from './Create';
import Confirm from './Confirm';
import Preview from './Preview';
import Success from './Success';

export default {
	Create, 
	Confirm,
	Preview,
	Success
};
