import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as swiftTopupActions from 'actions/swiftTopup';
import * as currencyActions from 'actions/currency';
import * as cardSelector from 'selectors/card';
import * as toastActions from 'actions/toast';

import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';

import BlockingScreen from '../../screens/Blocking';

import M from './Load.locale.json';
import './Load.scss';
import './PfsWarningModal.scss';

import './SwiftNew.scss';
import Form from '../../components/Form';
import Input from '../../components/Input';
import InputSelect from '../../components/InputSelect';
import Button from '../../components/Button';
import helpers from '../../helpers';
import Loader from '../../components/Loader';
import { controls } from '../../screens/InternationalPayment/components/Beneficiary/constants';

const validationSchema = {
	amount: {
		presence: { message: '^schemas.presence' },
		format: {
			pattern: /^[0-9.]*$/,
			message: '^schemas.alphanumeric.amount'
		},
		numericality: {
			greaterThanOrEqualTo: 0.01,
			notNaN: true,
			message: '^schemas.alphanumeric.amount'
		},
	},
	currency: {
		presence: { message: '^schemas.presence' },
	},
};
const currencies = [
	{ title: 'United States Dollar (USD)', value: 'USD' },
	{ title: 'Euro (EUR)', value: 'EUR' },
	{ title: 'Pound Sterling (GBP)', value: 'GBP' },
	{ title: 'Norwegian Krone (NOK)', value: 'NOK' },
	{ title: 'Swedish Krona (SEK)', value: 'SEK' },
	{ title: 'Danish Krone (DKK)', value: 'DKK' },
	{ title: 'Polish Zloty (PLN)', value: 'PLN' },
];
const mapState = state => ({
	card: state.card,
	currentCard: cardSelector.getById(state, state.card.current)
});
const mapDispatch = dispatch => ({
	actions: {
		swiftTopup: bindActionCreators(swiftTopupActions, dispatch),
		toast: bindActionCreators(toastActions, dispatch),
		currency: bindActionCreators(currencyActions, dispatch),
	},
});

const recipient = 'VELURE FINANCE LIMITED';
const address = '32 Byron Hill Road, Harrow On The Hill, Middx, England, HA2 0HY';

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: false,
		calculateAmountLoader: false,
		paymentDetails: {},
		equivalent: null,
		form: {
			amount: '',
			currency: '',
		},
		messages: [],
		errors: {
			amount: [],
			currency: []
		},
	};

	onLostFocus = async (value, name) => {
		const { form, errors } = this.state;
		if (['currency', 'amount'].includes(name)
			&& form.amount.length && form.currency.length
			&& !errors[controls.currency].length && !errors[controls.amount].length) {
			const params = {
				amount: form.amount,
				currencyFrom: form.currency,
				currencyTo: 'EUR'
			};
			this.setState({ calculateAmountLoader: true });
			const eurEquivalent = await this.props.actions.currency.convert(params);
			this.setState({ calculateAmountLoader: false, equivalent: eurEquivalent });
			if (eurEquivalent < 40) this.props.actions.toast.create({
				title: M.swift.minEquivalent,
				type: 'error',
			});
		}
	};

	onChange = (value, name) => {
		const { form, errors } = this.state;

		if (name === 'amount' && parseFloat(value) < 0) return;

		const errorValidation = helpers.validator.single({ ...form, [name]: value }, name, validationSchema);

		this.setState({
			form: { ...form, [name]: value },
			errors: { ...errors, [name]: errorValidation },
		});
	};

	onCreate = e => {
		e.preventDefault();
		this.setState({ loader: true });
		this.props.actions.swiftTopup.create()
			.then(response => {
				this.setState({ paymentDetails: { ...response, ...this.state.form } });
			})
			.finally(() => this.setState({ loader: false }));
	}

	render () {
		const { loader, calculateAmountLoader, equivalent, form, errors, paymentDetails } = this.state;
		const { intl, currentCard } = this.props, { cardholderId } = currentCard;
		const t = intl.formatMessage;

		if (['CARD_04', 'CARD_05', 'CARD_06', 'CARD_07', 'CARD_08', 'CARD_14', 'CARD_17', 'CARD_18'].includes(currentCard.product) && currentCard.cardStatus === 'OPEN') {
			return (
				<div>
					<h3>{t(M.swift.title)}</h3>
					{paymentDetails.iban ? (
						<div>
							{t({ id: 'sections.load.swift.useCredentials' })}
							<ul>
								<li style={{ marginBottom: 5 }}><strong>IBAN:</strong> {paymentDetails.iban}</li>
								<li style={{ marginBottom: 5 }}><strong>BIC:</strong> {paymentDetails.bic}</li>
								<li style={{ marginBottom: 5 }}><strong>{t({id: 'screens.c2cpayment.create.beneficiary.inputs.amount'})}:</strong> {paymentDetails.amount} {paymentDetails.currency}</li>
								<li style={{ marginBottom: 5 }}><strong>{t({ id: 'sections.load.swift.fields.name' })}:</strong> {recipient}</li>
								<li style={{ marginBottom: 5 }}><strong>{t({ id: 'sections.load.swift.fields.address' })}:</strong> {address}</li>
								<li style={{ marginBottom: 5 }}><strong style={{ color: 'red' }}>{t({ id: 'sections.load.swift.fields.reference' })}: {cardholderId}</strong> (<u>{t({ id: 'sections.load.swift.fields.reference.hint' })}</u>)</li>
							</ul>
							<p>
								*{t({id: 'screens.swiftTopUp.hint1'})}
							</p>
							<p>
								**{t({id: 'screens.swiftTopUp.hint2'})}
							</p>
						</div>
					) : (
						<Form onSubmit={this.onCreate}>
							<div style={{ display: 'flex', gap: 10 }}>
								<Form.Group className="amountContainerGroup">
									<Input
										type="number"
										min={0}
										disabled={paymentDetails.id}
										name="amount"
										placeholder={t(M.swift.amountField)}
										onChange={this.onChange}
										onBlur={this.onLostFocus}
										value={form.amount}
										errors={errors.amount}
									/>
								</Form.Group>
								<Form.Group>
									<InputSelect
										name="currency"
										disabled={paymentDetails.id}
										placeholder={t(M.swift.currencyField)}
										onChange={this.onChange}
										value={form.currency}
										onBlur={this.onLostFocus}
										errors={errors.currency}
										data={currencies}
									/>
								</Form.Group>
							</div>
							<Form.Note>{t({id: "screens.internationalPayment.prompts.equivalent"})} EUR:{' '}
								{calculateAmountLoader ? (
									<span className="AmountLoaderContainer">
										<Loader className="AmountLoaderContainer__Loader" />
									</span>
								) : (
									<span>{equivalent || 0}</span>
								)}
							</Form.Note>
							<Button disabled={!Object.values(form).every(value => value) || equivalent === null || equivalent < 40 || calculateAmountLoader} form loading={loader} small>
								{t(M.swift.continue)}
							</Button>
						</Form>
					)}
				</div>
			);
		}
		return (
			<Section className="LoadSection" >
				<Container>
					<Wrap>
						<BlockingScreen />
					</Wrap>
				</Container>
			</Section>
		);
	}
}
