import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Banner from 'components/Banner';
import Title from 'components/Title';
import * as paymentActions from 'actions/c2cpayment';
import * as paymentSelector from 'selectors/payment';
import helpers from 'helpers';
import PaymentSchema from 'schemas/c2cpayment';
import M from './Beneficiary.locale.json';

import intlTypes from 'types/intl';

const mapState = (state, props) => ({
	payment: paymentSelector.get(state, props.match.params.id)
});

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch)
	}
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		payment: PropTypes.object,
		actions: PropTypes.shape({
			payment: PropTypes.shape({
				create: PropTypes.func.isRequired
			}).isRequired
		}).isRequired,
		history: PropTypes.shape({
			push: PropTypes.func.isRequired
		}).isRequired
	};

	state = {
		loader: false,
		form: {
			to: '',
			amount: ''
		},
		errors: {
			to: [],
			amount: []
		}
	};

	componentDidUpdate (prevProps) {
		this.onUpdate(prevProps);
	}

	componentDidMount () {
		this.onMount();
	}

	onMount () {
		if (!this.props.location.state) return;
		const { state } = this.props.location;
		if (state.payment)
			this.setState({
				form: {
					...this.state.form,
					to:
						state.payment.recipientCardNumber ||
						state.payment.to ||
						'',
					amount: parseFloat(state.payment.amount).toFixed(2) || 0
				}
			});
	}

	onUpdate (prevProps) {
		const { state } = this.props.location;
		if (!state) return;
		const payment = state.payment;

		if (
			typeof payment === 'undefined' ||
			(prevProps.location.state &&
				prevProps.location.state.payment === payment)
		)
			return;

		this.setState({
			form: {
				...this.state.form,
				to: payment.recipientCardNumber || payment.to || '',
				amount: parseFloat(payment.amount).toFixed(2) || 0
			}
		});
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single(
				{
					...form,
					[name]: value
				},
				name,
				PaymentSchema.create
			);
		}

		this.setState({
			form: {
				...form,
				[name]: value
			},
			errors: {
				...errors,
				[name]: messages
			},
			loader: false
		});
	};

	onSubmit = async e => {
		e.preventDefault();
		const { form, loader } = this.state;
		const { actions, history } = this.props;
		if (loader) return;

		const errors = helpers.validator.all(form, PaymentSchema.create);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ loader: true });

		form.currency = 'EUR';

		const response = await actions.payment.create(form);

		const update = {
			errors: { ...this.state.errors, ...response.errors },
			loader: false
		};

		if (response && response.errors && response.messages) {
			update.messages = response.messages;

			this.setState(update);

			return;
		}

		// update.form = {
		// 	to: '',
		// 	amount: '',
		// };

		this.setState(update);

		history.push(`/c2cpayments/create/${response.id}`);
	};

	render () {
		const { intl } = this.props;
		const { form, errors, loader, messages } = this.state;
		const t = intl.formatMessage;
		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="PaymentCreateScreen__Title"
				/>
				{messages && !!messages.length && (
					<Banner type="error" data={messages} />
				)}
				<Form onSubmit={this.onSubmit}>
					<Form.Group>
						<Input
							name="to"
							placeholder={t(M.inputs.to)}
							onChange={this.onChange}
							value={form.to}
							errors={errors.to}
							autoFocus
						/>
					</Form.Group>
					<Form.Group>
						<Input
							name="amount"
							placeholder={t(M.inputs.amount)}
							onChange={this.onChange}
							value={form.amount}
							unit="EUR"
							errors={errors.amount}
						/>
					</Form.Group>
					<Button
						className="PaymentCreateScreen__Submit"
						loading={loader}
						small
						form
					>
						{t(M.buttons.next)}
					</Button>
				</Form>
			</div>
		);
	}
}
