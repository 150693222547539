/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { error, success } from 'actions/toast';
import Button from 'components/Button';
import { CopyIcon } from 'components/icons';
import copyToClipboard from 'helpers/copyToClipboard';
import { formatFloatToFixed } from 'helpers/formatter';
import { DetailsProps } from './types';
import { MAPPING_CRYPTO_ICON } from '../constants';

import trc20Icon from '../assets/exchange/usdt.png';
import M from '../Exchange.locale.json';

const mapDispatchToProps = dispatch => {
	return {
		errorToast: payload => {
			error(dispatch)(payload);
		},
		successToast: payload => {
			success(dispatch)(payload);
		}
	};
};

const CurrencyDetails = ({ currency, intl, successToast }) => {
	let chartUrl = `https://chart.apis.google.com/chart?cht=qr&chs=300x300&chl=${currency.address}`;
	const history = useHistory();
	const t = intl.formatMessage;
	const onCopyIconClick = e => {
		e.stopPropagation();

		if (currency.address) {
			copyToClipboard(currency.address);
			successToast({ title: { id: 'screens.exchange.currencydetails.label4' } });
		}
	};

	const onSend = () => {
		history.push(`/exchange/send/${currency.currencyCode.toLowerCase()}`, {
			currency
		});
	};

	const onExchange = () => {
		history.push(`/exchange/swap`, { currency });
	};

	return (
		<div className="ExchangeDetails__Currency">
			<h3>
				<img
					src={
						MAPPING_CRYPTO_ICON[currency.currencyCode] ||
						MAPPING_CRYPTO_ICON.XXX
					}
					width={24}
					alt=""
				/>{' '}
				{currency.name}
			</h3>
			<h2>{formatFloatToFixed(currency.balance)}{' '}{currency.currencyCode}</h2>
			{(currency.currencyCode === 'USDT' ||
				currency.currencyCode === 'USDC') && (
				<p
					style={{
						marginTop: 8,
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'row',
						gap: 4
					}}
				>
					<img src={trc20Icon} width={24} alt="" />{' '}
					<span style={{ fontWeight: '300', color: 'red' }}>
						TRC-20
					</span>
				</p>
			)}
			{currency.address &&
				currency.address !== 'NOT AVAILABLE, CONTACT SUPPORT' && (
				<div className="ExchangeDetails__Currency-Address">
					<div>
						<img src={chartUrl} alt="" width={300} />
					</div>
					<span id="address" onClick={onCopyIconClick}>
						{currency.address} <CopyIcon />
					</span>
				</div>
			)}
			<div className="ExchangeDetails__Currency-Actions">
				<Button onClick={onSend} disabled={!currency.balance}>
					{t(M.currencyDetails.buttons.send)}
				</Button>
				<Button onClick={onExchange} disabled={!currency.balance}>
					{t(M.currencyDetails.buttons.exchange)}
				</Button>
			</div>
		</div>
	);
};

CurrencyDetails.propTypes = DetailsProps;

export default compose(
	injectIntl,
	connect(null, mapDispatchToProps)
)(CurrencyDetails);
