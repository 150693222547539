import getEnv from "helpers/getEnv";

export const gtmId = 'GTM-NFZ95F3';
export const GAId = 'UA-66844477-1';

export const GTMEvents = {
	signup: {
		eventName: "signup_completed",
		eventParameters: {
			'event_category': 'SIGNUP',
			'event_action': 'SIGNUP_STEP_1'
		}
	},
	emailVerification: {
		eventName: "email_verified",
		eventParameters: {
			'event_category': 'SIGNUP',
			'event_action': 'SIGNUP_STEP_2'
		}
	},
	regulatoryData: {
		eventName: "regulatory-data_provided",
		eventParameters: {
			'event_category': 'SIGNUP',
			'event_action': 'SIGNUP_STEP_3'
		}
	},
	phoneVerification: {
		eventName: "phone_verified",
		eventParameters: {
			'event_category': 'SIGNUP',
			'event_action': 'SIGNUP_STEP_4'
		}
	},
	topUpAgreed: {
		eventName: "top_up_agreed",
		eventParameters: {
			'event_category': 'SIGNUP',
			'event_action': 'SIGNUP_STEP_4.1'
		}
	},
	awaitingPayment: {
		eventName: "awaiting_payment",
		eventParameters: {
			'event_category': 'SIGNUP',
			'event_action': 'SIGNUP_STEP_4.2'
		}
	},
	allCompleted: {
		eventName: "all_signup_completed",
		eventParameters: {
			'event_category': 'SIGNUP',
			'event_action': 'SIGNUP_STEP_5'
		}
	},
	sepaCompleted: {
		eventName: "sepa_completed",
		eventParameters: {
			'event_category': 'PAYMENT',
			'event_action': 'SEPA_COMPLETED'
		}
	},
	c2cCompleted: {
		eventName: "c2c_completed",
		eventParameters: {
			'event_category': 'PAYMENT',
			'event_action': 'C2C_COMPLETED'
		}
	},
	ipCompleted: {
		eventName: "ip_completed",
		eventParameters: {
			'event_category': 'PAYMENT',
			'event_action': 'IP_COMPLETED'
		}
	},
	secondaryOrdered: {
		eventName: "secondary_ordered",
		eventParameters: {
			'event_category': 'SECONDARY',
			'event_action': 'SECONDARY_ORDERED'
		}
	},
	cardActivated: {
		eventName: "card_activated",
		eventParameters: {
			'event_category': 'ACTIVATION',
			'event_action': 'CARD_ACTIVATED'
		}
	},
	businessApplication: {
		eventName: "business_apply_completed",
		eventParameters: {
			'event_category': 'BUSINESS',
			'event_action': 'BUSINESS_APPLICATION'
		}
	},
	plasticCardOrdered: {
		eventName: "plastic_card_ordered",
		eventParameters: {
			'event_category': 'CARD ORDER',
			'event_action': 'PLASTIC_CARD_ORDERED'
		}
	},
	bannerLMTClicked: {
		eventName: "banner_LMT_clicked",
		eventParameters: {}
	},
};

export const firebaseConfig = {
	//   apiKey: getEnv('AIzaSyCMfv6sDszk9LXLwq00eETd8bMfyyyRPkk', 'AIzaSyC5XoOKdATo3raTS_fzO_EPLanv0i13vD0'),
	//   authDomain: getEnv('testfirebase-1c8fe.firebaseapp.com', 'weststein-app.firebaseapp.com'),
	//   databaseURL: getEnv('https://testfirebase-1c8fe.firebaseio.com', 'https://weststein-app.firebaseio.com'),
	//   projectId: getEnv('testfirebase-1c8fe', 'weststein-app'),
	//   storageBucket: getEnv('testfirebase-1c8fe.appspot.com', 'weststein-app.appspot.com'),
	//   messagingSenderId: getEnv('371847385574', '997455524520'),
	//   appId: getEnv('1:371847385574:web:54874d902e0e8d742a8627', '1:997455524520:web:b5c4d7f7287957b1d4089d'),
	//   measurementId: getEnv('G-R1XMBMS6C3', 'G-9K4T3KPZMR'),
	apiKey: "AIzaSyC5XoOKdATo3raTS_fzO_EPLanv0i13vD0",
	authDomain: "weststein-app.firebaseapp.com",
	databaseURL: "https://weststein-app.firebaseio.com",
	projectId: "weststein-app",
	storageBucket: "weststein-app.appspot.com",
	messagingSenderId: "997455524520",
	appId: "1:997455524520:web:b5c4d7f7287957b1d4089d",
	measurementId: "G-9K4T3KPZMR"
};
