import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as transactionActions from 'actions/transaction';
import * as transactionSelector from 'selectors/transaction';

import helpers from 'helpers';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';

import M from './Single.locale.json';
import './Single.scss';
import * as cardSelector from "../../selectors/card";



const mapState = (state, props) => {
	const { match: { params: { id } } } = props;

	return {
		transaction: transactionSelector.get(state, id),
		card: state.card,
		user: state.user,
		language: state.language,
		currentCard: cardSelector.getById(state, state.card.current)
	};
};

const mapDispatch = dispatch => ({
	actions: {
		transaction: bindActionCreators(transactionActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loaderPage: true,
		loader: false,
	}

	componentDidMount () {
		this.onMount();
	}

	onMount = async () => {
		const { actions, history, match } = this.props;
		const { id } = match.params;

		const response = await actions.transaction.get(id);

		if (response && response.errors) {
			history.goBack();

			return;
		}

		this.setState({
			loaderPage: false,
		});
	}

    onExport = async () => {
    	const { actions, match, history } = this.props;
    	const { id } = match.params;
    	const { locale } = this.props.language;
    	const { loader } = this.state;

    	if (loader) return;

    	this.setState({
    		loader: true,
    	});



    	const response = await actions.transaction.singlepdf(id, locale);

    	if (response && response.errors) {
    		history.goBack();

    		return;
    	}

    	const name = `transactions-${id}.pdf`;

    	helpers.downloader.file(name, 'pdf', response);

    	this.setState({
    		loader: false,
    	});
    }

	pop = () => {
		const { history } = this.props;

		history.goBack();
	}

	render () {
		const t = this.props.intl.formatMessage;
		const { loaderPage, loader } = this.state;
		const { transaction, card, currentCard } = this.props;

		const type = transaction.type;

		const parsedAmount = Math.abs(transaction.amount);

		if (loaderPage) {
			return (
				<Modal
					onClick={this.pop}
					className="HistoryEditScreen"
				>
					<Modal.Loader />
				</Modal>
			);
		}

		return (
			<Modal
				onClick={this.pop}
				className="HistorySingleScreen Screen"
			>
				<Section>
					<Container medium>
						<Wrap>
							<Modal.Close
								onClick={this.pop}
							/>
							{type == 'IN' &&
							<Title
								title={t(M.title.incoming)}
								legend={transaction.translated}
							/>
							}
							{type == 'OUT' &&
							<Title
								title={t(M.title.outgoing)}
								legend={transaction.translated}
							/>
							}

							<div className="transactionWrap">

								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.date)}
									</div>
									<div className="transactionColRight">
										{helpers.formatter('date', transaction.date)}
									</div>
								</div>
								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.customer)}
									</div>
									<div className="transactionColRight">
										{currentCard.cardName}
									</div>
								</div>
								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.iban)}
									</div>
									<div className="transactionColRight">
										{currentCard.iban}
									</div>
								</div>
								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.type)}
									</div>
									<div className="transactionColRight">
										{transaction.translated}
									</div>
								</div>
								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.beneficiary)}
									</div>
									<div className="transactionColRight">
										{transaction.beneficiary}
									</div>
								</div>
								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.amount)}
									</div>
									<div className="transactionColRight">
										{helpers.formatter('amount', parsedAmount)}
									</div>
								</div>
								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.currency)}
									</div>
									<div className="transactionColRight">
										{transaction.currency}
									</div>
								</div>
								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.details)}
									</div>
									<div className="transactionColRight">
										{transaction.details}
									</div>
								</div>
								<div className="transactionRow">
									<div className="transactionColLeft">
										{t(M.transaction.status)}
									</div>
									<div className="transactionColRight">
										{helpers.formatter('status', transaction.status)}
									</div>
								</div>
							</div>

							{transaction.status == 'SUCCESSFUL' &&
							<Button
								onClick={this.onExport}
								loading={loader}
								form
								small
							>
								{t(M.buttons.pdf)}
							</Button>
							}
						</Wrap>
					</Container>
				</Section>
			</Modal>
		);
	}
}
