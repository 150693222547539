import fetcher from 'helpers/fetcher';

export default {
	getRecurringPayments () {
		return fetcher.get(`private/template/recurring-payments`); 
	},
	getTemplatesList () {   
		return fetcher.get(`private/template/list`);  
	},
	getTemplate (id) {   
		return fetcher.get(`private/template/${id}`);  
	},
	createTemplate (template) {   
		return fetcher.put(`private/template/create-from-payment`, template);  
	},
	createCustomTemplate (template) {   
		return fetcher.put(`private/template/create`, template);  
	},
     
	updateTemplate (template) {   
		return fetcher.post(`private/template/update`, template);  
	},

	deleteTemplate (id) {   
		return fetcher.delete(`private/template/delete/${id}`);  
	},

	getAutopayment (id) {
		return fetcher.get(`private/autopayment/load?templateId=${id}`);
	},
	deleteAutopayment (id) {
		return fetcher.post(`private/autopayment/delete`, {templateId: id });
	},
	saveAutopayment (autopayment) {
		return fetcher.post(`private/autopayment/create`, autopayment);
	},
	autopaymentCardholders () {
		return fetcher.get(`private/autopayment/cardholders`);
	},
	autopaymentSms (id) {
		return fetcher.get(`private/autopayment/sms?templateId=${id}`);
	}
};