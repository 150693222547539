import React from 'react';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Section from 'components/Section';
import TopUpSuccessComponent from 'components/Exchange/TopUp/Success';

const Success = () => {
	return (
		<Layout className="ExchangeScreen Screen" sidebar>
			<Nav />
			<Header withBack />
			<Section large>
				<TopUpSuccessComponent />
			</Section>
		</Layout>
	);
};



export default Success;
