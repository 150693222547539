import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Container from 'components/Container';
import Wrap from 'components/Wrap';
import Title from 'components/Title';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Icon from 'components/Icon';

import * as uiActions from 'actions/ui';
import * as twoFactorActions from 'actions/twoFactor';
import M from './TwoFactorAlert.locale.json';
import './TwoFactorAlert.scss';

const TwoFactorAlert = ({ intl, alert, history, actions, user }) => {
	const t = intl.formatMessage;

	if (history.location.pathname !== '/') {
		return null
	}

	if (!alert) {
		return null;
	} else if(!alert.showTwoFactorAlert || user.status !== "ACTIVE") {
		return null;
	}
	

	const hideTwoFactorAlert = () => {
		actions.ui.hideTwoFactorAlert();
	};

	const turnOn = () => {
		history.push('settings/security/two-factor');
		hideTwoFactorAlert();
	};

	const closeAlert = () => {
		hideTwoFactorAlert();
	};

	const notShowAnymore = () => {
		hideTwoFactorAlert();
		actions.twoFactor.disableModalDisplay();
	};

	return (
		<Modal>
			<Section>
				<Container medium>
					<Wrap className='TwoFactorAlert'>
						<Icon className="Close" onClick={closeAlert} name="close-small" />
						<div className='Content'>
							<Title title={t(M.title)} />
							<div className='Content__info'>
								{t(M.information)}
							</div>
						</div>

						<div className='Buttons'>
							<div className='Buttons__firstSection'>
								<Button
									onClick={turnOn}
									small
									className='ui-modal__button'
								>
									{t(M.buttons.turnOn)}
								</Button>
							</div>
							<ButtonLink
								onClick={notShowAnymore}
								small
								className='ui-modal__button'
							>
								{t(M.buttons.dontShow)}
							</ButtonLink>
						</div>
					</Wrap>
				</Container>
			</Section>
		</Modal>
	);
};
const mapState = state => ({
	alert: state.ui.twoFactor,
	user: state.user
});
const mapDispatch = dispatch => ({
	actions: {
		ui: bindActionCreators(uiActions, dispatch),
		twoFactor: bindActionCreators(twoFactorActions, dispatch)
	}
});
export default injectIntl(
	withRouter(connect(mapState, mapDispatch)(TwoFactorAlert))
);
