export const cardsUrl = '/cards';

export const loadUrl = '/load';

export const ORDER_PLASTIC_CARD_THRESHOLD = 7;

export const REISSUE_ADDRESS_CHECK = {
	CONFIRM: 'CONFIRM',
	CLARIFY: 'CLARIFY',
};

export const REISSUE_MODAL_STATE = {
	CLOSE: 'CLOSE',
	SECOND: 'SECOND',
	SECOND_TIANDE: 'SECOND_TIANDE',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	ERROR_FEE: 'ERROR_FEE',
	ADDRESS: 'ADDRESS',
};

export const FEE_TYPES = {
	VIRTUAL: 'virtual',
	PLASTIC: 'plastic',
};
