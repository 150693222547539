import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button';
import * as cardActions from 'actions/card';

import { isVirtualCard, getClientType, isFundsEnoughToReissue } from 'services/card';

import { CLIENT_TYPE } from 'constants/common';

import { cardActionsTypes } from 'types/actions';
import currentCardTypes from 'types/currentCard';

import intlTypes from '../../../../types/intl';
import historyTypes from '../../../../types/history';
import { cardsUrl, REISSUE_MODAL_STATE, FEE_TYPES } from '../constants';

import './Reissue.scss';

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	}
});

@withRouter
@injectIntl
@connect(null, mapDispatch)
class Renew extends React.PureComponent {
	static propTypes = {
		card: currentCardTypes.isRequired,
		actions: PropTypes.shape({
			card: cardActionsTypes.isRequired
		}).isRequired,
		intl: intlTypes.isRequired,
		setReissueState: PropTypes.func.isRequired,
		history: historyTypes.isRequired,
	};

	state = {
		isLoading: false,
	};

	onCancel = () => {
		const {
			history
		} = this.props;

		history.push(cardsUrl);
	};

	onReissue = async () => {
		const { setReissueState, card, actions } = this.props;

		if (!isFundsEnoughToReissue(card)) {
			setReissueState(REISSUE_MODAL_STATE.ERROR_FEE);
			return;
		}

		const isVirtual = isVirtualCard(card);

		if (isVirtual) {
			this.setState({ isLoading: true });
			const response = await actions.card.reissue(card.id);
			this.setState({ isLoading: false });

			if (!response || response.errors) {
				setReissueState(REISSUE_MODAL_STATE.ERROR, response.errors);
				return;
			}

			setReissueState(REISSUE_MODAL_STATE.SUCCESS);
		} else {
			if (getClientType(this.props.card) === CLIENT_TYPE.TIANDE) {
				setReissueState(REISSUE_MODAL_STATE.SECOND_TIANDE);
				return;
			}
			
			setReissueState(REISSUE_MODAL_STATE.SECOND);
		}
	};

	render () {
		const t = this.props.intl.formatMessage;
		const { isLoading } = this.state;
		const { card } = this.props;
		const typeFee = isVirtualCard(card) ? FEE_TYPES.VIRTUAL : FEE_TYPES.PLASTIC;

		return (
			<div className="RenewScreen">
				<p className="RenewScreen-text">{t({ id: 'card.renew.text' })}</p>
				<p className="RenewScreen-fee">{t({ id: `card.renew.fee.${typeFee}` })}</p>
				<div className="RenewScreen-controls">
					<Button onClick={this.onReissue} small className="RenewScreen-button" loading={isLoading}>
						<FormattedMessage id="card.renew.button.yes" />
					</Button>
					<Button onClick={this.onCancel} small className="RenewScreen-button" disabled={isLoading}>
						<FormattedMessage id="card.renew.button.no" />
					</Button>
				</div>
			</div>
		);
	}
}

export default Renew;
