import {
	PHONE_CREATE_SUCCESS,
} from 'constants';
import { success, error } from 'actions/toast';
import API from 'services/api';
import M from './phone.locale.json';
import { GTMEvents } from "../constants/gtm";

export const get = form => async dispatch => {
	try {
		const { data: { response } } = await API.phone.get(form);
		success(dispatch)({ title: M.success.get.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const create = form => async dispatch => {
	try {
		const { data: { response } } = await API.phone.create(form);

		gtag('event', GTMEvents.phoneVerification.eventName, { ...GTMEvents.phoneVerification.eventParameters });
		gtag_report_conversion("jzujCP-Cj40YEIqw7pcD", "https://my.weststeincard.com/auth/phone");

		dispatch({ type: PHONE_CREATE_SUCCESS, payload: { ...response } });
		return response;
	} catch (err) {
		return err;
	 }
};

export const change = form => async dispatch => {
	try {
		const { data: { response } } = await API.phone.change(form);
		return response;
	} catch (err) {
		return err;
	}
};

export const confirm = (formId, token) => async dispatch => {
	try {
		const { data: { response } } = await API.phone.confirm(formId, token);
		return response;
	} catch (err) {
		return err;
	}
};

export const resend = id => async dispatch => {
	try {
		const { data: { response } } = await API.phone.resend(id);
		success(dispatch)({ title: M.success.get.title });
		return response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const getLastRequest = () => async () => {
	try {
		const { data: { response } } = await API.phone.getLastRequest();
		return response;
	} catch (error) {
		return error;
	}
};
