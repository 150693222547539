import { OPEN_TWOFACTOR_AUTH, CLOSE_TWOFACTOR_AUTH } from "constants";

const initial = {
	twoFactorScreen: false,
	user: {}
};

export default (state = initial, action) => {
	switch (action.type) {
		case OPEN_TWOFACTOR_AUTH: {
			return {
				...state,
				isOpenTwoFactorScreen: true,
				user: {
					...action.payload
				}
			};
		}
		case CLOSE_TWOFACTOR_AUTH: {
			return {
				...state,
				isOpenTwoFactorScreen: false,
				user: {}
			};
		}
		default:
			return state;
	}
};
