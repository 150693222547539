import { deliveryTypes, CLIENT_TYPE, CARD_TIANDE, CARD_PRIVATE, CARD_SMALLC } from 'constants/common';

export const isPhysicalCard = card => [null, deliveryTypes.PLASTIC].includes(card.deliveryType);
export const isVirtualCard = card => card.deliveryType === deliveryTypes.VIRTUAL;

export const isCardExpiated = card => {
	const { expirationDate } = card;
	
	if (expirationDate) {
		const cardExpDate = new Date(`20${expirationDate.slice(0, 2)}-${expirationDate.slice(2)}`);
		const currentDate = new Date();

		return currentDate > cardExpDate;
	}

	return false;
};

export const getClientType = card => {
	if (CARD_PRIVATE.includes(card.product)) {
		return CLIENT_TYPE.PRIVATE;
	} else if (CARD_TIANDE.includes(card.product)) {
		return CLIENT_TYPE.TIANDE;
	}
	else if (CARD_SMALLC.includes(card.product)) {
		return CLIENT_TYPE.SMALLC;
	}

	return CLIENT_TYPE.COMMON;	
};

export const isFundsEnoughToReissue = card => {
	if (isVirtualCard(card) && card.availBal < 1) {
		return false;
	}

	if (!isVirtualCard(card) && card.availBal < 10) {
		return false;
	}

	return true;
};
