import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Banner from 'components/Banner/Banner';
import Section from 'components/Section';
import Container from 'components/Container';
import Table from 'components/Table';
import Title from 'components/Title';
import Button from 'components/Button';
import GlobalLoaderController from 'components/GlobalLoaderController';
import { cardStatus } from 'constants/common';
import * as dashboardActions from 'actions/dashboard';
import * as cardActions from 'actions/card';
import * as notificationsActions from 'actions/notifications';
import { getEntityClientId } from 'selectors/client';
import { fetch, getSortParams } from 'selectors/dashboard';
import {
	getCurrentCard, getCardsWithoutUDFS,
	getUnverifyCards, getDropdownValuesWithoutClosedStatus
} from 'selectors/card';
import helpers from 'helpers';
import { FETCH_COUNTER_IS_ZERO_EVENT } from 'helpers/fetchCounter';
import API from 'services/api';
import intlTypes from 'types/intl';
import currentCardTypes from 'types/currentCard';
import historyTypes from 'types/history';
import { cardActionsTypes, dashboardActionsTypes, notificationsActionsTypes } from 'types/actions';
import { getIsUserVerified } from 'selectors/user';

import RegulatoryDataModal from './RegulatoryDataModal';
import VerifyCardModal from './VerifyCardModal';
import { columns } from './constants';
import M from './Dashboard.locale.json';

import './Dashboard.scss';

const documentUrl = '/settings/document';
const historyUrl = '/history/';
export const getHistorySingleUrl = id => `${historyUrl}${id}`;

const mapState = (state, props) => ({
	transactions: fetch(state, props),
	sort: getSortParams(state),
	client: { id: getEntityClientId(state) },
	currentCard: getCurrentCard(state),
	cardsWithoutUDFS: getCardsWithoutUDFS(state),
	unverifiedCards: getUnverifyCards(state),
	isUserVerified: getIsUserVerified(state),
	dropdown: getDropdownValuesWithoutClosedStatus(state),
});

const mapDispatch = dispatch => ({
	actions: {
		dashboard: bindActionCreators(dashboardActions, dispatch),
		card: bindActionCreators(cardActions, dispatch),
		notifications: bindActionCreators(notificationsActions, dispatch)
	},
});

@withRouter
@injectIntl
@connect(mapState, mapDispatch)
export default class DashboardSection extends React.PureComponent {
	static propTypes = {
		actions: PropTypes.shape({
			dashboard: dashboardActionsTypes.isRequired,
			card: cardActionsTypes.isRequired,
		}).isRequired,
		currentCard: currentCardTypes,
		history: historyTypes.isRequired,
		intl: intlTypes.isRequired,
		sort: PropTypes.shape({
			id: PropTypes.string.isRequired,
			asc: PropTypes.bool.isRequired,
		}).isRequired,
		client: PropTypes.shape({
			id: PropTypes.number,
		}).isRequired,
		transactions: PropTypes.array,
		cardsWithoutUDFS: PropTypes.arrayOf(currentCardTypes),
		unverifiedCards: PropTypes.arrayOf(currentCardTypes),
		isUserVerified: PropTypes.bool
	}

	state = {
		verifyCardModal: false,
		isCardVerificationSkipped: false,
		isOpenRegulatoryDataModal: false,
		regulatoryDataUploaded: false,
		messages: [],
		tableLoading: false,
	}

	componentDidMount () {
		this.mounted = true;
		this.onFetch();
		GlobalLoaderController.show();
		document.addEventListener(FETCH_COUNTER_IS_ZERO_EVENT, this.firstFetched);

	}

	componentDidUpdate (prevProps) {
		const { client, currentCard } = this.props;
		this.isFirstFetched && this.checkUDFSAndVerified();
		const sameClient = prevProps.client.id === client.id;
		const sameCard = prevProps.currentCard && currentCard
			&& prevProps.currentCard.id === currentCard.id;

		if (sameClient && sameCard) return;

		if (sameCard !== undefined) {
			this.onFetch();
		}

	}

	componentWillUnmount () {
		this.mounted = false;
		document.removeEventListener(FETCH_COUNTER_IS_ZERO_EVENT, this.firstFetched);
	}

	onFetch = async () => {
		const { actions, currentCard } = this.props;
		await actions.card.fetch();
		const cardId = currentCard ? currentCard.id : '';
		await actions.notifications.getCount();
		await actions.notifications.startTracking();
	}

	getTransactions = async actions => {
		this.setState({ tableLoading: true });
		const params = {
			size: 10,
			page: 0,
			start: moment(new Date()).add(-31, 'day').format('DD-MM-YYYY'),
			end: moment(new Date()).format('DD-MM-YYYY'),
			type: 'ALL',
		};
		const result = await actions.dashboard.fetch(params);
		this.setState({ tableLoading: false });
		this.mounted && this.setState({ messages: result.messages });
	}


	onSort = async column => {
		const { actions, sort } = this.props;
		await actions.dashboard.sort({
			id: column.id,
			asc: (sort.id === column.id ? !sort.asc : true)
		});
	}





	checkUDFSAndVerified = () => {
		const { cardsWithoutUDFS, unverifiedCards, isUserVerified } = this.props;

		if (!cardsWithoutUDFS && !unverifiedCards) return;

		if (cardsWithoutUDFS && cardsWithoutUDFS.length) {
			this.setState({
				isOpenRegulatoryDataModal: true,
				verifyCardModal: false,
			});

			return;
		}

		this.setState({
			isOpenRegulatoryDataModal: false,
			verifyCardModal: !isUserVerified,
		});
	}

	firstFetched = () => {
		this.isFirstFetched = true;
		this.checkUDFSAndVerified();
	}




	onSingle = item => {
		const { history } = this.props;
		history.push(getHistorySingleUrl(item.id), { modal: true });
	}

	onMore = () => this.props.history.push(historyUrl)

	onVerifyCardSkip = () => this.setState({ isCardVerificationSkipped: true })

	onVerifyCard = () => this.props.history.push(documentUrl)

	renderHeader = () => {
		const { intl } = this.props;
		const t = intl.formatMessage;
		return columns.map(item => ({
			...item,
			title: t(item.title),
		}));
	}

	renderRow = row => (
		<Table.Row key={row.id} onClick={() => this.onSingle(row)}>
			{columns.map(item => (
				<Table.Cell key={item.id}>
					{helpers.formatter(item.id, row[item.id])}
				</Table.Cell>
			))}
		</Table.Row>
	)

	onSave = form => {
		const { actions } = this.props;

		actions.card.setNewCardData({ udfs: form })
			.then(() => this.setState({ regulatoryDataUploaded: true }));
	}

	onNextCard = () => {
		const { currentCard, cardsWithoutUDFS, actions } = this.props;
		const { id } = currentCard;
		const index = cardsWithoutUDFS.findIndex(card => card.id === id);
		const nextIndex = !isNaN(String(index)) ? index + 1 : 0;

		if (cardsWithoutUDFS.length <= nextIndex) {
			actions.card.current(cardsWithoutUDFS[0].id);
		} else {
			actions.card.current(cardsWithoutUDFS[nextIndex].id);
		}
	}

	getTableDataButton = (t, actions) => {
		return (
			<Button
				small
				onClick={() => this.getTransactions(actions)}
			>
				{t(M.buttons.loadRecentTransactions)}
			</Button>
		);
	}

	render () {
		const {
			verifyCardModal,
			messages,
			isOpenRegulatoryDataModal,
			isCardVerificationSkipped,
			regulatoryDataUploaded,
			tableLoading
		} = this.state;
		const { transactions, sort, intl, currentCard, cardsWithoutUDFS, dropdown, actions } = this.props;
		const t = intl.formatMessage;
		const isNextButton = cardsWithoutUDFS.length > 1 ||
			(cardsWithoutUDFS.length === 1 && currentCard.id !== cardsWithoutUDFS[0].id);
		return (
			<Section className="DashboardSection">
				<GlobalLoaderController />
				<Container>
					<Title title={t(M.title)} />
					{transactions.length > 0 && (
						<Button
							small
							className="DashboardSection__ReloadTransaction"
							onClick={() => this.getTransactions(actions)}
						>
							{t(M.buttons.reloadRecentTransactions)}
						</Button>
					)}
					{messages && !!messages.length && <Banner type="error" data={messages} />}
					<Table
						items={transactions}
						sort={sort}
						renderHeader={this.renderHeader}
						renderRow={this.renderRow}
						className="DashboardSection__Table"
						loading={tableLoading}
						empty={this.getTableDataButton(t, actions)}
					/>
				</Container>
				{!isCardVerificationSkipped && verifyCardModal && (
					<VerifyCardModal
						t={t}
						onSkip={this.onVerifyCardSkip}
						onVerify={this.onVerifyCard}
					/>
				)}
				{(isOpenRegulatoryDataModal && !regulatoryDataUploaded) && (
					<RegulatoryDataModal
						dropdown={dropdown}
						isNextButton={isNextButton}
						onNextCard={this.onNextCard}
						onSave={this.onSave}
						disabled={currentCard.currentCard === cardStatus.CLOSED || !!currentCard.updating}
						loading={!!currentCard.updating}
						values={currentCard.udfs}
					/>
				)}
			</Section>
		);
	}
}
