import API from 'services/api';
import { success, error } from 'actions/toast';
import M from './swiftTopup.locale.json';

export const create = () => async dispatch => {
	try {
		const { data: { response } } = await API.swiftTopup.create();
		return response;
	} catch (e) {
		if (e.errors) {
			let msg = '';
			const firstErrorKey = Object.keys(e.errors)[0];
			switch (e.errors[firstErrorKey][0].id) {
				case 'pfs.error.connection':
					msg = M.errors.connection;
					break;
				default:
					msg = M.errors.wrong;
			}
			error(dispatch)({ title: msg });
		} else error(dispatch)({ title: M.errors.wrong });
		throw e;
	}
};
