import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Section from 'components/Section';
import ExchangePreviewComponent from 'components/Exchange/Exchange/Preview';

const Preview = ({ location, history }) => {
	const data = location.state.data || {};
	if (!data || !data.exchangeId) {
		history.replace('/exchange');
		return <div />;
	} else {
		return (
			<Layout className="ExchangeScreen Screen" sidebar>
				<Nav />
				<Header withBack />
				<Section large>
					<ExchangePreviewComponent data={data} />
				</Section>
			</Layout>
		);
	}
};

Preview.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

export default Preview;
