import React, { Component } from 'react';
import Title from 'components/Title';
import InputToggle from 'components/InputToggleSwitch';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import helpers from 'helpers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import  { timeDifferenceMinutes }  from 'helpers/timeDifference';

import * as countryActions from 'actions/country';
import * as twoFactorActions from 'actions/twoFactor';
import { SuccessIcon } from 'components/icons';
import QuestionModalApp from './QuestionModalApp';

import M from './TwoFactorAuthenticationApp.locale.json';

import './TwoFactorAuthenticationApp.scss';

class TwoFactorAuthenticationApp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loader: false,
			isSuccessfullChange: false,
			isChecked: !!props.user.twoFactor,
			isEstablish: false,
			form: {
				code: ''
			},
			errors: {
				code: []
			}
		};
	}

	componentDidMount() {
		this.onMount();
	}

	componentWillUnmount() {
	}

	componentDidUpdate() {
	}

	onMount = () => {
	};

	async onChangeChecked(checked) {
		if (checked) {
			this.setState({ isEstablish: checked });
		} else {
			const { actions } = this.props;
			await actions.twoFactor.disableTwoFactor({ code:'' });
			this.setState({ isChecked: false });
		}

	};

	async onSuccess() {
		const { actions } = this.props;
		await actions.twoFactor.enableTwoFactor({code: this.props.user.twoFactorSecret });
		this.setState({isEstablish: false, isChecked: true, isSuccessfullChange: true});
	}

	render() {
		const t = this.props.intl.formatMessage;
		const {
			isChecked,
			isEstablish,
			form,
			errors,
			loader,
			isSuccessfullChange,
		} = this.state;
	
		return (
			<div className='TwoFactor'>
				<div className='TwoFactor__Content'>
					{!isSuccessfullChange && (
						<Title title={`${t(M.information.title)}`} />
					)}
						<div>
							<div className='TwoFactor__ToggleSwitch'>
								<p className='TwoFactor__Title'>
									{t(M.information.title)}
								</p>
								<InputToggle
									checked={isChecked}
									onChange={this.onChangeChecked.bind(this)}
									id='ToggleSwitch'
								/>
							</div>
							<p className='TwoFactor__Text'>
								{t(M.information.firstText)}
							</p>
							<p className='TwoFactor__Question'>
								{t(M.information.question)}
							</p>
							<p className='TwoFactor__Text'>
								{t(M.information.answer)}
							</p>
						</div>
				</div>

				{isEstablish && (
					<QuestionModalApp
						intl={this.props.intl}
						user={this.props.user}
						onSuccess={this.onSuccess.bind(this)}
						onCancel={()=>{this.setState({isEstablish: false, isChecked: false});}}
					/>
				)}
				{isSuccessfullChange && (
					<div className='TwoFactor__Success'>
						<SuccessIcon width='100px' height='100px' />
						<p>{`${t(M.success.information)}`}</p>
					</div>
				)}
			</div>
		);
	}
}

const mapState = (state, props) => ({
	user: state.user,
	phoneCountry: state.country
});

const mapDispatch = dispatch => ({
	actions: {
		country: bindActionCreators(countryActions, dispatch),
		twoFactor: bindActionCreators(twoFactorActions, dispatch)
	}
});

export default connect(
	mapState,
	mapDispatch
)(withRouter(injectIntl(TwoFactorAuthenticationApp)));
