import M from './Setting.locale.json';

export const nav = [
	{
		id: 'account',
		title: M.nav.account,
		icon: 'account',
	},
	{
		id: 'interface',
		title: M.nav.interface,
		icon: 'interface',
	},
	{
		id: 'phone',
		title: M.nav.phone,
		icon: 'phone',
	},
	{
		id: 'password',
		title: M.nav.password,
		icon: 'password',
	},
	{
		id: 'document',
		title: M.nav.document,
		icon: 'id-tag',
	},
	{
		id: 'applications',
		title: M.nav.applications,
		icon: 'applications',
	},
	{
		id: 'regulatoryData',
		title: M.nav.regulatoryData,
		icon: 'personal-task',
	}/*,
	{
		id: 'referralLink',
		title: M.nav.referralLink,
		icon: 'contact',
	}*/,
	{
		id: 'security',
		title: M.nav.security,
		icon: 'security',
	},
	{
		id: 'bug-report',
		title: M.nav.bugReport,
		icon: 'bug-report',
	}
];
