// PLACE
export const PLACE_AUTOCOMPLETE_SUCCESS = 'PLACE_AUTOCOMPLETE_SUCCESS';
export const PLACE_GEOCODE_SUCCESS = 'PLACE_GEOCODE_SUCCESS';


// AUTH
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_EMAIL_SUCCESS = 'AUTH_EMAIL_SUCCESS';

// TWO FACTOR AUTH
export const  OPEN_TWOFACTOR_AUTH = 'OPEN_TWOFACTOR_AUTH'
export const  CLOSE_TWOFACTOR_AUTH = 'CLOSE_TWOFACTOR_AUTH'

// TWO FACTOR SETTINGS
export const CHANGE_TWOFACTOR = 'CHANGE_TWOFACTOR'

// TWO FACTOR MODAL
export const NOT_SHOW_TWOFACTOR_MODAL = 'NOT_SHOW_TWOFACTOR_MODAL'

// PASSWORD
export const PASSWORD_SET_SUCCESS = 'PASSWORD_SET_SUCCESS';


// PHONE
export const PHONE_CREATE_SUCCESS = 'PHONE_CREATE_SUCCESS';

//PAYMENT-REGISTRATION
export const SET_REGISTRATION_PAYMENT = 'SET_REGISTRATION_PAYMENT';

// DASHBOARD
export const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS';
export const DASHBOARD_GET_SUCCESS = 'DASHBOARD_GET_SUCCESS';
export const DASHBOARD_SET_SORT = 'DASHBOARD_SET_SORT';


// TRANSACTIONS
export const TRANSACTION_FETCH_REQUEST = 'TRANSACTION_FETCH_REQUEST';
export const TRANSACTION_FETCH_SUCCESS = 'TRANSACTION_FETCH_SUCCESS';
export const TRANSACTION_GET_SUCCESS = 'TRANSACTION_GET_SUCCESS';
export const TRANSACTION_SET_SEARCH = 'TRANSACTION_SET_SEARCH';
export const TRANSACTION_SET_TYPES = 'TRANSACTION_SET_TYPES';
export const TRANSACTION_SET_COLUMNS = 'TRANSACTION_SET_COLUMNS';
export const TRANSACTION_SET_STATUS = 'TRANSACTION_SET_STATUS';
export const TRANSACTION_SET_SORT = 'TRANSACTION_SET_SORT';


// PAYMENTS
export const PAYMENT_FETCH_SUCCESS = 'PAYMENT_FETCH_SUCCESS';
export const PAYMENT_GET_SUCCESS = 'PAYMENT_GET_SUCCESS';
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS';
export const PAYMENT_AMOUNT_SUCCESS = 'PAYMENT_AMOUNT_SUCCESS';
export const PAYMENT_AMOUNT_ERROR = 'PAYMENT_AMOUNT_ERROR';
export const PAYMENT_PHONE_SUCCESS = 'PAYMENT_PHONE_SUCCESS';
export const PAYMENT_PHONE_ERROR = 'PAYMENT_PHONE_ERROR';
export const PAYMENT_SET_SEARCH = 'PAYMENT_SET_SEARCH';
export const PAYMENT_SET_COLUMNS = 'PAYMENT_SET_COLUMNS';
export const PAYMENT_SET_STATUS = 'PAYMENT_SET_STATUS';
export const PAYMENT_SET_SORT = 'PAYMENT_SET_SORT';


// MEMBER
export const MEMBER_FETCH_SUCCESS = 'MEMBER_FETCH_SUCCESS';
export const MEMBER_GET_SUCCESS = 'MEMBER_GET_SUCCESS';
export const MEMBER_CREATE_SUCCESS = 'MEMBER_CREATE_SUCCESS';
export const MEMBER_EDIT_SUCCESS = 'MEMBER_EDIT_SUCCESS';
export const MEMBER_SET_ACCESS = 'MEMBER_SET_ACCESS';
export const MEMBER_SET_SEARCH = 'MEMBER_SET_SEARCH';
export const MEMBER_SET_STATUS = 'MEMBER_SET_STATUS';


// CARD
export const CARD_FETCH_REQUEST = 'CARD_FETCH_REQUEST';
export const CARD_FETCH_SUCCESS = 'CARD_FETCH_SUCCESS';
export const CARD_GET_SUCCESS = 'CARD_GET_SUCCESS';
export const CARD_CREATE_SUCCESS = 'CARD_CREATE_SUCCESS';
export const CARD_SET_STATUS = 'CARD_SET_STATUS';
export const CARD_SET_SEARCH = 'CARD_SET_SEARCH';
export const CARD_CURRENT = 'CARD_CURRENT';
export const CARD_SET_REGULATORY_DATA_COLLECTION = 'CARD_SET_REGULATORY_DATA_COLLECTION';
export const CARD_REMOVE_REGULATORY_DATA_COLLECTION = 'CARD_REMOVE_REGULATORY_DATA_COLLECTION';
export const CARD_SET_NEW_DATA_REQUEST = 'CARD_SET_NEW_DATA_REQUEST';
export const CARD_SET_NEW_DATA_SUCCESS = 'CARD_SET_NEW_DATA_SUCCESS';
export const CARD_SET_NEW_DATA_ERROR = 'CARD_SET_NEW_DATA_ERROR';
export const CARD_GET_CVV_REQUEST = 'CARD_GET_CVV_REQUEST';
export const CARD_GET_CVV_SUCCESS = 'CARD_GET_CVV_SUCCESS';
export const CARD_GET_PIN_REQUEST = 'CARD_GET_PIN_REQUEST';
export const CARD_GET_PIN_SUCCESS = 'CARD_GET_PIN_SUCCESS';
export const CARD_UPDATE_TO_PLASTIC_REQUEST = 'CARD_UPDATE_TO_PLASTIC_REQUEST';
export const CARD_UPDATE_TO_PLASTIC_SUCCESS = 'CARD_UPDATE_TO_PLASTIC_SUCCESS';


// CLIENT BUSINESS
export const CLIENT_FETCH_BUSINESS_SUCCESS = 'CLIENT_FETCH_BUSINESS_SUCCESS';
export const CLIENT_CREATE_BUSINESS_SUCCESS = 'CLIENT_CREATE_BUSINESS_SUCCESS';
export const CLIENT_BUSINESS_NAV_SUCCESS = 'CLIENT_BUSINESS_NAV_SUCCESS';
export const CLIENT_CREATE_BUSINESS_CONTACT_SUCCESS = 'CLIENT_CREATE_BUSINESS_CONTACT_SUCCESS';
export const CLIENT_EDIT_BUSINESS_SUCCESS = 'CLIENT_EDIT_BUSINESS_SUCCESS';
export const CLIENT_GET_BUSINESS_SUCCESS = 'CLIENT_GET_BUSINESS_SUCCESS';
export const CLIENT_BUSINESS_FORM = 'CLIENT_BUSINESS_FORM';


// CLIENT PRIVATE
export const CLIENT_CREATE_PRIVATE_SUCCESS = 'CLIENT_CREATE_PRIVATE_SUCCESS';
export const CLIENT_CREATE_PRIVATE_ERROR = 'CLIENT_CREATE_PRIVATE_ERROR';
export const CLIENT_GET_ADDRESS_SUCCESS = 'CLIENT_GET_ADDRESS_SUCCESS';


// CLIENT
export const CLIENT_CURRENT = 'CLIENT_CURRENT';


// SHAREHOLDER
export const SHAREHOLDER_FETCH_SUCCESS = 'SHAREHOLDER_FETCH_SUCCESS';
export const SHAREHOLDER_CREATE_SUCCESS = 'SHAREHOLDER_CREATE_SUCCESS';
export const SHAREHOLDER_EDIT_SUCCESS = 'SHAREHOLDER_EDIT_SUCCESS';
export const SHAREHOLDER_REMOVE_REQUEST = 'SHAREHOLDER_REMOVE_REQUEST';
export const SHAREHOLDER_REMOVE_SUCCESS = 'SHAREHOLDER_REMOVE_SUCCESS';


// DOCUMENT
export const DOCUMENT_FETCH_SUCCESS = 'DOCUMENT_FETCH_SUCCESS';
export const DOCUMENT_CREATE_SUCCESS = 'DOCUMENT_CREATE_SUCCESS';
export const DOCUMENT_REMOVE_REQUEST = 'DOCUMENT_REMOVE_REQUEST';
export const DOCUMENT_REMOVE_SUCCESS = 'DOCUMENT_REMOVE_SUCCESS';


// AVATAR
export const AVATAR_CREATE_SUCCESS = 'AVATAR_CREATE_SUCCESS';
export const AVATAR_REMOVE_SUCCESS = 'AVATAR_REMOVE_SUCCESS';


// LANGUAGE
export const LANGUAGE_GET_SUCCESS = 'LANGUAGE_GET_SUCCESS';
export const LANGUAGE_EDIT_SUCCESS = 'LANGUAGE_EDIT_SUCCESS';


// COUNTRY
export const COUNTRY_FETCH_SUCCESS = 'COUNTRY_FETCH_SUCCESS';

// CURRENCY
export const CURRENCY_FETCH_SUCCESS = 'CURRENCY_FETCH_SUCCESS';


// TOAST
export const TOAST_CREATE = 'TOAST_CREATE';
export const TOAST_REMOVE = 'TOAST_REMOVE';
export const TOAST_HIDE = 'TOAST_HIDE';


// SESSION
export const SESSION_MODAL = 'SESSION_MODAL';
export const SESSION_EXTEND_SUCCESS = 'SESSION_EXTEND_SUCCESS';


// BANKINFO
export const BANK_GET_SUCCESS = 'BANK_GET_SUCCESS';


// MAINTENANCE MODAL
export const TOGGLE_MAINTENANCE_MODAL = 'TOGGLE_MAINTENANCE_MODAL';

// PFS PAYMENTS
export const PERFORM_PAYMENT = 'PERFORM_PAYMENT';

export const SET_LINKED_CARDS = 'SET_LINKED_CARDS';

// NOTIFICATIONS
export const NOTIFICATIONS_SET = 'NOTIFICATIONS_SET';
export const NOTIFICATIONS_COUNTER_SET = 'NOTIFICATIONS_COUNTER_SET';
export const NOTIFICATIONS_LOCK = 'NOTIFICATIONS_LOCK';
export const NOTIFICATIONS_UNLOCK = 'NOTIFICATIONS_UNLOCK';
export const NOTIFICATIONS_TYPE_SUCCESS = 'SUCCESS';
export const NOTIFICATIONS_TYPE_WARNING = 'WARNING';
export const NOTIFICATIONS_TYPE_ERROR = 'ERROR';
export const NOTIFICATIONS_REQUEST_INTERVAL = 30000;

// UI
export const SHOW_UI_ALERT = 'SHOW_UI_ALERT';
export const HIDE_UI_ALERT = 'HIDE_UI_ALERT';
export const SHOW_TWOFACTOR_ALERT = 'SHOW_TWOFACTOR_ALERT';
export const HIDE_TWOFACTOR_ALERT = 'HIDE_TWOFACTOR_ALERT';

export * from './build';

//token lifetime in minutes
export const TOKEN_LIFE_TIME = 598;

//  status manual verification
export const ACCOUNT_REVIEW = 'ACCOUNT_REVIEW';
export const ACCOUNT_REVIEW_DECLINE = 'ACCOUNT_REVIEW_DECLINE';
export const ACCOUNT_REVIEW_SUCCESS = 'ACCOUNT_REVIEW_SUCCESS';

// user status
export const  STATUS__ACTIVE = 'STATUS__ACTIVE';

export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.weststeincard.weststein';
export const APP_STORE_LINK = 'https://itunes.apple.com/us/app/weststein/id1131192886?ls=1&mt=8';

export const JUMIO_LINK_SUCCESS = 'JUMIO_LINK_SUCCESS';
export const JUMIO_VERIFICATION_STATUS = 'JUMIO_VERIFICATION_STATUS';

export const JUMIO_VERIFICATIONS__STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  IN_PROCESS: 'in_process'
};

export const IS_UPLOAD_DOCUMENTS = 'IS_UPLOAD_DOCUMENTS';

export const SET_MODALS_INFO = 'SET_MODAL_INFO';
export const CHANGE_MODAL_INFO = 'CHANGE_MODAL_INFO';

export const SHOW_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE = 'SHOW_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE';
export const HIDE_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE = 'HIDE_IS_INTENTIONAL_PAYMENT_MODAL_VISIBLE';

export const SEPA_WINDOW_TYPE = 'SEPA_WINDOW';

export const PAYMENT_FIELDS = ['bic', 'iban'];

export const STATUSES_UPLOAD_DOCUMENTS = [JUMIO_VERIFICATIONS__STATUS.SUCCESS, JUMIO_VERIFICATIONS__STATUS.FAILED];

const DISABLED_MINUTES_JIMIO_VERIFICATION = 30;

export const START_JUMIO_VERIFICATION_DATA = {
  uuid: null,
  minutes: DISABLED_MINUTES_JIMIO_VERIFICATION,
  seconds: 0
};

export const ENVIRONMENT = {
  production: 'production',
  development: 'development',
  demo: 'demo',
  local: 'local'
}
