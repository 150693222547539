import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Title from 'components/Title';
import Container from 'components/Container';
import Button from 'components/Button';
import { error } from 'actions/toast';
import * as exchangeActions from 'actions/exchange';
import { ExchangeCurrencyPreviewProps } from '../types';
import M from '../../Exchange.locale.json';

const mapDispatchToProps = dispatch => {
	return {
		errorToast: payload => {
			error(dispatch)(payload);
		}
	};
};

const ExchangeCurrencyPreview = ({ data, intl, ...props }) => {
	const history = useHistory();
	const t = intl.formatMessage;
	const {
		exchangeId,
		amount,
		rate,
		sourceCurrencyCode,
		targetCurrencyCode
	} = data;

	const onSubmit = async e => {
		e.preventDefault();

		try {
			const { success } = await exchangeActions.confirmExchange(exchangeId);

			if (success) {
				history.push(`/exchange/swap/success`);
			}
		} catch ({ errors }) {
			for (const error in errors) {
				if (Object.hasOwnProperty.call(errors, error)) {
					props.errorToast({
						title: {
							...errors[error][0],
							id: errors[error][0].id || 'undefinedError'
						}
					});
				}
			}
		}
	};

	return (
		<div className="ExchangeSend__Preview">
			<Container small>
				<Title title={t(M.sendPreview.title)} />
				<div className="ExchangeSend__Preview__Table">
					<table>
						<tr>
							<th>{t(M.exchangePreview.fields.from)}</th>
							<td>
								{sourceCurrencyCode !== 'EUR'
									? amount
										.toString()
										.substring(
											0,
											amount.toString().indexOf('.') +
													8
										)
									: Number(amount).toFixed(2)}{' '}
								{sourceCurrencyCode}
							</td>
						</tr>
						<tr>
							<th>{t(M.exchangePreview.fields.to)}</th>
							<td>
								{targetCurrencyCode !== 'EUR'
									? amount * rate
									: (amount * rate).toFixed(2)}{' '}
								{targetCurrencyCode}
							</td>
						</tr>
						<tr>
							<th>{t(M.exchangePreview.fields.fee)}</th>
							<td>0.00</td>
						</tr>
						<tr>
							<th>{t(M.exchangePreview.fields.rate)}</th>
							<td>{rate}</td>
						</tr>
					</table>
				</div>
				<Button onClick={onSubmit}>
					{t(M.exchangePreview.buttons.next)}
				</Button>
			</Container>
		</div>
	);
};

ExchangeCurrencyPreview.propTypes = ExchangeCurrencyPreviewProps;

export default compose(
	injectIntl,
	connect(null, mapDispatchToProps)
)(ExchangeCurrencyPreview);
