import React, { useEffect, useCallback, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl'
import * as jumioActions from 'actions/jumio';
import { success, error } from 'actions/toast';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Timer from 'components/Timer';
import { JUMIO_VERIFICATIONS__STATUS } from 'constants';
import BlockingScreen from '../../Blocking';

import M from './JumioVerification.locale.json';
import { startVerification } from '../../../actions/jumio';

const TRY_AGAIN_DELAY = 1800;

const VerificationStatusText = {
  [JUMIO_VERIFICATIONS__STATUS.IN_PROCESS]: M.inprogress,
  [JUMIO_VERIFICATIONS__STATUS.SUCCESS]: M.success,
  [JUMIO_VERIFICATIONS__STATUS.FAILED]: M.failed
};

const CARD_PRODUCT_NOT_VERIFICATION = ['CARD_04', 'CARD_05', 'CARD_06'];

const JumioVerification = ({ actions, cards, status, intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [splashScreenIsShown, setSplashScreenShown] = useState(false)
  const [isMount, setMount] = useState(false)
  const [countdown, setCountdown] = useState(0);
  const showTimer = countdown > 0 && isMount;

  const dispatch = useDispatch();

  if (!cards.items.length) {
    return (
      <div className="SettingScreen__Document">
        {
          !isLoading &&
          <BlockingScreen />
        }
      </div>);
  }

  useEffect(() => {
    actions.jumio.getVerificationStatus(cards.items[0].cardholderId).then(()=> setMount(true));
  }, []);

  const t = intl.formatMessage;
  const textStatus = status && VerificationStatusText[status];
  const verificatioIsSuccess = status === JUMIO_VERIFICATIONS__STATUS.SUCCESS;
  const verificatioIsFailed = status === JUMIO_VERIFICATIONS__STATUS.FAILED;
  const verificatioIsProgress = status === JUMIO_VERIFICATIONS__STATUS.IN_PROCESS;
  const isDisabledButton = verificatioIsProgress || verificatioIsSuccess;

  const triggerSplashScreen = async (link) => {
	const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

	setSplashScreenShown(true);
    setCountdown(TRY_AGAIN_DELAY);
	sleep(2500).then(() => {
	  setSplashScreenShown(false)
	  const win = window.open(link, "_blank");
	  win.focus();
	});
  }

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    try {
      const { uuid, minutes, seconds } = await actions.jumio.getLinkVerification();
      if (uuid) {
        gtag_report_conversion('zs_oCPOT6ogYEIqw7pcD', uuid);
        await triggerSplashScreen(uuid);
      } else {
        setCountdown(minutes * 60 + seconds);
      }
    } catch (e) {
		if (e.errors) {
			let msg = '';
			const firstErrorKey = Object.keys(e.errors)[0], errorId = e.errors[firstErrorKey][0].id;
			if (errorId && errorId === 'screens.cards.verify.errors.status') {
				error(dispatch)({ title: M.closedCards });
			}
		}
      return e;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <div className='SettingScreen__Jumio'>
      <div className='VerificationBox'>
        <Icon name='verify' className={`Icon ${verificatioIsSuccess && 'success' || verificatioIsFailed && 'warning'} `} />
        {verificatioIsSuccess && <Icon name='success' className='IconStatus success' />}
        {verificatioIsFailed && <Icon name='warning' className='IconStatus warning' />}
      </div>
      {!verificatioIsSuccess &&
        <Button
          type='a'
          // target='_blank'
          // href={linkInformation.uuid}
          disabled={isDisabledButton}
          loading={isLoading}
          className='ButtonVerification'
          secondary
          onClick={handleClick}
        >
          {t(M.start)}
        </Button>}
      {status && textStatus && <p className='description'>{t(textStatus)}</p>}
      {showTimer && <p className='timerMessage'>{t(M.timerMessage)} <Timer className='timerMessage' showMinutes time={countdown} /> {t(M.minutes)}</p>}
	  {splashScreenIsShown &&
		<div className='SettingScreen__Loading'>
		  <p className='loading-text'>
			{t(M.loading)}
		  </p>
		</div>
	  }
    </div>)
}

const mapState = state => ({
  cards: state.card,
  status: state.document.jumioStatus,
});

const mapDispatch = dispatch => ({
  actions: {
    jumio: bindActionCreators(jumioActions, dispatch),
  }
});

export default injectIntl(connect(mapState, mapDispatch)(JumioVerification));
