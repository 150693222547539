import React from 'react';
import { FormattedMessage } from 'react-intl';
import declinedIcon from '../assets/exchange/exchDeclined.png';

const Failed = (
	<div className="ExchangeBlocking">
		<FormattedMessage tagName="h1"  id={'screens.exchange.accountdeclined.label1'} />
		<img src={declinedIcon} alt='' />
		<FormattedMessage tagName="p" id={'screens.exchange.accountdeclined.label2'} />
	</div>
);

export default Failed;
