import React from 'react';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';
import Section from 'components/Section';
import ExchangeTopUpComponent from 'components/Exchange/TopUp';

const TopUp = () => {
	return (
		<Layout className="ExchangeScreen Screen" sidebar>
			<Nav />
			<Header withBack />
			<Section large>
				<ExchangeTopUpComponent />
			</Section>
		</Layout>
	);
};

export default TopUp;
