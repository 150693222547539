import { createSelector } from 'reselect';

export const getMaintenanceModalStatus = state => state.maintenanceModal;

export const getModal = state => state.modals.modals;
export const getTypeModal = (state, modalType) => modalType;
export const getModalInfo = createSelector(
	getModal,
	getTypeModal,
	(modals, modalType) => modals.filter((item, index) => item.windowType === modalType)[0]
);