import React from 'react';
import { DetailsProps } from './types';
import CurrencyDetails from './CurrencyDetails';
import TransactionsList from './TransactionsList';

const Transactions = ({ currency }) => {
	
	return (
		<div className="ExchangeDetails">
			<CurrencyDetails currency={currency} />
			<TransactionsList currency={currency} />
		</div>
	);
};

Transactions.propTypes = DetailsProps; 

export default Transactions;
