import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { SEPA_WINDOW_TYPE, PAYMENT_FIELDS } from 'constants';
import helpers from 'helpers';
import * as paymentActions from 'actions/payment';
import * as paymentSelector from 'selectors/payment';
import PaymentSchema from 'schemas/payment';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Title from 'components/Title';
import Modal from '../../BeneficiaryModal';
import { getModalInfo } from 'selectors/modals';
import * as modalsActions from 'actions/modals';
import M from './Beneficiary.locale.json';
import * as cardSelector from "../../../selectors/card";

const mapState = (state, props) => {
	const { match } = props;
	const { id } = match.params;

	return {
		user: state.user,
		currentCard: cardSelector.getById(state, state.card.current),
		payment: paymentSelector.get(state, id),
		modal: getModalInfo(state, SEPA_WINDOW_TYPE)
	};
};

const mapDispatch = dispatch => ({
	actions: {
		payment: bindActionCreators(paymentActions, dispatch),
		modal: bindActionCreators(modalsActions, dispatch)
	}
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends Component {
	state = {
		loader: false,
		warning: false,
		form: {
			beneficiary: '',
			iban: '',
			amount: '',
			details: ''
		},

		errors: {
			beneficiary: [],
			iban: [],
			amount: [],
			details: [],
			bic: []
		}
	};

	componentDidMount () {
		this.props.actions.modal.getModalInfo();
		this.onMount();
	}

	componentDidUpdate (prevProps) {
		this.onUpdate(prevProps);
	}

	onMount () {
		if (!this.props.location.state) return;
		const { state } = this.props.location;
		if (state.payment)
			this.setState({
				form: {
					...this.state.form,
					iban: state.payment.iban,
					amount: parseFloat(state.payment.amount).toFixed(2) || 0,
					beneficiary: state.payment.beneficiary,
					details: state.payment.details
				}
			});
	}

	onUpdate (prevProps) {
		const { state } = this.props.location;
		if (!state) return;

		const payment = state.payment;

		if (
			typeof payment === 'undefined' ||
			(prevProps.location.state &&
				prevProps.location.state.payment === payment)
		)
			return;

		this.setState({
			form: {
				...this.state.form,
				iban: payment.iban,
				amount: parseFloat(payment.amount).toFixed(2) || 0,
				beneficiary: payment.beneficiary,
				details: payment.details
			}
		});
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = helpers.validator.single(
				{
					...form,
					[name]: value
				},
				name,
				PaymentSchema.create
			);
		}

		let convert = value;

		if (PAYMENT_FIELDS.includes(name)) convert = value.toUpperCase();

		this.setState({
			form: {
				...form,
				[name]: convert
			},
			errors: {
				...errors,
				[name]: messages
			},
			loader: false
		});
	};

	onSubmit = e => {
		e.preventDefault();

		const { currentCard } = this.props;
		const { form, loader } = this.state;

		// check if form was already submitted
		if (loader) return;

		const errors = helpers.validator.all(form, PaymentSchema.create);
		console.log(errors);

		if (!currentCard.availBal || currentCard.availBal < form.amount) {
			const amountError = {
				id: 'schemas.not.enough',
				variables: {
					attribute: 'amount',
					value: form.amount
				}
			}

			if (!errors.amount) {
				errors.amount = [amountError]
			} else errors.amount.push(amountError)
			debugger
		}

		// set errors and return
		if (errors) {
			this.setState({
				errors: {
					...this.state.errors,
					...errors
				}
			});

			return false;
		}

		// if (!modal.show) {
		this.sendData();
		// } else {
		// this.setState({warning: true})
		// }
	};

	sendData = async () => {
		const { form, loader } = this.state;
		const { actions, history } = this.props;
		if (loader) return;
		this.setState({
			loader: true
		});

		// hard coded for now
		form.currency = 'EUR';

		const response = await actions.payment.create(form);

		const update = {
			errors: {
				...this.state.errors,
				...response.errors
			},
			loader: false
		};

		if (response && response.errors && response.messages) {
			update.messages = response.messages;

			this.setState(update);

			return;
		}

		// clean
		update.form = {
			beneficiary: '',
			iban: '',
			amount: '',
			details: ''
		};

		this.setState(update);

		history.push(`/payments/create/${response.id}`);
	};

	closeModal = () => {
		this.setState({ warning: false });
	};

	nextStep = notShowAnymore => {
		if (notShowAnymore) {
			this.props.actions.modal.sendModalInfo([
				{ windowType: SEPA_WINDOW_TYPE }
			]);
		}
		this.closeModal();
		this.sendData();
	};

	render () {
		const { payment, modal, user } = this.props;
		const { form, errors, loader, warning } = this.state;

		const t = this.props.intl.formatMessage;
		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="PaymentCreateScreen__Title"
				/>

				<Form onSubmit={this.onSubmit}>
					<Form.Group>
						<Input
							name="beneficiary"
							placeholder={t(M.inputs.beneficiary)}
							onChange={this.onChange}
							value={form.beneficiary}
							errors={errors.beneficiary}
							autoFocus
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="iban"
							placeholder={t(M.inputs.iban)}
							onChange={this.onChange}
							value={form.iban}
							errors={errors.iban}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="amount"
							placeholder={t(M.inputs.amount)}
							onChange={this.onChange}
							value={form.amount}
							unit="EUR"
							errors={errors.amount}
						/>
					</Form.Group>

					<Form.Group>
						<Input
							name="details"
							placeholder={t(M.inputs.details)}
							onChange={this.onChange}
							value={form.details}
							errors={errors.details}
						/>
					</Form.Group>

					<Button
						className="PaymentCreateScreen__Submit"
						loading={loader}
						small
						form
					>
						{t(M.buttons.next)}
					</Button>
				</Form>
			</div>
		);
	}
}
