import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Layout from 'components/Layout';
import Wrap from 'components/Wrap';
import Container from 'components/Container';
import Section from 'components/Section';
import Tab from 'components/Tab';
import Button from 'components/Button/Button';
import Nav from 'sections/Nav';
import Header from 'sections/Header';
import { fetchToAddSecondary } from 'selectors/card';
import CardCreateMe from '../CardCreateMe';
import CardCreateOther from '../CardCreateOther';
import M from '../CardCreate.locale.json';
import '../Create.scss';

import intlTypes from 'types/intl';
import historyTypes from 'types/history';
import currentCardTypes from 'types/currentCard';

const cardsUrl = '/cards';

const mapState = state => ({
	cards: fetchToAddSecondary(state)
});

@withRouter
@injectIntl
@connect(mapState)
class CardCreateTab extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		cards: PropTypes.arrayOf(currentCardTypes).isRequired,
		history: historyTypes.isRequired,
	}

	constructor (props) {
		super(props);
		const { cards, history } = this.props;
		if (!cards.length) history.push(cardsUrl);
	}


	state = {
		current: 0,
	}

	onTab = (tab, idx) => {
		this.setState({
			current: idx,
		});
		
	};

	static tabs = [
		{
			id: 'me',
			title: M.buttons.createMe,
		},
		{
			id: 'other',
			title: M.buttons.createOther,
		}
	];



	render () {
		const { intl } = this.props;
		const { current } = this.state;
		const t = intl.formatMessage;
		const tabs = this.constructor.tabs
			.filter(item => item.hasOwnProperty('title'))
			.map(item => {
				const title = t(item.title);

				return {
					...item,
					title,
				};
			});

		return (
			<Layout className="PaymentCreateScreen Screen" sidebar>
				<Nav />
				<Header />
				<Section>
					<Container>
						<Wrap>
							<Tab header={tabs} current={current} onChange={this.onTab}>
								<Tab.Panel>
									<CardCreateMe />
								</Tab.Panel>
								<Tab.Panel>
									<CardCreateOther />
								</Tab.Panel>
							</Tab>
						</Wrap>
					</Container>
				</Section>
			</Layout>
		);
	}
}

export default CardCreateTab;
