import React from 'react';
import { formatFloatToFixed } from 'helpers/formatter';
import { CurrencyItemProps } from '../types';
import { MAPPING_CRYPTO_ICON } from '../../constants';

const Item = ({ currency, index, onClick }) => {
	return (
		<div
			key={currency.currencyCode}
			className="ExchangeList__Item"
			tabIndex={index + 1}
			role="button"
			onClick={() => onClick(currency)}
		>
			<div className="ExchangeList__Item-Wrapper">
				<div className="ExchangeList__Item-Icon">
					<img
						alt=""
						width={64}
						src={
							MAPPING_CRYPTO_ICON[currency.currencyCode] ||
							MAPPING_CRYPTO_ICON.XXX
						}
					/>
				</div>
				<div className="ExchangeList__Item-Info">
					<span>{currency.currencyCode}</span>
					<span>{formatFloatToFixed(currency.balance)}</span>
				</div>
			</div>
		</div>
	);
};

Item.propTypes = CurrencyItemProps;

export default Item;
