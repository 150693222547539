import React from 'react';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Input from 'components/Input';
import Nav from 'sections/Nav';
import RegulatoryDataCollectionForm from 'sections/RegulatoryDataCollectionForm';
import Layout from 'components/Layout';
import Section from 'components/Section';
import Container from 'components/Container';
import Form from 'components/Form';
import Button from 'components/Button';
import Title from 'components/Title';
import * as cardActions from 'actions/card';
import M from './RegulatoryDataCollection.locale';
import tracker from 'helpers/tracker';
import { UTMParams } from 'constants/common';
import intlTypes from 'types/intl';

const mapState = (state) => ({
	user: state.user,
});

const mapDispatch = dispatch => ({
	actions: {
		card: bindActionCreators(cardActions, dispatch),
	},
});

@injectIntl
@connect(mapState, mapDispatch)
class RegulatoryDataCollection extends React.PureComponent {
	static propTypes = {
		intl: intlTypes.isRequired,
		actions: PropTypes.shape({
			card: PropTypes.shape({
				setRegulatoryDataCollection: PropTypes.func.isRequired,
			}).isRequired
		}).isRequired
	};

	componentDidMount() {
		document.addEventListener('mousemove', tracker.onMouseMove);
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', tracker.onMouseMove);
	}

	onSubmit = async data => {
		try {
			this.fixateTrackResult();
			await this.props.actions.card.setRegulatoryDataCollection({ udfs: data });
		}
		catch (e) {
			setTimeout(() => {
				this.props.actions.card.fetch();
			}, 3000)

		}
	};

	fixateTrackResult = () => {
		const result = tracker.analyse();
		if (result.count > 0) {
			const params = {
				utm_source: 'PROBABLY_UNSAFE',
				utm_medium: 'PROBABLY_UNSAFE',
				utm_campaign: result.mark,
			};
			localStorage.setItem(UTMParams, JSON.stringify(params));
		}
	};

	render() {
		const t = this.props.intl.formatMessage;

		return (
			<Layout className="RegulatoryDataCollectionScreen Screen">
				<Nav logo />
				<Section>
					<Container small>
						<Title title={t(M.title)} />
						<Form.Note>{t(M.subtitle)}</Form.Note>
						<RegulatoryDataCollectionForm
							onSubmit={this.onSubmit}
							button={<Button form small>{t(M.buttons.next)}</Button>}
							onKeyDown={tracker.onKeyboardEvent}
						/>
					</Container>
				</Section>
			</Layout>
		);
	}
}

export default RegulatoryDataCollection;
