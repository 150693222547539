
export const NotifyActionType = {
	SET_UNREAD_COUNT: `setUnreadCount`
}

const initial = {
	unreadCount: null,
};

export default (state = initial, action) => {
	switch (action.type) {
		case NotifyActionType.SET_UNREAD_COUNT:
			return { ...state, unreadCount: action.payload };
		default:
			return state;
	}
};
