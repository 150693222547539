import React, { useEffect, useState } from "react";

import Nav from 'sections/Nav';
import Header from 'sections/Header';
import Layout from 'components/Layout';

import NotificationItem from "../../components/NotificationItem/NotificationItem.jsx";
import { getCount } from 'actions/notifications';
import './notifications.scss'
import NotificationsFilter from "../../components/NotificationsFilter/NotificationsFilter.jsx";
import { useDispatch } from "react-redux";
import { NotifyActionType } from "../../reducers/notify";
import Icon from "../../components/Icon";
import fetcher from 'helpers/fetcher';
import { TOAST_CREATE, TOAST_HIDE, TOAST_REMOVE } from "../../constants";
import { create, error, success } from "../../actions/toast";
const DURATION = 5000;
const ANIMATION = 600;

const Notifications = () => {
	const dispatch = useDispatch();

	const [isFilter, setIsFilter] = useState(false);
	const [notifications, setNotifications] = useState(null);

	const [applyFilter, setApplyFilter] = useState(false);


	const [eventType, setEventType] = useState(``);
	const [dateFrom, setDateFrom] = useState(``);
	const [dateTo, setDateTo] = useState(``);
	// const [offset, setoffset] = useState(``);
	// const [limit, setlimit] = useState(``);
	const fetchNotifications = async () => {
		const eType = eventType ? `&eventType=${eventType}` : ``
		const from = dateFrom ? `&dateFrom=${dateFrom}` : ``
		const to = dateTo ? `&dateTo=${dateTo}` : ``

		const res = await fetcher.get(`notification/get?limit=50${eType}${from}${to}`);
		getCount()(dispatch);
		// console.log(res);
		const { data: { response } } = res;


		setNotifications(response.notifications)

		const allUnreadNotifications = response.notifications.filter(({ unread }) => unread)
		// const createNotifications = allUnreadNotifications.filter(({eventType}) => eventType === )
		// const successNotifications = allUnreadNotifications.filter(({eventType}) => eventType === )
		// const errorNotifications = allUnreadNotifications.filter(({eventType}) => eventType === )

		// allUnreadNotifications.forEach(noty => dispatch(create(noty))) // TO DO  : TOASTS ON NOTIFICATION
	}

	useEffect(() => {

		fetchNotifications()


	}, [applyFilter])


	return (
		<Layout
			className="HistoryScreen Screen"
			sidebar
		>
			<Nav />

			<Header />
			<div className="notifications-screen">
				{
					!isFilter &&

					<button
						onClick={() => setIsFilter(!isFilter)}
						className="filter-button">
						<Icon name="filter-noty" />
					</button>
				}
				{
					isFilter &&
					<NotificationsFilter
						eventType={eventType}
						dateFrom={dateFrom}
						dateTo={dateTo}
						applyFilter={applyFilter}
						setEventType={setEventType}
						setDateFrom={setDateFrom}
						setDateTo={setDateTo}
						setApplyFilter={setApplyFilter}
						setIsFilter={setIsFilter}
					/>
				}

				{
					!isFilter &&

					<div className="notifications__list">
						{
							notifications
								? notifications.map((item, idx) => {

									return (
										<NotificationItem key={idx} item={item} />
									)
								})
								: null
						}
					</div>
				}
			</div>
		</Layout>
	)
}

export default Notifications;