import moment from 'moment';

export const timeDifferenceCreatedToken = (start, finish) => {
	const now = moment(start);
	const end = moment(finish);
	const duration = moment.duration(now.diff(end));
	const minutes = Math.abs(duration.asMinutes());
	return minutes;
};

export const timeDifferenceMinutes = (start, finish) => {
	const now = moment(start);
	const end = moment(new Date(finish));
	const duration = moment.duration(now.diff(end));
	const minutes = Math.abs(duration.asMinutes() + start.getTimezoneOffset());
	return minutes;
};