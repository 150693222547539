import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import intlTypes from 'types/intl';

import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';

import M from '../../NotificationPopup.locale.json';

import './NotificationActions.scss';

const NotificationActions = ({ dropdown, intl, onSelect, scrollContainerToBottom }) => {
	const t = intl.formatMessage;

	const ref = useRef(null);

	
	const [open, setOpen] = useState(false);
	
	useEffect(() => {
		if (scrollContainerToBottom && open) {
			scrollContainerToBottom();
		}
	}, [open]);

	const onDropdown = () => {
		setOpen(current => !current);
	};

	return (
		<div className={'NotificationsSelect notification-info__actions'} ref={ref}>
			<div
				onClick={onDropdown}
				role='button'
				tabIndex={0}
				className='NotificationsSelect__Wrap'
			>
				<div className='NotificationsSelect__Placeholder'>{t(M.actions.title)}</div>
				<div className='NotificationsSelect__Toggle' role='button' tabIndex={0}>
					<Icon className='NotificationsSelect__Icon' name='caveat-down' />
				</div>
			</div>
			<Dropdown
				className='NotificationsSelect__Dropdown'
				data={open ? dropdown : []}
				onClick={onSelect}
				open={open}
			/>
		</div>
	);
};

NotificationActions.propTypes = {
	dropdown: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]),
			title: PropTypes.string,
			subtitle: PropTypes.string,
		})
	),
	intl: intlTypes.isRequired,
	onSelect: PropTypes.func,
	scrollContainerToBottom: PropTypes.func,
};

export default injectIntl(NotificationActions);

