import { createSelector } from 'reselect';
import { SEPA_SUB_TYPES_LIST, AUTHNUM } from 'constants/common';
import comparer from 'helpers/comparer';

const getListDashboardItems = state => state.dashboard.items;

export const getSortParams = state => state.dashboard.sort;

export const fetch = createSelector(
	getListDashboardItems,
	getSortParams,
	(list, sort) => {
		const items = list && sort ? list.sort(comparer(sort)) : list;
		return items
			.map(item => {
				if (item.details && !SEPA_SUB_TYPES_LIST.includes(item.subType) &&
				  item.details.includes(AUTHNUM, 0) && !item.details.includes(AUTHNUM, 2)) {
				  return { ...item, details: '' };
				}
				return item;
			});
	},
);
