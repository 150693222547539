/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import InputCheckbox from 'components/InputCheckbox';
import Title from 'components/Title';
import Container from 'components/Container';
import Button from 'components/Button';
import { error, success } from 'actions/toast';
import * as exchangeActions from 'actions/exchange';

import M from '../../Exchange.locale.json';

const fiterCard = (card, name, surname) => {
	return !!(
		card.cardStatus === 'OPEN' &&
		card.activated &&
		card.cardName &&
		card.cardName.trim() === (name + ' ' + surname).trim()
	);
};

const mapState = state => ({
	cards: state.card.items
		.filter(card => fiterCard(card, state.user.name, state.user.surname))
		.map(card => ({
			...card,
			value: card.id,
			balance: card.availBal,
			title: card.cardNumber
		}))
});

const mapDispatchToProps = dispatch => {
	return {
		errorToast: payload => {
			error(dispatch)(payload);
		},
		successToast: payload => {
			success(dispatch)(payload);
		}
	};
};

const ExchangeSendPreview = ({ data, intl, ...props }) => {
	const [feeSubtract, setFeeSubtract] = useState(true);
	const history = useHistory();
	const t = intl.formatMessage;
	const { address, amount, currency, fee, requestId, card } = data;
	const isFiat = currency.type === 'FIAT';

	const getTotalAmount = () => {
		return (feeSubtract && !isFiat)
			? amount
			: (parseFloat(amount) + parseFloat(fee)).toString();
	};

	const onSend = e => {
		if (isFiat) {
			onSubmitFiat(e);
		} else {
			onSubmit(e);
		}
	};

	const onSubmit = async e => {
		e.preventDefault();

		if (getTotalAmount() > currency.balance) {
			props.errorToast({
				title: {
					id: 'screens.exchange.sendpreview.label7'
				}
			});
			return;
		}

		try {
			const { success, data } = await exchangeActions.sendRequest({
				amount: Number(amount),
				currencyWallet: currency.currencyCode,
				address: address,
				subtractFee: feeSubtract,
				noSms: false
			});

			if (success) {
				history.push(
					`/exchange/send/${currency.currencyCode.toLowerCase()}/confirm`,
					{
						data: {
							requestId: data.requestId,
							currency
						}
					}
				);
			}
		} catch ({ errors }) {
			for (const error in errors) {
				if (Object.hasOwnProperty.call(errors, error)) {
					props.errorToast({
						title: {
							...errors[error][0],
							id: errors[error][0].id || 'undefinedError'
						}
					});
				}
			}
		}
	};

	const onSubmitFiat = async e => {
		e.preventDefault();

		try {
			const { success, data } = await exchangeActions.sendRequestFiat({
				amount: Number(amount),
				currencyWallet: currency.currencyCode,
				iban: card.iban,
				noSms: false
			});

			if (success) {
				history.push(
					`/exchange/send/${currency.currencyCode.toLowerCase()}/confirm`,
					{
						data: {
							requestId: data.requestId,
							fee: data.fee,
							currency,
							address,
							amount
						}
					}
				);
			}
		} catch (e) {
			if(e.errors) {
				for (const error in e.errors) {
					if (Object.hasOwnProperty.call(e.errors, error)) {
						props.errorToast({
							title: {
								...e.errors[error][0],
								id: e.errors[error][0].id || 'undefinedError'
							}
						});
					}
				}
			}

		}
	};

	const renderTable = () => (
		<table>
			<tr>
				<th>{t(M.sendPreview.fields.to)}</th>
				<td>{address}</td>
			</tr>
			<tr>
				<th>{t(M.sendPreview.fields.fee)}</th>
				<td>{fee}</td>
			</tr>
			<tr>
				<th>{t(M.sendPreview.fields.subtractFee)}</th>
				<td>
					<InputCheckbox
						name="feeSubtract"
						type="agree"
						onChange={() => {
							setFeeSubtract(prev => !prev);
						}}
						value="on"
						checked={feeSubtract}
					/>
				</td>
			</tr>
			{feeSubtract && (
				<tr>
					<th>{t(M.sendPreview.fields.receive)}</th>
					<td>{amount - fee}</td>
				</tr>
			)}
			<tr>
				<th>{t(M.sendPreview.fields.total)}</th>
				<td>{getTotalAmount()}</td>
			</tr>
		</table>
	);
	const renderFiatTable = () => {
		if (!card) return <div />;
		return (<table>
			<tr>
				<th>{t(M.sendPreview.fields.to)}</th>
				<td>{card.cardName}</td>
			</tr>
			<tr>
				<th>IBAN</th>
				<td>{card.iban}</td>
			</tr>
			<tr>
				<th>{t(M.sendPreview.fields.card)}</th>
				<td>{card.cardNumber}</td>
			</tr>
			<tr>
				<th>{t(M.sendPreview.fields.fee)}</th>
				<td>{fee}</td>
			</tr>
			<tr>
				<th>{t(M.sendPreview.fields.total)}</th>
				<td>{getTotalAmount()}</td>
			</tr>
		</table>);
	};
		

	return (
		<div className="ExchangeSend__Preview">
			<Container medium>
				<Title title={t(M.sendPreview.title)} />
				<div className="ExchangeSend__Preview__Table">
					{!isFiat ? renderTable() : renderFiatTable()}
				</div>
				<Button onClick={onSend}>
					{t(M.sendPreview.buttons.send)}
				</Button>
			</Container>
		</div>
	);
};

export default compose(
	injectIntl,
	connect(mapState, mapDispatchToProps)
)(ExchangeSendPreview);
