import React from 'react';
import { FormattedMessage } from 'react-intl';
import processingIcon from '../assets/exchange/exchSubmit.png';

const Processing = (
	<div className="ExchangeBlocking">
		<FormattedMessage tagName="h1" id="screens.exchange.topupnotready.label1" />
		<img src={processingIcon} alt=''  />
		<FormattedMessage tagName="p" id="screens.exchange.topupnotready.label2" />
	</div>
);

export default Processing;
