/* eslint-disable no-unused-vars */
import fetcher from 'helpers/fetcher';
import { gtmId, GAId, firebaseConfig } from 'constants/gtm';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getToken, onMessage } from '@firebase/messaging';
import { getMessaging } from 'firebase/messaging';
import API from 'services/api';
import { getCount } from 'actions/notifications';
import store from '../stores'; 
import { bindActionCreators } from 'redux';


const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// console.log(messaging);

async function requestPermission () {
	// console.log('Requesting permission...');
	Notification.requestPermission().then(permission => {
		if (permission === 'granted') {
			// console.log('Notification permission granted.');
		}
	});
}
export const subscribe = cardIdArray => {
	// запрашиваем разрешение на получение уведомлений
	requestPermission()
		.then(function () {
			getToken(messaging, { vapidKey: 'BEQCX80YDkSpPvbZzylihR_9quL9C5aA0TBU6B8uqjmyDPS6RQm8tMMsoFLA7C5vNNJGUbCoUErWQLPIMyM9lE8' })
				.then(function (currentToken) {
					if (currentToken) {
						sendTokenToServer(currentToken, cardIdArray);
					} else {
						// console.warn('Не удалось получить токен.');
						setTokenSentToServer(false);
					}
				})
				.catch(function (err) {
					// console.warn('При получении токена произошла ошибка.', err);
					setTokenSentToServer(false);
				});
		})
		.catch(err => { console.log(err, 'ERROR'); });
};



// отправка ID на сервер	
export const sendTokenToServer = (currentToken, cardIdArray) => {
	if (!isTokenSentToServer(currentToken)) {
		cardIdArray.forEach(cardId => {
			try {
				API.notifications.addToken(cardId, currentToken)
					.then(
						r => { setTokenSentToServer(currentToken); },
						err => {
							console.log('Error sending token: ', err);
							const serverError = err.errors[Object.keys(err.errors)[0]][0];
							if (serverError.id === 'errors.token.already.sent') setTokenSentToServer(currentToken);
						});

			}
			catch (e) {
				console.log('Error sending token: ', e);
			}
		});



	} else {
		// console.log('Токен уже отправлен на сервер.');
	}
};

// используем localStorage для отметки того,
// что пользователь уже подписался на уведомления
export const isTokenSentToServer = currentToken => {
	return window.localStorage.getItem('sentFirebaseMessagingToken') == currentToken;
};

export const setTokenSentToServer = currentToken => {
	window.localStorage.setItem(
		'sentFirebaseMessagingToken',
		currentToken ? currentToken : ''
	);
};

onMessage(messaging, payload => {
	try {
		getCount()(store.dispatch);
	}
	catch (e) {
		console.debug(e);
	}

	// console.log(
	// 	'Message received. ',
	// 	Date.now().toLocaleString("ru", {
	// 		hour: 'numeric',
	// 		minute: 'numeric',
	// 		second: 'numeric'
	// 	}),
	// 	payload
	// );
});