import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import  { timeDifferenceMinutes }  from 'helpers/timeDifference';
import Timer from 'components/Timer';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import ButtonLink from 'components/ButtonLink';
import Title from 'components/Title';
import { ArrowLeftIcon } from 'components/icons';
import * as authActions from 'actions/auth';
import * as countryActions from 'actions/country';
import helpers from 'helpers';
import { CLOSE_TWOFACTOR_AUTH } from 'constants';
import M from './Login.locale.json';
import './Login.scss';
import speakeasy from '../../Setting/TwoFactorAuthenticationApp/speakeasy';

const TwoFactorApp = ({ intl, actions, twoFactor, country }) => {
	const [valueCode, setValueCode] = useState('');
	const [errorsCode, setErrorsCode] = useState([]);
	const [isLoading, setLoading] = useState(false);
	let timeout = 0;
	const t = intl.formatMessage;

	useEffect(() => {

	}, []);

	const onSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		const errors = helpers.validator.all(
			{ code: valueCode },
			{
				code: {
					presence: { message: '^schemas.presence' }
				}
			}
		);

		if (errors) {
			setErrorsCode([...errors.code]);
			setLoading(false);
			return;
		}

		const form = {
			userId: twoFactor.user.id,
			email: twoFactor.user.email,
			code: valueCode
		};

		const valid = speakeasy.totp.verify({window:1,token: valueCode,secret: twoFactor.user.twoFactorSecret, encoding: 'ascii'});
		if (valid) {
			const response = await actions.auth.sendCodeTwoFactorApp(form);
		}
		else {
			setErrorsCode([{ id: 'schemas.two.factor.code.invalid' }]);
			setLoading(false);
		}
	};

	const onChange = (value, name) => {
		let messages = [];
		if (errorsCode.length) {
			messages = helpers.validator.single(
				{
					[name]: value
				},
				name,
				{
					code: {
						presence: { message: '^schemas.presence' }
					}
				}
			);
		}
		setValueCode(value);
		setErrorsCode(messages);
	};

	const logout = () => {
		actions.closeTwoFactor();
	};

	return (
		<div className='TwoFactor'>
			<div className='TwoFactor__Title'>
				<ArrowLeftIcon
					onClick={logout}
					className='TwoFactor__Arrow'
				/>
				<Title
					className='TwoFactor__Nowrap'
					title={t(M.twoFactor.title)}
					legend={t(M.twoFactor.legend)}
				/>
			</div>

			<div className='TwoFactor__form'>
				<Form onSubmit={onSubmit}>
					<Form.Group>
						<Input
							name='code'
							placeholder={t(M.twoFactor.code)}
							onChange={onChange}
							value={valueCode}
							errors={errorsCode}
							autoFocus
						/>
					</Form.Group>
					<Button form loading={isLoading}>
						{t(M.twoFactor.login)}
					</Button>
				</Form>
			</div>
		</div>
	);
};
const mapState = state => ({
	twoFactor: state.twoFactorAuth,
	country: state.country
});
const mapDispatch = dispatch => ({
	actions: {
		auth: bindActionCreators(authActions, dispatch),
		closeTwoFactor: () => {
			dispatch({ type: CLOSE_TWOFACTOR_AUTH });
		},
		country: bindActionCreators(countryActions, dispatch)
	}
});
export default injectIntl(connect(mapState, mapDispatch)(TwoFactorApp));
