import fetcher from 'helpers/fetcher';

export default {
	enableTwoFactor (form) {
		return fetcher.post('user/two-factor/enable',{}, {params:form});
	},
	disableTwoFactor (form) {
		return fetcher.post('user/two-factor/disable',{}, {params:form});
	},
	generateCode (form) {
		return fetcher.post('open/two-factor/generate-code', {}, { params: form });
	},
	sendCode (form) {
		return fetcher.post(`two-factor/phone`,{}, {params:form});
	},
	sendCodeApp (form) {
		return fetcher.post(`two-factor/app`,{}, {params:form});
	},
	settingsGenerateCode () {
		return fetcher.post('user/two-factor/generate-code');
	},
	disableModalDisplay () {
		return fetcher.post('user/two-factor/notification-disable');
	},
	getLastRequestLogin (form) {
		return fetcher.post('open/two-factor/last-code',{}, {params:form});
	},
	getLastRequestSetting () {
		return fetcher.get('user/two-factor/last-code');
	}
}; 
