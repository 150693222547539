import {
	PAYMENT_GET_SUCCESS,
	PAYMENT_CREATE_SUCCESS,
	PAYMENT_AMOUNT_SUCCESS,
	PAYMENT_AMOUNT_ERROR,
	PAYMENT_PHONE_SUCCESS,
	PAYMENT_PHONE_ERROR,
	PAYMENT_SET_SEARCH,
	PAYMENT_SET_STATUS,
	PAYMENT_SET_COLUMNS,
	PAYMENT_SET_SORT,
} from 'constants';
import { clientRole } from 'constants/common';
import { success, error } from 'actions/toast';
import renamer from 'helpers/renamer';
import API from 'services/api';
import * as cardSelector from 'selectors/card';
import * as clientSelector from 'selectors/client';
import M from './c2cpayment.locale.json';

export const get = id => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const bid = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const cid = cardSelector.getFirstCardId(state);
	try {
		const result = await API.c2cpayment.get(id, cid, bid);
		const convert = renamer.from.payment.get(result.data.response);
		dispatch({ type: PAYMENT_GET_SUCCESS, payload: convert });
		return convert;
	} catch (err) { return err; }
};

export const create = form => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const bid = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const cid = state.card.current;
	try {
		const result = await API.c2cpayment.create(cid, { ...form }, bid);
		const convert = renamer.from.payment.get(result.data.response);
		dispatch({ type: PAYMENT_CREATE_SUCCESS, payload: convert });
		return convert;
	} catch (err) { return err; }
};

export const amount = (id, form) => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const bid = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const cid = cardSelector.getFirstCardId(state);
	try {
		const result = await API.c2cpayment.amount(id, cid, { ...form }, bid);
		const convert = renamer.from.payment.get(result.data.response);
		dispatch({ type: PAYMENT_AMOUNT_SUCCESS, payload: convert, id: convert.id });
		return convert;
	} catch (err) {
		dispatch({ type: PAYMENT_AMOUNT_ERROR, error: err });
		return err;
	}
};

export const phone = (id, form) => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const bid = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const cid = cardSelector.getFirstCardId(state);
	try {
		const result = await API.c2cpayment.phone(id, cid, { ...form }, bid);
		const convert = renamer.from.payment.get(result.data.response);
		dispatch({ type: PAYMENT_PHONE_SUCCESS, payload: convert, id: convert.id });
		return convert;
	} catch (err) {
		dispatch({ type: PAYMENT_PHONE_ERROR, error: err });
		return err;
	}
};

export const code = id => async (dispatch, getState) => {
	const state = getState();
	const client = clientSelector.getCurrentClient(state);
	const bid = client.roleType === clientRole.BUSINESS ? client.entityId : null;
	const cid = cardSelector.getFirstCardId(state);
	try {
		const result = await API.c2cpayment.code(id, cid, bid);
		success(dispatch)({ title: M.success.code.title });
		return result.data.response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		};
		return err;
	}
};

export const search = value => ({
	type: PAYMENT_SET_SEARCH,
	payload: value,
});

export const status = value => ({
	type: PAYMENT_SET_STATUS,
	payload: value
});

export const columns = value => ({
	type: PAYMENT_SET_COLUMNS,
	payload: value
});

export const sort = value => ({
	type: PAYMENT_SET_SORT,
	payload: value
});
