import fetcher from 'helpers/fetcher';

export default {
	getLink () {
		return fetcher.get(`user/start-kyc?isWeb=true`);
	},
	startVerification(data) {
		return fetcher.post('jumio/jumioVerificationStart', data);
	},
	getVerificationStatus (cardholderId) {
		return fetcher.get(`jumio/getVerificationStatus?cardholderId=${cardholderId}`);
	}
};
