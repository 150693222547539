export function getOS () {
	if (window.navigator) {
		let userAgent = window.navigator.userAgent,
			platform =
				window.navigator.userAgentData &&
				window.navigator.userAgentData.platform
					? window.navigator.userAgentData.platform
					: window.navigator.platform,
			macosPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k'],
			windowsPlatforms = ['win32', 'win64', 'windows', 'wince'],
			iosPlatforms = ['iphone', 'ipad', 'ipod'];

		if (platform) platform = platform.toLowerCase();
		if (userAgent) userAgent = userAgent.toLowerCase();

		if (macosPlatforms.indexOf(platform) !== -1) {
			return 'macos';
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			return 'ios';
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			return 'windows';
		} else if (/android/.test(userAgent)) {
			return 'android';
		} else if (/linux/.test(platform)) {
			return 'linux';
		}
	}

	return 'unknown';
}
