export default {
	get (key, session) {
		const storage = session ? sessionStorage : localStorage ;
		try {
			const data = storage.getItem(key);
			if (!data) return null;
			return JSON.parse(data);
		} catch (err) { return null; }
	},
	set (key, value, session) {
		const storage = session ? sessionStorage : localStorage;
		try { storage.setItem(key, JSON.stringify(value)); } catch (err) { }
	},
	remove (key, session) {
		const storage = session ? sessionStorage : localStorage;
		return storage.removeItem(key);
	},
};
