import fetcher from 'helpers/fetcher';
import renamer from 'helpers/renamer';
import {PAYMENTS_TYPES} from "../../../screens/Auth/PurchaseProposal/PurchaseProposal";

export default {
	login (form) {
		return fetcher.post('auth/login', {}, { params: form });
	},
	logout() {
		return fetcher.post('user/logout');
	},
	loginCreditCard (form) {
		return fetcher.post('open/login-card-number', {}, { params: form });
	},
	activate (form) {
		return fetcher.post('open/activate-card-number', {}, { params: form });
	},
	signup: {
		private (form) {
			const convert = renamer.to.auth.signupPrivate(form);
			return fetcher.post('apply/private', convert);
		},
		business (form) {
			let convert = renamer.to.user(form);
			convert = renamer.to.business.signup(convert);
			return fetcher.post('apply/business', convert);
		},
	},
	email (form) {
		return fetcher.post(`user/confirm/${form.code}`);
	},
	reset (form) {
		return fetcher.post('reset/request', {}, { params: form });
	},
	password (form, token) {
		return fetcher.post(`reset/${token}`, {}, { params: form });
	},
	create_payment (type = PAYMENTS_TYPES.CARD) {
		if (type === PAYMENTS_TYPES.CARD) return fetcher.post('private/purchase');
		else if (type === PAYMENTS_TYPES.CONTACTPAY) return fetcher.post('private/purchase/contactpay');
		else if (type === PAYMENTS_TYPES.MULTIHUB) return fetcher.post('private/purchase/multihub');
		else return null;
	},
	check_payment (type = PAYMENTS_TYPES.CARD, paymentId, cardId) {
		if (type === PAYMENTS_TYPES.CARD) return fetcher.post(`private/${cardId}/purchase/${paymentId}`);
		else if (type === PAYMENTS_TYPES.CONTACTPAY) return fetcher.post(`private/purchase/contactpay/${paymentId}`);
		else if (type === PAYMENTS_TYPES.MULTIHUB) return fetcher.post(`private/purchase/multihub/${paymentId}`);
		else return null;
	},
};
