import { createSelector } from 'reselect';
import { SEPA_SUB_TYPES_LIST, AUTHNUM } from 'constants/common';
import comparer from 'helpers/comparer';

export const list = state => state.transaction.items;

// filters
export const columns = state => state.transaction.columns;
export const columnsNav = (props, items) => {
	const t = props.intl.formatMessage;

	return items
		.map(item => ({
			...item,
			title: t(item.title),
		}));
};

export const types = state => state.transaction.types;
export const typesNav = (props, items) => {
	const t = props.intl.formatMessage;

	return items
		.map(item => ({
			...item,
			title: t(item.title),
		}));
};

export const status = state => state.transaction.status;
export const statusNav = (props, items) => {
	const t = props.intl.formatMessage;

	return items
		.map(item => ({
			...item,
			title: t(item.title),
		}));
};

export const search = state => state.transaction.search;
export const sort = state => state.transaction.sort;

export const get = (state, id) => {
	if (id) {
		if (state.dashboard.items.find(item => item.id === id)) {
			return state.dashboard.items.find(item => item.id === id);
		}
		return state.transaction.items.find(item => item.id === id);
	}

	return undefined; // I'm assuming that there will be no cards with id 0
};

const filterSearch = raw => {
	const keyword = raw.toLowerCase();

	return item => {
		// details
		if (item.beneficiary && item.details.toLowerCase().includes(keyword)) return true;

		// recipient
		if (item.beneficiary && item.beneficiary.toLowerCase().includes(keyword)) return true;

		// id
		if (item.id && item.id.toLowerCase().includes(keyword)) return true;

		return false;
	};
};

export const fetch = createSelector(
	list,
	types,
	status,
	search,
	sort,
	(list, types, status, search, sort) => {
		let items = list;

		// if (types) items = items.filter(filterTypes(types));
		// else items = [];

		// if (status) items = items.filter(filterStatus(status));
		// else items = [];

		if (items && search) items = items.filter(filterSearch(search));

		// if (items && sort) items = items.sort(comparer(sort));

		return items
			.map(item => {
					if (item.details && !SEPA_SUB_TYPES_LIST.includes(item.subType) && // if item.subType is NOT one of these, and contains "AuthNum: ******" at the start
					  item.details.includes(AUTHNUM, 0) &&
					  !item.details.includes(AUTHNUM, 2)) {
					  item.details = '';
				  }
				return item;
			});
	},
);
