export default {
	name: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 2,
			tooShort: '^schemas.length.min',
			maximum: 20,
			tooLong: '^schemas.length.max',
		},
		format: {
			pattern: '[a-zA-Z ]+$',
			message: '^schemas.alphabetic'
		},
		fullnamelenght: {
			message: '^schemas.length.name.fullname'
		}
	},
	surname: {
		presence: {
			message: '^schemas.presence',
		},
		length: {
			minimum: 2,
			tooShort: '^schemas.length.min',
			maximum: 20,
			tooLong: '^schemas.length.max',
		},
		format: {
			pattern: '[a-zA-Z ]+$',
			message: '^schemas.alphabetic'
		},
		fullnamelenght: {
			message: '^schemas.length.surname.fullname'
		}
	},
	gender: {
		presence: {
			message: '^schemas.presence',
		},
	},
	nationality: {
		presence: {
			message: '^schemas.presence',
		},
	},
	dateOfBirth: {
		presence: { message: '^schemas.presence' },
		format: {
			pattern: '^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$',
			message: '^schemas.incorrect',
		},
		dateBefore: { message: '^schemas.date.future' },
	},
	email: {
		presence: { message: '^schemas.presence' },
		email: { message: '^schemas.email' },
		format: {
			pattern: "^[A-Z0-9]+(?:\-[A-Z0-9]+)*(?:_[A-Z0-9]+)*(?:.[A-Z0-9]+)*@(?:(?=[A-Z0-9-]{1,63}\.)[A-Z0-9]+(?:-[A-Z0-9]+)*\.){1,8}[A-Z]{2,63}$",
			message: '^schemas.email',
			flags:'i'
		}
	},
	phone: {
		presence: { message: '^schemas.presence' },
		numericality: {
			message: 'can only contain numbers',
		},
		length: {
			minimum: 2,
			message: '^schemas.length.min',
		},
		phonenumber: {
			message: '^schemas.phone.phonenumber'
		}
	},
	address: {
		presence: { message: '^schemas.presence' },
		length: {
			maximum: 60,
			message: '^schemas.length.max',
		},
		format: {
			pattern: '[a-zA-Z0-9 .,&/()-]+$',
			message: '^schemas.alphanumeric.address',
		}
	},
	city: {
		presence: { message: '^schemas.presence' },
		length: {
			maximum: 25,
			message: '^schemas.length.max',
		},
		format: {
			pattern: '[a-zA-Z -]+$',
			message: '^schemas.alphabetic'
		}
	},
	country: {
		presence: { message: '^schemas.presence' },
	},
	postalCode: {
		presence: { message: '^schemas.presence' },
		length: {
			maximum: 15,
			message: '^schemas.length.max',
		},
		alphanumeric: { message: '^schemas.alphanumeric.space', options: { space: true } },
	},
	phoneCountry: {
		presence: { message: '^schemas.presence' },
	},
	password: {
		presence: { message: '^schemas.presence' },
		length: {
			minimum: 8,
			maximum: 20,
			message: '^schemas.length.between',
		}
	},
	passwordConfirm: {
		presence: { message: '^schemas.presence' },
		equality: {
			attribute: 'password',
			message: '^schemas.password.match',
			comparator: (v1, v2) => v1 === v2
		},

	},
	agree: {
		presence: { message: '^schemas.presence' },
		inclusion: {
			within: [true],
			message: '^schemas.agree',
		}
	},
	agreeMonitored: {
		presence: { message: '^schemas.presence' },
		inclusion: {
			within: [true],
			message: '^schemas.agree',
		}
	},
};
