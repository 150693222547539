import {
	CLIENT_CREATE_PRIVATE_SUCCESS,
	CLIENT_CREATE_PRIVATE_ERROR,
	CLIENT_GET_ADDRESS_SUCCESS,
  STATUS__ACTIVE
} from 'constants';
import { removeRegulatoryDataCollection } from 'actions/card';
import * as clientSelector from 'selectors/client';
import API from 'services/api';
import { UTMParams } from 'constants/common';
import { GTMEvents } from "../../constants/gtm";
import {SET_REGISTRATION_PAYMENT} from "../../constants";

export const create = () => async (dispatch, getState) => {
	const clientId = clientSelector.getEntityClientId(getState());
	const utmParams = localStorage.getItem(UTMParams);
	const utm = utmParams ? JSON.parse(utmParams) : {};

	try {
		const data = {
			// udfs - user defined fields
			// udfs: getInitialRegulatoryDataCollection(getState()),
			utm,
			chanel: 'web',
		};
		const { data: { response } } = await API.client.private.create(data);

		gtag('event', GTMEvents.allCompleted.eventName, { ...GTMEvents.allCompleted.eventParameters });
		gtag_report_conversion("sPnvCIWDj40YEIqw7pcD", "https://my.weststeincard.com/clients/create/private");
		fbq('track', 'SignupCompleted');

		dispatch({ type: CLIENT_CREATE_PRIVATE_SUCCESS, payload: { id: clientId } });
		dispatch({type: STATUS__ACTIVE});
		dispatch(removeRegulatoryDataCollection());

		return response;
	} catch (error) {
		dispatch({ type: CLIENT_CREATE_PRIVATE_ERROR, payload: { id: clientId }, error });
		return error;
	}
};

export const get = () => async () => {
	try {
		const { data: { response } } = await API.client.private.get();
		return response;
	} catch (error) { return error; }
};

export const getAddress = () => async dispatch => {
	try {
		const { data: { response } } = await API.client.private.address();
		dispatch({ type: CLIENT_GET_ADDRESS_SUCCESS, payload: { address: response } });
		return response;
	} catch (error) { return error; }
};
