import {
	AUTH_LOGIN_SUCCESS,
	AUTH_SIGNUP_SUCCESS,
	AUTH_LOGOUT,
	AUTH_EMAIL_SUCCESS,
	SHOW_TWOFACTOR_ALERT,
	OPEN_TWOFACTOR_AUTH,
	STATUS__ACTIVE, CARD_CREATE_SUCCESS
} from 'constants';
import { success, error } from 'actions/toast';
import renamer from 'helpers/renamer';
import API from 'services/api';
import normalize from 'helpers/locales';
import M from './auth.locale.json';
import { GTMEvents } from '../constants/gtm';
import { SET_REGISTRATION_PAYMENT } from '../constants';
import { PAYMENTS_TYPES } from '../screens/Auth/PurchaseProposal/PurchaseProposal';
import moment from 'moment/moment';

const dispatchLoginAction = async (dispatch, response) => {
	const list = await API.client.business.fetch();
	const user = renamer.from.user.get(response);
	const locale = normalize.fromBackend(user.language);
	const messages = await API.language.get(locale);
	const { data: { response: { cardholderIds } } } = await API.client.private.get();
	dispatch({ type: CARD_CREATE_SUCCESS, payload: cardholderIds[0] });

	dispatch({
		type: AUTH_LOGIN_SUCCESS,
		payload: {
			user,
			list: list.data.response,
			language: {
				locale,
				messages: messages.data
			}
		}
	});

	if (!user.twoFactor && user.phoneVerified && !user.twoFactorNotification) {
		dispatch({ type: SHOW_TWOFACTOR_ALERT });
	}

	return user;
};

const dispatchSignupAction = async (dispatch, response) => {
	const list = await API.client.business.fetch();
	const user = renamer.from.user.get(response);
	const locale = normalize.fromBackend(user.language);
	const messages = await API.language.get(locale);

	gtag('event', GTMEvents.signup.eventName, { ...GTMEvents.signup.eventParameters });
	gtag_report_conversion('JiRyCPmCj40YEIqw7pcD', 'https://my.weststeincard.com/auth/signup/private');
	fbq('track', 'Lead');

	const { data: { response: address } } = await API.client.private.address();

	dispatch({
		type: AUTH_SIGNUP_SUCCESS,
		payload: {
			user: { ...user, address },
			list: list.data.response,
			language: {
				locale,
				messages: messages.data
			}
		}
	});
	return user;
};

export const login = form => async dispatch => {
	try {
		const {
			data: { response }
		} = await API.auth.login(form);

		if (response.twoFactor) {
			dispatch({ type: OPEN_TWOFACTOR_AUTH, payload: response });
			return response;
		}

		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const getCodeTwoFactor = form => async dispatch => {
	try {
		const result = await API.twoFactor.generateCode(form);
		success(dispatch)({ title: M.success.get.title });
		return result.data.response;
	} catch (err) {
		if (err.messages.length) {
			error(dispatch)({ title: { id: err.messages[0].defaultMessage } });
		}
		return err;
	}
};

export const sendCodeTwoFactor = form => async dispatch => {
	try {
		const { data: { response } } = await API.twoFactor.sendCode(form);

		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const sendCodeTwoFactorApp = form => async dispatch => {
	try {
		const { data: { response } } = await API.twoFactor.sendCodeApp(form);

		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const loginCreditCard = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.loginCreditCard(form);
		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const activate = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.activate(form);
		return await dispatchLoginAction(dispatch, response);
	} catch (error) {
		return error;
	}
};

export const signup = {
	private: form => async dispatch => {
		try {
			const { data: { response } } = await API.auth.signup.private(form);
			return await dispatchSignupAction(dispatch, response);
		} catch (error) {
			return error;
		}
	},
	business: form => async dispatch => {
		try {
			const { data: { response } } = await API.auth.signup.business(form);
			return await dispatchSignupAction(dispatch, response);
		} catch (error) {
			return error;
		}
	}
};

export const logout = () => async dispatch => {
	try {
		const { data: { response } } = await API.auth.logout();
		dispatch({ type: AUTH_LOGOUT });
		return response;
	} catch (error) {
		return error;
	}
};

export const reset = form => async () => {
	try {
		const { data: { response } } = await API.auth.reset(form);
		return response;
	} catch (error) {
		return error;
	}
};

export const password = (form, token) => async dispatch => {
	try {
		const result = await API.auth.password(form, token);
		success(dispatch)({ title: M.success.password.title });
		return result.data.response;
	} catch (error) {
		return error;
	}
};

export const email = form => async dispatch => {
	try {
		const { data: { response } } = await API.auth.email(form);

		gtag('event', GTMEvents.emailVerification.eventName, { ...GTMEvents.emailVerification.eventParameters });
		gtag_report_conversion('yf-OCPyCj40YEIqw7pcD', 'https://my.weststeincard.com/auth/email');

		dispatch({ type: AUTH_EMAIL_SUCCESS, payload: response });
		return response;
	} catch (error) {
		if (error.errors) error.errors = renamer.from.email(error.errors);
		return error;
	}
};

export const getLastRequest = form => async () => {
	try {
		const { data: { response } } = await API.twoFactor.getLastRequestLogin(form);
		return response;
	} catch (error) {
		return error;
	}
};

export const accountReviewSuccess = () => async dispatch => {
	try {
		const { data } = await API.user.accountReviewSuccess();
		dispatch({ type: STATUS__ACTIVE });
		return data;
	} catch(error) {
		return error;
	}
};

export const togglePurchaseRegistration = value => dispatch => {
	try {
		dispatch({ type: SET_REGISTRATION_PAYMENT, payload: value });
	} catch (e) {
		return e;
	}
};

export const checkPayment = (
	type = PAYMENTS_TYPES.CARD,
	cardId,
	paymentId,
	{ linking = true } = {}
) => async dispatch => {
	try {
		const { data: { response } } = await API.auth.check_payment(type, paymentId, cardId),
			{ checkoutUrl, status, exclusionError, updated } = response;
		if (
			status === 'paid' ||
			exclusionError ||
			(status === 'pending_execute' &&
				moment().diff(moment(updated, 'DD-MM-YYYY HH:mm:ss'), 'hours') > 24)
		) {
			return { ...response, success: true };
		} else if (linking) window.open(checkoutUrl, '_blank');
		return { ...response, success: false };
	} catch (e) {
		// console.log({ e });
		throw e;
	}
};
export const createPayment = (type = PAYMENTS_TYPES.CARD) => async dispatch => {
	try {
		const { data: { response } } = await API.auth.create_payment(type);

		gtag('event', GTMEvents.topUpAgreed.eventName, {...GTMEvents.topUpAgreed.eventParameters});
		gtag_report_conversion('4gpqCJjN77YYEIqw7pcD', 'https://my.weststeincard.com/auth/purchase');
		fbq('track', 'Success');

		return response;
	} catch (e) {
		// console.log({ e });
		throw e;
	}
};
