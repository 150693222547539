import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Form from 'components/Form';
import Input from 'components/Input';
import Button from 'components/Button';
import Timer from 'components/Timer';
import Title from 'components/Title';
import Label from 'components/Label';
import * as paymentActions from 'actions/c2cpayment';
import * as countryActions from 'actions/country';
import validator from 'helpers/validator';
import * as paymentSelector from 'selectors/payment';
import * as userSelector from 'selectors/user';
import PaymentSchema from 'schemas/c2cpayment';
import M from './Phone.locale.json';

import intlTypes from 'types/intl';

const disableTime = 15000;

const mapState = (state, props) => ({
	payment: paymentSelector.get(state, props.match.params.id),
	user: state.user,
	hmacEnabled: state.user.hmacSecret,
	userPhone: userSelector.getUserPhone(state),
});

const mapDispatch = dispatch => ({
	actions: {
		country: bindActionCreators(countryActions, dispatch),
		payment: bindActionCreators(paymentActions, dispatch),
	},
});

@injectIntl
@withRouter
@connect(mapState, mapDispatch)
export default class Screen extends React.PureComponent {
	static propTypes = {
		actions: PropTypes.shape({
			country: PropTypes.shape({
				fetch: PropTypes.func.isRequired,
			}).isRequired,
			payment: PropTypes.shape({
				code: PropTypes.func.isRequired,
			}).isRequired,
		}).isRequired,
		user: PropTypes.object.isRequired,
		userPhone: PropTypes.string,
		hmacEnabled: PropTypes.bool,
		intl: intlTypes.isRequired,
	}

	state = {
		loader: false,
		loaderResend: false,
		disabledResend: false,
		form: { code: '' },
		errors: { code: [] },
	}

	onSubmit = async e => {
		e.preventDefault();
		const { actions, payment } = this.props;
		const { form, loader, loaderResend } = this.state;

		if (loader || loaderResend) return;

		const errors = validator.all(form, PaymentSchema.phone);

		if (errors) {
			this.setState({ errors: { ...this.state.errors, ...errors } });
			return;
		}

		this.setState({ loader: true });

		const response = await actions.payment.phone(payment.id, form);

		const update = { loader: false };

		if (response && response.errors) {
			update.errors = {
				...this.state.errors,
				...response.errors,
			};

			if (response.messages) update.messages = response.messages;
		}

		this.setState(update);
	}

	componentDidMount() {
		const ac = new AbortController();
		if ('OTPCredential' in window) {

			// Invoke the Web OTP API
			navigator.credentials.get({
				otp: { transport: ['sms'] },
				signal: ac.signal,
			}).then(otp => {
				this.setState(prevState => ({ form: { ...prevState.form, code: otp.code } }));
				setTimeout(() => {
					this.onSubmit();
				}, 0);

				ac.abort();
				// Automatically submit the form when an OTP is obtained.
			}).catch(err => {
				console.log(err);
				ac.abort();
			});
		}
		this.onMount();

	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	onMount = () => this.props.actions.country.fetch()

	onResend = async () => {
		const { actions, payment } = this.props;
		const { loaderResend, disabledResend } = this.state;

		if (loaderResend || disabledResend) return;

		this.setState({ loaderResend: true });

		await actions.payment.code(payment.id);

		this.onDisable();
	}

	onDisable = () => {
		this.setState({ loaderResend: false, disabledResend: true });

		this.timeout = setTimeout(() =>
			this.setState({ disabledResend: false }), disableTime);
	}

	onRefresh = () => {
		const { actions, payment } = this.props;
		actions.payment.get(payment.id);
	}

	onChange = (value, name) => {
		const { form, errors } = this.state;

		let messages = [];

		if (errors[name].length) {
			messages = validator.single({
				...form,
				[name]: value,
			}, name, PaymentSchema.phone);
		}

		this.setState({
			loader: false,
			form: {
				...form,
				[name]: value,
			},
			errors: {
				...errors,
				[name]: messages,
			},
		});
	}



	render() {
		const { form, errors, loader, loaderResend, disabledResend } = this.state;
		const { user, userPhone, intl, hmacEnabled } = this.props;

		const t = intl.formatMessage;


		return (
			<div>
				<Title
					title={t(M.title)}
					legend={t(M.legend)}
					className="PaymentCreateScreen__Title"
				/>
				<Form onSubmit={this.onSubmit}>
					{!hmacEnabled ?
						<React.Fragment>
							<Form.Group>
								<Input
									name="phoneCountry"
									placeholder={t(M.inputs.phoneCountry)}
									value={`${userPhone}`}
									disabled
								/>
								<Input
									name="phone"
									placeholder={t(M.inputs.phone)}
									value={user.phone}
									disabled
								/>
							</Form.Group>
							<Form.Group>
								<Input
									name="code"
									placeholder={t(M.inputs.code)}
									onChange={this.onChange}
									value={form.code}
									errors={errors.code}
									otp
								/>
							</Form.Group>
						</React.Fragment>
						:
						<Label>{t(M.hmac)}</Label>
					}

					<Button
						className="PaymentCreateScreen__Submit"
						onClick={this.onResend}
						loading={loaderResend}
						disabled={loader || disabledResend}
						type="div"
						form
						small
						secondary
					>
						{disabledResend && <Timer time={15} />}
						{!disabledResend && t(M.buttons.resend)}
					</Button>
					{!hmacEnabled ?
						<Button
							className="PaymentCreateScreen__Submit"
							loading={loader}
							disabled={loaderResend}
							form
							small
						>
							{t(M.buttons.pay)}
						</Button>
						:
						<Button
							className="PaymentCreateScreen__Submit"
							loading={loader}
							disabled={loaderResend}
							onClick={this.onRefresh}
							form
							small
						>
							{t(M.buttons.next)}
						</Button>
					}
				</Form>
			</div>
		);
	}
}
