import React from 'react';
import Title from 'components/Title';
import { NavLink } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { injectIntl } from 'react-intl';
import TwoFactorAuthenticationApp from '../TwoFactorAuthenticationApp';
import M from './Security.locale.json';

const CheckSectionSecurity = ({ currentSection, t }) => {
	switch (currentSection) {
		case 'two-factor-app': {
			return <TwoFactorAuthenticationApp />;
		}

		default:
			return (
				<div className='Security'>
					<Title title={t(M.title)} />
					<NavLink className='Security__Link' to={`/settings/security/two-factor-app`}>
						{t(M.twoFactor)}
					</NavLink>
				</div>
			);
	}
};

const Security = props => {
	const { params: { block } } = useRouteMatch();
	const t = props.intl.formatMessage;
	
	return (
		<div>
			<CheckSectionSecurity currentSection={block} t={t} />
		</div>
	);
};


export default injectIntl(Security);
