/* eslint-disable react/prop-types */
import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Title from 'components/Title';
import Button from 'components/Button';
import Container from 'components/Container';
import SuccessIcon from '../../assets/exchange/exchSuccess.png';
import M from '../../Exchange.locale.json';

const SwapSuccess = ({ intl: { formatMessage: t } }) => {
	const history = useHistory();
	return (
		<div className="Exchange__Success">
			<Container small>
				<Title title={t(M.exchange.title)} />
				<div className="Exchange__Success__Wrapper">
					<h3>{t(M.exchangeSuccess.subtitle)}</h3>
					<img src={SuccessIcon} alt="" width={96} />
				</div>
				<Button
					onClick={() => {
						history.push('/exchange');
					}}
				>
					{t(M.exchangeSuccess.button)}
				</Button>
			</Container>
		</div>
	);
};

export default injectIntl(SwapSuccess);
